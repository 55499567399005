import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdClear } from 'react-icons/md';
import { PalmettoDatePicker } from "@zawarski/palmetto-ui-components";
import { NormalTextField} from "../../styled-components/TextFields/TextFields.js";
import moment from 'moment';
import { NormalDropdown } from "../../styled-components/Dropdowns/Dropdown.js";
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { CardComponent2R1C } from '../../layout/CardComponent';
import { PrimaryButton, HeaderButton } from "../../styled-components/Buttons/Buttons";

import { FileUploadDialog } from '../../common/FiileUploadDialog';
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { submitMonitoringSiteVisitReview } from '../../../redux/actions'

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear,
        currentMonitoringSiteVisitReview: state.rootReducer.currentMonitoringSiteVisitReview,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitMonitoringSiteVisitReview: (payload, callback) => dispatch(submitMonitoringSiteVisitReview(payload, callback)),
    }
}


const DropdownMenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
    },
    PaperProps: {
        style: {
            minWidth: "200px",
        },
    },
};


class MonitoringSiteVisitReviewEditPage extends React.Component {

    state = {

        gaOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.gaOnFile) || '',
        gaSupportingDocumentationOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.gaSupportingDocumentationOnFile) || '',
        gaComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.gaComments) || '',
    
        personnelFunded: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.personnelFunded) || '',
        personnelDocumentationOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.personnelDocumentationOnFile) || '',
        personnelRecordsOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.personnelRecordsOnFile) || '',
        personnelComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.personnelComments) || '',
    
        contractualServiceOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.contractualServiceOnFile) || '',
        contractualServiceExecutedContractsOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.contractualServiceExecutedContractsOnFile) || '',
        contractualServiceComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.contractualServiceComments) || '',
    
        travelOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.travelOnFile) || '',
        travelDocumentationOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.travelDocumentationOnFile) || '',
        travelComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.travelComments) || '',
    
        equipmentProperlySheltered: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.equipmentProperlySheltered) || '',
        equipmentTransferred: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.equipmentTransferred) || '',
        equipmentComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.equipmentComments) || '',
    
        suppliesOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.suppliesOnFile) || '',
        suppliesTransferred: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.suppliesTransferred) || '',
        suppliesComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.suppliesComments) || '',
    
        otherOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.otherOnFile) || '',
        otherComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.otherComments) || '',
    
        summaryComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.summaryComments) || '',
        monitoringReportSubmittedBy: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.monitoringReportSubmittedBy) || '',
        monitoringReportSubmittedAt: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.monitoringReportSubmittedAt) || '',
    
        scemdPOCReviewedBy: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.scemdPOCReviewedBy) || '',
        scemdPOCReviewedAt: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.scemdPOCReviewedAt) || '',      

        monitoringReportSubmittedAtDisplay: '',
        scemdPOCReviewedAtDisplay: '',
        
        uploadedFiles: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.monitoringFiles && this.props.currentMonitoringSiteVisitReview.monitoringFiles.items) || []
    }

    gaInputs= [
        {
            type: '',
            title: '',
            inputs: [
                {
                    label: 'Grant application on file?',
                    field: 'gaOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Are the quarterly reimbursements and supporting documentation on file?',
                    field: 'gaSupportingDocumentationOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'gaComments',
                    type: 'comments',
                },
        
            ],
        },
    ]

    categories= [
        {
            type: 'personnel',
            title: '1. Personnel',
            inputs: [
                {
                    label: 'Are personnel funded under this grant?',
                    field: 'personnelFunded',
                    type: 'dropdown',
                },
                {
                    label: 'Are personnel timesheets, attendance records, activity reports, and payroll records in the grant file and available for review?',
                    field: 'personnelDocumentationOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Are personnel training and exercise records in the grant file?',
                    field: 'personnelRecordsOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'personnelComments',
                    type: 'comments',
                },
        
            ],
        },
        {
            type: 'contractualServices',
            title: '2. Contractual Services',
            inputs: [
                {
                    label: 'Are there contractual services?',
                    field: 'contractualServiceOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'If so, are there executed contracts on file?',
                    field: 'contractualServiceExecutedContractsOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'contractualServiceComments',
                    type: 'comments',
                },
            ],
        },
        {
            type: 'travel',
            title: '3. Travel',
            inputs: [
                {
                    label: 'Was travel conducted?',
                    field: 'travelOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Are travel expenditures documented with expense reports and/or detailed receipts?',
                    field: 'travelDocumentationOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'travelComments',
                    type: 'comments',
                },
            ],
        },
        {
            type: 'equipment',
            title: '4. Equipment',
            inputs: [
                {
                    label: 'Is the equipment properly sheltered from the elements, safeguarded against theft and vandalism, and protected to ensure operational capability and effectiveness?',
                    field: 'equipmentProperlySheltered',
                    type: 'dropdown',
                },
                {
                    label: 'Has grant funded equipment been transferred to other agencies?',
                    field: 'equipmentTransferred',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'equipmentComments',
                    type: 'comments',
                },
            ],
        },
        {
            type: 'supplies',
            title: '5. Supplies',
            inputs: [
                {
                    label: 'Have supplies been purchased with this grant?',
                    field: 'suppliesOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Has grant funded supplies been transferred to other agencies?',
                    field: 'suppliesTransferred',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'suppliesComments',
                    type: 'comments',
                },
            ],
        },
        {
            type: 'other',
            title: '6. Other',
            inputs: [
                {
                    label: 'Have other purchases been made with this grant?',
                    field: 'otherOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'otherComments',
                    type: 'comments',
                },
            ],
        },
    ]



    constructor(props, context) {
        super(props, context);

        if (this.props.currentMonitoringSiteVisitReview) {
            this.state.scemdPOCReviewedAtDisplay = this.props.currentMonitoringSiteVisitReview.scemdPOCReviewedAt && (new Date(this.toUTC(new Date(this.props.currentMonitoringSiteVisitReview.scemdPOCReviewedAt))));

            this.state.monitoringReportSubmittedAtDisplay = this.props.currentMonitoringSiteVisitReview.monitoringReportSubmittedAt && (new Date(this.toUTC(new Date(this.props.currentMonitoringSiteVisitReview.monitoringReportSubmittedAt))));
        }
    }

    toUTC = (date) => {
        var newDate = new Date();
        newDate.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        return newDate;
    }
    
    handleDateChange = (date, field) => {
        this.state[field] = moment(date).format("YYYY-MM-DD");
        this.setState({ [field]: this.state[field] });
        this.state[field+'Display'] = new Date(date);
        this.setState({ [field+'Display']: this.state[field+'Display'] });
    }
    

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }        


    saveImg = (payload) => {
        this.state.uploadedFiles = this.state.uploadedFiles.concat(payload);
        this.setState({ uploadedFiles: this.state.uploadedFiles });
    }


    getProperKeyName = (key) => {
        if(key) {
            return key.substring(key.indexOf("__") + 2, key.length)
        }

        return key;
    }

    bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };

    /**
     * 
    */

    deleteFile = (e, file) => {
        this.state.uploadedFiles.forEach( (uploadedFile, index) => {
            if(file.key === uploadedFile.key) {
                this.state.uploadedFiles[index].deleted = true;
            }
        })

        this.setState({ uploadedFiles: this.state.uploadedFiles });
        // this.props.fileToDelete && this.props.fileToDelete(file, this.props.field);
        e.preventDefault();
        e.stopPropagation();
    }

    removeDeletedFiles = (files) => {

        let arr = []

        files.forEach((file, index) => {
            if (!file.deleted) {
                arr.push(file)
            }
        })

        return arr;
    }    

    save = () => {
        let payload = {
            ...this.state
        }

        this.props.submitMonitoringSiteVisitReview(payload, () => {this.props.history.goBack()})
    }


    submit = () => {
        let payload = {
            submit: true,
            ...this.state
        }

        this.props.submitMonitoringSiteVisitReview(payload, () => {this.props.history.goBack()})
    }

    submitWithIssues = () => {
        let payload = {
            submitWithIssues: true,
            ...this.state
        }

        this.props.submitMonitoringSiteVisitReview(payload, () => { this.props.history.goBack()})
    }

    goBack = () => {
        this.props.history.goBack()
    }


    render() {
        return (


            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdClear className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Monitoring</span>}
                    titleSmall={<span>Site Visit Review - LEMPG {this.props.selectedYear}</span>}
                    suffix={<HeaderButton onClick={this.save}>Save</HeaderButton>}
                />



                <div className="flex container-overflow-y" style={{ padding: '10px'}}>

                <div className='layout horizontal center section-title form-margin'>Grant Application</div>
                    {
                        this.gaInputs.map((input) => {
                            return (
                                <>
                                    {
                                        input.inputs.map((item) => {
                                            return (
                                                <>
                                                    {
                                                        item.type === 'dropdown' ?
                                                        <>
                                                            <div className="layout horizontal">
                                                                <FormControl className="flex">
                                                                    <InputLabel style={{ margin: "0 6px" }} shrink htmlFor={`${item.field}`}> {item.label} </InputLabel>
                                                                    <NormalDropdown
                                                                        value={this.state[item.field]}
                                                                        onChange={this.handleChange(item.field)}
                                                                        input={<Input name={`${item.field}`} id={`${item.field}`} />}
                                                                        MenuProps={DropdownMenuProps}
                                                                        custommargin="true"
                                                                        displayEmpty
                                                                        name={`${item.field}`}
                                                                        className="required-dropdown"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <span className="placeholder">Select answer</span>
                                                                        </MenuItem>
                                                                        <MenuItem value="Yes">Yes</MenuItem>
                                                                        <MenuItem value="No">No</MenuItem>
                                                                    </NormalDropdown>
                                                                </FormControl>

                                                            </div>

                                                            <div className='height-10'></div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="layout horizontal wrap">
                                                                <NormalTextField value={this.state[item.field]} multiline onChange={this.handleChange(item.field)} id={`${item.field}`} placeholder="Enter comments"  label="Comments" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </>
            
                            )                            
                        })
                    }


                    <div className='height-10'></div>
                    <div className='layout horizontal center section-title form-margin'>Grant budget categories</div>
                    {
                        this.categories.map((input) => {
                            return (
                                <>
                                    <div className='height-10'></div>
                                    <div className='layout horizontal center section-title form-margin' style={{ opacity: 0.54}}>{input.title}</div>
                                    {
                                        input.inputs.map((item) => {
                                            return (
                                                <>
                                                    {
                                                        item.type === 'dropdown' ?
                                                        <>
                                                            <div className="layout horizontal">
                                                                <FormControl className="flex">
                                                                    <InputLabel style={{ margin: "0 6px" }} shrink htmlFor={`${item.field}`}> {item.label} </InputLabel>
                                                                    <NormalDropdown
                                                                        value={this.state[item.field]}
                                                                        onChange={this.handleChange(item.field)}
                                                                        input={<Input name={`${item.field}`} id={`${item.field}`} />}
                                                                        MenuProps={DropdownMenuProps}
                                                                        custommargin="true"
                                                                        displayEmpty
                                                                        name={`${item.field}`}
                                                                        className="required-dropdown"
                                                                    >
                                                                        <MenuItem value="">
                                                                            <span className="placeholder">Select answer</span>
                                                                        </MenuItem>
                                                                        <MenuItem value="Yes">Yes</MenuItem>
                                                                        <MenuItem value="No">No</MenuItem>
                                                                    </NormalDropdown>
                                                                </FormControl>

                                                            </div>

                                                            <div className='height-10'></div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="layout horizontal wrap">
                                                                <NormalTextField value={this.state[item.field]} multiline onChange={this.handleChange(item.field)} id={`${item.field}`} placeholder="Enter comments"  label="Comments" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                                                            </div>
                                                        </>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </>
            
                            )                            
                        })
                    }


                    <div className='height-10'></div>
                    <div className='layout horizontal center section-title form-margin'>Summary</div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.summaryComments} multiline onChange={this.handleChange('summaryComments')} id={`${'summaryComments'}`} placeholder="Enter comments"  label="Comments" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                    </div>
                    <div className='height-10'></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.monitoringReportSubmittedBy}  onChange={this.handleChange('monitoringReportSubmittedBy')} id={`${'monitoringReportSubmittedBy'}`}  placeholder="Enter name"  label="Monitoring report submitted by" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                        <div className="layout horizontal flex" style={{minWidth: 250, margin: '0 6px'}}>
                                <PalmettoDatePicker
                                    id="mui-pickers-date"
                                    label="Date"
                                    placeholder="Select date"
                                    className="flex"
                                    value={this.state.monitoringReportSubmittedAtDisplay}
                                    onChange={(e) => this.handleDateChange(e, 'monitoringReportSubmittedAt')}
                                    autoOk={true}
                                    inputFormat='MMM dd, yyyy'
                                    invalidDateMessage=""
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                        </div>                 
                    </div>

                    {/* <div className='height-10'></div> */}

                    {/* <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.scemdPOCReviewedBy}  onChange={this.handleChange('scemdPOCReviewedBy')} id={`${'scemdPOCReviewedBy'}`}  placeholder="Enter name"  label="SCEMD Admin/Finance POC reviewed by" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />

                        <div className="layout horizontal flex" style={{minWidth: 250, margin: '0 6px'}}>
                               <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <PalmettoDatePicker
                                    id="mui-pickers-date"
                                    label="Date"
                                    placeholder="Select date"
                                    className="flex"
                                    value={this.state.scemdPOCReviewedAtDisplay}
                                    onChange={(e) => this.handleDateChange(e, 'scemdPOCReviewedAt')}
                                    autoOk={true}
                                    format='MMM dd, yyyy'
                                    invalidDateMessage=""
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </LocalizationProvider>
                        </div>                 
                    </div> */}

                    <div className='height-20'></div>

                    <div className='layout horizontal center section-title'>Photos</div>
                    
                    {/* Show files */}

                    <div className='layout horizontal wrap'>
                        {
                            this.removeDeletedFiles(this.state.uploadedFiles).map((item) => {
                                return (
                                    <div className="upload-card-margin" key={item.key}>
                                        <a target="_blank" title={item.downloadTitle || item.title}  href={item.downloadLink}>
                                            <CardComponent2R1C 
                                                className="set-width"
                                                title={<span className="truncate">{item.downloadTitle || item.title}</span>}
                                                title2={<span className="opacity-54">{this.bytesToSize(item.size)}</span>}
                                                suffix={

                                                    this.props.noEdit ? "" :
                                                    <IconButton
                                                        aria-label="Delete"
                                                        aria-haspopup="true"
                                                        onClick={(e) => this.deleteFile(e, item)}
                                                    >
                                                        <MdClear className="icon-2" />
                                                    </IconButton>
                                                }
                                            />
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </div>


                    <div className='form-margin'>
                        <FileUploadDialog callback={this.saveImg} />
                    </div>


                    <div className='height-20'></div>

                    <div className="layout horizontal center-justified form-container wrap center" style={{ borderTop: "1px solid #E0E0E0", paddingBottom: 0 }}>
                        <PrimaryButton setwidth="true" onClick={() => this.submit()}>
                            Submit
                        </PrimaryButton>
                        <div style={{ width: 8}}></div>
                        <PrimaryButton setwidth="true" onClick={() => this.submitWithIssues()}>
                            Submit with issues
                        </PrimaryButton>
                    </div>

                </div>
            </div>
        )
    }
}

// export default MonitoringSiteVisitReviewEditPage;
export default connect(mapStateToProps, mapDispatchToProps)(MonitoringSiteVisitReviewEditPage);
