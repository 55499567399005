import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdArrowBack, MdMoreVert } from 'react-icons/md';
import { NormalTextField} from "../../styled-components/TextFields/TextFields.js";
import { HeaderButton } from "../../styled-components/Buttons/Buttons";
import moment from 'moment';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { printMonitoringForm } from '../../../redux/actions/index';

// js
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear,
        selectedCounty: state.rootReducer.selectedCounty,
        currentMonitoringGrantInformationPage: state.rootReducer.currentMonitoringGrantInformationPage,
        currentMonitoringSiteVisitReview: state.rootReducer.currentMonitoringSiteVisitReview,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        printMonitoringForm: () => dispatch(printMonitoringForm()),
    }
}

class MonitoringGrantInformationPage extends React.Component {

    state = {
        monitoringType: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.monitoringType) || '',
        monitoringDate: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.monitoringDate) || '',
        subRecipientAgency: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.subRecipientAgency) || '',
        grantProjectTitle: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.grantProjectTitle) || '',
        grantNumber: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.grantNumber) || '',
    
        approvedGrantStartDate: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.approvedGrantStartDate) || '',
        approvedGrantEndDate: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.approvedGrantEndDate) || '',
        extensionEndDate: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.extensionEndDate) || '',
    
        name: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.name) || '',
        title: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.title) || '',
    
        projectDirectorName: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.projectDirectorName) || '',
        projectDirectorTitle: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.projectDirectorTitle) || '',
        
        personnelName: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.personnelName) || '',
        personnelTitle: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.personnelTitle) || '', 
        anchorEl: null,
       
    }

    constructor(props, context) {
        super(props, context);

        if (this.props.currentMonitoringGrantInformationPage) {
            this.state.monitoringDateDisplay = this.props.currentMonitoringGrantInformationPage.monitoringDate && (moment(this.toUTC(new Date(this.props.currentMonitoringGrantInformationPage.monitoringDate))).format('MM-DD-YYYY'));

            this.state.extensionEndDateDisplay = this.props.currentMonitoringGrantInformationPage.extensionEndDate && (moment(this.toUTC(new Date(this.props.currentMonitoringGrantInformationPage.extensionEndDate))).format('MM-DD-YYYY'));
        }

        this.state.grantNumber = `${this.props.selectedYear} LEMPG01`;
        this.state.grantProjectTitle = `Local EMPG`;
        this.state.name = `${(this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.monitoringReportSubmittedBy) || ''}`;
        this.state.title = `REM Region ${this.props.selectedCounty && this.props.selectedCounty.region && this.props.selectedCounty.region.split('').reverse()[0] }`

        this.state.approvedGrantStartDate = this.getYearStartEndDates(this.props.selectedYear).start;
        this.state.approvedGrantEndDate = this.getYearStartEndDates(this.props.selectedYear).end;

        this.state.approvedGrantStartDateDisplay = moment(this.state.approvedGrantStartDate).format("MM-DD-YYYY")

        this.state.approvedGrantEndDateDisplay = moment(this.state.approvedGrantEndDate).format("MM-DD-YYYY")

    }
    toUTC = (date) => {
        var newDate = new Date();
        newDate.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        return newDate;
    }
    

    getYearStartEndDates = (year) => {
        if (!year) return
    
        var obj = {}
        obj.quarter1 = { start: moment(`01-01-${year}`, "MM-DD-YYYY").month(6).startOf('month').format("MM-DD-YYYY"), end: moment(`01-01-${year}`, "MM-DD-YYYY").month(8).endOf('month').toDate()}
        obj.quarter2 = { start: moment(`01-01-${year}`, "MM-DD-YYYY").month(9).startOf('month').format("MM-DD-YYYY"), end: moment(`01-01-${year}`, "MM-DD-YYYY").month(11).endOf('month').toDate()}
        obj.quarter3 = { start: moment(`01-01-${year}`, "MM-DD-YYYY").month(0).startOf('month').add('years', 1).format("MM-DD-YYYY"), end: moment(`01-01-${year}`, "MM-DD-YYYY").month(2).endOf('month').add('years', 1).toDate()}
        obj.quarter4 = { start: moment(`01-01-${year}`, "MM-DD-YYYY").month(3).startOf('month').add('years', 1).format("MM-DD-YYYY"), end: moment(`01-01-${year}`, "MM-DD-YYYY").month(5).endOf('month').add('years', 1).toDate()}
    
        return {start: obj.quarter1.start, end: obj.quarter4.end};
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    goBack = () => {
        this.props.history.goBack()
    }

    openEditPage = () => {
        this.props.history.push('/monitoring/county/grantinformation/edit')
    }

    printMonitoringForm = () => {
        this.setState({ anchorEl: null });
        this.props.printMonitoringForm();

    }


    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (


            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Monitoring</span>}
                    titleSmall={<span>LEMPG {this.props.selectedYear}</span>}
                    suffix={
                        <div className='layout horizontal'>

                            {/* {
                                this.props.currentMonitoringGrantInformationPage &&
                                (this.props.currentMonitoringGrantInformationPage.status === 'SUBMITTED' || this.props.currentMonitoringGrantInformationPage.status === 'SUBMITTED_WITH_ISSUES') ? '' : <HeaderButton onClick={this.openEditPage}>Edit</HeaderButton>
                            }
                            */}
                            <HeaderButton onClick={this.openEditPage}>Edit</HeaderButton>
                            
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'application-menu' : undefined}
                                onClick={this.handleIconDropdownClick}
                            >
                                <MdMoreVert className="icon" />
                            </IconButton>

                            <Menu
                                id="application-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                <MenuItem key="print" onClick={() => this.printMonitoringForm()}>
                                    Print Monitoring Form
                                </MenuItem>
                            </Menu>                            
                        </div>
                    }
                />

                <div className="flex container-overflow-y" style={{ padding: 10 }}>

                    <div className='height-10'></div>

                    <div className="layout horizontal wrap">
                        {/* Add monitoring type input */}
                        <NormalTextField value={this.state.monitoringType}  id="monitoringType" multiline placeholder=" "  label="Monitoring Type" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />

                        {/* Add read only date input */}
                        <NormalTextField value={this.state.monitoringDateDisplay}  id="monitoringDate" multiline placeholder=" "  label="Monitoring date" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />

                    </div>

                    <div className='height-10'></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.subRecipientAgency}  id="subRecipientAgency" multiline placeholder=" "  label="Sub-recipient agency" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />

                        <NormalTextField value={this.state.grantProjectTitle}  id="grantProjectTitle" multiline placeholder=" "  label="Grant project title" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className='height-10'></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.grantNumber}  id="grantNumber" multiline placeholder="Enter number"  label="Grant number" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className='height-10'></div>

                    <div className='layout horizontal center section-title form-margin'> Approved Grant Performance Period </div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.approvedGrantStartDateDisplay}  id="approvedGrantStartDate" multiline placeholder=" "  label="Start date" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />

                        <NormalTextField value={this.state.approvedGrantEndDateDisplay}  id="approvedGrantEndDate" multiline placeholder=" "  label="End Date" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className='height-10'></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.extensionEndDateDisplay}  id="extensionEndDateDisplay" multiline placeholder=" "  label="Extension end date" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className='height-10'></div>

                    <div className='layout horizontal center section-title form-margin'> Emergency management program staff </div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.name}  id="name" multiline placeholder=" "  label="Name" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />

                        <NormalTextField value={this.state.title}  id="title" multiline placeholder=" "  label="Title" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className='height-10'></div>

                    <div className='layout horizontal center section-title form-margin'> Sub-grantee program personnel interviewed </div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.projectDirectorName}  id="projectDirectorName" multiline placeholder=" "  label="Project director name" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />

                        <NormalTextField value={this.state.projectDirectorTitle}  id="projectDirectorTitle" multiline placeholder=" "  label="Title" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className='height-10'></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.personnelName}  id="personnelName" multiline placeholder=" "  label="Personnel name" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />

                        <NormalTextField value={this.state.personnelTitle}  id="personnelTitle" multiline placeholder=" "  label="Title" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>


                </div>
            </div>
        )
    }
}

// export default MonitoringGrantInformationPage;
export default connect(mapStateToProps, mapDispatchToProps)(MonitoringGrantInformationPage);
