import React from 'react';
import IconButton from "@mui/material/IconButton";
import { MdMenu } from 'react-icons/md';
import ReactD3Gauge from 'react-d3-gauge';

import { HeaderComponent, DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import { connect } from 'react-redux'

import { CardComponentExecSummaryCard } from "../layout/CardComponent";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        counties: state.rootReducer.counties,
        selectedCounty: state.rootReducer.selectedCounty,
        selectedYear: state.rootReducer.selectedYear
    };
};

class SplashScreen extends React.Component {

    constructor(props, context) {
        super(props, context);
console.log("SPLASHSCREEN");
        this.calcInProgressApplications = this.calcInProgressApplications.bind(this);
        this.calcSubmittedApplications = this.calcSubmittedApplications.bind(this);
        this.calcAwardedApplications = this.calcAwardedApplications.bind(this);
        this.calcCompletedApplications = this.calcCompletedApplications.bind(this);
        this.calcGaugePercent = this.calcGaugePercent.bind(this);
    }

    calcInProgressApplications() {
        var count = 0;
        this.props.counties.forEach(county => {
            var a = county.grantapplication && county.grantapplication.items[0] && county.grantapplication.items[0].status === "DRAFT" ? (count++) : null;
        });

        return count;
    }

    calcSubmittedApplications() {
        var count = 0;
        this.props.counties.forEach(county => {
            var a = county.grantapplication && county.grantapplication.items[0] && county.grantapplication.items[0].status === "SUBMITTED" ? (count++) : null;
        });

        return count;

    }
    calcApprovedApplications() {
        var count = 0;
        this.props.counties.forEach(county => {
            var a = county.grantapplication && county.grantapplication.items[0] && county.grantapplication.items[0].status === "APPROVED" ? (count++) : null;
        });

        return count;

    }

    calcAwardedApplications() {
        var count = 0;
        this.props.counties.forEach(county => {
            var a = county.grantapplication && county.grantapplication.items[0] && (county.grantapplication.items[0].status === "AWARDED") && !(county.grantapplication.items[0].awardDocumentsUploadedByCounty && county.grantapplication.items[0].awardDocumentsUploadedByCounty.id) ? (count++) : null;
        });

        return count;

    }

    calcCompletedApplications() {
        var count = 0;
        this.props.counties.forEach(county => {
            var a = county.grantapplication && county.grantapplication.items[0] && (county.grantapplication.items[0].status === "AWARDED") && (county.grantapplication.items[0].awardDocumentsUploadedByCounty && county.grantapplication.items[0].awardDocumentsUploadedByCounty.id) ? (count++) : null;
        });

        return count;
    }

    calcGaugePercent(selectedCounty) {
        if (selectedCounty && selectedCounty.grantapplication && selectedCounty.grantapplication.items[0]) {
            var status = selectedCounty.grantapplication.items[0].status;
            return status === "AWARDED" ? 85 : (status === "APPROVED" ? 60 : (status === "SUBMITTED" ? 40 : 15));
        }

        return 0
    }

    calcGaugeTitle(selectedCounty) {
        if (selectedCounty && selectedCounty.grantapplication && selectedCounty.grantapplication.items[0]) {
            var status = selectedCounty.grantapplication.items[0].status;
            return status === "AWARDED" ? "Awarded" : (status === "SUBMITTED" ? "Submitted" : status === "APPROVED" ? "Approved" : "Draft");
        }

        return "Draft";
    }

    calcGaugeDescription(selectedCounty) {
        if (selectedCounty && selectedCounty.grantapplication && selectedCounty.grantapplication.items[0]) {
            var status = selectedCounty.grantapplication.items[0].status;

            switch (status) {
                case 'AWARDED':
                    return "The grant application has been awarded."
                case 'APPROVED':
                    return "The grant application has been approved."
                case 'SUBMITTED':
                    return "The  grant application has been submitted and awaiting review by the State."
                case 'DRAFT':
                    return ""
            }

        }

    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    render() {

        const inProgressComp = (
            <span>{this.calcInProgressApplications()}</span>
        )

        const submittedComp = (
            <span>{this.calcSubmittedApplications()}</span>
        )

        const approvedComp = (
            <span>{this.calcApprovedApplications()}</span>
        )

        const awardedComp = (
            <span>{this.calcAwardedApplications()}</span>
        )

        const completedComp = (
            <span>{this.calcCompletedApplications()}</span>
        )

        const lempgStateUser = (
            <>
                <div className="layout horizontal section-title end form-margin-16">Grant Status</div>
                {/* <div className="height-20"></div> */}

                <div className="layout horizontal around-justified wrap">

                    <CardComponentExecSummaryCard
                        className="flex margin-16"
                        cardText={inProgressComp}
                        cardTitle={<span>DRAFT</span>}
                        cardClass="card-grey-1"
                    />

                    <CardComponentExecSummaryCard
                        className="flex margin-16"
                        cardText={submittedComp}
                        cardTitle={<span>SUBMITTED</span>}
                        cardClass="card-submitted"
                    />

                    <CardComponentExecSummaryCard
                        className="flex margin-16"
                        cardText={approvedComp}
                        cardTitle={<span>APPROVED</span>}
                        cardClass="card-in-progress"
                    />

                    <CardComponentExecSummaryCard
                        className="flex margin-16"
                        cardText={awardedComp}
                        cardTitle={<span>AWARDED</span>}
                        cardClass="card-awarded"
                    />

                    <CardComponentExecSummaryCard
                        className="flex margin-16"
                        cardText={completedComp}
                        cardTitle={<span>COMPLETE</span>}
                        cardClass="card-complete"
                    />

                </div>

            </>
        );

        const lempgCountyUser = (
            <div className="layout vertical full-height vertical-center react-3d-gauge">

                <ReactD3Gauge
                    needleColor="black"
                    colors={['var(--status-grey-1)', 'var(--status-yellow-2)', 'var(--status-green-2)', 'var(--status-green-1)']}
                    width={380}
                    percent={this.calcGaugePercent(this.props.selectedCounty)}
                />

                <div className="height-20"></div>
                <div className="height-20"></div>

                <span className="application-status-title">{this.calcGaugeTitle(this.props.selectedCounty)}</span>

                <div className="height-20"></div>

                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">{this.calcGaugeDescription(this.props.selectedCounty)}</span>

            </div>
        );

        return (

            <div className="layout vertical full-height splash-screen">

                <HeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    title={<span>LEMPG {this.props.selectedYear}</span>}
                />

                <div className="flex container-overflow-y">
                    {
                        this.props.lempgUserType
                            ?
                            (this.props.lempgUserType === "LEMPG_ACCESS" ?
                                lempgCountyUser : lempgStateUser)
                            : ""
                    }

                </div>

            </div>

        )
    }
}

export default connect(mapStateToProps, null)(SplashScreen);