import React from 'react';
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

// Local imports

// Component imports
import NarrativesStatePage from "./NarrativesStatePage.js";
// import NarrativesAddEntry from "./NarrativesAddEntry.js";
import REMContactHome from './REMContactHome';
import REMContactEditPage from './REMContactEditPage';
import NarrativesEditpage from "./NarrativesEditpage";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        loading: state.rootReducer.loading,
        currentNarrative: state.rootReducer.currentNarrative,
        // currentGroupNarrativeM2M: state.rootReducer.currentGroupNarrativeM2M,
        lempgUserType: state.rootReducer.lempgUserType,

    }
};

class NarrativesHome extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    componentDidUpdate() { }

    render() {
        return (
            <Switch>
                <Route
                    exact
                    path="/narratives/"
                    component={(props) =>
                        this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading narrative. 1Please wait</span>
                            </div>
                            : <NarrativesStatePage {...props} />}
                />
                {/* <Route
                    exact
                    path="/narratives/view/"
                    component={(props) => <NarrativesSpecificView {...props} />}
                /> */}
                <Route
                    exact
                    path="/narratives/edit/"
                    component={(props) =>
                        this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading narrative. 2Please wait</span>
                            </div>
                            : <NarrativesEditpage {...props} />}
                />
                <Route
                    exact
                    path="/narratives/new/"
                    component={(props) =>
                        this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading narrative. 3Please wait</span>
                            </div>
                            : <NarrativesEditpage {...props} />}
                />
                <Route
                    exact
                    path="/narratives/rem/"
                    component={(props) =>
                        this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading REM Contacts page. Please wait</span>
                            </div>
                            : <REMContactHome {...props} />}
                />                

                <Route
                    exact
                    path="/narratives/rem/new"
                    component={(props) =>
                        this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading narrative. 4Please wait</span>
                            </div>
                            : <REMContactEditPage {...props} />}
                />                


                <Route
                    exact
                    path="/narratives/rem/edit"
                    component={(props) =>
                        this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading narrative.5 Please wait</span>
                            </div>
                            : <REMContactEditPage {...props} />}
                />

            </Switch>

        )
    }
}

export default connect(mapStateToProps, null)(NarrativesHome);
