import React from 'react';
import { connect } from 'react-redux'
import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import IconButton from "@mui/material/IconButton";
import { MdArrowBack, MdCheckCircle, MdQueryBuilder, MdMoreVert } from 'react-icons/md';
import { getFileUploadedLink } from '../common/FileUploadApi';
import { SecondaryButton, DownloadButton } from "../styled-components/Buttons/Buttons";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//  Redux import
import { printGrantApplicationForm } from "../../redux/actions/index";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        printGrantApplicationForm: (payload) => dispatch(printGrantApplicationForm(payload)),           
    }
}

class ApplicationAwarded extends React.Component {

    state = {
        coverLetterFileLink: "",
        anchorEl: null,
    }

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
        this.printGrantApplicationForm = this.printGrantApplicationForm.bind(this);
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    printGrantApplicationForm() {
        this.handleClose();
        this.props.printGrantApplicationForm(this.props.currentApplicationState)
    }


    goBack() {
        this.props.history.goBack();
    }

    downloadForms = () => {
        this.state.coverLetterFileLink && window.open(this.state.coverLetterFileLink);
    }

    async componentDidMount() {
        let coverLetterFileLink = await getFileUploadedLink(this.props.grantApplication && this.props.grantApplication.awardDocumentsUploadedByCounty);

        
        this.setState({
            coverLetterFileLink: coverLetterFileLink
        })

    }
    
    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        const ApplicationCompletedComponent = () => {
            return (
                <div className="flex layout vertical only-vertical-center container-overflow-y">

                    <div className="layout horizontal center center-justified">
                        <MdCheckCircle className="icon-2 application-status-icon color-green" />
                    </div>
                    <div className="height-20"></div>
                    <span className="layout horizontal center center-justified application-status-title-small">Completed</span>
                    <div className="height-20"></div>

                    <div className="layout horizontal center center-justified">
                        <span style={{ padding: "0 16px", maxWidth: "500px" }} className="text-center title-big opacity-54">County has completed the grant application process. Download signed files below for your record</span>
                    </div>
                    <div className="height-20"></div>


                    <div className="layout horizontal center-justified">
                        <div className="layout vertical award-letters-download-box">

                            <div className="layout horizontal center center-justified award-file">
                                <span className="award-file-title">Award letter (signed)</span>
                                <div className="flex"></div>
                                <DownloadButton onClick={this.downloadForms} nowidth="true">
                                    Download
                                </DownloadButton>
                            </div>
                        </div>
                    </div>

                    {/* {
                        this.props.grantApplication && this.props.grantApplication.awardDocumentsUploadedByCounty ?
                            <SecondaryButton onClick={this.downloadForms} setwidth="true">
                                Download
                            </SecondaryButton>
                            : <span> No documents uploaded yet </span>

                    } */}
                </div>
            )
        }

        const PendingSignatureComponent = () => {
            return (
                <div className="flex layout vertical center vertical-center container-overflow-y">
                    <MdQueryBuilder className="icon-2 application-status-icon" />
                    <div className="height-20"></div>
                    <span className="layout horizontal center center-justified application-status-title-small">Pending Signature</span>
                    <div className="height-20"></div>

                    <span style={{ padding: "0 16px", maxWidth: "280px" }} className="text-center title-big opacity-54">County has been awarded grant. Currently waiting for county to sign and return award letter</span>
                    <div className="height-20"></div>
                </div>
            )
        }

        return (
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Application</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>LEMPG {this.props.selectedYear} • {this.props.grantApplication.group && this.props.grantApplication.group.groupName}</span>}
                    suffix={
                        <div>
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'application-menu' : undefined}
                                onClick={this.handleIconDropdownClick}
                            >
                                <MdMoreVert className="icon" />
                            </IconButton>

                            <Menu
                                id="application-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                <MenuItem key="printApplicationForm" onClick={() => this.printGrantApplicationForm()}>
                                    Print Grant Application Form
                                    </MenuItem>
                            </Menu>
                        </div>

                    }                        
                />

                {
                    this.props.grantApplication.status && (this.props.grantApplication.status === "AWARDED") && this.props.grantApplication.awardDocumentsUploadedByCounty && this.props.grantApplication.awardDocumentsUploadedByCounty.id ?
                    <ApplicationCompletedComponent />
                        : <PendingSignatureComponent />
                }

            </div>


        );
    }
}

export default connect(null, mapDispatchToProps)(ApplicationAwarded);
