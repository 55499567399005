import React from 'react';
import { connect } from 'react-redux'

// Local imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";
import { MdError, MdInfo, MdCheckCircle } from 'react-icons/md';


const mapStateToProps = (state) => {
    return {
        loading: state.rootReducer.loading,
        lempgUserType: state.rootReducer.lempgUserType,
        proposedFiscalYear: state.rootReducer.proposedFiscalYear,
        fiscalYearStatus: state.rootReducer.fiscalYearStatus,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};


class BeginPrefiscalYear extends React.Component {

    state = {

    }

    constructor(props, context) {
        super(props, context);
    }


    render() {
        return (
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    titleBig={<span>Pre-fiscal year</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>LEMPG {this.props.proposedFiscalYear} </span>}
                />

                <div className="flex container-overflow-y" style={{ padding: 16}}>
                    <center style={{ fontSize: 16, opacity: 0.84}}>
                        You're about to start {this.props.proposedFiscalYear} fiscal year process.
                    </center>
                    <div className='height-20'></div>
                    <center style={{ fontSize: 14, opacity: 0.84, margin: '0 32px'}}>
                       <b>Please do not close the window.</b>  If you don't see SUCESSS message after the process is completed please contact your system administrator.
                    </center>

                    <div className='height-20'></div>
                    <center style={{ fontSize: 14, opacity: 0.84, margin: '0 32px'}}>
                       This is a 5-step process. Along the way, we'll list the step that has been completed.
                    </center>

                    <div className='height-20'></div>
                    <div className='height-20'></div>

                    <center>
                        {
                            this.props.fiscalYearStatus.map((item, index) => {
                                if(item.info) {
                                    return (
                                        <>
                                            <div className='layout horizontal center center-justified'>
                                                <MdInfo style={{ color: 'blue', width: 24, height: 24}} />
                                                <div style={{ width: 16}}></div>
                                                <span>{item.message}</span>
                                            </div>
                                            <div className='height-20'></div>
                                        </>
                                    )
                                } else if(item.success) {
                                    return (
                                        <>
                                            <div className='layout horizontal center center-justified'>
                                                <MdCheckCircle style={{ color: 'green', width: 24, height: 24}} />
                                                <div style={{ width: 16}}></div>
                                                <span>{item.message}</span>
                                            </div>
                                            <div className='height-20'></div>
                                        </>
                                    )
                                } else if(item.error) {
                                    return (
                                        <>
                                            <div className='layout horizontal center center-justified'>
                                                <MdError style={{ color: 'red', width: 24, height: 24}} />
                                                <div style={{ width: 16}}></div>
                                                <span>{item.message}</span>
                                            </div>
                                            <div className='height-20'></div>
                                        </>
                                    )
                                }
                            })
                        }
                    </center>

                </div>
            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeginPrefiscalYear);
