import React from 'react';
import moment from 'moment';
import { ProgressBarMulti } from "./Charts";

import "../../../styles/common.css";
import "../../../styles/common.charts.css";

// Parent here is the CountySplashScreen, which passes on the selectedYear
class CountyPreAwardProgress extends React.Component {

    state = {
    }


    constructor(props, context) {
        super(props, context);
    }

    calcProgressBarValue = (counties) => {
        var count = 0;
        counties.forEach(county => {
            if(county.awardAmount && (+county.awardAmount) > 0) count++
        });

        return Math.floor((count/counties.length) * 100)
    }

    calcProgressBarSuffix = (counties) => {
        var count = 0;
        counties.forEach(county => {
            if (county.awardAmount && (+county.awardAmount) > 0) count++
        });

        return count + " of " + counties.length + " submitted"
    }

    render() {
        return (

            <div className="layout vertical pie-chart-container county-preaward-progress-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">County pre-award progress</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="layout horizontal center form-margin-16 flex">
                    <div className="flex">
                        <ProgressBarMulti height="16px" title=" " suffix={this.calcProgressBarSuffix(this.props.counties)} value={this.calcProgressBarValue(this.props.counties)} />
                    </div>
                </div>
            </div>
        )
    }

}


export default CountyPreAwardProgress;
