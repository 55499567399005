export const GetFinancialReimbursement = `query GetFinancialReimbursement($id: ID!) {
  getFinancialReimbursement(id: $id) {
    id
    quarter
    quarterStartDate
    quarterEndDate
    updatedBy
    year
    group {
      id
      groupName
      createdAt
      updatedAt
      region
      palmettoId
      narratives {
        nextToken
      }
      deleted
      grantapplication {
        nextToken
      }
      financialreimbursement {
        nextToken
      }
    }
    createdAt
    updatedAt
    deleted
    status
    notes
    awarded
    checkNumber
    checkAmount
    checkMailDate
    stateSceisDocumentNumber 
    stateSceisSubmissionDate 
    lastSubmitDate
    lastSubmitName
    lastSubmitEmail
    lastSubmitUserId
    checkNumber2
    checkAmount2
    checkMailDate2
    stateSceisDocumentNumber2
    stateSceisSubmissionDate2

    checksReissued(limit: 50) {
      items {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        reason

        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      nextToken
    }
    currentReissuedCheck {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      reason

      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
  }
    additionalChecks {
      items {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        reason

        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2

      }
      nextToken
    }
    additionalCheckInProgress {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      reason

      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2

    }
    extensions(limit: 50) {
      items {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      nextToken
    }
    currentExtension {
      id
      updatedAt
      createdAt
      updatedBy
      year
      type
      group
      status
      for
      quarterFrom
      quarterTo
      dueDate
      reasonForExtension
      dateAdjustedTo
      reasonForAdjustment
      reasonForDeniedExtension
    }


    personnel(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        notes
        taxAmount
        file
      }
      nextToken
    }
    contractualService(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        notes
        taxAmount
        file
      }
      nextToken
    }
    travel(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        notes
        taxAmount
        file
      }
      nextToken
    }
    equipment(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        notes
        taxAmount
        file
      }
      nextToken
    }
    supply(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        notes
        taxAmount
        file
      }
      nextToken
    }
    cert(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        notes
        taxAmount
        file
      }
      nextToken
    }
    other(limit: 50) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        notes
        taxAmount
        file
      }
      nextToken
    }
    deobligatedFunds
    deobligatedFundsReason
  }
}
`;
