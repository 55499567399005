import React from 'react';
import { connect } from 'react-redux'
import moment from 'moment';
import numeral from 'numeral';

import NewChart from "./Charts";
import { calcQuarters } from '../../utilities/QuarterDates';

import "../../../styles/common.css";
import "../../../styles/common.charts.css";



const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        counties: state.rootReducer.counties,
        selectedCounty: state.rootReducer.selectedCounty,
        awardAmountForCounties: state.rootReducer.awardAmountForCounties,
        selectedYear: state.rootReducer.selectedYear
    };
};


class TotalMoneySpentByCountyDoughnutChart extends React.Component {

    state = {

    }


    checkQuarterBasedOnDate = (quarterStart, quarterEnd) => {
        var start = new Date(quarterStart);
        var current = new Date();
        current.setHours(0, 0, 0, 0);
        // var end = new Date(quarterEnd);
        // && current <= end
        // We need to allow users to test pass the current FY year
        return (start <= current);
    }

    checkWhichQuarters = () => {
        let obj = calcQuarters(this.props.selectedYear);

        // Check per quarter basis, which quarter we are in

        var quarters = []

        if (this.checkQuarterBasedOnDate(obj.quarter1.start, obj.quarter1.end)) {
            quarters = ["quarter1"];
        }
        if (this.checkQuarterBasedOnDate(obj.quarter2.start, obj.quarter2.end)) {
            quarters = ["quarter1", "quarter2"];
        }
        if (this.checkQuarterBasedOnDate(obj.quarter3.start, obj.quarter3.end)) {
            quarters = ["quarter1", "quarter2", "quarter3"];
        }
        if (this.checkQuarterBasedOnDate(obj.quarter4.start, obj.quarter4.end)) {
            quarters = ["quarter1", "quarter2", "quarter3", "quarter4"];
        }

        return quarters;
    }


    calculateAmount = (type, currentQuarter) => {
        let amount = 0;
        if (!currentQuarter[type]) return amount;
        if (!currentQuarter[type].items) return amount;
        if (!currentQuarter[type].items.length) return amount;

        currentQuarter[type].items.forEach((item) => {
            if (!item.deleted) {
                if (item.quarterAmount && !isNaN(parseFloat(item.quarterAmount))) {
                    amount += parseFloat(item.quarterAmount);
                }
                // if (item.matchAmount && !isNaN(parseFloat(item.matchAmount))) {
                //     amount += parseFloat(item.matchAmount);
                // }
                if (item.taxAmount && !isNaN(parseFloat(item.taxAmount))) {
                    amount += parseFloat(item.taxAmount);
                }
            }
        })

        return Number.parseFloat(amount).toFixed(2);
    }

    /**
     * 
     * Calculates amount for a financial reimbursements by amountType
     * 
     */

    getQuarterAmountByAmountType = (currentQuarter) => {
        let totalAmount = 0;

        let types = [
            "personnel",
            "equipment",
            "contractualService",
            "supply",
            "travel",
            "cert",
            "other"
        ]

        types.forEach((type) => {
            totalAmount += parseFloat(this.calculateAmount(type, currentQuarter))
        })

        return parseFloat(totalAmount);
    }    

    getEstimatedCountyBudgetFromGrantApplication = (county) => {

        var arr = []

        var applicationTypes = ['salary', 'contractualService', 'travel', 'equipment', 'supply', 'other', 'cert'];

        // First calculate total amount awarded for all types
        // Then calculate how much percent is being used in each category

        let amount = 0;
        
        if (county.grantapplication && county.grantapplication.items) {
            county.grantapplication.items.forEach((grantApp) => {


                applicationTypes.forEach((type) => {
                    grantApp[type].items.forEach((item) => {
                        if (!item.deleted) {

                            let typesOfFunding = ["federal"]
                            let sum = 0;

                            typesOfFunding.forEach((typeOfFunding) => {

                                if (item[typeOfFunding] && parseFloat(item[typeOfFunding]) && !isNaN(parseFloat(item[typeOfFunding]))) {
                                    sum += parseFloat(item[typeOfFunding]);
                                }
                            })

                            amount += sum;
                        }
                    })

                })

            });
        }

        return amount;
    }

    calculateConfig = () => {

        /**
         * To calculate `Total money spent by counties` we have to calculate the total amount all counties entered in their grant application (federal) and then how much they have spent up until the current quarter using financial reimbursement
         */

        let data = [];
        let summationOfGrantApplicationAmountForAllCounties = 0;
        let totalSpentAmount = 0;
        let quarters = this.checkWhichQuarters();

        // Calculate total amount entered by all counties in their grant application

        this.props.counties.forEach( (county) => {
            summationOfGrantApplicationAmountForAllCounties += this.getEstimatedCountyBudgetFromGrantApplication(county);
        })

        this.state.summationOfGrantApplicationAmountForAllCounties = summationOfGrantApplicationAmountForAllCounties;

        // Calculate amount spent by counties

        this.props.counties.forEach((county) => {
            if (county.financialreimbursement && county.financialreimbursement.items) {

                // Get financial reimbursements forms for quarters up until now
                let forms = []

                county.financialreimbursement.items.forEach((item) => {
                    if(item.status === "PROCESSED") {
                        if(quarters.indexOf(item.quarter) >= 0) {
                            forms.push(item);
                        }
                    }
                })

                forms.forEach( (form) => {
                    totalSpentAmount += this.getQuarterAmountByAmountType(form)
                })
            }
        })

        let percentAmoutSpent = ((totalSpentAmount / summationOfGrantApplicationAmountForAllCounties) * 100);
        percentAmoutSpent = percentAmoutSpent < 100.0 ? percentAmoutSpent : 100.0;
        let config = {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [
                        percentAmoutSpent, (100 - percentAmoutSpent)
                    ],
                    backgroundColor: [
                        "#417505",
                        "#a1a1a1",
                    ],
                    label: 'TOTAL MONEY SPENT BY COUNTIES'
                }],
                labels: [
                    'Spent',
                    'Remaining',
                ]
            },
            options: {
                layout: {
                    padding: {
                        top: 16
                    }
                },
                cutoutPercentage: 70,
                responsive: true,
                segmentShowStroke: false,
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                legend: {
                    display: false,
                },
                tooltips: {
                    callbacks: {
                        label:  (tooltipItem, data) => {
                            var dataset = data.datasets[tooltipItem.datasetIndex];
                            var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                            var total = meta.total;
                            var currentValue = dataset.data[tooltipItem.index];
                            var percentage = parseFloat((currentValue / total * 100).toFixed(1));

                            var spentAmount = this.state.summationOfGrantApplicationAmountForAllCounties * (percentage / 100);

                            return numeral(spentAmount).format('($ 0.00 a)');;
                        },
                        title: function (tooltipItem, data) {
                            return data.labels[tooltipItem[0].index];
                        }
                    }
                }
            },
            centerText: {
                display: true,
                // Leave this as parseInt
                text: parseInt(percentAmoutSpent) + "%"
            },
            plugins: [{
                beforeDraw:  (chart, options) => {
                    //..
                    if (chart.config.centerText.display !== null &&
                        typeof chart.config.centerText.display !== 'undefined' &&
                        chart.config.centerText.display) {
                        this.drawTotals(chart);
                    }
                }
            }]
        };

        return config;
    }

    drawTotals = (chart) => {

        var width = chart.chart.width,
            height = chart.chart.height,
            ctx = chart.chart.ctx;

        ctx.restore();
        var fontSize = (height / 94).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";

        var text = chart.config.centerText.text,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
    }

    render() {
        return (

            <div className="pie-chart-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">Total money spent by counties</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="pie-chart">
                    <NewChart config={this.calculateConfig()} />
                </div>
            </div>
        )
    }

}


export default connect(mapStateToProps, null)(TotalMoneySpentByCountyDoughnutChart);
