import moment from 'moment';
import uuid from "uuid";

const compareTwoObjects = (item1, item2, fieldTitleReference) => {
    var str = "";

    for (var a in item1) {
        if (fieldTitleReference[a]) {
            if (item1[a] !== item2[a]) {
                // str += fieldTitleReference[a] + ", ";
                str += a + ", ";
            }
        }
    }
    return str;
}

const _getReferencedValuePrint = (item, path, value, fieldValueReference) => {
    if (fieldValueReference && fieldValueReference[path]) {
        return _getReferencedValue(item, path, value, fieldValueReference);
    } else {
        return value;
    }
}

const _getReferencedValue = (item, path, value, fieldValueReference) => {
        if(typeof fieldValueReference[path] === "function") {
            return fieldValueReference[path](value);
        } else if (typeof fieldValueReference[path][value] === "string") {
            return fieldValueReference[path][value];
        } else if (typeof fieldValueReference[path][value] === "object") {
            var values = fieldValueReference[path][value].values;
            var refPath = fieldValueReference[path][value].refPath;
            return values[item[refPath]] ? (values[item[refPath]].trim() ? values[item[refPath]].trim() : "") : "";
        }
}


export const generateHistoryComments = (history, comments, fieldTitleReference, fieldValueReference) => {
    var arr = [];

    if (comments) {
        for (var i = 0, len = comments.length; i < len; i++) {
            arr.push({
                "type": "comment",
                "displayId": uuid.v4(),
                "text": comments[i].comment,
                "showVerticalLine": true,
                "time": moment(comments[i].createdAt).local().format("MM/DD/YYYY hh:mm A"),
                "timestamp": parseInt(moment(comments[i].createdAt).local().format('x')),
                "name": ((comments[i].updatedByFirstName || "") + " " + (comments[i].updatedByLastName || "")) 
            });
        }
    }


    if (history) {
        if (history.length === 1) {
            var baseEntry = history[0];

            arr.push({
                "type": "history",
                "showVerticalLine": false,
                "displayId": uuid.v4(),
                "text": "Entry Created",
                "printText": "Entry Created",
                "original": true,
                "time": moment(baseEntry.updatedAt).local().format("MM/DD/YYYY hh:mm A"),
                "timestamp": parseInt(moment(baseEntry.updatedAt).local().format('x')),
                "name": ((baseEntry.updatedByFirstName || "") + " " + (baseEntry.updatedByLastName || "")) 
            });
        } else if (history.length > 1) {

            history.sort(function (a, b) {
                if (a.updatedAt < b.updatedAt) { return 1; }
                if (a.updatedAt > b.updatedAt) { return -1; }
                return 0;
            })


            var baseEntry = history[history.length - 1];
            if (!baseEntry) {
                return;
            }

            arr.push({
                "type": "history",
                "showVerticalLine": false,
                "displayId": uuid.v4(),
                "text": "Entry Created",
                "printText": "Entry Created",
                "original": true,
                "time": moment(baseEntry.updatedAt).local().format("MM/DD/YYYY hh:mm A"),
                "timestamp": parseInt(moment(baseEntry.updatedAt).local().format('x')),
                "name": ((baseEntry.updatedByFirstName || "") + " " + (baseEntry.updatedByLastName || "")) 
            });

            for (var i = history.length - 2; i >= 0; i--) {
                var str = compareTwoObjects(baseEntry, history[i], fieldTitleReference);
                if (str) {

                    var updatedItems = str.split(",");
                    updatedItems.forEach(function (updatedItem) {
                        updatedItem = updatedItem.trim();
                        if (fieldTitleReference[updatedItem] && fieldTitleReference[updatedItem].trim()) {

                            arr.push({
                                "type": "history",
                                "text": fieldTitleReference[updatedItem],
                                "displayId": uuid.v4(),
                                "printText": _getReferencedValuePrint(history[i], updatedItem, history[i][updatedItem]),
                                "showVerticalLine": false,
                                "original": false,
                                "path": updatedItem,
                                "time": moment(history[i].updatedAt).local().format("MM/DD/YYYY hh:mm A"),
                                "timestamp": parseInt(moment(history[i].updatedAt).local().format('x')),
                                "name": ((history[i].updatedByFirstName || "") + " " + (history[i].updatedByLastName || "")) 
                            });
                        }
                    }.bind(this));
                    baseEntry = history[i];
                }
            }
        }
    }

    arr.sort(function (a, b) {
        if (a.timestamp < b.timestamp) { return 1; }
        if (a.timestamp > b.timestamp) { return -1; }
        return 0;
    })


    // this is for entries which has files and comments saved before an entry was created
    if (arr[arr.length - 1]) {
        // hide the vertical line
        // find the last history element and toggle the showVerticalLine field
        arr[arr.length - 1].showVerticalLine = false;
        for (var i = 0; i < arr.length - 1; i++) {
            if (arr[i]) {
                arr[i].showVerticalLine = true;
            }
        }
    }


    return arr;
}

export const generateDetailedHistory = (item, history, fieldValueReference = {}, fieldTitleReference = {}) => {

    let changedItemTitle = "";
    let changedHistory = [];

    if (!item.original) {
        changedItemTitle = item.text.split("Updated")[0];

        var baseEntry = history[0];
        var historyName = (((baseEntry.updatedByFirstName || "") + " " + (baseEntry.updatedByLastName || ""))) 

        changedHistory = [];

        if (fieldValueReference[item.path]) {
            changedHistory.push({
                "displayId": uuid.v4(),
                "value": _getReferencedValue(baseEntry, item.path, baseEntry[item.path]),
                "time": moment(baseEntry.pvEntryDate).local().format("MM/DD/YYYY hh:mm A"),
                "timestamp": parseInt(moment(baseEntry.pvEntryDate).local().format('x')),
                "name": historyName
            });
        } else {
            changedHistory.push({
                "displayId": uuid.v4(),
                "value": baseEntry[item.path],
                "time": moment(baseEntry.pvEntryDate).local().format("MM/DD/YYYY hh:mm A"),
                "timestamp": parseInt(moment(baseEntry.pvEntryDate).local().format('x')),
                "name": historyName
            });
        }

        for (var i = 1; i < history.length; i++) {
            if (baseEntry[item.path] !== history[i][item.path]) {
                var historyName = (((history[i].updatedByFirstName || "") + " " + (history[i].updatedByLastName || ""))) 

                if (fieldValueReference[item.path]) {
                    changedHistory.push({
                        "displayId": uuid.v4(),
                        "value": _getReferencedValue(history[i], item.path, history[i][item.path]),
                        "time": moment(history[i].pvEntryDate).local().format("MM/DD/YYYY hh:mm A"),
                        "timestamp": parseInt(moment(history[i].pvEntryDate).local().format('x')),
                        "name": historyName
                    });
                } else {
                    changedHistory.push({
                        "displayId": uuid.v4(),
                        "value": history[i][item.path],
                        "time": moment(history[i].pvEntryDate).local().format("MM/DD/YYYY hh:mm A"),
                        "timestamp": parseInt(moment(history[i].pvEntryDate).local().format('x')),
                        "name": historyName
                    });
                }
            }

            baseEntry = history[i];
        }
        return {
            changedItemTitle: changedItemTitle,
            changedHistory: changedHistory,
        };
    } else {
        return {
            changedItemTitle: "",
            changedHistory: [],
        };
    }
}