import React from 'react';
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

// Local imports

// Component imports
import AcceptanceOfAuditRequirements from "./AcceptanceOfAuditRequirements";
import CertificationDocument from "./CertificationDocument";
import AssurancesNonConstruction from "./AssurancesNonConstruction";
import LobbyingCertification from './LobbyingCertification';
import EDMEquipmentPolicy from './EDMEquipmentPolicy';
import ScopeOfWorkDocument from "./ScopeOfWorkDocument";
import NarrativesDocument from "./NarrativesDocument";
import GuidelinesDocument from "./GuidelinesDocument";

import PreFiscalYearStatePackage from "./PreFiscalYearStatePackage";
import PreGrantNewPage from "../pre_grant_awards/PreGrantNewPage";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        loading: state.rootReducer.loading,
        lempgUserType: state.rootReducer.lempgUserType,
        selectedYear: state.rootReducer.selectedYear,
    }
};

class PreFiscalYearHome extends React.Component {

    state = {
    }

    constructor(props, context) {
        super(props, context);
    }

    componentDidUpdate() { }


    render() {
        return (
            <Switch>
                <Route
                    exact
                    path="/prefiscalyear/pregrantawards/new"

                    component={(props) =>
                        this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading narrative. Please wait</span>
                            </div>
                            :  <PreGrantNewPage {...props} />}
                />
                <Route
                    exact
                    path="/prefiscalyear/"
                    component={(props) => 
                        this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading narrative. Please wait</span>
                            </div>
                            :  <PreFiscalYearStatePackage {...props} />}
                />


                <Route
                    path="/prefiscalyear/termsandcondition/auditreq"
                    component={(props) => <AcceptanceOfAuditRequirements {...props} />}
                />

                <Route
                    path="/prefiscalyear/termsandcondition/certificationdocuments"
                    component={(props) => <CertificationDocument {...props} />}
                />

                <Route
                    path="/prefiscalyear/termsandcondition/assurances"
                    component={(props) => <AssurancesNonConstruction {...props} />}
                />


                <Route
                    path="/prefiscalyear/termsandcondition/lobbying"
                    component={(props) => <LobbyingCertification {...props} />}
                />

                <Route
                    path="/prefiscalyear/termsandcondition/edm"
                    component={(props) => <EDMEquipmentPolicy {...props} />}
                />

                <Route
                    path="/prefiscalyear/termsandcondition/scopeofworkdocument"
                    component={(props) => <ScopeOfWorkDocument {...props} />}
                />

                <Route
                    path="/prefiscalyear/termsandcondition/narrativesdocument"
                    component={(props) => <NarrativesDocument {...props} />}
                />

                <Route
                    path="/prefiscalyear/termsandcondition/guidelinesdocument"
                    component={(props) => <GuidelinesDocument {...props} />}
                />

            </Switch>

        )
    }
}

export default connect(mapStateToProps, null)(PreFiscalYearHome);
