import React from 'react';
import { connect } from 'react-redux';
import IconButton from "@mui/material/IconButton";
import { MdArrowBack } from 'react-icons/md';
import { RequiredTextField } from "../../styled-components/TextFields/TextFields.js";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

// Component imports
import { DecimalFormatCustom } from "../../utilities/NumberFormat.js";
import { HeaderComponent } from "../../layout/HeaderComponent.js";
import { PrimaryButton } from "../../styled-components/Buttons/Buttons.js";
import ErrorBox from "../../common/ErrorBox.js";

import {
    savePreGrantAwardAmount
} from "../../../redux/actions/index";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    return {
        preGrantSelectedCounty: state.rootReducer.preGrantSelectedCounty,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        savePreGrantAwardAmount: (payload, callback) => dispatch(savePreGrantAwardAmount(payload, callback)),

    }
};

class PreGrantNewPage extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            smallScreen: false,
            county: (this.props.preGrantSelectedCounty && this.props.preGrantSelectedCounty.county) || "",
            awardAmount: (this.props.preGrantSelectedCounty && this.props.preGrantSelectedCounty.awardAmount) || "",
            errors: [],
            results: {},
            validate: false,

        };
        this.submitForm = this.submitForm.bind(this);
    }

    submitForm() {

        this.state.validate = false;
        this.state.errors = [];
        this.setState({ validate: this.state.validate });
        this.setState({ errors: this.state.errors });

        if (!this.state.awardAmount) {
            this.state.errors.push({
                text: "Please enter award amount"
            });
        }
        if (this.state.errors.length > 0) {
            this.state.validate = true;
            this.setState({ validate: this.state.validate });
            this.setState({ errors: this.state.errors });
            return;
        }

        this.props.savePreGrantAwardAmount(this.state, () => {
            this.goBack()
        })
    }
    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    goBack = () => {
        this.props.history.goBack();
    }
    
    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }
    
    render() {
        return (
            <div className="layout vertical full-height">
                <HeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>
                    }
                    title={<span>{this._convertToTitleCase(this.state.county)}</span>}
                />

                <div className="flex container-overflow-y form-shell">

                    {
                        this.state.errors.length ? <ErrorBox errors={this.state.errors} /> : ""
                    }

                    <div className="layout horizontal wrap">
                        <RequiredTextField value={this.state.awardAmount} onChange={this.handleChange('awardAmount')} id="awardAmountField" label="Award Amount" placeholder="Enter amount" custommargin="true" className="flex" InputProps={{ readOnly: false, inputComponent: DecimalFormatCustom}}  InputLabelProps={{ shrink: true, }} />
                    </div>
                </div>
                <div className="layout horizontal center form-container">
                    <div className="flex"></div>
                    <PrimaryButton setwidth="true" onClick={() => this.submitForm()}> Submit </PrimaryButton>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreGrantNewPage);