import React from 'react';
import { connect } from 'react-redux';
import { MdAccessTime, MdClear } from 'react-icons/md';
import InputBase from '@mui/material/InputBase';
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



import { DualTitleHeaderComponent } from "../layout/HeaderComponent";

import "../../styles/common.css";
import "../../styles/common.activityfeed.css";

import { saveComment, getActivityFeedHistory } from "../../redux/actions/index";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        lempgUserType: state.rootReducer.lempgUserType,
        historyComments: state.rootReducer.historyComments, 
        history: state.rootReducer.history, 
        fieldValueReference: state.rootReducer.fieldValueReference, 
        fieldTitleReference: state.rootReducer.fieldTitleReference, 
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveComment: (payload, fieldToSave, callback) => dispatch(saveComment(payload, fieldToSave, callback)),
        getActivityFeedHistory: (item, history, fieldValueReference, fieldTitleReference, callback) => dispatch(getActivityFeedHistory(item, history, fieldValueReference, fieldTitleReference, callback)),
    }
}

class ActivityFeed extends React.Component {

    state = {
        showFullActivity: false, 
        comment: "",
        open: false,
        historyComments: this.props.historyComments || [],
        changedItemTitle: "",
        changedHistory: [],
        smallScreen: false,

    }

    constructor(props, context) {
        super(props, context);
    }
    checkWidth = () => {
        const match = window.matchMedia(`(max-width: 768px)`).matches;
        if (match === this.state.smallScreen) return;
        if (match) {
            this.setState({ smallScreen: true });
        } else {
            this.setState({ smallScreen: false });
        }
    }

    componentDidMount() {
        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkWidth);
    }

    showHistory = () => {

    }


    handleClose = () => {
        this.setState({ open: false });
    };


    handleClickOpen = () => {
        this.setState({ open: true });
    };

    _getTitle = (title, index) => {
        return index === 0 ? (title + " updated to") : "From";
    }

    openHistoryDialog = (item) => {
        this.handleClickOpen()
        this.props.getActivityFeedHistory(item, this.props.history, this.props.fieldValueReference, this.props.fieldTitleReference, (obj) => {
            this.state.changedHistory = obj.changedHistory;
            this.state.changedItemTitle = obj.changedItemTitle;

            this.setState({ changedItemTitle: this.state.changedItemTitle });
            this.setState({ changedHistory: this.state.changedHistory });

        })
    }

    saveComment = () => {
        this.props.saveComment({ comment: this.state.comment }, this.props.modelFieldName, (historyComments) => {
            this.state.historyComments = historyComments;
            this.setState({
                historyComments: this.state.historyComments
            })
            this.state.comment = "";
            this.setState({
                comment: this.state.comment
            })
        })
    }

    onCommentChange = (event) => {
        this.state.comment = event.target.value;
        this.setState({
            comment: this.state.comment
        })
    }

    render() {
        return (
            <>

                <Dialog
                    fullScreen={this.state.smallScreen}
                    id="activityDialog"
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className=" layout vertical history-paper-dialog" id="viewChangesModal">
                        <DualTitleHeaderComponent
                            icon={
                                <IconButton
                                    aria-label="close"
                                    onClick={this.handleClose}
                                >
                                    <MdClear className="icon" />
                                </IconButton>

                            }
                            titleBig={<span>{this.state.changedItemTitle}</span>}
                            titleSmall={<span>Change History</span>}
                        />

                        <div className="flex history-dialog-container" id="historyContainer">
                            {
                                this.state.changedHistory.map( (item, index ) => {
                                    return (
                                        <div key={item.displayId}>
                                            <span className="layout horizontal form-margin-16"> {this._getTitle(this.state.changedItemTitle, index) }</span>
                                            <div className="changed-history-container">
                                                {item.value}
                        					</div>
                                            <div className="activity-user-info">
                                                <span className="activity-user-name">{item.name}</span>
                                                <span className="activity-user-time"> @ {item.time}</span>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>


                    </div>



                </Dialog>

            
                <div className="layout horizontal center flex comment-box">
                    <InputBase className="flex" value={this.state.comment} multiline placeholder="Enter comment" onChange={this.onCommentChange} />
                    <div onClick={this.saveComment} className="button-imposter">POST</div>
                </div>

                {
                    this.state.historyComments &&
                    this.state.historyComments.map(( item ) => {
                        return (
                            <div key={item.displayId}>
                                {/* History  */}
                                {
                                    item.type === "history" ?
                                    <div className="layout horizontal flex history-box">
                                        <div className="layout vertical history-box-icon-container">
                                            {
                                                item.original ? <MdAccessTime className="history-icon" /> : <MdAccessTime onClick={() => this.openHistoryDialog(item)} className="history-icon" />
                                            } 
                                            {
                                                item.showVerticalLine ? <div className="vert-line"></div> : ""
                                            }
                                        </div>

                                        <div className="layout truncate vertical flex ">
                                            <div className="truncate activity-title">
                                                {
                                                        item.original ? <span>{item.text}</span> : <span className="cursor-pointer" onClick={this.showHistory}>{item.text}</span>
                                                } 
                                            </div>
                                            <div className="">
                                                {
                                                    item.original ? 
                                                    <div>
                                                        <span className="activity-user-name">{item.name}</span>
                                                        <span className="activity-user-time"> @ {item.time}</span>
                                                    </div> 
                                                    : 
                                                    <div onClick={this.showHistory}>
                                                        <span className="activity-user-name cursor-pointer">{item.name}</span>
                                                        <span className="activity-user-time cursor-pointer"> @ {item.time}</span>
                                                    </div>
                                                } 
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                                }

                                {/* Comments */}
                                
                                {
                                    item.type === "comment" ?
                                    <div key={item.id} className="layout vertical ">
                                        <div className="comment-container activity-comment">
                                            {item.text}
                                        </div>

                                        <div className="layout horizontal">
                                            <div className="flipped-right-triangle"></div>
                                            <div className="">
                                                <span className="activity-user-name">{item.name}</span>
                                                <span className="activity-user-time"> @ {item.time}</span>
                                            </div>
                                        </div>
                                        {
                                            item.showVerticalLine ? 
                                            <div className="activity-feed-extra"></div>
                                            : ""
                                        }
                                    </div>
                                    : ""
                                }

                            </div>
                        )
                    })
                }
            </>
        )
    }

}

ActivityFeed.defaultProps = {
    historyComments: [],
    fieldValueReference: {},
    fieldTitleReference: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityFeed);
