import React from 'react';
import ReactDOM from 'react-dom';

import './logs/index';

import { Provider } from "react-redux";
import store from "./redux/store/index";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


import Amplify from '@aws-amplify/core';
import PubSub from '@aws-amplify/pubsub';
import API from '@aws-amplify/api';
import awsconfig from './aws-exports';

// Amplify.Logger.LOG_LEVEL = "DEBUG";
Amplify.configure(awsconfig);
PubSub.configure(awsconfig);
API.configure(awsconfig);

// Remove this when proper routing is implemented
window.location.hash = "#/"

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
