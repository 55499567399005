import React from 'react';
import { connect } from 'react-redux'
import numeral from 'numeral';

import "../../../styles/common.css";
import "../../../styles/common.charts.css";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear
    };
};


class BSIRPersonnelCard extends React.Component {

    state = {
        item: {
            title: "",
            timeRemaining: "",
        }
    }

    constructor(props, context) {
        super(props, context);
    }

    calculateTotalBudgetedPersonnel = () => {
        var amount = 0;

        if(this.props.counties && this.props.counties.length) {
            this.props.counties.forEach( (county) => {
                if (county && county.grantapplication && county.grantapplication.items && county.grantapplication.items.length) {
                    county.grantapplication.items.forEach( (item) => {
                        if (item.salary && item.salary.items && item.salary.items.length) {
                            for (let i = 0; i < item.salary.items.length; i++) {
                                let user = item.salary.items[i];
                                if (!user.deleted && user.federal) {
                                    amount += parseFloat(user.federal);
                                }
                            }
                        }
                        if (item.fbFederalFunding) {
                            amount += parseFloat(item.fbFederalFunding);
                        }
                    })
                }
            })
        }

        return numeral(amount).format('($ 0,0.00)');
    }

    calculateTotalExpendedPersonnel = () => {
        var amount = 0;

        if (this.props.counties && this.props.counties.length) {
            this.props.counties.forEach((county) => {
                if (county && county.financialreimbursement && county.financialreimbursement.items && county.financialreimbursement.items.length) {
                    county.financialreimbursement.items.forEach((item) => {
                        if(item.status === 'PROCESSED') {
                            if (item.personnel && item.personnel.items && item.personnel.items.length) {
                                item.personnel.items.forEach((user) => {
                                    if (!user.deleted && user.quarterAmount) {
                                        amount += parseFloat(user.quarterAmount);
                                    }
                                })
                            }
                        }
                    })
                }
            })
        }

        return numeral(amount).format('($ 0,0.00)');
    }

    render() {
        return (

            <div className="pie-chart-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">BSIR Personnel (Budgeted | Expended)</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="layout horizontal center bsir-personnel-container">
                    <span className="bsir-personnel-amount">{this.calculateTotalBudgetedPersonnel()} &nbsp; | &nbsp;</span> 
                    <span className="bsir-personnel-amount">{this.calculateTotalExpendedPersonnel()}</span> 
                </div>
            </div>
        )
    }

}

export default connect(mapStateToProps, null)(BSIRPersonnelCard);
