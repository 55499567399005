// https://stackoverflow.com/a/9204568
export function ValidateEmailAddress(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function ValidatePhoneNumber(phone) {
    var re = /^\d{10}$/;
    return re.test(phone);
}
