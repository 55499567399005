import React from 'react';
import IconButton from "@mui/material/IconButton";
import { MdMenu } from 'react-icons/md';

import { HeaderComponent } from "../layout/HeaderComponent.js"
import { connect } from 'react-redux'

// Chart imports

import CountySpendingByCategoryPieChart from "./charts/CountySpendingByCategoryPieChart";
import TimeRemainingInQuarter from "./charts/TimeRemainingInQuarterIndicator";
import AvailableGrantFundsDoughnutChart from "./charts/AvailableGrantFundsDoughnutChart";
import CountyProgressReportingStatusProgressBar from "./charts/CountyProgressReportingStatusProgressBar";
import SpendingByQuarterHorizontalBar from "./charts/SpendingByQuarterHorizontalBar";

// CSS imports
import "../../styles/common.css";
import "../../styles/common.charts.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        counties: state.rootReducer.counties,
        selectedCounty: state.rootReducer.selectedCounty,
        selectedYear: state.rootReducer.selectedYear
    };
};

class CountySplashScreen extends React.Component {

    state = {
        config: {}
    }

    constructor(props, context) {
        super(props, context);

    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    render() {

        return (

            <div className="layout vertical full-height splash-screen">

                <HeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                title={<span>LEMPG FY{this.props.selectedYear}</span>}
                />

                <div className="county-charts-container flex form-container container-overflow-y">
                    <div className="county-charts-container-col-1">
                        <CountySpendingByCategoryPieChart />
                        <AvailableGrantFundsDoughnutChart />
                    </div>
                    <div className="county-charts-container-col-2">
                        <TimeRemainingInQuarter selectedYear={this.props.selectedYear}/>
                        <CountyProgressReportingStatusProgressBar />
                        <SpendingByQuarterHorizontalBar />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(CountySplashScreen);