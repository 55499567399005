import React from 'react';
import { connect } from 'react-redux'
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import IconButton from "@mui/material/IconButton";
import { MdArrowBack, MdWarning } from 'react-icons/md';

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        currentQuarter: state.rootReducer.currentQuarter,
        grantApplication: state.rootReducer.grantApplication,
        selectedCounty: state.rootReducer.selectedCounty,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

class FinancialReimbursementChangesRequestedState extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())

        return (
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Financial Reimbursement</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{quarterSubheaderTitle}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                />

                <div className="flex layout vertical center vertical-center container-overflow-y">

                    <MdWarning className="icon-2 application-status-icon" />
                    <div className="height-20"></div>
                    <span className="layout horizontal center center-justified application-status-title-small">Changes Requested</span>
                    <div className="height-20"></div>

                    <div style={{ padding: "0 16px", maxWidth: "456px", lineHeight: "1.5" }} className="text-center title-big opacity-54">We’ve let <span style={{ textTransform: "capitalize"}}> {this.props.selectedCounty && this.props.selectedCounty.groupName} </span>  County know that their Financial Reimbursement form requires changes.</div>

                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReimbursementChangesRequestedState);
