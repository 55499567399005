export const GetGroupIds = `query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        year
    
      }
      nextToken
    }
  }
  `;