export const GetGroupsNarrativesM2M = `

query ListGroups(
	$filter: ModelGroupFilterInput
	$limit: Int
	$nextToken: String
) {
	listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
		items {
			id
			groupName
			createdAt
			updatedAt
			region
			palmettoId
			year
			awardAmount
			narratives(limit: 50) {
				nextToken
				items {
					id
					completed
					createdAt
					updatedAt
					deleted
					updatedBy
					status
					notes
					extraFile
					quarter
					currentExtension {
						id
						updatedAt
						createdAt
						updatedBy
						year
						type
						group
						status
						for
						quarterFrom
						quarterTo
						dueDate
						reasonForExtension
						dateAdjustedTo
						reasonForAdjustment
						reasonForDeniedExtension
					}

					narrative {
						id
						narrativeTitle
						narrativeCategory
						narrativeShortDescription
						narrativeLongDescription
						narrativeFrequency
						narrativePOCFirstName
						narrativePOCLastName
						narrativePOCPhoneNumber
						narrativePOCEmailAddress
						narrativePOCPositionTitle
						usePalmettoForREMInformation
						useFinancialReimbursementStatus
						quarter
						deleted
						createdAt
						updatedAt
						updatedBy
						groups {
							nextToken
						}
					}
				}
			}
			deleted
		}
		nextToken
	}
}


`;