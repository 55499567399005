import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { is } from 'date-fns/locale';

export function NumberFormatPhone(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            format="###-###-####" 
        />
    );
}

export function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            // decimalSeparator=" "
            inputMode="decimal"
            pattern="[0-9]*"
            prefix="$"
        />
    );
}

export function DecimalFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            inputMode="decimal"
            pattern="[0-9]*"
            prefix="$"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

export function getValue(args) {
    var total = 0;
    args.forEach(function (arg) {
        if (!isNaN(parseFloat(arg))) {
            total += parseFloat(arg)
        }
    });
    return total;
}

export function isInteger (value) {
    // Check its a number.
    return value !== undefined && 
        value !== null && 
           value.length !== 0 &&    
               Number.isInteger(parseInt(value));
}

export function isNumber(value) {
    // Check its a number.
    // isNaN(null) -> true
    return value !== null && !isNaN(value)
}

export function parseFloatWithPrecision(value) {
    return Number(Number.parseFloat(value).toFixed(2));
}

// Adds Commas to a number
export function getFormattedNumberForPrint(value) {
    if (!value && value !== 0) return "";
    let options = {
        "minimumFractionDigits": 2
    };
    return new Intl.NumberFormat('en-US', options).format(value);
}