import React from 'react';
import { Route, Switch, Link } from "react-router-dom";
import { connect } from 'react-redux'


// section imports
import ApplicationSubmitted from "./ApplicationSubmitted";

import ApplicationForm from "./ApplicationForm";
import Salary from  "./Salary";
import ContractualService from  "./ContractualService";
import Travel from  "./Travel";
import Equipment from  "./Equipment";
import Supply from  "./Supply";
import Other from  "./Other";
import Cert from  "./Cert";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // saveSalary: () => dispatch(saveSalary()),
    }
}

class Application extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this); // i think you are missing this
    }


    goBack() {
        this.props.history.goBack();
    }

    render () {

        return (
            <Switch>

                <Route
                    exact
                    path="/application"
                    component={(props) => 
                        this.props.lempgUserType === "LEMPG_ACCESS" && this.props.currentApplicationState && this.props.currentApplicationState.status === "SUBMITTED" ? <ApplicationSubmitted {...this.props} />
                        : 
                        <ApplicationForm revision="true" {...props} />
                    }
                />

                <Route
                    exact
                    path="/application/application_id"
                    component={(props) => <ApplicationForm {...props} {...this.props} />}
                />

                <Route
                    path="/application/application_id/salary/:id"
                    component={(props) => <Salary {...props} />}
                />

                <Route
                    path="/application/application_id/salary"
                    component={(props) => <Salary {...props} />}
                />


                <Route
                    path="/application/application_id/contractualService/:id"
                    component={(props) => <ContractualService {...props} />}
                />

                <Route
                    path="/application/application_id/contractualService"
                    component={(props) => <ContractualService {...props} />}
                />


                <Route
                    path="/application/application_id/travel/:id"
                    component={(props) => <Travel {...props} />}
                />

                <Route
                    path="/application/application_id/travel"
                    component={(props) => <Travel {...props} />}
                />

                <Route
                    path="/application/application_id/equipment/:id"
                    component={(props) => <Equipment {...props} />}
                />

                <Route
                    path="/application/application_id/equipment"
                    component={(props) => <Equipment {...props} />}
                />

                <Route
                    path="/application/application_id/supply/:id"
                    component={(props) => <Supply {...props} />}
                />

                <Route
                    path="/application/application_id/supply"
                    component={(props) => <Supply {...props} />}
                />

                <Route
                    path="/application/application_id/other/:id"
                    component={(props) => <Other {...props} />}
                />

                <Route
                    path="/application/application_id/other"
                    component={(props) => <Other {...props} />}
                />

                <Route
                    path="/application/application_id/cert/:id"
                    component={(props) => <Cert {...props} />}
                />

                <Route
                    path="/application/application_id/cert"
                    component={(props) => <Cert {...props} />}
                />

            </Switch>
        )
    }
}


// export default Application;
export default connect(mapStateToProps, mapDispatchToProps)(Application);
