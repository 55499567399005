import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdArrowBack, MdMoreVert } from 'react-icons/md';
import { NormalTextField} from "../../styled-components/TextFields/TextFields.js";
import moment from 'moment';
import { HeaderButton } from "../../styled-components/Buttons/Buttons";
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { CardComponent2R1C } from '../../layout/CardComponent';
import { getObject } from "../../common/FileUploadApi";
import { printMonitoringForm, approveMonitoringSiteVisitReview } from '../../../redux/actions/index';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear,
        currentMonitoringSiteVisitReview: state.rootReducer.currentMonitoringSiteVisitReview,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        printMonitoringForm: () => dispatch(printMonitoringForm()),
        approveMonitoringSiteVisitReview: (callback) => dispatch(approveMonitoringSiteVisitReview(callback)),

    }
}

class MonitoringSiteVisitReview extends React.Component {

    state = {

        gaOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.gaOnFile) || '',
        gaSupportingDocumentationOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.gaSupportingDocumentationOnFile) || '',
        gaComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.gaComments) || '',
    
        personnelFunded: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.personnelFunded) || '',
        personnelDocumentationOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.personnelDocumentationOnFile) || '',
        personnelRecordsOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.personnelRecordsOnFile) || '',
        personnelComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.personnelComments) || '',
    
        contractualServiceOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.contractualServiceOnFile) || '',
        contractualServiceExecutedContractsOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.contractualServiceExecutedContractsOnFile) || '',
        contractualServiceComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.contractualServiceComments) || '',
    
        travelOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.travelOnFile) || '',
        travelDocumentationOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.travelDocumentationOnFile) || '',
        travelComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.travelComments) || '',
    
        equipmentProperlySheltered: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.equipmentProperlySheltered) || '',
        equipmentTransferred: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.equipmentTransferred) || '',
        equipmentComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.equipmentComments) || '',
    
        suppliesOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.suppliesOnFile) || '',
        suppliesTransferred: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.suppliesTransferred) || '',
        suppliesComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.suppliesComments) || '',
    
        otherOnFile: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.otherOnFile) || '',
        otherComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.otherComments) || '',
    
        summaryComments: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.summaryComments) || '',
        monitoringReportSubmittedBy: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.monitoringReportSubmittedBy) || '',
        monitoringReportSubmittedAt: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.monitoringReportSubmittedAt) || '',
    
        scemdPOCReviewedBy: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.scemdPOCReviewedBy) || '',
        scemdPOCReviewedAt: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.scemdPOCReviewedAt) || '',      

        monitoringReportSubmittedAtDisplay: '',
        scemdPOCReviewedAtDisplay: '',
        
        uploadedFiles: (this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.monitoringFiles && this.props.currentMonitoringSiteVisitReview.monitoringFiles.items) || [],
        anchorEl: null,

    }

    gaInputs= [
        {
            type: '',
            title: '',
            inputs: [
                {
                    label: 'Grant application on file?',
                    field: 'gaOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Are the quarterly reimbursements and supporting documentation on file?',
                    field: 'gaSupportingDocumentationOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'gaComments',
                    type: 'comments',
                },
        
            ],
        },
    ]

    categories= [
        {
            type: 'personnel',
            title: '1. Personnel',
            inputs: [
                {
                    label: 'Are personnel funded under this grant?',
                    field: 'personnelFunded',
                    type: 'dropdown',
                },
                {
                    label: 'Are personnel timesheets, attendance records, activity reports, and payroll records in the grant file and available for review?',
                    field: 'personnelDocumentationOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Are personnel training and exercise records in the grant file?',
                    field: 'personnelRecordsOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'personnelComments',
                    type: 'comments',
                },
        
            ],
        },
        {
            type: 'contractualServices',
            title: '2. Contractual Services',
            inputs: [
                {
                    label: 'Are there contractual services?',
                    field: 'contractualServiceOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'If so, are there executed contracts on file?',
                    field: 'contractualServiceExecutedContractsOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'contractualServiceComments',
                    type: 'comments',
                },
            ],
        },
        {
            type: 'travel',
            title: '3. Travel',
            inputs: [
                {
                    label: 'Was travel conducted?',
                    field: 'travelOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Are travel expenditures documented with expense reports and/or detailed receipts?',
                    field: 'travelDocumentationOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'travelComments',
                    type: 'comments',
                },
            ],
        },
        {
            type: 'equipment',
            title: '4. Equipment',
            inputs: [
                {
                    label: 'Is the equipment properly sheltered from the elements, safeguarded against theft and vandalism, and protected to ensure operational capability and effectiveness?',
                    field: 'equipmentProperlySheltered',
                    type: 'dropdown',
                },
                {
                    label: 'Has grant funded equipment been transferred to other agencies?',
                    field: 'equipmentTransferred',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'equipmentComments',
                    type: 'comments',
                },
            ],
        },
        {
            type: 'supplies',
            title: '5. Supplies',
            inputs: [
                {
                    label: 'Have supplies been purchased with this grant?',
                    field: 'suppliesOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Has grant funded supplies been transferred to other agencies?',
                    field: 'suppliesTransferred',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'suppliesComments',
                    type: 'comments',
                },
            ],
        },
        {
            type: 'other',
            title: '6. Other',
            inputs: [
                {
                    label: 'Have other purchases been made with this grant?',
                    field: 'otherOnFile',
                    type: 'dropdown',
                },
                {
                    label: 'Comments',
                    field: 'otherComments',
                    type: 'comments',
                },
            ],
        },
    ]



    constructor(props, context) {
        super(props, context);

        if (this.props.currentMonitoringSiteVisitReview) {
            this.state.scemdPOCReviewedAtDisplay = this.props.currentMonitoringSiteVisitReview.scemdPOCReviewedAt && (moment(this.toUTC(new Date(this.props.currentMonitoringSiteVisitReview.scemdPOCReviewedAt))).format('MM-DD-YYYY'));

            this.state.monitoringReportSubmittedAtDisplay = this.props.currentMonitoringSiteVisitReview.monitoringReportSubmittedAt && (moment(this.toUTC(new Date(this.props.currentMonitoringSiteVisitReview.monitoringReportSubmittedAt))).format('MM-DD-YYYY'));
        }
    }

    toUTC = (date) => {
        var newDate = new Date();
        newDate.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        return newDate;
    }
    

    saveImg = (payload) => {
        this.state.uploadedFiles = this.state.uploadedFiles.concat(payload);
        this.setState({ uploadedFiles: this.state.uploadedFiles });
    }


    getProperKeyName = (key) => {
        if(key) {
            return key.substring(key.indexOf("__") + 2, key.length)
        }

        return key;
    }

    bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };

    removeDeletedFiles = (files) => {

        let arr = []

        files.forEach((file, index) => {
            if (!file.deleted) {
                arr.push(file)
            }
        })

        return arr;
    }    
    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };


    goBack = () => {
        this.props.history.goBack()
    }

    openEditPage = () => {
        this.props.history.push(`/monitoring/county/sitevisitreview/edit`)
    }

    getFileExtension = (filename) => {
        var ext = /^.+\.([^.]+)$/.exec(filename);
        return ext === null ? "" : ext[1];
    }

    downloadFile = async (item) => {
        let data = await getObject(item.title)

        let blob = new Blob([data.Body], {type: data.ContentType});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let ext = item.title.split('.')
        ext = ext[ext.length - 1];
        // if the file_name does not have any extension associated with it get the extension from file_key
        let name = this.getFileExtension(item.downloadTitle) ? item.downloadTitle : (item.downloadTitle + '.' + ext);

        link.download = name;
        link.click();
    }


    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    printMonitoringForm = () => {
        this.setState({ anchorEl: null });
        this.props.printMonitoringForm();
    }    

    setStatusToApproved = () => {
        this.props.approveMonitoringSiteVisitReview()
    }

    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (


            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Monitoring</span>}
                    titleSmall={<span>LEMPG {this.props.selectedYear}</span>}
                    suffix={

                        <div className='layout horizontal'>
                            
                            <HeaderButton onClick={this.openEditPage}>Edit</HeaderButton>
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'application-menu' : undefined}
                                onClick={this.handleIconDropdownClick}
                            >
                                <MdMoreVert className="icon" />
                            </IconButton>

                            <Menu
                                id="application-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                {
                                    this.props.currentMonitoringSiteVisitReview &&
                                    ( this.props.currentMonitoringSiteVisitReview.status === 'APPROVED') ? 
                                    "" 
                                    : <MenuItem key="print" onClick={() => this.setStatusToApproved()}>
                                    Set Status to Approved
                                </MenuItem>
                                }


                                <MenuItem key="print" onClick={() => this.printMonitoringForm()}>
                                    Print Monitoring Form
                                </MenuItem>
                            </Menu>                            
                        </div>                        
                    }
                />



                <div className="flex container-overflow-y" style={{ padding: '10px'}}>

                <div className='layout horizontal center section-title form-margin'>Grant Application</div>
                    {
                        this.gaInputs.map((input) => {
                            return (
                                <>
                                    {
                                        input.inputs.map((item) => {
                                            return (
                                                <>
                                                    <div className="layout horizontal wrap">
                                                        <NormalTextField value={this.state[item.field]} multiline  id={`${item.field}`} placeholder=" "  label={`${item.label}`} custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                                                    </div>
                                                    <div className='height-10'></div>
                                                </>
                                            )
                                        })
                                    }
                                </>
            
                            )                            
                        })
                    }


                    <div className='height-10'></div>
                    <div className='layout horizontal center section-title form-margin'>Grant budget categories</div>
                    {
                        this.categories.map((input) => {
                            return (
                                <>
                                    <div className='height-10'></div>
                                    <div className='layout horizontal center section-title form-margin' style={{ opacity: 0.54}}>{input.title}</div>
                                    {
                                        input.inputs.map((item) => {
                                            return (
                                                <>
                                                    <div className="layout horizontal wrap">
                                                        <NormalTextField value={this.state[item.field]} multiline  id={`${item.field}`} placeholder=" "  label={`${item.label}`} custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                                                    </div>
                                                    <div className='height-10'></div>
                                                </>
                                            )
                                        })
                                    }
                                </>
            
                            )                            
                        })
                    }


                    <div className='height-10'></div>
                    <div className='layout horizontal center section-title form-margin'>Summary</div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.summaryComments} multiline  id={`${'summaryComments'}`} placeholder=" "  label="Comments" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>
                    <div className='height-10'></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.monitoringReportSubmittedBy}  id={`${'monitoringReportSubmittedBy'}`}  placeholder=" "  label="Monitoring report submitted by" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />

                        <NormalTextField value={this.state.monitoringReportSubmittedAtDisplay}  id={`${'monitoringReportSubmittedAtDisplay'}`}  placeholder=" "  label="Date" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className='height-10'></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.scemdPOCReviewedBy}  id={`${'scemdPOCReviewedBy'}`}  placeholder=" "  label="SCEMD Admin/Finance POC reviewed by" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />


                        <NormalTextField value={this.state.scemdPOCReviewedAtDisplay}  id={`${'scemdPOCReviewedAtDisplay'}`}  placeholder=" "  label="Date" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className='height-20'></div>

                    <div className='layout horizontal center section-title'>Photos</div>
                    
                    {/* Show files */}

                    <div className='layout horizontal wrap'>
                        {
                            this.removeDeletedFiles(this.state.uploadedFiles).map((item) => {
                                return (
                                    <div onClick={() => this.downloadFile(item)} className="upload-card-margin cursor-pointer" key={item.key}>
                                        <CardComponent2R1C 
                                            className="set-width"
                                            title={<span className="truncate">{item.downloadTitle || item.title}</span>}
                                            title2={<span className="opacity-54">{this.bytesToSize(item.size)}</span>}
                                            suffix={<></>}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>
        )
    }
}

// export default MonitoringSiteVisitReview;
export default connect(mapStateToProps, mapDispatchToProps)(MonitoringSiteVisitReview);
