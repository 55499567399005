export const YEAR_QUARTER_NARRATIVE = {
    "2019": {
        "quarter1": {
            "M-101": 1,
            "M-102": 1,
            "M-103": 1,
            "M-112": 1,
            "M-113": 1,
            "M-114": 1,
            "M-115": 1,
            "M-116": 1,
            "M-117": 1,
        },
        "quarter2": {
            "M-104": 1,
            "M-112": 1,
            "M-113": 1,
            "M-114": 1,
            "M-115": 1,
            "M-116": 1,
            "M-117": 1,
        },
        "quarter3": {
            "M-105": 1,
            "M-112": 1,
            "M-113": 1,
            "M-114": 1,
            "M-115": 1,
            "M-116": 1,
            "M-117": 1,
        },
        "quarter4": {
            "M-106": 1,
            "M-107": 1,
            "M-108": 1,
            "M-109": 1,
            "M-110": 1,
            "M-111": 1,
            "M-112": 1,
            "M-113": 1,
            "M-114": 1,
            "M-115": 1,
            "M-116": 1,
            "M-117": 1,
        },
    },
    "2020": {
        "quarter1": {
            "M-101": 1,
            "M-102": 1,
            "M-103": 1,
            "M-104": 1,
            "M-112": 1,
            "M-113": 1,
            "M-114": 1,
            "M-115": 1,
            "M-116": 1,
            "M-117": 1,
        },
        "quarter2": {
            "M-112": 1,
            "M-113": 1,
            "M-114": 1,
            "M-115": 1,
            "M-116": 1,
            "M-117": 1,
        },
        "quarter3": {
            "M-105": 1,
            "M-112": 1,
            "M-113": 1,
            "M-114": 1,
            "M-115": 1,
            "M-116": 1,
            "M-117": 1,
        },
        "quarter4": {
            "M-106": 1,
            "M-107": 1,
            "M-108": 1,
            "M-109": 1,
            "M-110": 1,
            "M-111 (Optional)": 1,
            "M-112": 1,
            "M-113": 1,
            "M-114": 1,
            "M-115": 1,
            "M-116": 1,
            "M-117": 1,
        },
    },
}