import React from 'react';
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

// Local imports

// Component imports
import ProgressReportStatePage from "./ProgressReportStatePage";
import ProgressReportCountyPage from "./ProgressReportCountyPage";
import ProgressReportNarrativePage from "./ProgressReportNarrativePage";
import ProgressReportNarrativeCountyStatusPage from "./ProgressReportNarrativeCountyStatusPage";
import ProgressReportCountyPageForState from "./ProgressReportCountyPageForState";
import ProgressReportGenerateTaskingSlip from "./ProgressReportGenerateTaskingSlip";

import ProgressReportExtensionByQuarter from "../extensions/ProgressReportingExtensionByQuarter";
import ProgressReportExtensionByDate from "../extensions/ProgressReportingExtensionByDate";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        loading: state.rootReducer.loading,
        currentNarrative: state.rootReducer.currentNarrative,
        currentGroupNarrativeM2M: state.rootReducer.currentGroupNarrativeM2M,
        lempgUserType: state.rootReducer.lempgUserType,

    }
};

class ProgressHome extends React.Component {

    state = {
    }

    constructor(props, context) {
        super(props, context);
    }

    componentDidUpdate() { }

    render() {
        return (
            <Switch>

                <Route
                    exact
                    path="/progressreport/"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <ProgressReportStatePage {...props} />
                    }
                />
                <Route
                    path="/progressreport/county/narrative"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading narrative. Please wait</span>
                            </div>
                            : 
                            (this.props.lempgUserType === "LEMPG_ACCESS" && this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.status && (this.props.currentGroupNarrativeM2M.status === "SUBMITTED" || this.props.currentGroupNarrativeM2M.status === "CHANGES_REQUESTED"  ))
                                ? <ProgressReportNarrativeCountyStatusPage {...props} status={this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.status} />
                            :
                            <ProgressReportNarrativePage {...props} />
                    }
                />
                {/* <Route
                    path="/progressreport/county/narrative"
                    component={(props) => <ProgressReportNarrativePage {...props} />}
                /> */}
                <Route
                    path="/progressreport/state/county"
                    component={(props) => <ProgressReportCountyPageForState {...props} />}
                />
                <Route
                    path="/progressreport/county/taskingslip"
                    component={(props) => <ProgressReportGenerateTaskingSlip {...props} />}
                />
                <Route
                    path="/progressreport/county/extension/quarter"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <ProgressReportExtensionByQuarter {...props} />
                    }
                />
                <Route
                    path="/progressreport/county/extension/date"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <ProgressReportExtensionByDate {...props} />
                    }
                />
                <Route
                    path="/progressreport/county"
                    component={(props) => <ProgressReportCountyPage {...props} />}
                />
                

            </Switch>

        )
    }
}

export default connect(mapStateToProps, null)(ProgressHome);
