import React from 'react';
import "../../styles/common.css";

class ErrorBox extends React.Component {

    state = {

    }

    constructor(props, context) {
        super(props, context);
        this._hasErrorMsgContainer = this._hasErrorMsgContainer.bind(this);
    }

    _hasErrorMsgContainer() {
        return 
    }

    render() {
        return (
            <div className="error-msg-container" hidden={!this.props.errors.length}>
                <div className="error-container-padding">
                    {
                        this.props.errors.map((error) => {
                            return (
                                <li key={error.text} className="error-container-margin">{error.text}</li>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

}

ErrorBox.defaultProps = {
    errors: [],
};

export default ErrorBox;
