import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from "react";

const StandardTextField = (props) => <TextField variant={"standard"} {...props}></TextField>;

export const NormalTextField = styled(StandardTextField)`
    && {

        min-width: ${(props) => props.custom.minWidth ? props.custom.minWidth : '250px'};
        margin-top: {'auto'};
        margin-bottom: {'auto'};
        margin-left: ${(props) => props.custom.marginLeft ? props.custom.marginLeft : '0'};
        margin-right: ${(props) => props.custom.marginRight ? props.custom.marginRight : '0'};
        min-height: {'56px'};

        // we dont want to highlight our labels
        & > label {
            line-height: 1.33;
            color: var(--pcl-default-label-color);
        }

        // This styling will override default hover color of material ui textfield
        & > div:hover:before {
            border-bottom: 1px solid var(--pcl-default-label-color) !important;
        }
    }
`;

NormalTextField.propTypes = {
    custom: PropTypes.object
};

NormalTextField.defaultProps = {
    custom: {
        minWidth: "250px",
        marginLeft: "6px",
        marginRight: "6px",
        marginTop: "auto",
        marginBottom: "auto"
    }
}

export const RequiredTextField = styled(StandardTextField)`
    && {

        min-width: ${(props) => props.custom.minWidth ? props.custom.minWidth : '250px'};
        margin-left: ${(props) => props.custom.marginLeft ? props.custom.marginLeft : '0'};
        margin-right: ${(props) => props.custom.marginRight ? props.custom.marginRight : '0'};
        margin-top: {'auto'};
        margin-bottom: {'auto'};
        min-height: 56px;

        // we dont want to highlight our labels
        & > label {
            line-height: 1.33;
            color: ${(props) => props.error ? 'var(--pcl-required-textfield-color)' : 'var(--pcl-default-label-color)'};
        }

        // This styling will override default hover color of material ui textfield
        & > div:hover:before {
            border-bottom: 1px solid var(--pcl-default-label-color) !important;
        }

        & > label::after {
            content: "●";
            width: 6px;
            height: 6px;
            margin-left: 2px;
            color: var(--pcl-required-textfield-color);
        }

    }
`;

RequiredTextField.defaultProps = {
    custom: {
        minWidth: "250px",
        marginLeft: "6px",
        marginRight: "6px",
        marginTop: "auto",
        marginBottom: "auto"
    }
};

RequiredTextField.propTypes = {
    custom: PropTypes.string,
    error: PropTypes.bool
};
