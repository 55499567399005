import React from 'react';
import moment from "moment";
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import { MdArrowBack, MdInsertDriveFile, MdFileDownload, MdMoreVert } from 'react-icons/md';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormControl from '@mui/material/FormControl'
// Local imports
import ErrorBox from "../../common/ErrorBox";

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { PrimaryButton, HeaderButton, DeleteButton } from "../../styled-components/Buttons/Buttons.js";
import { NormalTextField, RequiredTextField } from "../../styled-components/TextFields/TextFields.js";
import FileUpload from "../../common/FileUpload.js";
import { getFileUploadedObject } from "../../common/FileUploadApi";
import { CardComponentDownloadCard } from "../../layout/CardComponent";
import ActivityFeed from "../../common/ActivityFeed";

// Redux imports
import { dismissExtensionProgressReporting, saveGroupNarrativeM2M, updateStatusGroupNarrativeM2M } from "../../../redux/actions/index";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        currentNarrative: state.rootReducer.currentNarrative,
        currentGroupNarrativeM2M: state.rootReducer.currentGroupNarrativeM2M,
        lempgUserType: state.rootReducer.lempgUserType,
        isTaskingSlipGeneratedForQuarter: state.rootReducer.isTaskingSlipGeneratedForQuarter,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveGroupNarrativeM2M: (payload, callback) => dispatch(saveGroupNarrativeM2M(payload, callback)),
        updateStatusGroupNarrativeM2M: (payload, callback) => dispatch(updateStatusGroupNarrativeM2M(payload, callback)),
        dismissExtensionProgressReporting: (payload, callback) => dispatch(dismissExtensionProgressReporting(payload, callback))
    }
};

class ProgressReportNarrativePage extends React.Component {
    state = {
        narrativeTitle: (this.props.currentNarrative && this.props.currentNarrative.narrativeTitle) || "",
        narrativeFrequency: (this.props.currentNarrative && this.props.currentNarrative.narrativeFrequency) || "",
        narrativeFrequency: (this.props.currentNarrative && this.props.currentNarrative.narrativeFrequency) || "",
        narrativeShortDescription: (this.props.currentNarrative && this.props.currentNarrative.narrativeShortDescription) || "",
        narrativeLongDescription: (this.props.currentNarrative && this.props.currentNarrative.narrativeLongDescription) || "",

        narrativePOCFirstName: (this.props.currentNarrative && this.props.currentNarrative.narrativePOCFirstName) || "",
        narrativePOCLastName: (this.props.currentNarrative && this.props.currentNarrative.narrativePOCLastName) || "",

        pocFullName: (((this.props.currentNarrative && this.props.currentNarrative.narrativePOCFirstName) || "") + " " + ((this.props.currentNarrative && this.props.currentNarrative.narrativePOCLastName) || "")),
        narrativePOCPositionTitle: (this.props.currentNarrative && this.props.currentNarrative.narrativePOCPositionTitle) || "",
        narrativePOCPhoneNumber: (this.props.currentNarrative && this.props.currentNarrative.narrativePOCPhoneNumber) || "",
        narrativePOCEmailAddress: (this.props.currentNarrative && this.props.currentNarrative.narrativePOCEmailAddress) || "",

        id: (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.id) || "",

        notes: (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.notes) || "",
        status: (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.status) || "Draft",

        isTaskingSlipGeneratedForQuarter: this.props.isTaskingSlipGeneratedForQuarter || false,
        
        file: {},
        other_files: [],
        errors: [],

        open: false,
        validate: false,
        m117DownloadLink: (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.m117DownloadLink) || "",

        m117CompletedDocument: (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.m117CompletedDocument) || null,

        m117CompletedDocumentId: (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.m117CompletedDocument && this.props.currentGroupNarrativeM2M.m117CompletedDocument.id) || null,
        openMenu: false,

        selectedExtensionType: "",
        extensionPending: this.props.currentGroupNarrativeM2M &&
            this.props.currentGroupNarrativeM2M.currentExtension &&
            this.props.currentGroupNarrativeM2M.currentExtension.status === "PENDING" || false,
        extensionApproved: this.props.currentGroupNarrativeM2M &&
            this.props.currentGroupNarrativeM2M.currentExtension &&
            this.props.currentGroupNarrativeM2M.currentExtension.status === "APPROVED" || false,
         extensionDenied: this.props.currentGroupNarrativeM2M &&
            this.props.currentGroupNarrativeM2M.currentExtension &&
            this.props.currentGroupNarrativeM2M.currentExtension.status === "DENIED" || false,    

        uploadedFileWithNarrative: {}

    }

    constructor(props, context) {
        super(props, context);
        this.fileToSave = this.fileToSave.bind(this);
        this.fileToDelete = this.fileToDelete.bind(this);
        this.saveFileCallback = this.saveFileCallback.bind(this);

        this.state.other_files = this.getExtraFiles(this.props.currentGroupNarrativeM2M);
        this.state.file = this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.uploadedFile ? this.props.currentGroupNarrativeM2M.uploadedFile : {};

        if(this.props.currentNarrative && (this.props.currentNarrative.useFinancialReimbursementStatus)) {
            this.state.status = this.getFinancialReimbursementStatus(this.props.selectedCounty && this.props.selectedCounty.financialreimbursement && this.props.selectedCounty.financialreimbursement.items, this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.quarter)
        }
    
        if (this.state.file.title) {
            this.state.file.key = this.state.file.title;
        }
    }

    getFinancialReimbursementStatus(items, quarter) {
        let status = ''
        items.forEach((item) => {
            if(item.quarter === quarter) {
                status = item.status;
            }
        })
        let updatedStatus = status ? status : "Draft";
        if (status === "AWARDED") updatedStatus = "Approved";
        if (status === "PROCESSED") updatedStatus = "Approved";
        if (status === "NOT_AVAILABLE") updatedStatus = "Draft";
        if (status === "NOT_STARTED") updatedStatus = "Draft";

        return updatedStatus;
    }
    
    async componentDidMount() {
        if (this.props.currentNarrative && this.props.currentNarrative.uploadedFile) {
            this.state.uploadedFileWithNarrative = await getFileUploadedObject(this.props.currentNarrative.uploadedFile)

            this.setState({
                uploadedFileWithNarrative: this.state.uploadedFileWithNarrative
            });
        }
    }

    /**
     * 
    */

    fileToSave(data) {
        if (!this.state.file.id) {
            data.data.createFile.key = data.data.createFile.title;
            this.setState({ file: data.data.createFile });
        } else {
            this.state.other_files.push({
                type: 'groupNarrativeM2MUploadedFileId',
                file: data.data.createFile.id,
                key: data.data.createFile.title,
                title: data.data.createFile.title,
                size: data.data.createFile.size,
            })
        }

    }

    /**
     * 
    */


    fileToDelete(file) {

        if (file.key === this.state.file.key) {
            this.state.file = {}
            this.setState({ file: this.state.file });
        } else {
            // check whether the deleted file exists in the normal file connection or in "other files"
            var files = this.state.other_files;
            for (let i = files.length; i--;) {
                var _file = files[i];
                if (file.key === _file.key) {
                    files.splice(i, 1);
                    break;
                }
            }
            this.state.other_files = files
        }

    }


    m117FileToSave = (data) => {
        this.setState({ m117CompletedDocumentId: data.data.createFile.id });
    }

    m117FileToDelete = () => {
        this.setState({ m117CompletedDocumentId: null });
    }


    saveFile(data) {
        // this.props.saveFileAppSync(this.state, "groupNarrativeM2MUploadedFileId", this.saveFileCallback)
    }

    saveFileCallback(data) {
        this.goBack()
    }    

    getExtraFiles(groupNarrativeM2M) {

        var arr = []

        if (groupNarrativeM2M && groupNarrativeM2M.uploadedFile) {
            var files = groupNarrativeM2M.extraFile;
            files = JSON.parse(files)
            return files;
        }

        return arr;
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }


    goBack = () => {
        this.props.history.goBack();
    }

    saveGroupNarrativeM2M = () => {
        this.state.validate = false;
        this.state.errors = [];
        this.setState({ validate: this.state.validate });
        this.setState({ errors: this.state.errors });


        if (!this.state.notes) {
            this.state.errors.push({
                text: "Please enter notes"
            });
        }

        if (this.state.errors.length > 0) {
            this.state.validate = true;
            this.setState({ validate: this.state.validate });
            this.setState({ errors: this.state.errors });
            return;
        }

        this.props.saveGroupNarrativeM2M(this.state, this.saveCalllback)
    }
    
    dismissExtensionProgressReporting = () => {
        this.props.dismissExtensionProgressReporting(this.state, () => {
            });
    }

    handleClickOpen = () => {

        this.state.validate = false;
        this.state.errors = [];
        this.setState({ validate: this.state.validate });
        this.setState({ errors: this.state.errors });


        if (!this.state.notes) {
            this.state.errors.push({
                text: "Please enter notes"
            });
        }

        if (this.state.errors.length > 0) {
            this.state.validate = true;
            this.setState({ validate: this.state.validate });
            this.setState({ errors: this.state.errors });
            return;
        }


        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.setState({openExtensionDialog: false})
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleRadioButtonChange = event => {
        this.setState({ selectedExtensionType: event.target.value})
    };

    showProgressReportingExtensionDialog = () => {
        this.setState({ openExtensionDialog: true })
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    saveCallback = () => {
        this.goBack();
    }

    submitGroupNarrative = () => {
        this.state.status = "SUBMITTED";
        this.props.updateStatusGroupNarrativeM2M(this.state, this.saveCallback);
        this.handleClose();
    }

    approveGroupNarrative = () => {
        this.state.status = "APPROVED";
        this.props.updateStatusGroupNarrativeM2M(this.state, this.saveCallback);
        this.handleClose();
    }

    requestChangesInGroupNarrative = () => {
        this.state.status = "CHANGES_REQUESTED";
        this.props.updateStatusGroupNarrativeM2M(this.state, this.saveCallback);
        this.handleClose();
    }

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    showExtensionPage = () => {
        if(this.state.selectedExtensionType === "BY_QUARTER") {
            this.props.history.push("/progressreport/county/extension/quarter")
        } else {
            this.props.history.push("/progressreport/county/extension/date")
        }
    }

    getDownloadTitle = () => {

        // M-117 Training Plan
        if (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.narrative && this.props.currentGroupNarrativeM2M.narrative.uploadedFile) {
            return this.props.currentGroupNarrativeM2M.narrative.narrativeTitle + " Document";
        } else {

            if (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.narrative && this.props.currentGroupNarrativeM2M.narrative.narrativeTitle === "M-111") {
                return "CERT Report template";
            }
            else if (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.narrative && this.props.currentGroupNarrativeM2M.narrative.narrativeTitle === "M-117") {
                return "M-117 Training Plan";
            }
            else if (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.narrative && this.props.currentGroupNarrativeM2M.narrative.narrativeTitle === "M-106") {
                return "M-106 Document";
            }
        }
    }

    getDownloadTitlePostFY2019 = () => {
        if (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.narrative && this.props.currentGroupNarrativeM2M.narrative.uploadedFile) {
            return this.props.currentGroupNarrativeM2M.narrative.narrativeTitle + " Document";
        }
    }

    render() {
        const { anchorEl } = this.state;
        const openMenu = Boolean(anchorEl);

        const UploadedFiles = (
            <a target="_blank" href={this.state.m117DownloadLink}>
                <CardComponentDownloadCard
                    icon={<MdInsertDriveFile className="icon-2" />}
                    title={<span>{this.getDownloadTitle()}</span>}
                    suffix={<MdFileDownload className="icon-2" />}
                />
            </a>
        )

        const UploadedFilesPostFY2019 = (
            <a target="_blank" href={this.state.uploadedFileWithNarrative && this.state.uploadedFileWithNarrative.link}>
                <CardComponentDownloadCard
                    icon={<MdInsertDriveFile className="icon-2" />}
                    title={<span>{this.getDownloadTitlePostFY2019()}</span>}
                    suffix={<MdFileDownload className="icon-2" />}
                />
            </a>
        )

      
        return (

            <div className="layout vertical full-height">

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Submit narrative"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are about to submit this narrative to the State for review for this quarter. Are you sure you want to do this?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                            </Button>
                        <Button onClick={this.submitGroupNarrative} color="primary" autoFocus>
                            Submit
                        </Button>
                    </DialogActions>

                </Dialog>

                <Dialog
                    open={this.state.openExtensionDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Request extension"}</DialogTitle>
                    <DialogContent>
                        <FormControl>
                            <RadioGroup aria-label="gender" name="gender1" value={this.state.selectedExtensionType} onChange={this.handleRadioButtonChange}>
                                <FormControlLabel  value="BY_DATE" control={<Radio color="primary" />} label="Extend due date" />
                                {
                                    this.state.narrativeFrequency != "Recurring" ?
                                    <FormControlLabel value="BY_QUARTER" control={<Radio color="primary" />} label="Move to another quarter" /> : ''
                                }
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                            </Button>
                        <Button onClick={this.showExtensionPage} color="primary" autoFocus>
                            Next
                        </Button>
                    </DialogActions>

                </Dialog>


                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span> {this.state.narrativeTitle} </span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{"Q" + (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.quarter && parseInt(this.props.currentGroupNarrativeM2M.quarter.split("").reverse().join("")))} {this.props.selectedYear}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}                   
                    suffix={
                        this.props.currentGroupNarrativeM2M 
                        && this.props.currentGroupNarrativeM2M.narrative
                        && (!this.props.currentGroupNarrativeM2M.narrative.useFinancialReimbursementStatus) 
                        && !this.state.isTaskingSlipGeneratedForQuarter ? 
                            <div className="form-margin-16">
                                <HeaderButton hidden={ this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"} onClick={() => this.saveGroupNarrativeM2M()}>
                                    Save
                                </HeaderButton>

                                {
                                    this.props.lempgUserType === "LEMPG_ACCESS" ? 
                                    this.state.extensionPending ? "" :
                                    <>
                                        <IconButton
                                            aria-label="More"
                                            aria-haspopup="true"
                                            // eslint-disable-next-line no-restricted-globals
                                            aria-owns={openMenu ? 'application-menu' : undefined}
                                            onClick={this.handleIconDropdownClick}>
                                            <MdMoreVert className="icon" />
                                        </IconButton>

                                        <Menu
                                            id="application-menu"
                                            // anchorEl={anchorEl}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                                            // eslint-disable-next-line no-restricted-globals
                                            open={openMenu}
                                            onClose={this.handleMenuClose}>
                                            {/* Cannot ask for another extension for the same quarter if its in pending state */}
                                            {   
                                                this.state.extensionPending ? "" :
                                                    <MenuItem key="ProgressReportingExtension" onClick={() => this.showProgressReportingExtensionDialog()}>
                                                        Request an Extension
                                                    </MenuItem>
                                            }
                                        </Menu>

                                    </>
                                    : ""
                                }

                            </div> 
                        : <> </>
                    }
                />
                {
                    this.state.extensionPending ?
                        <div className="layout horizontal center center-justified" style={{ height: "24px", backgroundColor: "var(--extension-pending-color", color: "white" }}>
                            Extension request pending approval
                                </div>
                        : ""
                }
                {
                    this.state.extensionApproved && this.props.currentGroupNarrativeM2M.currentExtension.dueDate ?
                        <div className="layout horizontal center center-justified" style={{ height: "24px", backgroundColor: "var(--extension-approved-color", color: "white" }}>
                            Extension approved until {this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.currentExtension && moment(this.props.currentGroupNarrativeM2M.currentExtension.dateAdjustedTo || this.props.currentGroupNarrativeM2M.currentExtension.dueDate, "YYYY-MM-DD").local().format("MM-DD-YYYY")}
                                </div>
                        : ""
                }
                {
                    this.state.extensionDenied ?
                        <div className="layout horizontal center center-justified" style={{ height: "24px", backgroundColor: "var(--extension-denied-color", color: "white" }}>
                            <span>Extension request denied. </span>
                            {/* <Button style={{ color: "white", marginLeft: "4px", fontWeight: "500", textDecoration: "underline"}}  onClick={() => this.dismissExtensionProgressReporting()}>Dismiss</Button> */}
                        </div>
                        : ""
                }
                {
                    this.state.errors.length ?
                        <div className="margin-10">
                            <ErrorBox errors={this.state.errors} />
                        </div>
                        : ""
                }
                <div className="flex form-shell container-overflow-y">
                
                    <div className="layout horizontal center section-title form-margin">EMF Details</div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.narrativeTitle} id="narrativeTitle" label="EMF name" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />

                        <NormalTextField value={this._convertToTitleCase(this.state.status)} id="narrativeStatus" label="Status" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true, }} />

                        <NormalTextField value={this.state.narrativeFrequency} id="narrativeFrequency" label="Type" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="layout horizontal">
                        <NormalTextField id="narrativeShortDesc" multiline value={this.state.narrativeShortDescription} label="Description" custommargin="true" placeholder="Enter narrative" className="flex" margin="normal" InputProps={{ readOnly: true }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="layout horizontal">
                        <NormalTextField id="narrativeLongDesc" multiline value={this.state.narrativeLongDescription} label="Explanatory paragraph" custommargin="true" placeholder="Enter narrative" className="flex" margin="normal" InputProps={{ readOnly: true }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="layout horizontal center section-title form-margin">POC Details</div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.pocFullName} id="pocFullName" label="Name" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true, }} />

                        <NormalTextField value={this.state.narrativePOCPositionTitle} id="narrativePOCPositionTitle" label="Position/Title" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true, }} />
                    </div>
                    
                    <div className="height-10"></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.narrativePOCPhoneNumber} id="narrativePOCPhoneNumber" label="Phone number" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true, }} />

                        <NormalTextField value={this.state.narrativePOCEmailAddress} id="narrativePOCEmailAddress" label="Email address" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true, }} />
                    </div>

                    {
                        this.props.currentGroupNarrativeM2M 
                        && this.props.currentGroupNarrativeM2M.narrative
                        && (this.props.currentGroupNarrativeM2M.narrative.useFinancialReimbursementStatus) ?
                        <>  
                            <div className='height-20'></div>
                            <span className='layout horizontal section-title form-margin center'>This narrative follows financial reimbursement quarterly status</span>
                        </> :
                        <>
                            {
                                /*
                                    Adding M-117 narrative download upload option
                                    Only for 2019 
                                */
                                    this.props.selectedYear === "2019"
                                    && this.props.currentGroupNarrativeM2M 
                                    && this.props.currentGroupNarrativeM2M.narrative
                                    && (this.props.currentGroupNarrativeM2M.narrative.narrativeTitle === "M-117" || this.props.currentGroupNarrativeM2M.narrative.narrativeTitle === "M-111" || this.props.currentGroupNarrativeM2M.narrative.narrativeTitle === "M-106")

                                    ? 
                                    
                                    <>
                                        <div className="layout horizontal end section-title form-margin">{this.props.currentGroupNarrativeM2M.narrative.narrativeTitle} Document</div>

                                        <div className="height-10"></div>
                                        <div className="layout horizontal center form-margin opacity-87">
                                            Download the document and upload the completed table.
                                        </div>
                                        <div className="height-10"></div>
                                        <div className="layout horizontal form-margin">
                                            {UploadedFiles}
                                        </div>
                                    </>
                                    
                                    : ""

                            }

                            {
                                /*
                                    Adding M-117 narrative download upload option
                                    Only for 2019 
                                */
                                    this.props.selectedYear != "2019"
                                    && this.props.currentGroupNarrativeM2M
                                    && this.props.currentGroupNarrativeM2M.narrative
                                    && this.props.currentGroupNarrativeM2M.narrative.uploadedFile

                                    ?

                                    <>
                                        <div className="layout horizontal end section-title form-margin">Attached File</div>

                                        <div className="height-10"></div>
                                        {/* <div className="layout horizontal center form-margin opacity-87">
                                            Download the document and upload the completed table.
                                        </div> */}
                                        <div className="height-10"></div>
                                        <div className="layout horizontal form-margin">
                                            {UploadedFilesPostFY2019}
                                        </div>
                                    </>

                                    : ""

                            }                    

                            <div className="layout horizontal end section-title form-margin">File Attachments</div>

                            <div className="layout horizontal form-margin">
                                <FileUpload fileToSave={this.fileToSave} fileToDelete={this.fileToDelete} hideUploadContainer={this.state.isTaskingSlipGeneratedForQuarter} noEdit={this.state.isTaskingSlipGeneratedForQuarter} supportingTitle="Include supporting documentation" notitle="true" className="flex" uploadedFile={this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.uploadedFile ? this.props.currentGroupNarrativeM2M.uploadedFile : null} extraFiles={this.getExtraFiles(this.props.currentGroupNarrativeM2M)} />
                            </div>

                            <div className="layout horizontal end section-title form-margin">Narrative Details</div>

                            <div className="layout horizontal">
                                <RequiredTextField id="notes" multiline value={this.state.notes} error={this.state.validate ? true : false}  onChange={this.handleChange('notes')} label="Notes" custommargin="true" placeholder="Enter notes" className="flex" margin="normal" InputProps={{ readOnly: this.state.isTaskingSlipGeneratedForQuarter || (this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS") }} InputLabelProps={{ shrink: true, }} />
                            </div>

                            <div className="layout horizontal end section-title form-margin">Activity Feed</div>
                            
                            <div className="form-margin">
                                <ActivityFeed modelFieldName="groupNarrativeM2MCommentsId" />
                            </div>
                        </>
                    }


                </div>
                {
                    this.props.currentGroupNarrativeM2M 
                    && this.props.currentGroupNarrativeM2M.narrative
                    && (!this.props.currentGroupNarrativeM2M.narrative.useFinancialReimbursementStatus) 
                    ?
                    <>
                        {
                            (this.state.status === "APPROVED" || this.state.extensionPending) 
                            ? "" 
                            :
                                !this.state.isTaskingSlipGeneratedForQuarter 
                                ? this.props.lempgUserType && this.props.lempgUserType === "LEMPG_ACCESS" 
                                    ?
                                        <div className="layout horizontal center-justified form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                                            <PrimaryButton setwidth="true" onClick={() => this.handleClickOpen()}> Submit </PrimaryButton>
                                         </div>
                                    : (this.props.lempgUserType  && (this.props.lempgUserType != "LEMPG_ACCESS") && (this.state.status === "SUBMITTED" )) 
                                        ?
                                            <div className="layout horizontal center center-justified wrap  form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                                                <DeleteButton setwidth="true" onClick={() => this.requestChangesInGroupNarrative()}>
                                                Request changes
                                                 </DeleteButton>
                                                <div style={{ width: "20px" }}></div>
                                                <PrimaryButton setwidth="true" onClick={() => this.approveGroupNarrative()}>
                                                Approve
                                                </PrimaryButton>
                                            </div>
                                        : <div>HERE1</div>
                                : <div>HERE2</div>
                        }
                    </>
                    :<div>HERE3</div>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressReportNarrativePage);
