import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdClear } from 'react-icons/md';
import { RequiredDropdown } from "../../styled-components/Dropdowns/Dropdown.js";
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { PalmettoDatePicker } from "@zawarski/palmetto-ui-components";
import { NormalTextField, RequiredTextField} from "../../styled-components/TextFields/TextFields.js";
import { HeaderButton } from "../../styled-components/Buttons/Buttons";
import moment from 'moment';
import ErrorBox from "../../common/ErrorBox.js";

import { submitMonitoringGrantApplication } from '../../../redux/actions'
// js
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear,
        selectedCounty: state.rootReducer.selectedCounty,
        currentMonitoringGrantInformationPage: state.rootReducer.currentMonitoringGrantInformationPage,
        currentMonitoringSiteVisitReview: state.rootReducer.currentMonitoringSiteVisitReview,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        submitMonitoringGrantApplication: (payload, callback) => dispatch(submitMonitoringGrantApplication(payload, callback)),
    }
}

const DropdownMenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
    },
    PaperProps: {
        style: {
            minWidth: "200px",
        },
    },
};


class MonitoringGrantInformationEditPage extends React.Component {

    state = {
        monitoringType: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.monitoringType) || '',
        monitoringDate: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.monitoringDate) || '',
        subRecipientAgency: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.subRecipientAgency) || '',
        grantProjectTitle: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.grantProjectTitle) || '',
        grantNumber: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.grantNumber) || '',
    
        approvedGrantStartDate: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.approvedGrantStartDate) || '',
        approvedGrantEndDate: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.approvedGrantEndDate) || '',
        extensionEndDate: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.extensionEndDate) || '',
    
        name: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.name) || '',
        title: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.title) || '',
    
        projectDirectorName: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.projectDirectorName) || '',
        projectDirectorTitle: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.projectDirectorTitle) || '',
        
        personnelName: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.personnelName) || '',
        personnelTitle: (this.props.currentMonitoringGrantInformationPage && this.props.currentMonitoringGrantInformationPage.personnelTitle) || '',        
        errors: [],
    }

    constructor(props, context) {
        super(props, context);

        if (this.props.currentMonitoringGrantInformationPage) {
            if(this.props.currentMonitoringGrantInformationPage.monitoringDate) {
                this.state.monitoringDateDisplay = this.props.currentMonitoringGrantInformationPage.monitoringDate && (new Date(this.toUTC(new Date(this.props.currentMonitoringGrantInformationPage.monitoringDate))));
            }

            if(this.props.currentMonitoringGrantInformationPage.extensionEndDate) {
                this.state.extensionEndDateDisplay = this.props.currentMonitoringGrantInformationPage.extensionEndDate && (new Date(this.toUTC(new Date(this.props.currentMonitoringGrantInformationPage.extensionEndDate))));
            }

        }

        this.state.grantNumber = `${this.props.selectedYear} LEMPG01`;
        this.state.grantProjectTitle = `Local EMPG`;
        this.state.name = `${(this.props.currentMonitoringSiteVisitReview && this.props.currentMonitoringSiteVisitReview.updatedBy) || ''}`;
        this.state.title = `REM Region ${this.props.selectedCounty && this.props.selectedCounty.region && this.props.selectedCounty.region.split('').reverse()[0] }`

        this.state.approvedGrantStartDate = this.getYearStartEndDates(this.props.selectedYear).start;
        this.state.approvedGrantEndDate = this.getYearStartEndDates(this.props.selectedYear).end;

        this.state.approvedGrantStartDateDisplay = moment(this.state.approvedGrantStartDate).format("MM-DD-YYYY")

        this.state.approvedGrantEndDateDisplay = moment(this.state.approvedGrantEndDate).format("MM-DD-YYYY")

    }


    getYearStartEndDates = (year) => {
        if (!year) return
    
        var obj = {}
        obj.quarter1 = { start: moment(`01-01-${year}`, "MM-DD-YYYY").month(6).startOf('month').format("MM-DD-YYYY"), end: moment(`01-01-${year}`, "MM-DD-YYYY").month(8).endOf('month').toDate()}
        obj.quarter2 = { start: moment(`01-01-${year}`, "MM-DD-YYYY").month(9).startOf('month').format("MM-DD-YYYY"), end: moment(`01-01-${year}`, "MM-DD-YYYY").month(11).endOf('month').toDate()}
        obj.quarter3 = { start: moment(`01-01-${year}`, "MM-DD-YYYY").month(0).startOf('month').add('years', 1).format("MM-DD-YYYY"), end: moment(`01-01-${year}`, "MM-DD-YYYY").month(2).endOf('month').add('years', 1).toDate()}
        obj.quarter4 = { start: moment(`01-01-${year}`, "MM-DD-YYYY").month(3).startOf('month').add('years', 1).format("MM-DD-YYYY"), end: moment(`01-01-${year}`, "MM-DD-YYYY").month(5).endOf('month').add('years', 1).toDate()}
    
        return {start: obj.quarter1.start, end: obj.quarter4.end};
    }

    toUTC = (date) => {
        var newDate = new Date();
        newDate.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        return newDate;
    }
    
    handleDateChange = (date, field) => {
        this.state[field] = moment(date).format("MM-DD-YYYY");
        this.setState({ [field]: this.state[field] });
        this.state[field+'Display'] = new Date(date);
        this.setState({ [field+'Display']: this.state[field+'Display'] });
    }
    
    

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }
    
    submit = event => {

        this.state.validate = false;
        this.state.errors = [];
        this.setState({ validate: this.state.validate });
        this.setState({ errors: this.state.errors });


        if (!this.state.monitoringType) {
            this.state.errors.push({
                text: "Please select a monitoring type"
            });
        }

        if (!this.state.monitoringDateDisplay) {
            this.state.errors.push({
                text: "Please select monitoring date"
            });
        }

        if (!this.state.subRecipientAgency) {
            this.state.errors.push({
                text: "Please enter sub-recipient agency"
            });
        }

        if (!this.state.projectDirectorName) {
            this.state.errors.push({
                text: "Please enter project director name"
            });
        }

        if (!this.state.projectDirectorTitle) {
            this.state.errors.push({
                text: "Please enter project director title"
            });
        }

        if (this.state.errors.length > 0) {
            this.state.validate = true;
            this.setState({ validate: this.state.validate });
            this.setState({ errors: this.state.errors });
            return;
        }



        this.props.submitMonitoringGrantApplication(this.state, () => { this.props.history.goBack()})
    }

    goBack = () => {
        this.props.history.goBack()
    }


    render() {
        return (


            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdClear className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Monitoring</span>}
                    titleSmall={<span>Grant Information - LEMPG {this.props.selectedYear}</span>}
                    suffix={<HeaderButton onClick={this.submit}>Save</HeaderButton>}
                />



                <div className="flex container-overflow-y" style={{ padding: 10 }}>
                {
                        this.state.errors.length ?
                            <ErrorBox errors={this.state.errors} />
                            : ""
                    }
                    <div className='height-10'></div>

                    <div className="layout horizontal wrap">
                        <div className="layout horizontal flex" style={{minWidth: 250, height: 56}}>
                            <FormControl className="flex">
                                <InputLabel style={{ margin: "0 6px" }} shrink htmlFor="selectMonitoringType"> Onsite monitoring type <span className="required"></span></InputLabel>
                                <RequiredDropdown
                                    error={!this.state.monitoringType && this.state.validate ? true : false} 
                                    value={this.state.monitoringType}
                                    onChange={this.handleChange('monitoringType')}
                                    input={<Input name="selectMonitoringType" id="selectMonitoringType" />}
                                    MenuProps={DropdownMenuProps}
                                    custommargin="true"
                                    displayEmpty
                                    name="selectMonitoringType"
                                    className="required-dropdown"
                                >
                                    <MenuItem value="">
                                        <span className="placeholder">Select type</span>
                                    </MenuItem>
                                    <MenuItem value="Programmatic Only">Programmatic Only</MenuItem>
                                    <MenuItem value="Financial Only">Financial Only</MenuItem>
                                    <MenuItem value="Programmatic and Financial">Programmatic and Financial</MenuItem>
                                </RequiredDropdown>
                            </FormControl>

                        </div>
                        <div className="layout horizontal flex" style={{minWidth: 250, margin: '0 6px'}}>
                                <PalmettoDatePicker
                                    id="mui-pickers-date"
                                    label="Monitoring date"
                                    placeholder="Select date"
                                    className="flex"
                                    error={!this.state.monitoringDate && this.state.validate ? true : false} 
                                    value={this.state.monitoringDateDisplay}
                                    onChange={(e) => this.handleDateChange(e, 'monitoringDate')}
                                    mandatory='true' 
                                    autoOk={true}
                                    inputFormat='MMM dd, yyyy'
                                    invalidDateMessage=""
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    style={{
                                        margin: '0 !important',
                                        root: {
                                            margin: '0 !important'
                                        }
                                    }}
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>                 

                    </div>

                    <div className="layout horizontal wrap">
                        <RequiredTextField error={!this.state.subRecipientAgency && this.state.validate ? true : false} value={this.state.subRecipientAgency} onChange={this.handleChange('subRecipientAgency')} id="subRecipientAgency" multiline placeholder="Enter agency"  label="Sub-recipient agency" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />

                        <NormalTextField  value={this.state.grantProjectTitle} onChange={this.handleChange('grantProjectTitle')} id="grantProjectTitle" placeholder="Enter title"  label="Grant project title" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className='height-10'></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.grantNumber} onChange={this.handleChange('grantNumber')} id="grantNumber" multiline placeholder="Enter number"  label="Grant number" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className='height-10'></div>

                    <div className='layout horizontal center section-title form-margin'> Approved Grant Performance Period </div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.approvedGrantStartDateDisplay}  id="approvedGrantStartDate" multiline placeholder=" "  label="Start date" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />

                        <NormalTextField value={this.state.approvedGrantEndDateDisplay}  id="approvedGrantEndDate" multiline placeholder=" "  label="End Date" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className="layout horizontal wrap" style={{ margin: '0 6px'}}>
                   
                            <PalmettoDatePicker
                                margin="normal"
                                id="mui-pickers-date"
                                label="Extension due date"
                                placeholder="Select date"
                                className="flex"
                                value={this.state.extensionEndDateDisplay}
                                onChange={(e) => this.handleDateChange(e, 'extensionEndDate')}
                                autoOk={true}
                                inputFormat='MMM dd, yyyy'
                                invalidDateMessage=""
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                    </div>

                    <div className='height-10'></div>

                    <div className='layout horizontal center section-title form-margin'> Emergency management program staff </div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.name}  id="name" multiline placeholder=" "  label="Name" custommargin="true" className="flex" InputProps={{ readOnly: true, }} InputLabelProps={{ shrink: true,}} />

                        <NormalTextField value={this.state.title} onChange={this.handleChange('title')} id="title" multiline placeholder="Enter title"  label="Title" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                    </div>



                    <div className='height-10'></div>

                    <div className='layout horizontal center section-title form-margin'> Sub-grantee program personnel interviewed </div>

                    <div className="layout horizontal wrap">
                        <RequiredTextField error={!this.state.projectDirectorName && this.state.validate ? true : false} value={this.state.projectDirectorName} onChange={this.handleChange('projectDirectorName')} id="projectDirectorName" multiline placeholder="Enter name"  label="Project director name" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />

                        <RequiredTextField error={!this.state.projectDirectorTitle && this.state.validate ? true : false} value={this.state.projectDirectorTitle} onChange={this.handleChange('projectDirectorTitle')} id="projectDirectorTitle" multiline placeholder="Enter title"  label="Project director title" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className='height-10'></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.personnelName} onChange={this.handleChange('personnelName')} id="personnelName" multiline placeholder="Enter name"  label="Personnel name" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />

                        <NormalTextField value={this.state.personnelTitle} onChange={this.handleChange('personnelTitle')} id="personnelTitle" multiline placeholder="Enter title"  label="Personnel title" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                    </div>


                </div>
            </div>
        )
    }
}

// export default MonitoringGrantInformationEditPage;
export default connect(mapStateToProps, mapDispatchToProps)(MonitoringGrantInformationEditPage);
