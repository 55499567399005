import React from 'react';
import IconButton from "@mui/material/IconButton";
import { MdMenu } from 'react-icons/md';
import Button from '@mui/material/Button';

import { PrimaryButton } from "../styled-components/Buttons/Buttons.js";
import { HeaderComponent } from "../layout/HeaderComponent.js"
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import NarrativesCreated from "./charts/NarrativesCreated";
import CountyPreAwardProgress from "./charts/CountyPreAwardProgress";
import GrantPackageDocumentProgress from "./charts/GrantPackageDocumentProgress";

import { startNewFiscalYear } from "../../redux/actions/index";


// CSS imports
import "../../styles/common.css";
import "../../styles/common.charts.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        counties: state.rootReducer.counties,
        currentFiscalYear: state.rootReducer.currentFiscalYear,
        selectedYear: state.rootReducer.selectedYear,
        allNarratives: state.rootReducer.allNarratives,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        startNewFiscalYear: (callback) => dispatch(startNewFiscalYear(callback)),
    }
}

class StatePreFiscalYearScreen extends React.Component {

    state = {
        config: {},
        errorDialogOpen: false,
        successDialogOpen: false, 
        documents: {
            "acceptanceOfAuditReqFile": "",
            "certificationDocumentFile": "",
            "assurancesNonContructionFile": "",
            "lobbyingCertificationFile": "",
            "edmEquipmentPolicyFile": "",
            "narrativesFile": "",
            "guidelinesFile": "",
        },
    }

    constructor(props, context) {
        super(props, context);
    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    areAwardAmountsEntered = () => {
        for(var item of this.props.counties) {
            if(!(item.awardAmount && parseFloat(item.awardAmount) > 0)) {
                return false;
            }
        }
        return true
    }

    areTermsAndConditionDocumentsUploaded = () => {
        for(var document in this.state.documents) {
            if(!this.props.currentFiscalYear[document]) {
                return false;
            }
        }

        return true
    }

    showFiscalYearDialog = () => {

        // error check
        if(this.areAwardAmountsEntered() && this.areTermsAndConditionDocumentsUploaded()) {
            
            this.setState({successDialogOpen: true})
        } else {
            
            this.setState({errorDialogOpen: true})
        }
    }

    handleClose = (variable) => {
        this.setState({ [variable]: false });
    };

    startNewFiscalYear = () => {
        this.props.startNewFiscalYear();
    }

    render() {

        return (

            <div className="layout vertical full-height splash-screen">

                <Dialog
                    open={this.state.errorDialogOpen}
                    onClose={() => this.handleClose("errorDialogOpen")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{ maxWidth: "320px" }}>
                        <DialogTitle id="alert-dialog-title">{"Finish pre-fiscal year"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                In order to finish pre-fiscal year workflow, narratives must be created, all counties pre-awarded, and all grant package documents uploaded.
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.handleClose("errorDialogOpen")} color="primary" autoFocus>
                                Got it
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>

                <Dialog
                    open={this.state.successDialogOpen}
                    onClose={() => this.handleClose("successDialogOpen")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{ maxWidth: "320px" }}>
                        <DialogTitle id="alert-dialog-title">{"Submit pre-fiscal year"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You are about to submit pre-fiscal year workflow for <b> FY{this.props.selectedYear} </b>. Are you sure you want to do this?
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.handleClose("successDialogOpen")} color="primary" autoFocus>
                                Cancel
                            </Button>
                            <Button onClick={this.startNewFiscalYear} color="primary" autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>

                <HeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    title={<span>LEMPG FY{this.props.selectedYear}</span>}
                />

                <div className="state-prefiscal-year-container flex form-container container-overflow-y">
                    <div>
                        <NarrativesCreated number={(this.props.allNarratives && this.props.allNarratives.length) || 0} />
                        <div className="height-20"></div>
                        <CountyPreAwardProgress counties={this.props.counties} />
                    </div>
                    <GrantPackageDocumentProgress documents={this.props.currentFiscalYear} />
                </div>

                <div className="layout horizontal center-justified form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                    <PrimaryButton setwidth="true" onClick={() => this.showFiscalYearDialog()}>
                        Submit
                    </PrimaryButton>

                </div>                
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatePreFiscalYearScreen);