import moment from 'moment';

export function calcQuarters(selectedYear) {

    let obj = {};
    obj.quarter1 = { start: moment("01-01-" + selectedYear, "MM-DD-YYYY").month(6).startOf('month').format("MM/DD/YYYY"), end: moment("01-01-" + selectedYear, "MM-DD-YYYY").month(8).endOf('month').format("MM/DD/YYYY") }
    obj.quarter2 = { start: moment("01-01-" + selectedYear, "MM-DD-YYYY").month(9).startOf('month').format("MM/DD/YYYY"), end: moment("01-01-" + selectedYear, "MM-DD-YYYY").month(11).endOf('month').format("MM/DD/YYYY") }
    obj.quarter3 = { start: moment("01-01-" + selectedYear, "MM-DD-YYYY").month(0).startOf('month').add('years', 1).format("MM/DD/YYYY"), end: moment("01-01-" + selectedYear, "MM-DD-YYYY").month(2).endOf('month').add('years', 1).format("MM/DD/YYYY") }
    obj.quarter4 = { start: moment("01-01-" + selectedYear, "MM-DD-YYYY").month(3).startOf('month').add('years', 1).format("MM/DD/YYYY"), end: moment("01-01-" + selectedYear, "MM-DD-YYYY").month(5).endOf('month').add('years', 1).format("MM/DD/YYYY") }

    return obj;
}

function checkQuarterBasedOnDate(quarterStart, quarterEnd, dateToCheck=undefined) {
    var start = new Date(quarterStart);
    var current = dateToCheck ? new Date(dateToCheck) : new Date();
    current.setHours(0, 0, 0, 0);
    // var end = new Date(quarterEnd);
    // && current <= end
    // We need to allow users to test pass the current FY year
    return (start <= current);
}

/**
 * Returns opposite boolean of how its being read.
 * 
 * So if we say checkIfQuarterIsStarted(0, 2020) and we are within bounds of quarter 1
 * it would return false and if we are out it would return true
 * 
 * 
 * @param { Number } selectedQuarter 0 -> Quarter 1 and so on ...
 * @param { String } selectedYear 
 * 
 * @returns opposite of what it should return. Make sure you are doing a `!` check
 * 
 */

export function checkIfQuarterIsStarted(selectedQuarter, selectedYear) {
    let obj = calcQuarters(selectedYear);
    // Check per quarter basis, which quarter we are in
    if (checkQuarterBasedOnDate(obj.quarter1.start, obj.quarter2.start)) {
        // Check if quarter 1
        if(selectedQuarter < 1) return false;

    } 
    if (checkQuarterBasedOnDate(obj.quarter2.start, obj.quarter3.start)) {
        if(selectedQuarter < 2) return false;

    } 
    if (checkQuarterBasedOnDate(obj.quarter3.start, obj.quarter4.start)) {
        if(selectedQuarter < 3) return false;

    }
    if (checkQuarterBasedOnDate(obj.quarter4.start, obj.quarter4.end)) {
        if(selectedQuarter < 4) return false;
    }

    return true;
}

/**
 * This function checks whether the quarter has started or passed based on current time
 * 
 * @param { Number } selectedQuarter 0 -> Quarter 1 and so on ...
 * @param { String } selectedYear
 */

export function checkIfQuarterHasStartedOrPassed(selectedQuarter, selectedYear) {
    let obj = calcQuarters(selectedYear);
    let quarterVariable = "quarter" + (parseInt(selectedQuarter) + 1);

    let currentDateTime = new Date(moment().format("MM/DD/YYYY"));
    let quarterStartDateTime = new Date(obj[quarterVariable].start)

    if (currentDateTime >= quarterStartDateTime) return true;

    return false;

}


export function returnQuarterForDate(date, selectedYear) {

    let obj = calcQuarters(selectedYear);
    let q;
    if (checkQuarterBasedOnDate(obj.quarter1.start, obj.quarter2.start, date)) {
        // Check if quarter 1
        q = 1;

    }
    if (checkQuarterBasedOnDate(obj.quarter2.start, obj.quarter3.start, date)) {
        q = 2;
    }
    if (checkQuarterBasedOnDate(obj.quarter3.start, obj.quarter4.start, date)) {
        q = 3;
    }
    if (checkQuarterBasedOnDate(obj.quarter4.start, obj.quarter4.end, date)) {
        q = 4;
    }

    return q
}