import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MdMenu } from "react-icons/md";
import { Skeleton } from "@mui/lab";
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import { getGridSingleSelectOperators } from '@mui/x-data-grid-pro';
import { PalmettoReactTable } from "@zawarski/palmetto-direflow-react-table";
// Local imports
import { getFileUploadedLinkFromBucket } from '../../common/FileUploadApi';
import { checkIfQuarterIsStarted } from '../../utilities/QuarterDates';

// Redux

import {
    getCountyGroupNarrativeDataM2M,
    printSOWTracker,
    selectQuarterProgressReporting,
    setCurrentProgressReportFilterModel,
    setProgressReportingStateCountiesTableSort,
    printTable,
    setLoading
} from "../../../redux/actions/index";


// CSS imports
//import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        counties: state.rootReducer.counties,
        progressReportingCounties: state.rootReducer.progressReportingCounties,
        currentProgressReportFilterModel: state.rootReducer.currentProgressReportFilterModel,
        selectedQuarterProgress: state.rootReducer.selectedQuarterProgress,
        progressReportingStateCountiesTableSort: state.rootReducer.progressReportingStateCountiesTableSort,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCountyGroupNarrativeDataM2M: (payload, callback) => (dispatch(getCountyGroupNarrativeDataM2M(payload, callback))),
        printSOWTracker: (payload, callback) => (dispatch(printSOWTracker(payload, callback))),
        selectQuarterProgressReporting: (payload) => (dispatch(selectQuarterProgressReporting(payload))),
        setProgressReportingStateCountiesTableSort: (payload) => (dispatch(setProgressReportingStateCountiesTableSort(payload))),
        printTable: (type, payload, callback) => dispatch(printTable(type, payload, callback)),
        setCurrentProgressReportFilterModel: (payload) => dispatch(setCurrentProgressReportFilterModel(payload)),
        setLoading: (payload) =>
        dispatch(setLoading(payload)),  
    }
};

const filterOperators = getGridSingleSelectOperators().filter(({ value }) =>
    ['isAnyOf' /* add more over time */ ].includes(value),
);

const COLUMNS = [
    {
      minWidth: 100,
      field: "index",
      numeric: true,
      headerName: "#",
      enabled: false,
      filterable: false
  },
  {
      minWidth: 200,
      field: "updatedGroupName",
      numeric: false,
      headerName: "County",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "updatedGroupName"
  },
  {
      minWidth: 200,
      field: "completion",
      numeric: false,
      headerName: "Completion",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "completion"
  },
  {
      minWidth: 100,
      field: "region",
      numeric: false,
      headerName: "Region",
      enabled: true,
      filterable: false
  },
  {
    minWidth: 140,
    field: "updatedProgressReportingTaskingSlipStatus",
    numeric: false,
    headerName: "Tasking slip",
    enabled: true,
    type: 'singleSelect',
    valueOptions: [
        { "value": "Complete", "label": "Complete" },
        { "value": "Incomplete", "label": "Incomplete" }
    ],
    renderCell: param => showStatusCell(param.value),
    filterOperators
    }
  ];

const defaultFilterModel =
{"items":[{"columnField":"updatedStatus","operatorValue":"isAnyOf","id":31248,"value":[]}],"linkOperator":"and","quickFilterValues":[],"quickFilterLogicOperator":"and"};

    
const showStatusCell = (value, mobile = false) => {
    const style = {};
    style.paddingTop = "8px";
    style.paddingBottom = "9px";
    style.paddingLeft = "0";
    style.textAlign = "center";
    style.borderRadius = "0";
    switch (value.toLowerCase()) {
        case "processed":
        case "complete":
            style.whiteSpace = 'nowrap';
            style.backgroundColor = '#19C922';
            style.color = 'rgba(255, 255, 255, 0.87)';
            break;
        case "approved":
        case "completed":
        case "awarded":
            style.whiteSpace = 'nowrap';
            style.backgroundColor = '#417505';
            style.color = 'rgba(255, 255, 255, 0.87)';
            break;
        case "changes requested":
        case "tasked":
        case "submitted with issues":
            style.backgroundColor = '#4b97f2';
            style.color = 'rgba(255, 255, 255, 0.87)';
            break;
        case "cancelled":
            style.backgroundColor = '#990013';
            style.color = 'rgba(255,255,255,0.87)';
            break;
        case "draft":
        case "incomplete":
        case "not available":
            style.whiteSpace = 'nowrap';
            style.backgroundColor = '#A1A1A1';
            style.color = 'rgba(0, 0, 0, 0, 0.87)';
            break;
        case "not started":
            style.whiteSpace = 'nowrap';
            style.backgroundColor = '#4A4A4A';
            style.color = 'rgba(255, 255, 255, 0.87)';
            break;
        case "submitted":
            style.whiteSpace = 'nowrap';
            style.backgroundColor = '#C2B61B';
            style.color = 'rgba(0, 0, 0, 0, 0.87)';
            break;
        default:
            style.backgroundColor = 'black';
            style.color = 'rgba(255, 255, 255, 0.87)';
            break;
    }
    style.marginTop = '-10px';
    style.marginLeft = '-10px';
    style.marginRight = '-10px';
    style.textOverflow = 'ellipsis';
  
    style.overflow = 'hidden';
    style.height = '40px !important';
    style.minHeight = '40px !important';
    return (
        <>{value !== undefined ?
            <div style={style} className={`${mobile ? "" : "flex"}`}>{value}</div> :
            <Skeleton style={{height: 20, width: '100%'}}/>
        } </>);
  }

class ProgressReportStatePage extends React.Component {
    
    state = {
        counties: this.props.progressReportingCounties || [],
        selectedQuarter: this.props.selectedQuarterProgress || 0,
        anchorEl: null,
        filterModel: this.props.currentProgressReportFilterModel || defaultFilterModel,
        progressReportingStateCountiesTableSort: this.props.progressReportingStateCountiesTableSort
    }

    constructor(props, context) {
        super(props, context);
        this.selectedCountyCallback = this.selectedCountyCallback.bind(this);
        this.checkIfQuarterIsStarted = checkIfQuarterIsStarted;
    }

    componentDidUpdate(prevProps, prevState) {
        // Typical usage (don't forget to compare props):
        if (this.state.filterModel !== prevState.filterModel) {
          this.setState({filterModel: this.state.filterModel});
        }
        this.props.setLoading(false);
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    selectTab = (index) => {
        let filterModel = this.state.filterModel;
        this.setState({
          filterModel: filterModel,
          selectedQuarter: index
        });
        this.props.selectQuarterProgressReporting(index);
      };
    
      onRowClick = (rowInfo) => {
        setTimeout(() => {
          this.props.setLoading(true);
          rowInfo.row.quarter = "quarter" + (this.state.selectedQuarter + 1);
          this.props.getCountyGroupNarrativeDataM2M(rowInfo.row);
          this.props.history.push("/progressreport/state/county/")
          }
        );
       };
    

      printTable = () => {
        var data = this.getQuarterData(this.state.counties, this.state.selectedQuarter)
        var payload = {
            items: [],
            columns: [
                {
                    title: "County",
                    path: "updatedGroupName",
                    checked: true,
                },
                {
                    title: "Completion",
                    path: "completion",
                    checked: true,
                },
                {
                    title: "Region",
                    path: "region",
                    checked: true,
                },
                {
                    title: "Tasking Slip Status",
                    path: "updatedProgressReportingTaskingSlipStatus",
                    checked: true,
                },
                {
                    title: "Quarter",
                    path: "quarter",
                    checked: true,
                },
            ],
            timezone: "America/New_York"
        }
        data.forEach((item) => {
            payload.items.push({
                updatedGroupName: " " + item.updatedGroupName,
                updatedProgressReportingTaskingSlipStatus: " " + item.updatedProgressReportingTaskingSlipStatus,
                completion: " " + item.completion + "%",
                region: " " + item.region,
                quarter: " Quarter " + (parseInt(this.state.selectedQuarter) + 1),
            })
        })
        this.props.printTable("progressreporting", payload);
        this.handleClose()
    }    

    getGroupNarrativesCompletion = (narratives, finItems) => {

        if(narratives.items && narratives.items.length) {

            let narrativesForSelectedQuarter = 0;

            narratives.items.forEach((item) => {
                if ((parseInt(item.quarter.split("").reverse().join("")) === (this.state.selectedQuarter + 1))) {
                    narrativesForSelectedQuarter++;
                }
            })


            let approvedNarratives = 0;
            narratives.items.forEach( (item) => {
                if ((parseInt(item.quarter.split("").reverse().join("")) === (this.state.selectedQuarter + 1))) {

                    if(item.narrative && ((item.narrative.useFinancialReimbursementStatus)) && finItems && finItems.items && finItems.items.length) {
                        finItems.items.forEach((finItem) => {
                            if((parseInt(finItem.quarter.split("").reverse().join("")) === (this.state.selectedQuarter + 1))) {
                                item.status = finItem.status;
                                if (item.status === "AWARDED") item.status = "APPROVED";
                                else if (item.status === "PROCESSED") item.status = "APPROVED";
                                else if (item.status === "NOT_AVAILABLE") item.status = "DRAFT";
                                else if (item.status === "NOT_STARTED") item.status = "DRAFT";
                            }
                        })
                    }
                    
                    if(item.status && item.status === "APPROVED") {
                        approvedNarratives++;
                    }
                }
            })

            return Math.floor(((approvedNarratives / narrativesForSelectedQuarter) * 100))
        }

        return 0;
    }

    getGroupTaskingSlipStatus = (payload, quarter) => {
        var status = "Incomplete";
        if (payload.quarterlyprogressreporting
            && payload.quarterlyprogressreporting.items
            && payload.quarterlyprogressreporting.items.length) {

            payload.quarterlyprogressreporting.items.forEach((item) => {
                if (item.quarter === quarter) {
                    status = "Complete";
                }
            })
        }
        return status;

    }

    getQuarterData = (counties, selectedQuarter) => {
        let quarter = "quarter" + (selectedQuarter + 1);

        var arr = [];
        counties.forEach((county) => {
            arr.push(
                {
                    ...county,
                    ...{
                        updatedGroupName: this._convertToTitleCase(county.groupName),
                        completion: this.getGroupNarrativesCompletion(county.narratives, county.financialreimbursement),
                        updatedProgressReportingTaskingSlipStatus: this.getGroupTaskingSlipStatus(county, quarter),
                    },
                }
            )

        });

        return arr;
    }
    
    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    downloadProgressReportingUserGuide = async () => {
        this.handleClose();
        var a = await getFileUploadedLinkFromBucket({ key: "LEMPG-ProgressReporting.pdf" }, "palmetto-lempg-global-documents");
        window.open(a);
    }

    printSOWTracker = () => {
        this.handleClose();
        this.props.printSOWTracker()
    }

    onSortChange = (column) => {
        this.props.setProgressReportingStateCountiesTableSort(column)
    }

    selectedCountyCallback(...args) { }



    render() {
        let quarterData = this.getQuarterData(
          this.state.counties,
          this.state.selectedQuarter);
      
          const quarterSubheaderTitle = "Q" + parseInt((this.props.currentQuarter && this.props.currentQuarter.quarter && this.props.currentQuarter.quarter.split("").reverse()) || "1")
            
        //console.log("QD: " + JSON.stringify(quarterData));
       let tabs =  <Tabs
       className="layout horizontal center"
       value={this.state.selectedQuarter}
       indicatorColor="primary"
       textColor="primary"
       classes={{
         root: "tabs-root",
         flexContainer: "flex",
         indicator: "tab-indicator-color",
       }}
       // onChange={handleChange}
       aria-label="disabled tabs example"
     >
       <Tab
         className="flex"
         classes={{
           root: "tab-root",
           selected: "tab-selected",
         }}
         onClick={() => this.selectTab(0)}
         label="Q1"
       />
    
       <Tab
         className="flex"
         classes={{
           root: "tab-root",
           selected: "tab-selected",
         }}
         onClick={() => this.selectTab(1)}
         label="Q2"
       />
       <Tab
         className="flex"
         classes={{
           root: "tab-root",
           selected: "tab-selected",
         }}
         onClick={() => this.selectTab(2)}
         label="Q3"
       />
       <Tab
         className="flex"
         classes={{
           root: "tab-root",
           selected: "tab-selected",
         }}
         onClick={() => this.selectTab(3)}
         label="Q4"
       />
     </Tabs>;
    
        return (
          <>
          <div style={{display:!this.props.loading ? 'none' : 'flex', zIndex:'9',position:'absolute', top:'57px',left:'0',width:'100vw',height:'100vh',backgroundColor:'white'}} className="layout vertical vertical-center full-height">
              <Fade
                  in={this.props.loading}
                  size={80}
                  unmountOnExit
              >
                  <CircularProgress />
              </Fade>
          </div>
         
          <div className="layout vertical full-height">
              <PalmettoReactTable
                columns={COLUMNS}
                dataset={quarterData}
                extraHeaders={<></>}
                filterModel={this.state.filterModel}
                headerIcon={
                  <IconButton aria-label="menu"
                    onClick={
                     () => window.dispatchEvent(new CustomEvent("toggle-sidebar"))
                    }
                  >
                      <MdMenu className="icon"/>
                  </IconButton>
                }
                onFilterModelChange={ (params) => {
                  this.props.setCurrentProgressReportFilterModel(params);
                }}
                gridToolbar={true}
                idField={"id"}
                subheader={tabs}
                limit={200}
                onRowClick={this.onRowClick}
                loading={true}
                subtitleSuffix={"reports • " + this.props.selectedYear}
                title={"Progress Reporting"}
                rowHeight={48}
                virtualRowHeight={48}
                MenuItems={[
                        { onClick: () => this.printTable(), title: 'Print table' },
                        { onClick:() => this.printSOWTracker(), title: 'Print SOW tracker' },
                        { onClick: () => this.downloadProgressReportingUserGuide(), title: 'Help' }
                ]}
              />
          </div>
          </>
        );
      }
    }

export default connect(mapStateToProps, mapDispatchToProps)(ProgressReportStatePage);
