import React from 'react';
import { connect } from 'react-redux'
import numeral from 'numeral';

import "../../../styles/common.css";
import "../../../styles/common.charts.css";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear
    };
};


class BSIROtherCard extends React.Component {

    state = {
        item: {
            title: "",
            timeRemaining: "",
        }
    }

    constructor(props, context) {
        super(props, context);
    }

    calculateTotalBudgetedOther = () => {
        var amount = 0;
        var applicationTypes = ['contractualService', 'travel', 'equipment', 'supply', 'other', 'cert'];

        if (this.props.counties && this.props.counties.length) {
            this.props.counties.forEach((county) => {
                if (county && county.grantapplication && county.grantapplication.items && county.grantapplication.items.length) {
                    county.grantapplication.items.forEach((grantApp) => {
                        applicationTypes.forEach((type) => {
                            grantApp[type].items.forEach((item) => {
                                if (!item.deleted) {

                                    if (item['federal'] && parseFloat(item['federal']) && !isNaN(parseFloat(item['federal']))) {
                                        amount += parseFloat(item['federal']);
                                    }

                                }
                            })

                        })

                    })
                }
            })
        }

        return numeral(amount).format('($ 0,0.00)');
    }

    calculateTotalExpendedOther = () => {
        var amount = 0;
        var applicationTypes = ['contractualService', 'travel', 'equipment', 'supply', 'other', 'cert'];

        if (this.props.counties && this.props.counties.length) {
            this.props.counties.forEach((county) => {
                if (county && county.financialreimbursement && county.financialreimbursement.items && county.financialreimbursement.items.length) {
                    county.financialreimbursement.items.forEach((finReim) => {
                        if (finReim.status === 'PROCESSED') {
                            applicationTypes.forEach((type) => {
                                finReim[type].items.forEach((item) => {
                                    if (!item.deleted) {
                                        if (item['quarterAmount'] && parseFloat(item['quarterAmount']) && !isNaN(parseFloat(item['quarterAmount']))) {
                                            amount += parseFloat(item['quarterAmount']);
                                        }

                                    }
                                })
                            })
                        }
                    })
                }
            })
        }

       return numeral(amount).format('($ 0,0.00)');
    }

    render() {
        return (

            <div className="pie-chart-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">BSIR Other (Budgeted | Expended)</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="layout horizontal center bsir-other-container">
                    <span className="bsir-other-amount">{this.calculateTotalBudgetedOther()} &nbsp; | &nbsp;</span>
                    <span className="bsir-other-amount">{this.calculateTotalExpendedOther()}</span>
                </div>
            </div>
        )
    }

}

export default connect(mapStateToProps, null)(BSIROtherCard);
