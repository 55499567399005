// All styles are referenced from https://app.zeplin.io/project/594c3653f67172e69b3cb0f7/styleguide/components

// Mockup https://app.zeplin.io/project/594c3653f67172e69b3cb0f7/styleguide/components

import styled from 'styled-components';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';

export const PrimaryButton = styled(Button)`
    && {
        height: 40px;
        border-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        background-color: var(--primary-button-color);
        color: var(--primary-button-text-color);
        max-width: 288px;
        width: ${props => props.setwidth ? "256px" : undefined};
        margin: ${props => props.margin ? props.margin : undefined};


        :hover {
            background-color: var(--primary-button-hover-color);
        }

        :active {
            background-color: var(--primary-button-active-color);
        }

        @media screen and (max-width: 768px) {
            width: ${props => props.setwidth ? "256px" : "100%"};
            max-width: 100%;
        }

    }
`;

export const DeleteButton = styled(Button)`
    && {
        height: 40px;
        border-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        background-color: var(--danger-button-color);
        color: var(--danger-button-text-color);
        max-width: 288px;
        width: ${props => props.setwidth ? "256px" : undefined};
        margin: ${props => props.margin ? props.margin : undefined};


        :hover {
            background-color: var(--danger-button-hover-color);
        }

        :active {
            background-color: var(--danger-button-active-color);
        }

        @media screen and (max-width: 768px) {
            width: ${props => props.setwidth ? "256px" : "100%"};
            max-width: 100%;
        }

    }
`;

export const SecondaryButton = styled(Button)`
    && {
        height: 40px;
        border-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        background-color: var(--secondary-button-color);
        color: var(--secondary-button-text-color);
        max-width: 288px;
        width: ${props => props.setwidth ? (props.widthpx ? props.widthpx : "256px") : undefined};
        margin: ${props => props.margin ? props.margin : undefined};


        :hover {
            background-color: var(--secondary-button-hover-color);
        }

        :active {
            background-color: var(--secondary-button-active-color);
        }

        @media screen and (max-width: 768px) {
            width: ${props => props.setwidth ? (props.widthpx ? props.widthpx : "256px") : "100%"};
            max-width: 100%;
        }

    }
`;

export const DownloadButton = styled(Button)`
    && {
        height: 40px;
        border-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        background-color: var(--secondary-button-color);
        color: var(--secondary-button-text-color);


        :hover {
            background-color: var(--secondary-button-hover-color);
        }

        :active {
            background-color: var(--secondary-button-active-color);
        }

        @media screen and (max-width: 768px) {
        }

    }
`;


export const GreenButton = styled(Button)`
    && {
        height: 40px;
        border-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        background-color: var(--green-button-color);
        color: var(--green-button-text-color);
        max-width: 288px;
        width: ${props => props.setwidth ? "256px" : undefined};


        :hover {
            background-color: var(--green-button-hover-color);
        }

        :active {
            background-color: var(--green-button-active-color);
        }

        @media screen and (max-width: 768px) {
            width: ${props => props.setwidth ? "256px" : "100%"};
            max-width: 100%;
        }

    }
`;

export const HeaderButton = styled(Button)`
    && {
        color: rgba(255, 255, 255, 0.87);
		margin: 0;
        min-width: 0;
        font-weight: bold;
        margin-right: ${props => props.nomargin ? "0px" : "16px"};
        letter-spacing: 0.5px;
    }
`

export const PaperFabButton = styled(Fab)`
    && {
        height: 56px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
        background-color: #ff4081;
        right: 24px;
        bottom: 24px;
        position: absolute;

        :hover {
            background-color: #ff4081;
        }
    }
`;