import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdMenu } from 'react-icons/md';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { SecondaryButton } from '../../styled-components/Buttons/Buttons.js';
import { getQuarter } from '../financial_reimbursements/FinancialCommonApi';

// js
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { CardComponent2R1C } from "../../layout/CardComponent";

import { getCountyAction, getFilesAssociatedWithCounty, genPersonnelReport, printTable } from "../../../redux/actions/index";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCountyAction: (payload, callback) => dispatch(getCountyAction(payload, callback)),
        getFilesAssociatedWithCounty: () => dispatch(getFilesAssociatedWithCounty()),
        genPersonnelReport: (payload, callback) => dispatch(genPersonnelReport(payload, callback)),
        printTable: (type, payload, callback) => dispatch(printTable(type, payload, callback)),
    }
}


class FileLibraryState extends React.Component {

    state = {
        selectedTab: 1,
        smallScreen: false,
    }

    constructor(props, context) {
        super(props, context);
        this.selectedCountyCallback = this.selectedCountyCallback.bind(this);
    }

    checkWidth = () => {
        const match = window.matchMedia(`(max-width: 768px)`).matches;
        if (match === this.state.smallScreen) return;
        if (match) {
            this.setState({ smallScreen: true });
        } else {
            this.setState({ smallScreen: false });
        }
    }

    componentDidMount() {
        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkWidth);
    }

    getSelectedCountyFiles(county) {
        this.props.getCountyAction({ selectedGroupID: parseInt(county.palmettoId) }, this.selectedCountyCallback);
    }

    selectedCountyCallback() {
        this.props.getFilesAssociatedWithCounty()
    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    selectTab = (index) => {
        this.setState({ selectedTab: index })
    }

    downloadPersonnelReport = () => this.props.genPersonnelReport();

    getKeysTotal = (data) => {

        let sum = 0;

        Object.keys(data).forEach((key) => {
            sum += data[key]
        })

        return sum
    }

    downloadCountyMatchReport = () => {

        let data = {}

        for(let i = 0; i < this.props.counties.length; i++) {
            let county = this.props.counties[i];
            data[county.groupName] = data[county.groupName] || {}
            if(county && county.financialreimbursement && county.financialreimbursement.items && county.financialreimbursement.items.length) {
                let items =  county.financialreimbursement.items
                for(let j = 0; j < items.length; j++) {
                    let d = getQuarter(items[j]);
                    data[county.groupName][items[j].quarter] = {}
                    data[county.groupName][items[j].quarter].federal = this.getKeysTotal(d.totalFedExpendedQuarter);
                    data[county.groupName][items[j].quarter].match = this.getKeysTotal(d.totalMatchQuarter);

                }
            }

        }


        var payload = {
            items: [],
            columns: [
                {
                    title: "County",
                    path: "groupName",
                    checked: true,
                },
                {
                    title: "Q1 Match",
                    path: "quarter1match",
                    checked: true,
                },
                {
                    title: "Q1 Federal",
                    path: "quarter1federal",
                    checked: true,
                },
                {
                    title: "Q2 Match",
                    path: "quarter2match",
                    checked: true,
                },
                {
                    title: "Q2 Federal",
                    path: "quarter2federal",
                    checked: true,
                },
                {
                    title: "Q3 Match",
                    path: "quarter3match",
                    checked: true,
                },
                {
                    title: "Q3 Federal",
                    path: "quarter3federal",
                    checked: true,
                },
                {
                    title: "Q4 Match",
                    path: "quarter4match",
                    checked: true,
                },
                {
                    title: "Q4 Federal",
                    path: "quarter4federal",
                    checked: true,
                },
                {
                    title: "YTD Match",
                    path: "ytdmatch",
                    checked: true,
                },
                {
                    title: "YTD Federal",
                    path: "ytdfederal",
                    checked: true,
                },
                {
                    title: "Year",
                    path: "selectedYear",
                    checked: false,
                },

            ],
            timezone: "America/New_York"
        }

        for(let i = 0; i < this.props.counties.length; i++) {
            let quarters = ['quarter1','quarter2','quarter3','quarter4'];
            let obj = {
                'groupName': this.props.counties[i].groupName.charAt(0).toUpperCase() + this.props.counties[i].groupName.slice(1),
                'quarter1match': 0,
                'quarter1federal': 0,
                'quarter2match': 0,
                'quarter2federal': 0,
                'quarter3match': 0,
                'quarter3federal': 0,
                'quarter4match': 0,
                'quarter4federal': 0,
                'ytdmatch': 0,
                'ytdfederal': 0,
                'selectedYear': this.props.selectedYear,
            }
            for(let j = 0; j < quarters.length; j++) {
                obj[quarters[j] + 'federal'] = data[this.props.counties[i].groupName][quarters[j]].federal;
                obj[quarters[j] + 'match'] = data[this.props.counties[i].groupName][quarters[j]].match;
                obj['ytdmatch'] += data[this.props.counties[i].groupName][quarters[j]].match;
                obj['ytdfederal'] += data[this.props.counties[i].groupName][quarters[j]].federal;
            }


            payload.items.push(obj)
        }


        this.props.printTable(`County Match Report FY ${this.props.selectedYear} - ${+(this.props.selectedYear) + 1}`, payload);
    };

    render() {
        return (


            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Documents</span>}
                    titleSmall={<span>LEMPG {this.props.selectedYear}</span>}
                />



                <Tabs className="layout horizontal center"
                    value={this.state.selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{
                        root: "tabs-root",
                        flexContainer: "flex",
                        indicator: "tab-indicator-color",
                    }}
                    // onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(0)}
                        label="State"
                    />

                    <Tab
                        className="flex"
                        classes={{
                            root: "tab-root",
                            selected: "tab-selected",
                        }}
                        onClick={() => this.selectTab(1)}
                        label="County"
                    />
                </Tabs>

                <div className="flex container-overflow-y">
                    {
                        this.state.selectedTab === 1 ? 
                        <>
                            {
                                this.props.counties.map((county) => {
                                    return (
                                        <Link key={county.groupName} to="/filelibrary/county" onClick={() => this.getSelectedCountyFiles(county)} className="layout horizontal">
                                            <CardComponent2R1C
                                                className="flex no-opacity-important-childs"
                                                title={<span style={{ textTransform: "capitalize" }}>{county.groupName}</span>}
                                            />
                                        </Link>

                                    )
                                })
                            }
                        </>
                        :
                        <>
                        {
                            this.state.smallScreen ?
                            <>
                                <div onClick={this.downloadPersonnelReport} className="layout vertical award-letters-download-box cursor-pointer" style={{ width: "100%" }}>

                                    <div className="layout horizontal center award-file">
                                        <span className="award-file-title">{`Personnel Report`}</span>
                                    </div>

                                </div>                            
                                <div onClick={this.downloadCountyMatchReport} className="layout vertical award-letters-download-box cursor-pointer" style={{ width: "100%" }}>

                                    <div className="layout horizontal center award-file">
                                        <span className="award-file-title">{`County Match Report`}</span>
                                    </div>

                                </div>                            
                            </>
                            :
                            <>
                                <div className="layout horizontal award-letters-download-box" style={{ width: "100%" }}>

                                    <div className="layout horizontal flex center award-file form-margin-16">
                                        <span className="award-file-title">{`Personnel Report`}</span>
                                        <div className="flex"></div>
                                        <SecondaryButton onClick={this.downloadPersonnelReport} nowidth="true">
                                            Download
                                        </SecondaryButton>
                                    </div>


                                </div>
                                <div className="layout horizontal award-letters-download-box" style={{ width: "100%" }}>
                                    <div className="layout horizontal flex center award-file form-margin-16">
                                        <span className="award-file-title">{`County Match Report`}</span>
                                        <div className="flex"></div>
                                        <SecondaryButton onClick={this.downloadCountyMatchReport} nowidth="true">
                                            Download
                                        </SecondaryButton>
                                    </div>
                                </div>
                            </>

                        }
                        </>
                    }

                </div>
            </div>
        )
    }
}

// export default FileLibraryState;
export default connect(mapStateToProps, mapDispatchToProps)(FileLibraryState);
