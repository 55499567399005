import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';

import NewChart from "./Charts";

import "../../../styles/common.css";
import "../../../styles/common.charts.css";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        selectedCounty: state.rootReducer.selectedCounty,
    };
};


class CountySpendingByCategoryPieChart extends React.Component {

    state = {

    }

    constructor(props, context) {
        super(props, context);
    }


    getAwardedTotal = (data, type) => {
        let total = 0;

        if (data[type] && data[type].items) {
            for (var i = 0; i < data[type].items.length; i++) {
                let item = data[type].items[i];
                if (!item.deleted) {
                    total += (item.federal && !isNaN(item.federal) ? parseFloat(item.federal) : 0);
                }
            }
        }
        return total;
    }

    returnNonDeletedEntries = (data, type) => {
        let arr = [];

        if (data[type] && data[type].items) {
            for (var i = 0; i < data[type].items.length; i++) {
                let item = data[type].items[i];
                if (!item.deleted) {
                    arr.push(item);
                }
            }
        }

        return arr.length;
    }


    calculateConfig = () => {
        let grantApplication = (this.props.selectedCounty && this.props.selectedCounty.grantapplication && this.props.selectedCounty.grantapplication.items && this.props.selectedCounty.grantapplication.items[0]) || {}

        let data = [],
            labels = [],
            colors = [];

        let isSalaryAvailable = this.returnNonDeletedEntries(grantApplication, "salary") && data.push(this.getAwardedTotal(grantApplication, 'salary'));
        let isContractualServiceAvailable = this.returnNonDeletedEntries(grantApplication, "contractualService") && data.push(this.getAwardedTotal(grantApplication, 'contractualService'));
        let isTravelAvailable = this.returnNonDeletedEntries(grantApplication, "travel") && data.push(this.getAwardedTotal(grantApplication, 'travel'));
        let isEquipmentAvailable = this.returnNonDeletedEntries(grantApplication, "equipment") && data.push(this.getAwardedTotal(grantApplication, 'equipment'));
        let isSupplyAvailable = this.returnNonDeletedEntries(grantApplication, "supply") && data.push(this.getAwardedTotal(grantApplication, 'supply'));
        let isOtherAvailable = this.returnNonDeletedEntries(grantApplication, "other") && data.push(this.getAwardedTotal(grantApplication, 'other'));
        let isCertAvailable = this.returnNonDeletedEntries(grantApplication, "cert") && data.push(this.getAwardedTotal(grantApplication, 'cert'));

        isSalaryAvailable && labels.push("PERSONNEL") && colors.push("#2961fd");
        isContractualServiceAvailable && labels.push("CONTRACTUAL") && colors.push("#4e7dfd");
        isTravelAvailable && labels.push("TRAVEL") && colors.push("#7498fd");
        isEquipmentAvailable && labels.push("EQUIPMENT") && colors.push("#9ab4fd");
        isSupplyAvailable && labels.push("SUPPLIES") && colors.push("#c0d0fd");
        isOtherAvailable && labels.push("OTHER") && colors.push("#dae3fd");
        isCertAvailable && labels.push("CERT") && colors.push("#7498fd");


        let config = {
            type: 'pie',
            data: {
                datasets: [{
                    data: data,
                    backgroundColor: colors,
                    label: 'Dataset 1'
                }],
                labels: labels
            },
            options: {
                layout: {
                    padding: {
                        top: 20
                    }
                },
                responsive: true,
                segmentShowStroke: false,
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                        boxWidth: 16,
                        fontColor: "#333",
                        fontSize: 14,
                        // padding: 30,
                    },
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            var dataset = data.datasets[tooltipItem.datasetIndex];
                            var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                            var total = meta.total;
                            var currentValue = dataset.data[tooltipItem.index];
                            var percentage = parseFloat((currentValue / total * 100).toFixed(1));

                            var spentAmount = this.state.totalAwardAmountForAllCounties * (percentage / 100);

                            return numeral(currentValue).format('($ 0.00 a)');;
                        },
                        title: function (tooltipItem, data) {
                            return data.labels[tooltipItem[0].index];
                        }
                    }
                }
            }
        };

        return config;
    };
   

    render() {
        return (
            <div className="pie-chart-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">County Spending By Category </span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="pie-chart">
                    <NewChart id="CountySpendingByCategoryPieChartComp" config={this.calculateConfig()} />
                </div>
            </div>
        )
    }

}


export default connect(mapStateToProps, null)(CountySpendingByCategoryPieChart);
