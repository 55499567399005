 
import React from 'react';
import { connect } from 'react-redux';
import IconButton from "@mui/material/IconButton";
import { MdMenu, MdMoreVert, MdAdd } from 'react-icons/md';
import { PaperFabButton } from "../../styled-components/Buttons/Buttons.js";
import ReactTable from "react-table";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PalmettoReactTable } from "@zawarski/palmetto-direflow-react-table";
// Local imports

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"

// CSS imports

////import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

// Redux imports
import { resetNarrative, setCurrentNarrative } from "../../../redux/actions/index";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        selectedQuarterProgress: state.rootReducer.selectedQuarterProgress,
        selectedYear: state.rootReducer.selectedYear,
        allNarratives: state.rootReducer.allNarratives,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetNarrative: () => dispatch(resetNarrative()),
        setCurrentNarrative: (payload) => dispatch(setCurrentNarrative(payload)),
    }
};

const COLUMNS = [
    {
      minWidth: 100,
      field: "index",
      numeric: true,
      headerName: "#",
      enabled: false,
      filterable: false
  },
  {
      minWidth: 200,
      field: "narrativeTitle",
      numeric: false,
      headerName: "Title",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "narrativeTitle"
  },
   {
      minWidth: 200,
      field: "narrativeQuarterDisplay",
      numeric: false,
      headerName: "Quarter",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "narrativeQuarterDisplay"
  },
   {
      minWidth: 100,
      field: "narrativeFileAttached",
      numeric: false,
      headerName: "File",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "narrativeFileAttached"
  },
   {
      minWidth: 700,
      field: "narrativeDescription",
      numeric: false,
      headerName: "Description",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "narrativeDescription"
  }
];
    
class NarrativesStatePage extends React.Component {

    state = {
        displayQuarter: {
            "quarter1": "Quarter 1",
            "quarter2": "Quarter 2",
            "quarter3": "Quarter 3",
            "quarter4": "Quarter 4",
            "all": "Recurring",
        },
        anchorEl: null,

    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    getAllNarratives = (narratives) => {
        var arr = []

        narratives.forEach( (data) => {
            arr.push({
                ...data,
                ...{
                    narrativeTitle: data.narrativeTitle,
                    narrativeQuarter: data.quarter,
                    narrativeQuarterDisplay: this.state.displayQuarter[data.quarter],
                    narrativeDescription: data.narrativeShortDescription,
                    narrativeFileAttached: data.uploadedFile ? "Yes" : "",
                }
            })
        })
        return arr.sort( (a, b) => a.narrativeTitle.localeCompare(b.narrativeTitle));
    }

    onRowClick = (rowInfo) => {
        setTimeout(() => {
          this.props.setCurrentNarrative(rowInfo.row);
          this.props.history.push("/narratives/edit");
          }
        );
       };

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    openNewPage = () => {
        this.props.resetNarrative();
        this.props.history.push("/narratives/new")
    }

    updateREMContactInformation = () => {
        this.props.history.push("/narratives/rem")
    }

    
    render() {

        return (

            <div className="layout vertical full-height">
          <PalmettoReactTable
            columns={COLUMNS}
            dataset={this.getAllNarratives(this.props.allNarratives)}
            extraHeaders={<></>}
            hideFilter={true}
            idField={"id"}
            limit={20}
            onRowClick={this.onRowClick}
            loading={true}
            subtitleSuffix={"entries"}
            title={"Narratives"}
             MenuItems={[
                { onClick: () => this.updateREMContactInformation(), title: 'Update REM Contact Information' }
        ]}
        rowHeight={48}
        virtualRowHeight={48}
          />
                    <PaperFabButton onClick={() => this.openNewPage()} aria-label="add">
                        <MdAdd className="icon" />
                    </PaperFabButton>
                </div>
       


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NarrativesStatePage);
