import React from 'react';
import { connect } from 'react-redux'
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import IconButton from "@mui/material/IconButton";
import {
    MdArrowBack,
    MdMail,
    MdMoreVert
} from 'react-icons/md';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
    reissueCheckFinancialReimbursement,
    sendAdditionalCheckFinancialReimbursement,
    resetStatusToApprovedFinancialReimbursement
} from "../../../redux/actions/index";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        currentQuarter: state.rootReducer.currentQuarter,
        grantApplication: state.rootReducer.grantApplication,
        selectedCounty: state.rootReducer.selectedCounty,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reissueCheckFinancialReimbursement: (payload, callback) => dispatch(reissueCheckFinancialReimbursement(payload, callback)),
        sendAdditionalCheckFinancialReimbursement: (payload, callback) => dispatch(sendAdditionalCheckFinancialReimbursement(payload, callback)),
        resetStatusToApprovedFinancialReimbursement: (callback) => dispatch(resetStatusToApprovedFinancialReimbursement(callback)),
    };
};

class CountyApplicationAward extends React.Component {

    state = {
        errors: [],
        anchorEl: null,
        open: false,
        allChecks: []
    }

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);

        if (this.props.currentQuarter && this.props.currentQuarter.currentReissuedCheck) {
            this.state.allChecks.push({
                checkNumber: this.props.currentQuarter.currentReissuedCheck.checkNumber,
                checkAmount: this.props.currentQuarter.currentReissuedCheck.checkAmount,
                checkMailDate: moment(this.props.currentQuarter.currentReissuedCheck.checkMailDate).format("MMM DD, YYYY"),
                checkNumber2: this.props.currentQuarter.currentReissuedCheck.checkNumber2,
                checkAmount2: this.props.currentQuarter.currentReissuedCheck.checkAmount2,
                checkMailDate2: moment(this.props.currentQuarter.currentReissuedCheck.checkMailDate2).format("MMM DD, YYYY")
            })
        } else {
            this.state.allChecks.push({
                checkNumber: this.props.currentQuarter.checkNumber,
                checkAmount: this.props.currentQuarter.checkAmount,
                checkMailDate: moment(this.props.currentQuarter.checkMailDate).format("MMM DD, YYYY"),
                checkNumber2: this.props.currentQuarter.checkNumber2,
                checkAmount2: this.props.currentQuarter.checkAmount2,
                checkMailDate2: moment(this.props.currentQuarter.checkMailDate2).format("MMM DD, YYYY"),
            })
        }

        if (this.props.currentQuarter && this.props.currentQuarter.additionalChecks && this.props.currentQuarter.additionalChecks.items) {
            this.props.currentQuarter.additionalChecks.items.forEach( (check) => {
                if (check.checkAmount && check.checkNumber && check.checkMailDate) {
                    this.state.allChecks.push({
                        checkNumber: check.checkNumber,
                        checkAmount: check.checkAmount,
                        checkMailDate: moment(check.checkMailDate).format("MMM DD, YYYY"),
                        checkNumber2: check.checkNumber2,
                        checkAmount2: check.checkAmount2,
                        checkMailDate2: moment(check.checkMailDate2).format("MMM DD, YYYY"),
                    })
                }
            })
        }
    }

    goBack() {
        this.props.history.goBack();
    }

    
    handleIconDropdownClick = event => {
        this.state.anchorEl = event.currentTarget;
        this.setState({
            anchorEl: this.state.anchorEl
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    
    handleClose = (variable) => {
        this.setState({
            [variable]: false
        });
    };
    
    openDialog = (variable) => {
        this.setState({
            [variable]: true
        });
        this.handleMenuClose()
    }

    reissueCheck = () => {
        this.handleClose();
        this.props.reissueCheckFinancialReimbursement()
    }

    sendAdditionalCheck = () => {
        this.handleClose();
        this.props.sendAdditionalCheckFinancialReimbursement()
    }

    resetStatusToApprovedFinancialReimbursement =  () => {
        this.props.resetStatusToApprovedFinancialReimbursement(() => {
            this.props.history.goBack()
        })
    }

    getCheckNumber = () => {
        if (this.props.currentQuarter && this.props.currentQuarter.currentReissuedCheck) {
            return this.props.currentQuarter.currentReissuedCheck.checkNumber;
        }
        return this.props.currentQuarter && this.props.currentQuarter.checkNumber;
    }

    getCheckAmount = () => {
        if (this.props.currentQuarter && this.props.currentQuarter.currentReissuedCheck) {
            return this.props.currentQuarter.currentReissuedCheck.checkAmount;
        }
        return this.props.currentQuarter && this.props.currentQuarter.checkAmount
    }

    getCheckMailDate = () => {
        if (this.props.currentQuarter && this.props.currentQuarter.currentReissuedCheck) {
            return moment(this.props.currentQuarter.currentReissuedCheck.checkMailDate).format("MMM DD, YYYY");
        }
        return this.props.currentQuarter && moment(this.props.currentQuarter.checkMailDate).format("MMM DD, YYYY");
    }

    render() {
        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())
        const {
            anchorEl
        } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div className="layout vertical full-height">

                <Dialog
                    open={this.state.open}
                    onClose={() => this.handleClose("open")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{ maxWidth: "320px" }}>
                        <DialogTitle id="alert-dialog-title">{"Reissue check"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You are about to reissue a check to the {<span style={{ textTransform: "capitalize" }}> {this.props.selectedCounty && this.props.selectedCounty.groupName} </span>} County.This is done
                                if current check is lost & voided.Reissue check amount cannot be changed.This will change the {<span style={{ textTransform: "capitalize" }}> {this.props.selectedCounty && this.props.selectedCounty.groupName} </span>} County’ s status from <b>Processed</b> back to <b>Approved</b> Are you sure you want to do this ?
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.handleClose("open")} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.reissueCheck} color="primary" autoFocus>
                                Continue
                            </Button>
                        </DialogActions>
                    </div>

                </Dialog>

                <Dialog
                    open={this.state.openAdditionalCheckDialog}
                    onClose={() => this.handleClose("openAdditionalCheckDialog")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{ maxWidth: "320px" }}>
                        <DialogTitle id="alert-dialog-title">{"Additional check"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You are about to send an additional check to {<span style={{ textTransform: "capitalize" }}> {this.props.selectedCounty && this.props.selectedCounty.groupName} </span>} County.This is done if the county is entitled to additonal reimbursement funding.  This will change  {<span style={{ textTransform: "capitalize" }}> {this.props.selectedCounty && this.props.selectedCounty.groupName} </span>} County’ s status from <b>Processed</b> back to <b>Approved</b> Are you sure you want to do this ?
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.handleClose("openAdditionalCheckDialog")} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.sendAdditionalCheck} color="primary" autoFocus>
                                Continue
                            </Button>
                        </DialogActions>
                    </div>

                </Dialog>

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Financial Reimbursement</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{quarterSubheaderTitle}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                    suffix={
                        this.props.lempgUserType !== "LEMPG_ACCESS" ?
                        <div className="layout horizontal">
                            <MdMoreVert onClick={this.handleIconDropdownClick} className="icon" />
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'reissue-menu' : undefined}
                                
                            >
                            </IconButton>

                            <Menu
                                id="reissue-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                onClose={this.handleMenuClose}
                            >
                                <MenuItem key="resetApprovedStatus" onClick={() => this.resetStatusToApprovedFinancialReimbursement()}>
                                    Reset status to Approved
                                </MenuItem>
                                <MenuItem key="reissueCheck" onClick={() => this.openDialog("open")}>
                                    Reissue check
                                </MenuItem>
                                <MenuItem key="additionalCheck" onClick={() => this.openDialog("openAdditionalCheckDialog")}>
                                    Additional check
                                </MenuItem>
                            </Menu>
                        </div>
                        : ""
                    }
                />

                <div className="flex layout vertical only-vertical-center">

                    <div className="layout horizontal center center-justified">
                        <MdMail className="icon-2 application-status-icon" />
                    </div>
                    <div className="height-20"></div>
                    <span className="layout horizontal center center-justified application-status-title-small">Check sent</span>
                    <div className="height-20"></div>

                    <div className="layout horizontal center center-justified">
                        <div style={{ padding: "0 16px", maxWidth: "580px" }} className="text-center title-big opacity-54">{quarterSubheaderTitle} {this.props.selectedYear} Financial Reimbursment for <span style={{ textTransform: "capitalize" }}> {this.props.selectedCounty && this.props.selectedCounty.groupName} </span> County has been completed. </div>
                    </div>

                    <div className="height-20"></div>
                    <div className="layout horizontal wrap center-justified">

                        {
                            this.state.allChecks.map( (item) => {
                                return (
                                    <div key={item.checkNumber} className="layout vertical check-details-box form-margin-10">

                                        <div className="layout horizontal center center-justified award-file">
                                            <span className="award-file-title">Check/Document Number <br /> -   Check 1</span>

                                            <div className="flex"></div>
                                            <span className="opacity-54">{item.checkNumber}</span>
                                        </div>

                                        <div className="layout horizontal center center-justified award-file">
                                            <span className="award-file-title">Check/Document amount</span>
                                            <div className="flex"></div>
                                            <span className="opacity-54">
                                                <NumberFormat value={item.checkAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            </span>
                                        </div>

                                        <div className="layout horizontal center center-justified award-file">
                                            <span className="award-file-title">Mailed/Transferred Date</span>
                                            <div className="flex"></div>
                                            <span className="opacity-54">{item.checkMailDate}</span>
                                        </div>
                                        {
                                            item.checkNumber2 || item.checkAmount2 || item.checkMailDate2 ? 
                                            <>

                                                <div className="layout horizontal center center-justified award-file">
                                                    <span className="award-file-title">Check/Document Number <br /> -  Check 2</span>
                                                    <div className="flex"></div>
                                                    <span className="opacity-54">{item.checkNumber2}</span>
                                                </div>

                                                <div className="layout horizontal center center-justified award-file">
                                                    <span className="award-file-title">Check/Document amount</span>
                                                    <div className="flex"></div>
                                                    <span className="opacity-54">
                                                        <NumberFormat value={item.checkAmount2} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                    </span>
                                                </div>

                                                <div className="layout horizontal center center-justified award-file">
                                                    <span className="award-file-title">Mailed/Transferred Date</span>
                                                    <div className="flex"></div>
                                                    <span className="opacity-54">{item.checkMailDate2}</span>
                                                </div>
                                            </> : ''
                                        }
                                    </div>

                                )
                            })
                        }


                    </div>

                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CountyApplicationAward);
