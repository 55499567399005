import React, { useRef, useState, useEffect} from 'react';
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { MdClear } from 'react-icons/md';
import CircularProgress from '@mui/material/CircularProgress';
import { NormalTextField} from "../styled-components/TextFields/TextFields.js";
import { HeaderComponent } from "../layout/HeaderComponent.js"
import { PrimaryButton, SecondaryButton } from "../styled-components/Buttons/Buttons.js";
import { CardComponent2R1C } from "../layout/CardComponent";
import { Storage } from 'aws-amplify';
import { saveFile } from "../../redux/actions/index";

import '../../styles/common.css';

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
    };
};


const mapdispatch2props = dispatch => {
    return {
        saveFile: (payload, callback) => dispatch(saveFile(payload, callback)),
    }
}

export const FileUploadDialog = connect(mapStateToProps ,mapdispatch2props)((props) => {
    const [open, setOpen] = React.useState(props.open || false);
    const [uploadedFile, setUploadedFile] = React.useState(false);
    const [fileName, setFileName] = React.useState('');
    const [uploading, setUploading] = useState(false);

    let uploadInput = useRef();

    useEffect( () => {
        if(props.open) {
            setOpen(true);
        }
    }, [props])

    const handleClose = () => {
        setOpen(false);
        props.closeDialog && props.closeDialog()
    };

    const openDialog = () => {
        setUploadedFile(null);
        setOpen(true)
    }

    const saveImg = () => {
        if(Object.keys(uploadedFile).length) {
            let image = {
                'file_name': fileName || uploadedFile.title,
                ...uploadedFile
            }
    
            props.callback && props.callback([image]);
            handleClose();
        }
    }

    const uploadFile = async (file, file_name) => {
        return await Storage.put(file_name, file,{
            contentType: file.type, 
            credentials: {
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            }
        })
    }

    const onUploadChange = async (e) => {
        // Upload it to s3
        let file = e.target.files[0];
        let funcs = [];
        file.downloadTitle = fileName;
        let file_name = file.name;
        file_name = "" + new Date().getTime() + "__" + file_name;

        setUploading(true);
        funcs.push(uploadFile(file, file_name));


        try {
            let uploadResponse = await Promise.all(funcs);
            let payload = {
                key: file_name,
                title: file.name,
                downloadTitle: file.downloadTitle || file.name || null,
                size: file.size || null,
            }

   
            props.saveFile(payload, (fileRes) => {
                console.log('data saved', fileRes)
                setUploading(false);
                setUploadedFile(fileRes.data.createFile);
            })

        } catch (e) {
            console.error('Error occured while uploading file', e)
            setUploading(false);
        }
    }

    const onInputChange = (event) => {
        setFileName(event.target.value);
    }

    const selectFile = () => {
        uploadInput.click();
    }

    return (

        <>
            <Dialog 
            open={open} 
            onClose={handleClose} 
            PaperProps={{
                style: {
                    width: '320px',
                    boxShadow: 'none',
                },
            }}
            aria-labelledby="form-dialog-title">
                <DialogTitle style={{ padding: '0px'}}>
                    <HeaderComponent
                        icon={
                            <IconButton aria-label="close" onClick={handleClose}>
                                <MdClear className="icon" />
                            </IconButton>
                        }
                        title={<span> Upload Image </span>}
                    />
                </DialogTitle>
                <DialogContent classes={{ root: 'no-overflow-y dialog-content-padding-adjust'}}>
                    <NormalTextField
                        onChange={onInputChange}
                        autoFocus
                        margin="dense"
                        id="file_name"
                        placeholder='Enter a caption'
                        label="Caption"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <div className='height-20'></div>

                    {
                        uploading ? 
                        <div className='layout horizontal center center-justified'>
                            <CircularProgress />
                        </div>
                        :
                        uploadedFile ?
                                <CardComponent2R1C 
                                    title={uploadedFile.title} 
                                    suffix={
                                        <IconButton onClick={() => setUploadedFile(false)}>
                                            <MdClear />
                                        </IconButton>
                                    } 
                                />
                        :
                        <div className='layout horizontal'>
                            
                            <PrimaryButton onClick={selectFile}>Select Photo</PrimaryButton>
                            <div className='flex'></div>
                        </div>
                    }

                </DialogContent>
                
                <input ref={input => { uploadInput = input; }} type="file" onChange={(e) => onUploadChange(e)} />
                <div className='height-20'></div>
                <DialogActions>
                    {/* <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button> */}
                    <Button onClick={saveImg} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <div className='height-10'></div>
            <div className='layout horizontal'>
                <div className='flex'></div>
                <SecondaryButton onClick={openDialog}>Add photo</SecondaryButton>
            </div>
        </>


    );
})