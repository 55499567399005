import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdArrowBack } from 'react-icons/md';

import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { NumberFormatPhone } from "../../utilities/NumberFormat";
import { ValidateEmailAddress, ValidatePhoneNumber } from "../../utilities/utils";

import ErrorBox from "../../common/ErrorBox";

import { RequiredDropdown } from "../../styled-components/Dropdowns/Dropdown.js";
import { HeaderComponent } from "../../layout/HeaderComponent.js"
import { HeaderButton, DeleteButton, PrimaryButton } from "../../styled-components/Buttons/Buttons.js";
import { NormalTextField, RequiredTextField} from "../../styled-components/TextFields/TextFields.js";
import FileUpload from "../../common/FileUpload.js";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

import { createNarrative, updateNarrative, deleteNarrative } from "../../../redux/actions/index";



const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        currentNarrative: state.rootReducer.currentNarrative,
        currentGroupNarrativeM2M: state.rootReducer.currentGroupNarrativeM2M,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createNarrative: (payload, callback) => dispatch(createNarrative(payload, callback)),
        updateNarrative: (payload, currentNarrative, callback) => dispatch(updateNarrative(payload, currentNarrative, callback)),
        deleteNarrative: (currentNarrative, callback) => dispatch(deleteNarrative(currentNarrative, callback)),
    }
}


const DropdownMenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
    },
    PaperProps: {
        style: {
            minWidth: "200px",
        },
    },
};

class NarrativesEditpage extends React.Component {

    state = {
        narrativeQuarter: "",
        
        narrativeTitle: (this.props.currentNarrative && this.props.currentNarrative.narrativeTitle) || null,
        narrativeCategory: (this.props.currentNarrative && this.props.currentNarrative.narrativeCategory) || null,
        narrativeShortDescription: (this.props.currentNarrative && this.props.currentNarrative.narrativeShortDescription) || null,
        narrativeLongDescription: (this.props.currentNarrative && this.props.currentNarrative.narrativeLongDescription) || null,
        // Recurring | Once
        narrativeFrequency: (this.props.currentNarrative && this.props.currentNarrative.narrativeFrequency) || null,
        narrativePOCFirstName: (this.props.currentNarrative && this.props.currentNarrative.narrativePOCFirstName) || null,
        narrativePOCLastName: (this.props.currentNarrative && this.props.currentNarrative.narrativePOCLastName) || null,
        narrativePOCPhoneNumber: (this.props.currentNarrative && this.props.currentNarrative.narrativePOCPhoneNumber) || null,
        narrativePOCEmailAddress: (this.props.currentNarrative && this.props.currentNarrative.narrativePOCEmailAddress) || null,
        narrativePOCPositionTitle: (this.props.currentNarrative && this.props.currentNarrative.narrativePOCPositionTitle) || null,
        usePalmettoForREMInformation: (this.props.currentNarrative && this.props.currentNarrative.usePalmettoForREMInformation) || false,
        useFinancialReimbursementStatus: (this.props.currentNarrative && this.props.currentNarrative.useFinancialReimbursementStatus) || false,
        quarter: (this.props.currentNarrative && this.props.currentNarrative.quarter) || "",

        file_id: this.props.currentNarrative && this.props.currentNarrative.uploadedFile && this.props.currentNarrative.uploadedFile.id || null,
        errors: [],
        validate: false,

    }

    constructor(props, context) {
        super(props, context);

        this.fileToSave = this.fileToSave.bind(this);
        this.fileToDelete = this.fileToDelete.bind(this);
    }

    goBack = () => {
        this.props.history.goBack()
    }

    submitForm(e) {
        this.props.history.push("/narratives/view");
    }


    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    performErrorCheck = (callback) => {
        this.state.validate = false;
        this.state.phoneValidate = false;
        this.state.errors = [];
        this.setState({ phoneValidate: this.state.phoneValidate });
        this.setState({ validate: this.state.validate });
        this.setState({ errors: this.state.errors });

        if (!this.state.narrativeTitle) {
            this.state.errors.push({
                text: "Please enter narrative title"
            });
        }

        if (!this.state.quarter) {
            this.state.errors.push({
                text: "Please select quarter"
            });
        }

        if (!this.state.usePalmettoForREMInformation) {
            if (!this.state.narrativePOCFirstName) {
                this.state.errors.push({
                    text: "Please enter first name"
                });
            }
    
            if (!this.state.narrativePOCLastName) {
                this.state.errors.push({
                    text: "Please enter last name"
                });
            }

            if (this.state.narrativePOCPhoneNumber && !ValidatePhoneNumber(this.state.narrativePOCPhoneNumber)) {
                this.state.errors.push({
                    text: "Please enter valid phone number"
                });
                this.state.phoneValidate = true;
                this.setState({ phoneValidate: this.state.phoneValidate });
            }
            
            if (!this.state.narrativePOCEmailAddress) {
                this.state.errors.push({
                    text: "Please enter email address"
                });
            }

            if (this.state.narrativePOCEmailAddress && !ValidateEmailAddress(this.state.narrativePOCEmailAddress)) {
                this.state.errors.push({
                    text: "Please enter a valid email address"
                });
            }

        }


        if (this.state.errors.length > 0) {
            this.state.validate = true;
            this.setState({ validate: this.state.validate });
            this.setState({ errors: this.state.errors });
            callback(new Error("Error"));
            return
        }

        callback();
    }

    createNewNarrative = () => {
        // dont let user to update narrative until the error is found: Error:  The quarter in the narrative is changed to `all` after a user uploaded a file
        // return
        // Error checking

        this.performErrorCheck( (errors) => {
            if(!errors) {
                // do db action
                if (this.state.quarter === "all") {
                    this.state.narrativeFrequency = "Recurring"
                } else {
                    // Quarterly status is only available for recurring narratives
                    this.state.useFinancialReimbursementStatus = false;
                    this.state.narrativeFrequency = "Once"
                }

                this.props.createNarrative(this.state, this.goBack)
            }
        })        
    }

    updateNarrative = () => {
        // dont let user to update narrative until the error is found: Error:  The quarter in the narrative is changed to `all` after a user uploaded a file
        // uncommenting return
        // return

        this.performErrorCheck((errors) => {
            if (!errors) {
                // do db action
                if (this.state.quarter === "all") {
                    this.state.narrativeFrequency = "Recurring"
                } else {
                    // Quarterly status is only available for recurring narratives
                    this.state.useFinancialReimbursementStatus = false;
                    this.state.narrativeFrequency = "Once"
                }

                this.props.updateNarrative(this.state, this.props.currentNarrative, this.goBack)
            }
        })        
    }

    deleteNarrative = () => {
        this.props.deleteNarrative( this.props.currentNarrative, this.goBack)
    }

    fileToSave(data) {
        this.setState({ file_id: data.data.createFile.id });
    }

    fileToDelete() {
        this.setState({ file_id: null });
    }

    handleCheckboxChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    };
    
    render() {
        return (
            <div className="layout vertical full-height">

                <HeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    title={<span>{this.props.currentNarrative && this.props.currentNarrative.id ? "Edit Narrative" : "Add Narrative"}</span>}
                    suffix={
                        this.props.currentNarrative && this.props.currentNarrative.id ?
                        <HeaderButton hidden={false} onClick={(e) => this.updateNarrative(e)}> Save </HeaderButton> : ""
                    }
                />

                <div className="flex form-shell container-overflow-y">
                    {
                        this.state.errors.length ? <ErrorBox errors={this.state.errors} /> : ""
                    }

                    <div className="layout horizontal center section-title form-margin">Narrative Details</div>

                    <div className="layout horizontal wrap">
                        <RequiredTextField value={this.state.narrativeTitle} onChange={this.handleChange('narrativeTitle')} error={this.state.validate ? true : false} id="narrativeTitle" label="Title" placeholder="Enter title" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />


                        <FormControl className="flex">
                            <InputLabel style={{ margin: "0 6px" }} shrink htmlFor="selectQuarter"> New quarter <span className="required"></span> </InputLabel>
                            <RequiredDropdown
                                error={this.state.validate ? true : false} 
                                value={this.state.quarter}
                                onChange={this.handleChange('quarter')}
                                input={<Input name="selectQuarter" id="selectQuarter" />}
                                MenuProps={DropdownMenuProps}
                                custommargin="true"
                                displayEmpty
                                name="selectQuarter"
                                className="required-dropdown"
                            >
                                <MenuItem value="">
                                    <span className="placeholder">Select new quarter</span>
                                </MenuItem>
                                <MenuItem value="quarter1">Q1</MenuItem>
                                <MenuItem value="quarter2">Q2</MenuItem>
                                <MenuItem value="quarter3">Q3</MenuItem>
                                <MenuItem value="quarter4">Q4</MenuItem>
                                <MenuItem value="all">Recurring</MenuItem>
                            </RequiredDropdown>
                        </FormControl>

                    </div>

                    <div className="height-10"></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.narrativeShortDescription} onChange={this.handleChange('narrativeShortDescription')} id="narrativeShortDescription" multiline placeholder="Enter short description"  label="Short description" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className="height-10"></div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.narrativeLongDescription} onChange={this.handleChange('narrativeLongDescription')} id="narrativeLongDescription" multiline label="Long Description" placeholder="Enter long description" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                    </div>

                    <div className="height-10"></div>

                    <div className="layout horizontal center section-title form-margin">Point of Contact</div>

                    <div className="form-margin">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.usePalmettoForREMInformation}
                                    onChange={this.handleCheckboxChange}
                                    name="usePalmettoForREMInformation"
                                    color="primary"
                                />
                            }
                            label="Use REM Information from LEMPG"
                        />
                    </div>

                    {
                        !this.state.usePalmettoForREMInformation ?
                        <>
                            <div className="layout horizontal wrap">
                                <RequiredTextField value={this.state.narrativePOCFirstName} onChange={this.handleChange('narrativePOCFirstName')}  error={this.state.validate ? true : false} id="narrativePOCFirstName" label="First name" placeholder="Enter first name" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />

                                <RequiredTextField value={this.state.narrativePOCLastName} onChange={this.handleChange('narrativePOCLastName')} error={this.state.validate ? true : false} id="narrativePOCLastName" label="Last name" placeholder="Enter last name" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true, }} />

                            </div>

                            <div className="height-10"></div>

                            <div className="layout horizontal wrap">
                                <NormalTextField value={this.state.narrativePOCPositionTitle} id="narrativePOCPositionTitle" onChange={this.handleChange('narrativePOCPositionTitle')} label="Position/Title" placeholder="Enter position/title" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true, }} />

                                    <NormalTextField value={this.state.narrativePOCPhoneNumber} error={this.state.phoneValidate ? true : false} id="narrativePOCPhoneNumber" onChange={this.handleChange('narrativePOCPhoneNumber')} label="Phone number" placeholder="Enter phone number" custommargin="true" className="flex" InputProps={{ inputComponent: NumberFormatPhone, readOnly: false, }} InputLabelProps={{ shrink: true,}} />

                                <RequiredTextField value={this.state.narrativePOCEmailAddress} error={this.state.validate ? true : false} onChange={this.handleChange('narrativePOCEmailAddress')} id="narrativePOCEmailAddress" label="Email address" placeholder="Enter email address" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                            </div>
                        </>
                        : ""
                    }

                    {
                        this.state.quarter === 'all' ? 
                        <>
                            <div className="height-10"></div>
                            <div className="layout horizontal center section-title form-margin">Narrative Status</div>

                            <div className="form-margin">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.useFinancialReimbursementStatus}
                                            onChange={this.handleCheckboxChange}
                                            name="useFinancialReimbursementStatus"
                                            color="primary"
                                        />
                                    }
                                    label="Follow Financial Reimbursement Quarterly Status"
                                />
                            </div>                    
                        </>
                        : <></>
                    }



                    <div className="height-10"></div>

                    <div className="layout horizontal end section-title form-margin">File Attachment</div>

                    <div className="layout horizontal form-margin">
                        <FileUpload fileToSave={this.fileToSave} fileToDelete={this.fileToDelete} uploadedFile={this.props.currentNarrative && this.props.currentNarrative.uploadedFile ? this.props.currentNarrative.uploadedFile : null} notitle="true" className="flex" singleupload="true" allowStateToEdit="true" />

                    </div>
                </div>
                

                <div className="layout horizontal center-justified form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                    {
                        this.props.currentNarrative && this.props.currentNarrative.id
                        ?
                            <DeleteButton setwidth="true" onClick={() => this.deleteNarrative()}>
                                Delete
                            </DeleteButton>
                        :
                            <PrimaryButton setwidth="true" onClick={() => this.createNewNarrative()}>
                                Submit
                            </PrimaryButton>
                    }
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NarrativesEditpage);
