import React from 'react';
import { connect } from 'react-redux'

import NewChart from "./Charts";

import "../../../styles/common.css";
import "../../../styles/common.charts.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        counties: state.rootReducer.counties,
        awardAmountForCounties: state.rootReducer.awardAmountForCounties,

    };
};

class CountiesEstimatedBudgetByCategoryLineChart extends React.Component {

    constructor(props, context) {
        super(props, context);
    }


    state = {

    }
  

    getEstimatedCountyBudgetFromGrantApplication = (county) => {
       //console.log("county: " + JSON.stringify(county));
        var arr = []

        /**
         * 
         * Line chart will be calculated by calculating percentage of individual category vs summation of entered amount in category.
         * Values are taken from grant application object
         * 
        */
        

        var applicationTypes = ['salary', 'contractualService', 'travel', 'equipment', 'supply', 'other', 'cert'];

        // First calculate total amount awarded for all types
        // Then calculate how much percent is being used in each category

        if (county.grantapplication && county.grantapplication.items) {
            county.grantapplication.items.forEach( (grantApp) => {

                let amount = 0;
                let obj = {
                    'salary': 0,
                    'contractualService': 0,
                    'travel': 0,
                    'equipment': 0,
                    'supply': 0,
                    'other': 0,
                    'cert': 0,
                }

                applicationTypes.forEach( (type) => {
                    grantApp[type].items.forEach( (item) => {
                        if (!item.deleted) {

                            let typesOfFunding = ["federal"]
                            let sum = 0;

                            typesOfFunding.forEach((typeOfFunding) => {

                                if (item[typeOfFunding] && parseFloat(item[typeOfFunding]) && !isNaN(parseFloat(item[typeOfFunding]))) {
                                    sum += parseFloat(item[typeOfFunding]);
                                }
                            })

                            // Individual category sum
                            obj[type] += sum;

                            // Total sum
                            amount += sum;
                        }
                    })

                })
                
                // Calculate average for all types
              //  console.log("amount:" + amount);
                Object.keys(obj).forEach((type) => {
                    let a = isNaN((obj[type] / amount) * 100) ? 0 : ((obj[type] / amount) * 100)
                    arr.push(a)
                });

            });
        }
      
        return arr;
    }

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }


    getDataSet = () => {

        var arr = []
        var average = []

        this.props.counties.forEach((county) => {
            arr.push({
                label: this._convertToTitleCase(county.groupName),
                backgroundColor: "#a1a1a1",
                borderColor: "#a1a1a1",
                borderWidth: '1',
                data: this.getEstimatedCountyBudgetFromGrantApplication(county),
                fill: false,
            })
        })

        var applicationTypes = ['salary', 'contractualService', 'travel', 'equipment', 'supply', 'other', 'cert'];

        /**
         * Now we need to find how much average amount each county spends
         * To do that we will calculate summation of individual type 
         *  
        */

        applicationTypes.forEach((type, index) => {
            let sum = 0;
            arr.forEach( (item) => {
                sum += item.data[index];
            })
            average.push( (sum / arr.length))
        })

        arr.unshift({
            label: 'Estimated average budget spend by category',
            backgroundColor: "#2961fd",
            borderColor: "#2961fd",
            borderWidth: '4',
            data: average,
            fill: false,
        })

        return arr;
    }


    calculateConfig = () => {
        let datasets = this.getDataSet()
       // console.log("calcconfig:" + JSON.stringify(datasets));
        return {
            type: 'line',
            data: {
                labels: ['Personnel', 'Contractual Services', 'Travel', 'Equipment', 'Supply', 'Other', 'CERT'],
                datasets: datasets
            },
            options: {
                responsive: true,
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                layout: {
                    padding: {
                        top: 16
                    }
                },
                elements: {
                    point: {
                        radius: 0
                    }
                },
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            // return data.datasets[tooltipItem.datasetIndex].label + " - " + parseFloat(tooltipItem.value).toFixed(2) + "%"
                            return ""
                        },
                        title: function (tooltipItem, data) {
                            let prefix = data.labels[tooltipItem[0].index] + " budgeted \n\n";
                            if(tooltipItem && tooltipItem.length > 1) {
                                let helperText = " ( +" + (tooltipItem.length - 1) + (tooltipItem.length - 1 === 1 ? " other): " : " others): ");
                                return prefix + data.datasets[tooltipItem[0].datasetIndex].label + helperText + (parseFloat(tooltipItem[0].value).toFixed(2) + "%")
                            } 
                            else {
                                return prefix + data.datasets[tooltipItem[0].datasetIndex].label + ": " + (parseFloat(tooltipItem[0].value).toFixed(2) + "%")
                            }
                        }

                    }
                },
                
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            display: true,
                        },
                        scaleLabel: {
                            display: false,
                            labelString: 'Month'
                        }
                    }],
                    yAxes: [{
                        display: true,
                        gridLines: {
                            display: true,
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, values) {
                                if(!isNaN(parseFloat(value))) {
                                    if ((parseFloat(value) === 0) || (parseFloat(value) === 100)) {
                                        return value + "%"
                                    }
                                }
                                return ''
                            }
                        },
                        scaleLabel: {
                            display: false,
                            labelString: 'Value'
                        }
                    }]
                },
                legend: {
                    display: false,
                }
            }
        };

    }

    render() {
        return (
            <div className="pie-chart-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">County Estimated budget by Category</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="pie-chart">
                    <NewChart id="LineChartComp" config={this.calculateConfig()} />
                </div>
            </div>
        )
    }

}


export default connect(mapStateToProps, null)(CountiesEstimatedBudgetByCategoryLineChart);
