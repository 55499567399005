import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdArrowBack, MdChevronRight, MdInsertDriveFile, MdFileDownload } from 'react-icons/md';


// Local imports
import { HeaderComponent, DualTitleHeaderComponent } from "../layout/HeaderComponent.js";
import { HeaderButton } from "../styled-components/Buttons/Buttons";
import FileUpload from "../common/FileUpload";
import { CardComponentDownloadCard } from "../layout/CardComponent";
import { saveFileAppSync } from "../../redux/actions/index";


// CSS imports
import "../../styles/common.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        lempgUserType: state.rootReducer.lempgUserType,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveFileAppSync: (payload, fieldName, callback) => dispatch(saveFileAppSync(payload, fieldName, callback)),
    };
};

class PositionDescription extends React.Component {

    state = {
        file: {},
        other_files: [],
    }

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
        this.fileToSave = this.fileToSave.bind(this);
        this.fileToDelete = this.fileToDelete.bind(this);
        this.saveFileCallback = this.saveFileCallback.bind(this);
    }

    componentDidMount () {
        this.state.other_files = this.getExtraFiles(this.props.currentApplicationState);
        this.state.file = this.props.currentApplicationState && this.props.currentApplicationState.positionDescFile ? this.props.currentApplicationState.positionDescFile : {};

        if(this.state.file.title) {
            this.state.file.key = this.state.file.title;
        }

        this.setState({
            other_files: this.state.other_files
        });

        this.setState({
            file: this.state.file
        });
    }

    goBack() {
        this.props.history.goBack();
    }

    /**
     * 
    */

    fileToSave(data) {
        if(!this.state.file.id) {
            data.data.createFile.key = data.data.createFile.title;
            this.setState({ file: data.data.createFile });
        } else {
            this.state.other_files.push({
                type: 'grantApplicationPositionDescFileId',
                file: data.data.createFile.id,
                key: data.data.createFile.title,
                title: data.data.createFile.title,
                size: data.data.createFile.size,
            })
        }

    }

    /**
     * 
    */


    fileToDelete(file) {

        if(file.key === this.state.file.key) {
            this.state.file = {}
            this.setState({ file: this.state.file });
        } else {
            // check whether the deleted file exists in the normal file connection or in "other files"
            var files = this.state.other_files;
            for (let i = files.length; i--; ) {
                var _file = files[i];
                if(file.key === _file.key) {
                    files.splice(i, 1);
                    break;
                }
            }
            this.state.other_files = files
        }

    }

    /**
     * 
    */


    saveFile(data) {
        this.props.saveFileAppSync(this.state, "grantApplicationPositionDescFileId", this.saveFileCallback)
    }

    /**
     * 
    */

    saveFileCallback(data) {
        this.goBack()
    }

    getExtraFiles(application) {

        var arr = []

        if (application && application.extraFile) {

            var files = application.extraFile;

            try {
                var files = JSON.parse(files);

                for (var i = 0; i < files.length; i++) {
                    if (files[i].type === "grantApplicationPositionDescFileId") {
                        arr.push(files[i])
                    }
                }

            } catch (e) {
                return arr;
            }

        }

        return arr;
    }

    render() {

        const UploadedFiles = (
            <div>
                <CardComponentDownloadCard
                    icon={<MdInsertDriveFile className="icon-2" />}
                    title={<span>Position Description</span>}
                    suffix={<MdFileDownload className="icon-2" />}
                />
            </div>
        )

        const DownloadDocuments = (
            <>
                <div className="layout horizontal section-title">
                    Download Documents
                </div>
                {UploadedFiles}

            </>

        )

        return (

            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Position Description</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>LEMPG {this.props.selectedYear} • {this.props.grantApplication.group && this.props.grantApplication.group.groupName}</span>}

                    suffix={
                        <div className="form-margin-16">
                            <HeaderButton hidden={this.props.lempgUserType && this.props.lempgUserType !== "LEMPG_ACCESS"} onClick={() => this.saveFile()}>
                                Save
                            </HeaderButton>
                        </div>
                    }
                />

                {/* Only use form-container class when you are not using input fields in the form */}
                <div className="flex form-container container-overflow-y">
                    {/* Not needed per phil */}
                    {/* {DownloadDocuments} */}

                    <div className="height-20"></div>

                    <FileUpload fileToSave={this.fileToSave} fileToDelete={this.fileToDelete}
                        uploadedFile={this.props.currentApplicationState && this.props.currentApplicationState.positionDescFile ? this.props.currentApplicationState.positionDescFile : null} extraFiles={this.getExtraFiles(this.props.currentApplicationState)} />
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PositionDescription);
