import React from 'react';
import { connect } from 'react-redux';
import IconButton from "@mui/material/IconButton";
import { MdMenu }  from 'react-icons/md';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import moment from 'moment';
import { Skeleton } from "@mui/lab";
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import { getGridSingleSelectOperators } from '@mui/x-data-grid-pro';
import { PalmettoReactTable } from "@zawarski/palmetto-direflow-react-table";

// Local imports
import {  getFileUploadedLinkFromBucket } from '../../common/FileUploadApi';
import { checkIfQuarterIsStarted } from '../../utilities/QuarterDates';

// CSS imports

//import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

// Redux imports
import { getNarrativeAndGroupNarrativeM2MData,
    selectQuarterProgressReporting,
    setCurrentProgressReportCountyPageFilterModel,
    setLoading
 } from "../../../redux/actions/index";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        selectedQuarterProgress: state.rootReducer.selectedQuarterProgress,
        selectedYear: state.rootReducer.selectedYear,
        currentProgressReportCountyPageFilterModel: state.rootReducer.currentProgressReportCountyPageFilterModel,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNarrativeAndGroupNarrativeM2MData: (payload, callback) => dispatch(getNarrativeAndGroupNarrativeM2MData(payload, callback)),
        selectQuarterProgressReporting: (payload) => (dispatch(selectQuarterProgressReporting(payload))),
        setCurrentProgressReportCountyPageFilterModel: (payload) => dispatch(setCurrentProgressReportCountyPageFilterModel(payload)),
        setLoading: (payload) => dispatch(setLoading(payload)),  
    };
  };
  
  const filterOperators = getGridSingleSelectOperators().filter(({ value }) =>
      ['isAnyOf' /* add more over time */ ].includes(value),
  );

  const _convertToTitleCase = (str) => {
    str = str.toLowerCase();
    return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
  }
  
  const _showDate = (value) => {
    return  value !== undefined ?
        <div>{value}</div> :
        value === null ?
        <Skeleton style={{height: 20, width: '100%'}}/> : ""
      };

  const COLUMNS = [
      {
        minWidth: 100,
        field: "index",
        numeric: true,
        headerName: "#",
        enabled: true,
        filterable: false
    },
    {
        minWidth: 200,
        field: "narrativeTitle",
        numeric: false,
        headerName: "EMF",
        enabled: true,
        operatorValue: 'contains',
        filterable: false,
        columnField: "narrativeTitle"
    },
    {
        minWidth: 200,
        field: "narrativeFrequency",
        numeric: false,
        headerName: "Frequency",
        enabled: true,
        operatorValue: 'contains',
        filterable: false,
        columnField: "narrativeFrequency"
    },
    {
      minWidth: 200,
      field: "extensionDueDateDisplay",
      numeric: false,
      headerName: "Extension",
      enabled: true,
      filterable: false,
      columnField: "extensionDueDateDisplay",
      renderCell: param => _showDate(param.value)
    },
  {
      minWidth: 140,
      field: "updatedStatus",
      numeric: false,
      headerName: "Status",
      enabled: true,
      type: 'singleSelect',
      valueOptions: [
          { "value": "Approved", "label": "Approved" },
          { "value": "Changes Requested", "label": "Changes Requested" },
          { "value": "Draft", "label": "Draft" },
          { "value": "Not Started", "label": "Not Started" },
          { "value": "Processed", "label": "Processed" }
      ],
      renderCell: param => showStatusCell(param.value),
      filterOperators
      }
  ];
  
  const defaultFilterModel =
  {"items":[{"columnField":"updatedStatus","operatorValue":"isAnyOf","id":31248,"value":[]}],"linkOperator":"and","quickFilterValues":[],"quickFilterLogicOperator":"and"};
      
  const showStatusCell = (value, mobile = false) => {
      const style = {};
      style.paddingTop = "8px";
      style.paddingBottom = "9px";
      style.paddingLeft = "0";
      style.textAlign = "center";
      style.borderRadius = "0";
      switch (value.toLowerCase()) {
          case "processed":
          case "complete":
              style.whiteSpace = 'nowrap';
              style.backgroundColor = '#19C922';
              style.color = 'rgba(255, 255, 255, 0.87)';
              break;
          case "approved":
          case "completed":
          case "awarded":
              style.whiteSpace = 'nowrap';
              style.backgroundColor = '#417505';
              style.color = 'rgba(255, 255, 255, 0.87)';
              break;
          case "changes requested":
          case "tasked":
          case "submitted with issues":
              style.backgroundColor = '#4b97f2';
              style.color = 'rgba(255, 255, 255, 0.87)';
              break;
          case "cancelled":
              style.backgroundColor = '#990013';
              style.color = 'rgba(255,255,255,0.87)';
              break;
          case "draft":
          case "incomplete":
          case "not available":
              style.whiteSpace = 'nowrap';
              style.backgroundColor = '#A1A1A1';
              style.color = 'rgba(0, 0, 0, 0, 0.87)';
              break;
          case "not started":
              style.whiteSpace = 'nowrap';
              style.backgroundColor = '#4A4A4A';
              style.color = 'rgba(255, 255, 255, 0.87)';
              break;
          case "submitted":
              style.whiteSpace = 'nowrap';
              style.backgroundColor = '#C2B61B';
              style.color = 'rgba(0, 0, 0, 0, 0.87)';
              break;
          default:
              style.backgroundColor = 'black';
              style.color = 'rgba(255, 255, 255, 0.87)';
              break;
      }
      style.marginTop = '-10px';
      style.marginLeft = '-10px';
      style.marginRight = '-10px';
      style.textOverflow = 'ellipsis';
    
      style.overflow = 'hidden';
      style.height = '40px !important';
      style.minHeight = '40px !important';
      return (
        <>{value !== undefined ?
            <div style={style} className={`${mobile ? "" : "flex"}`}>{_convertToTitleCase(value)}</div> :
            <Skeleton style={{height: 20, width: '100%'}}/>
        } </>);
    }



class ProgressReportCountyPage extends React.Component {

    state = {
        selectedQuarter: this.props.selectedQuarterProgress || 0,
        anchorEl: null,
       filterModel: this.props.currentProgressReportCountyPageFilterModel || defaultFilterModel,
    }

    componentDidUpdate(prevProps, prevState) {
        // Typical usage (don't forget to compare props):
     
        if (this.state.filterModel !== prevState.filterModel) {
              this.setState({filterModel: this.state.filterModel});
        }
    }

    constructor(props, context) {
        super(props, context);
        this.processAndSortItems = this.processAndSortItems.bind(this);
        this.checkIfQuarterIsStarted = checkIfQuarterIsStarted.bind(this);
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    processAndSortItems(county) {

        let items = county && county.narratives && county.narratives.items;
        let finItems = county && county.financialreimbursement && county.financialreimbursement.items;

        var arr = []

        items.forEach((item) => {
            if ((+item.quarter.split('').reverse()[0]) === (+this.state.selectedQuarter + 1)) {
                if((item.narrative.useFinancialReimbursementStatus)) {
                    // loop over finItems
                    finItems.forEach((finItem) => {
                        if((+finItem.quarter.split('').reverse()[0]) === (+this.state.selectedQuarter + 1)) {
                            item.status = finItem.status;

                        }
                    })

                    item.updatedStatus = item.status ? item.status : "Draft";
                    if (item.status === "AWARDED") item.updatedStatus = "Approved";
                    if (item.status === "PROCESSED") item.updatedStatus = "Approved";
                    if (item.status === "NOT_AVAILABLE") item.updatedStatus = "Draft";
                    if (item.status === "NOT_STARTED") item.updatedStatus = "Draft";
                } 
            }
        })

        items.forEach( (item) => {
            if ((parseInt(item.quarter.split("").reverse().join("")) === (this.state.selectedQuarter + 1))) {
                item.updatedStatus = item.status ? item.status : "Draft";
                if (item.status === "NOT_AVAILABLE") item.updatedStatus = "Draft";
                if (item.status === "NOT_STARTED") item.updatedStatus = "Not Started";
                if (item.status === "CHANGES_REQUESTED") item.updatedStatus = "Changes Requested";

                if (item.narrative) {
                  item.narrativeTitle =
                    (item.narrative.narrativeTitle || "");
                  item.narrativeFrequency =
                    (item.narrative.narrativeFrequency || "");
                }

                if(item.currentExtension && item.currentExtension.status === "APPROVED" &&
                  item.currentExtension.dueDate) {
                    item.extensionDueDateDisplay = 
                       moment(item.currentExtension.dateAdjustedTo || item.currentExtension.dueDate, "YYYY-MM-DD").local().format("MM-DD-YYYY");
                }

                arr.push(item);
            }
        })

        return arr && arr.sort((a, b) => {
            return a.narrative.narrativeTitle.localeCompare(b.narrative.narrativeTitle);
        });

    }
  
    downloadProgressReportingUserGuide = async () => {
        var a = await getFileUploadedLinkFromBucket({ key: "LEMPG-ProgressReporting.pdf"}, "palmetto-lempg-global-documents");
        window.open(a);
    }


    selectTab = (index) => {
        let filterModel = this.state.filterModel;
        this.setState({
          filterModel: filterModel,
          selectedQuarter: index
        });
        this.props.selectQuarterProgressReporting(index);
      };
    
      onRowClick = (rowInfo) => {
        setTimeout(() => {
          //this.props.setLoading(true);
          rowInfo.row.quarter = "quarter" + (this.state.selectedQuarter + 1);
          this.props.getNarrativeAndGroupNarrativeM2MData(rowInfo.row);
          this.props.history.push("/progressreport/county/narrative")
          }
        );
       };
    
      _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) {
          return t.toUpperCase();
        });
      };
    
      selectedCountyCallback(...args) {}
    
      handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"));
      };
    
      render() {
       let tabs =  <Tabs
       className="layout horizontal center"
       value={this.state.selectedQuarter}
       indicatorColor="primary"
       textColor="primary"
       classes={{
         root: "tabs-root",
         flexContainer: "flex",
         indicator: "tab-indicator-color",
       }}
       // onChange={handleChange}
       aria-label="disabled tabs example">
       <Tab
         className="flex"
         classes={{
           root: "tab-root",
           selected: "tab-selected",
         }}
         onClick={() => this.selectTab(0)}
         label="Q1"/>
       <Tab
         className="flex"
         classes={{
           root: "tab-root",
           selected: "tab-selected",
         }}
         onClick={() => this.selectTab(1)}
         label="Q2"
       />
       <Tab
         className="flex"
         classes={{
           root: "tab-root",
           selected: "tab-selected",
         }}
         onClick={() => this.selectTab(2)}
         label="Q3"
       />
       <Tab
         className="flex"
         classes={{
           root: "tab-root",
           selected: "tab-selected",
         }}
         onClick={() => this.selectTab(3)}
         label="Q4"
       />
     </Tabs>;
    
        return (
          <>
          <div style={{display: true ? 'none' : 'flex', zIndex:'9',position:'absolute', top:'57px',left:'0',width:'100vw',height:'100vh',backgroundColor:'white'}} className="layout vertical vertical-center full-height">
              <Fade
                  in={this.props.loading}
                  size={80}
                  unmountOnExit
              >
                  <CircularProgress />
              </Fade>
          </div>
         
          <div className="layout vertical full-height">
              <PalmettoReactTable
                columns={COLUMNS}
                dataset={this.processAndSortItems(this.props.selectedCounty)}
                extraHeaders={<></>}
                filterModel={this.state.filterModel}
                headerIcon={
                  <IconButton aria-label="menu"
                    onClick={
                     () => window.dispatchEvent(new CustomEvent("toggle-sidebar"))
                    }
                  >
                      <MdMenu className="icon"/>
                  </IconButton>
                }
                onFilterModelChange={ (params) => {
                  this.props.setCurrentProgressReportCountyPageFilterModel(params);
                }}
                gridToolbar={true}
                idField={"id"}
                subheader={tabs}
                limit={200}
                onRowClick={this.onRowClick}
                loading={true}
                subtitleSuffix={"reports • " + this.props.selectedYear + " • " + _convertToTitleCase(this.props.selectedCounty?.groupName)}
                title={"Progress Reporting"}
                rowHeight={48}
                virtualRowHeight={48}
                MenuItems={[
                        { onClick: () => this.downloadProgressReportingUserGuide(), title: 'Help' }
                ]}
               />

          </div>
          </>
        );
      }
    }

export default connect(mapStateToProps, mapDispatchToProps)(ProgressReportCountyPage);
