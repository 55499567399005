/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFiscalYear = /* GraphQL */ `
  mutation CreateFiscalYear($input: CreateFiscalYearInput!) {
    createFiscalYear(input: $input) {
      id
      year
      availableToCounties
      createdAt
      updatedAt
      updatedBy
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      guidelinesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const updateFiscalYear = /* GraphQL */ `
  mutation UpdateFiscalYear($input: UpdateFiscalYearInput!) {
    updateFiscalYear(input: $input) {
      id
      year
      availableToCounties
      createdAt
      updatedAt
      updatedBy
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      guidelinesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const deleteFiscalYear = /* GraphQL */ `
  mutation DeleteFiscalYear($input: DeleteFiscalYearInput!) {
    deleteFiscalYear(input: $input) {
      id
      year
      availableToCounties
      createdAt
      updatedAt
      updatedBy
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      guidelinesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const createYearCountyApplication = /* GraphQL */ `
  mutation CreateYearCountyApplication(
    $input: CreateYearCountyApplicationInput!
  ) {
    createYearCountyApplication(input: $input) {
      id
      year
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateYearCountyApplication = /* GraphQL */ `
  mutation UpdateYearCountyApplication(
    $input: UpdateYearCountyApplicationInput!
  ) {
    updateYearCountyApplication(input: $input) {
      id
      year
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteYearCountyApplication = /* GraphQL */ `
  mutation DeleteYearCountyApplication(
    $input: DeleteYearCountyApplicationInput!
  ) {
    deleteYearCountyApplication(input: $input) {
      id
      year
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
      groupName
      createdAt
      updatedAt
      region
      palmettoId
      narratives {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
        }
        nextToken
      }
      quarterlyprogressreporting {
        items {
          id
          quarter
          taskingslipstatus
          updatedAt
          updatedBy
          updatedByRemName
          createdAt
        }
        nextToken
      }
      monitoringgrantinformation {
        id
        updatedAt
        createdAt
        updatedBy
        monitoringType
        monitoringDate
        subRecipientAgency
        grantProjectTitle
        grantNumber
        approvedGrantStartDate
        approvedGrantEndDate
        extensionEndDate
        name
        title
        projectDirectorName
        projectDirectorTitle
        personnelName
        personnelTitle
      }
      monitoringsitevisitreview {
        id
        updatedAt
        createdAt
        updatedBy
        gaOnFile
        gaSupportingDocumentationOnFile
        gaComments
        personnelFunded
        personnelDocumentationOnFile
        personnelRecordsOnFile
        personnelComments
        contractualServiceOnFile
        contractualServiceExecutedContractsOnFile
        contractualServiceComments
        travelOnFile
        travelDocumentationOnFile
        travelComments
        equipmentProperlySheltered
        equipmentTransferred
        equipmentComments
        suppliesOnFile
        suppliesTransferred
        suppliesComments
        otherOnFile
        otherComments
        summaryComments
        monitoringReportSubmittedBy
        monitoringReportSubmittedAt
        scemdPOCReviewedBy
        scemdPOCReviewedAt
        monitoringFiles {
          nextToken
        }
        status
      }
      awardAmount
      year
      deleted
      grantapplication {
        items {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        nextToken
      }
      financialreimbursement {
        items {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          notes
          awarded
          lastSubmitDate
          lastSubmitName
          lastSubmitUserId
          lastSubmitEmail
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          deobligatedFunds
          deobligatedFundsReason
        }
        nextToken
      }
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      id
      groupName
      createdAt
      updatedAt
      region
      palmettoId
      narratives {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
        }
        nextToken
      }
      quarterlyprogressreporting {
        items {
          id
          quarter
          taskingslipstatus
          updatedAt
          updatedBy
          updatedByRemName
          createdAt
        }
        nextToken
      }
      monitoringgrantinformation {
        id
        updatedAt
        createdAt
        updatedBy
        monitoringType
        monitoringDate
        subRecipientAgency
        grantProjectTitle
        grantNumber
        approvedGrantStartDate
        approvedGrantEndDate
        extensionEndDate
        name
        title
        projectDirectorName
        projectDirectorTitle
        personnelName
        personnelTitle
      }
      monitoringsitevisitreview {
        id
        updatedAt
        createdAt
        updatedBy
        gaOnFile
        gaSupportingDocumentationOnFile
        gaComments
        personnelFunded
        personnelDocumentationOnFile
        personnelRecordsOnFile
        personnelComments
        contractualServiceOnFile
        contractualServiceExecutedContractsOnFile
        contractualServiceComments
        travelOnFile
        travelDocumentationOnFile
        travelComments
        equipmentProperlySheltered
        equipmentTransferred
        equipmentComments
        suppliesOnFile
        suppliesTransferred
        suppliesComments
        otherOnFile
        otherComments
        summaryComments
        monitoringReportSubmittedBy
        monitoringReportSubmittedAt
        scemdPOCReviewedBy
        scemdPOCReviewedAt
        monitoringFiles {
          nextToken
        }
        status
      }
      awardAmount
      year
      deleted
      grantapplication {
        items {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        nextToken
      }
      financialreimbursement {
        items {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          notes
          awarded
          lastSubmitDate
          lastSubmitName
          lastSubmitUserId
          lastSubmitEmail
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          deobligatedFunds
          deobligatedFundsReason
        }
        nextToken
      }
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      id
      groupName
      createdAt
      updatedAt
      region
      palmettoId
      narratives {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
        }
        nextToken
      }
      quarterlyprogressreporting {
        items {
          id
          quarter
          taskingslipstatus
          updatedAt
          updatedBy
          updatedByRemName
          createdAt
        }
        nextToken
      }
      monitoringgrantinformation {
        id
        updatedAt
        createdAt
        updatedBy
        monitoringType
        monitoringDate
        subRecipientAgency
        grantProjectTitle
        grantNumber
        approvedGrantStartDate
        approvedGrantEndDate
        extensionEndDate
        name
        title
        projectDirectorName
        projectDirectorTitle
        personnelName
        personnelTitle
      }
      monitoringsitevisitreview {
        id
        updatedAt
        createdAt
        updatedBy
        gaOnFile
        gaSupportingDocumentationOnFile
        gaComments
        personnelFunded
        personnelDocumentationOnFile
        personnelRecordsOnFile
        personnelComments
        contractualServiceOnFile
        contractualServiceExecutedContractsOnFile
        contractualServiceComments
        travelOnFile
        travelDocumentationOnFile
        travelComments
        equipmentProperlySheltered
        equipmentTransferred
        equipmentComments
        suppliesOnFile
        suppliesTransferred
        suppliesComments
        otherOnFile
        otherComments
        summaryComments
        monitoringReportSubmittedBy
        monitoringReportSubmittedAt
        scemdPOCReviewedBy
        scemdPOCReviewedAt
        monitoringFiles {
          nextToken
        }
        status
      }
      awardAmount
      year
      deleted
      grantapplication {
        items {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        nextToken
      }
      financialreimbursement {
        items {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          notes
          awarded
          lastSubmitDate
          lastSubmitName
          lastSubmitUserId
          lastSubmitEmail
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          deobligatedFunds
          deobligatedFundsReason
        }
        nextToken
      }
    }
  }
`;
export const createQuarterlyProgressReporting = /* GraphQL */ `
  mutation CreateQuarterlyProgressReporting(
    $input: CreateQuarterlyProgressReportingInput!
  ) {
    createQuarterlyProgressReporting(input: $input) {
      id
      quarter
      taskingslipstatus
      updatedAt
      updatedBy
      updatedByRemName
      createdAt
    }
  }
`;
export const updateQuarterlyProgressReporting = /* GraphQL */ `
  mutation UpdateQuarterlyProgressReporting(
    $input: UpdateQuarterlyProgressReportingInput!
  ) {
    updateQuarterlyProgressReporting(input: $input) {
      id
      quarter
      taskingslipstatus
      updatedAt
      updatedBy
      updatedByRemName
      createdAt
    }
  }
`;
export const deleteQuarterlyProgressReporting = /* GraphQL */ `
  mutation DeleteQuarterlyProgressReporting(
    $input: DeleteQuarterlyProgressReportingInput!
  ) {
    deleteQuarterlyProgressReporting(input: $input) {
      id
      quarter
      taskingslipstatus
      updatedAt
      updatedBy
      updatedByRemName
      createdAt
    }
  }
`;
export const createFinancialReimbursement = /* GraphQL */ `
  mutation CreateFinancialReimbursement(
    $input: CreateFinancialReimbursementInput!
  ) {
    createFinancialReimbursement(input: $input) {
      id
      quarter
      quarterStartDate
      quarterEndDate
      updatedBy
      year
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      createdAt
      updatedAt
      deleted
      status
      notes
      awarded
      lastSubmitDate
      lastSubmitName
      lastSubmitUserId
      lastSubmitEmail
      checkNumber
      checkAmount
      checkMailDate
      checksReissued {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      currentReissuedCheck {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      additionalChecks {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      additionalCheckInProgress {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      attachedGrantApplication {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      personnel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualService {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      travel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      equipment {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      supply {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      cert {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      other {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      deobligatedFunds
      deobligatedFundsReason
    }
  }
`;
export const updateFinancialReimbursement = /* GraphQL */ `
  mutation UpdateFinancialReimbursement(
    $input: UpdateFinancialReimbursementInput!
  ) {
    updateFinancialReimbursement(input: $input) {
      id
      quarter
      quarterStartDate
      quarterEndDate
      updatedBy
      year
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      createdAt
      updatedAt
      deleted
      status
      notes
      awarded
      lastSubmitDate
      lastSubmitName
      lastSubmitUserId
      lastSubmitEmail
      checkNumber
      checkAmount
      checkMailDate
      checksReissued {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      currentReissuedCheck {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      additionalChecks {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      additionalCheckInProgress {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      attachedGrantApplication {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      personnel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualService {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      travel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      equipment {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      supply {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      cert {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      other {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      deobligatedFunds
      deobligatedFundsReason
    }
  }
`;
export const deleteFinancialReimbursement = /* GraphQL */ `
  mutation DeleteFinancialReimbursement(
    $input: DeleteFinancialReimbursementInput!
  ) {
    deleteFinancialReimbursement(input: $input) {
      id
      quarter
      quarterStartDate
      quarterEndDate
      updatedBy
      year
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      createdAt
      updatedAt
      deleted
      status
      notes
      awarded
      lastSubmitDate
      lastSubmitName
      lastSubmitUserId
      lastSubmitEmail
      checkNumber
      checkAmount
      checkMailDate
      checksReissued {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      currentReissuedCheck {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      additionalChecks {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      additionalCheckInProgress {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      attachedGrantApplication {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      personnel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualService {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      travel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      equipment {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      supply {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      cert {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      other {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      deobligatedFunds
      deobligatedFundsReason
    }
  }
`;
export const createChecksReissued = /* GraphQL */ `
  mutation CreateChecksReissued($input: CreateChecksReissuedInput!) {
    createChecksReissued(input: $input) {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const updateChecksReissued = /* GraphQL */ `
  mutation UpdateChecksReissued($input: UpdateChecksReissuedInput!) {
    updateChecksReissued(input: $input) {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const deleteChecksReissued = /* GraphQL */ `
  mutation DeleteChecksReissued($input: DeleteChecksReissuedInput!) {
    deleteChecksReissued(input: $input) {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const createAdditionalChecks = /* GraphQL */ `
  mutation CreateAdditionalChecks($input: CreateAdditionalChecksInput!) {
    createAdditionalChecks(input: $input) {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const updateAdditionalChecks = /* GraphQL */ `
  mutation UpdateAdditionalChecks($input: UpdateAdditionalChecksInput!) {
    updateAdditionalChecks(input: $input) {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const deleteAdditionalChecks = /* GraphQL */ `
  mutation DeleteAdditionalChecks($input: DeleteAdditionalChecksInput!) {
    deleteAdditionalChecks(input: $input) {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const createFimPersonnel = /* GraphQL */ `
  mutation CreateFimPersonnel($input: CreateFimPersonnelInput!) {
    createFimPersonnel(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFimPersonnel = /* GraphQL */ `
  mutation UpdateFimPersonnel($input: UpdateFimPersonnelInput!) {
    updateFimPersonnel(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFimPersonnel = /* GraphQL */ `
  mutation DeleteFimPersonnel($input: DeleteFimPersonnelInput!) {
    deleteFimPersonnel(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFimContractualService = /* GraphQL */ `
  mutation CreateFimContractualService(
    $input: CreateFimContractualServiceInput!
  ) {
    createFimContractualService(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFimContractualService = /* GraphQL */ `
  mutation UpdateFimContractualService(
    $input: UpdateFimContractualServiceInput!
  ) {
    updateFimContractualService(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFimContractualService = /* GraphQL */ `
  mutation DeleteFimContractualService(
    $input: DeleteFimContractualServiceInput!
  ) {
    deleteFimContractualService(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFimTravel = /* GraphQL */ `
  mutation CreateFimTravel($input: CreateFimTravelInput!) {
    createFimTravel(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFimTravel = /* GraphQL */ `
  mutation UpdateFimTravel($input: UpdateFimTravelInput!) {
    updateFimTravel(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFimTravel = /* GraphQL */ `
  mutation DeleteFimTravel($input: DeleteFimTravelInput!) {
    deleteFimTravel(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFimEquipment = /* GraphQL */ `
  mutation CreateFimEquipment($input: CreateFimEquipmentInput!) {
    createFimEquipment(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFimEquipment = /* GraphQL */ `
  mutation UpdateFimEquipment($input: UpdateFimEquipmentInput!) {
    updateFimEquipment(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFimEquipment = /* GraphQL */ `
  mutation DeleteFimEquipment($input: DeleteFimEquipmentInput!) {
    deleteFimEquipment(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFimSupply = /* GraphQL */ `
  mutation CreateFimSupply($input: CreateFimSupplyInput!) {
    createFimSupply(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFimSupply = /* GraphQL */ `
  mutation UpdateFimSupply($input: UpdateFimSupplyInput!) {
    updateFimSupply(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFimSupply = /* GraphQL */ `
  mutation DeleteFimSupply($input: DeleteFimSupplyInput!) {
    deleteFimSupply(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFimCert = /* GraphQL */ `
  mutation CreateFimCert($input: CreateFimCertInput!) {
    createFimCert(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFimCert = /* GraphQL */ `
  mutation UpdateFimCert($input: UpdateFimCertInput!) {
    updateFimCert(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFimCert = /* GraphQL */ `
  mutation DeleteFimCert($input: DeleteFimCertInput!) {
    deleteFimCert(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFimOther = /* GraphQL */ `
  mutation CreateFimOther($input: CreateFimOtherInput!) {
    createFimOther(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFimOther = /* GraphQL */ `
  mutation UpdateFimOther($input: UpdateFimOtherInput!) {
    updateFimOther(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFimOther = /* GraphQL */ `
  mutation DeleteFimOther($input: DeleteFimOtherInput!) {
    deleteFimOther(input: $input) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGrantApplication = /* GraphQL */ `
  mutation CreateGrantApplication($input: CreateGrantApplicationInput!) {
    createGrantApplication(input: $input) {
      id
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      updatedBy
      version
      versionName
      createdAt
      updatedAt
      year
      deleted
      status
      awarded
      deductCertFromFederal
      salary {
        items {
          id
          deleted
          updatedBy
          name
          positionTitle
          federal
          county
          countyInKind
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          createdAt
          updatedAt
        }
        nextToken
      }
      salaryNarrative
      fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        createdAt
        updatedAt
      }
      fringeBenefitNarrative
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      contractualService {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualServiceNarrative
      travel {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      travelNarrative
      equipment {
        items {
          id
          name
          femaItemNumber
          quantity
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      equipmentNarrative
      supply {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      supplyNarrative
      other {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      otherNarrative
      cert {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      certNarrative
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      awardDocumentsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardCoverLetterUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardSpecialInstructionsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentsUploadedByCounty {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      changesRequestedOnApplication
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      name
      address
      address2
      city
      state
      county
      zipcode
      latitude
      longitude
      enableReverseGeocoding
      implementingAgency
      phoneNumber
      faxNumber
      currentApplicationRevisionId
      grantApplicationRevision {
        items {
          id
          createdAt
          updatedAt
          grantApplicationLastUpdatedAt
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const updateGrantApplication = /* GraphQL */ `
  mutation UpdateGrantApplication($input: UpdateGrantApplicationInput!) {
    updateGrantApplication(input: $input) {
      id
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      updatedBy
      version
      versionName
      createdAt
      updatedAt
      year
      deleted
      status
      awarded
      deductCertFromFederal
      salary {
        items {
          id
          deleted
          updatedBy
          name
          positionTitle
          federal
          county
          countyInKind
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          createdAt
          updatedAt
        }
        nextToken
      }
      salaryNarrative
      fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        createdAt
        updatedAt
      }
      fringeBenefitNarrative
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      contractualService {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualServiceNarrative
      travel {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      travelNarrative
      equipment {
        items {
          id
          name
          femaItemNumber
          quantity
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      equipmentNarrative
      supply {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      supplyNarrative
      other {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      otherNarrative
      cert {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      certNarrative
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      awardDocumentsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardCoverLetterUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardSpecialInstructionsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentsUploadedByCounty {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      changesRequestedOnApplication
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      name
      address
      address2
      city
      state
      county
      zipcode
      latitude
      longitude
      enableReverseGeocoding
      implementingAgency
      phoneNumber
      faxNumber
      currentApplicationRevisionId
      grantApplicationRevision {
        items {
          id
          createdAt
          updatedAt
          grantApplicationLastUpdatedAt
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const deleteGrantApplication = /* GraphQL */ `
  mutation DeleteGrantApplication($input: DeleteGrantApplicationInput!) {
    deleteGrantApplication(input: $input) {
      id
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      updatedBy
      version
      versionName
      createdAt
      updatedAt
      year
      deleted
      status
      awarded
      deductCertFromFederal
      salary {
        items {
          id
          deleted
          updatedBy
          name
          positionTitle
          federal
          county
          countyInKind
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          createdAt
          updatedAt
        }
        nextToken
      }
      salaryNarrative
      fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        createdAt
        updatedAt
      }
      fringeBenefitNarrative
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      contractualService {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualServiceNarrative
      travel {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      travelNarrative
      equipment {
        items {
          id
          name
          femaItemNumber
          quantity
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      equipmentNarrative
      supply {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      supplyNarrative
      other {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      otherNarrative
      cert {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      certNarrative
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      awardDocumentsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardCoverLetterUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardSpecialInstructionsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentsUploadedByCounty {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      changesRequestedOnApplication
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      name
      address
      address2
      city
      state
      county
      zipcode
      latitude
      longitude
      enableReverseGeocoding
      implementingAgency
      phoneNumber
      faxNumber
      currentApplicationRevisionId
      grantApplicationRevision {
        items {
          id
          createdAt
          updatedAt
          grantApplicationLastUpdatedAt
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const createGrantApplicationRevision = /* GraphQL */ `
  mutation CreateGrantApplicationRevision(
    $input: CreateGrantApplicationRevisionInput!
  ) {
    createGrantApplicationRevision(input: $input) {
      id
      createdAt
      updatedAt
      grantApplicationLastUpdatedAt
      revision {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      updatedBy
    }
  }
`;
export const updateGrantApplicationRevision = /* GraphQL */ `
  mutation UpdateGrantApplicationRevision(
    $input: UpdateGrantApplicationRevisionInput!
  ) {
    updateGrantApplicationRevision(input: $input) {
      id
      createdAt
      updatedAt
      grantApplicationLastUpdatedAt
      revision {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      updatedBy
    }
  }
`;
export const deleteGrantApplicationRevision = /* GraphQL */ `
  mutation DeleteGrantApplicationRevision(
    $input: DeleteGrantApplicationRevisionInput!
  ) {
    deleteGrantApplicationRevision(input: $input) {
      id
      createdAt
      updatedAt
      grantApplicationLastUpdatedAt
      revision {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      updatedBy
    }
  }
`;
export const createSalary = /* GraphQL */ `
  mutation CreateSalary($input: CreateSalaryInput!) {
    createSalary(input: $input) {
      id
      deleted
      updatedBy
      name
      positionTitle
      federal
      county
      countyInKind
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSalary = /* GraphQL */ `
  mutation UpdateSalary($input: UpdateSalaryInput!) {
    updateSalary(input: $input) {
      id
      deleted
      updatedBy
      name
      positionTitle
      federal
      county
      countyInKind
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSalary = /* GraphQL */ `
  mutation DeleteSalary($input: DeleteSalaryInput!) {
    deleteSalary(input: $input) {
      id
      deleted
      updatedBy
      name
      positionTitle
      federal
      county
      countyInKind
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFringeBenefit = /* GraphQL */ `
  mutation CreateFringeBenefit($input: CreateFringeBenefitInput!) {
    createFringeBenefit(input: $input) {
      id
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFringeBenefit = /* GraphQL */ `
  mutation UpdateFringeBenefit($input: UpdateFringeBenefitInput!) {
    updateFringeBenefit(input: $input) {
      id
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFringeBenefit = /* GraphQL */ `
  mutation DeleteFringeBenefit($input: DeleteFringeBenefitInput!) {
    deleteFringeBenefit(input: $input) {
      id
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createContractualService = /* GraphQL */ `
  mutation CreateContractualService($input: CreateContractualServiceInput!) {
    createContractualService(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateContractualService = /* GraphQL */ `
  mutation UpdateContractualService($input: UpdateContractualServiceInput!) {
    updateContractualService(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteContractualService = /* GraphQL */ `
  mutation DeleteContractualService($input: DeleteContractualServiceInput!) {
    deleteContractualService(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTravel = /* GraphQL */ `
  mutation CreateTravel($input: CreateTravelInput!) {
    createTravel(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTravel = /* GraphQL */ `
  mutation UpdateTravel($input: UpdateTravelInput!) {
    updateTravel(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTravel = /* GraphQL */ `
  mutation DeleteTravel($input: DeleteTravelInput!) {
    deleteTravel(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEquipment = /* GraphQL */ `
  mutation CreateEquipment($input: CreateEquipmentInput!) {
    createEquipment(input: $input) {
      id
      name
      femaItemNumber
      quantity
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEquipment = /* GraphQL */ `
  mutation UpdateEquipment($input: UpdateEquipmentInput!) {
    updateEquipment(input: $input) {
      id
      name
      femaItemNumber
      quantity
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEquipment = /* GraphQL */ `
  mutation DeleteEquipment($input: DeleteEquipmentInput!) {
    deleteEquipment(input: $input) {
      id
      name
      femaItemNumber
      quantity
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSupply = /* GraphQL */ `
  mutation CreateSupply($input: CreateSupplyInput!) {
    createSupply(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSupply = /* GraphQL */ `
  mutation UpdateSupply($input: UpdateSupplyInput!) {
    updateSupply(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSupply = /* GraphQL */ `
  mutation DeleteSupply($input: DeleteSupplyInput!) {
    deleteSupply(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOther = /* GraphQL */ `
  mutation CreateOther($input: CreateOtherInput!) {
    createOther(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOther = /* GraphQL */ `
  mutation UpdateOther($input: UpdateOtherInput!) {
    updateOther(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOther = /* GraphQL */ `
  mutation DeleteOther($input: DeleteOtherInput!) {
    deleteOther(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCert = /* GraphQL */ `
  mutation CreateCert($input: CreateCertInput!) {
    createCert(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCert = /* GraphQL */ `
  mutation UpdateCert($input: UpdateCertInput!) {
    updateCert(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCert = /* GraphQL */ `
  mutation DeleteCert($input: DeleteCertInput!) {
    deleteCert(input: $input) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          nextToken
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFunding = /* GraphQL */ `
  mutation CreateFunding($input: CreateFundingInput!) {
    createFunding(input: $input) {
      id
      federal
      county
      countyInKind
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const updateFunding = /* GraphQL */ `
  mutation UpdateFunding($input: UpdateFundingInput!) {
    updateFunding(input: $input) {
      id
      federal
      county
      countyInKind
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteFunding = /* GraphQL */ `
  mutation DeleteFunding($input: DeleteFundingInput!) {
    deleteFunding(input: $input) {
      id
      federal
      county
      countyInKind
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile($input: CreateFileInput!) {
    createFile(input: $input) {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile($input: UpdateFileInput!) {
    updateFile(input: $input) {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile($input: DeleteFileInput!) {
    deleteFile(input: $input) {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
  }
`;
export const createNarrative = /* GraphQL */ `
  mutation CreateNarrative($input: CreateNarrativeInput!) {
    createNarrative(input: $input) {
      id
      narrativeTitle
      narrativeCategory
      narrativeShortDescription
      narrativeLongDescription
      narrativeFrequency
      usePalmettoForREMInformation
      useFinancialReimbursementStatus
      narrativePOCFirstName
      narrativePOCLastName
      narrativePOCPhoneNumber
      narrativePOCEmailAddress
      narrativePOCPositionTitle
      quarter
      deleted
      createdAt
      updatedAt
      updatedBy
      year
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      testField
      groups {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
        }
        nextToken
      }
    }
  }
`;
export const updateNarrative = /* GraphQL */ `
  mutation UpdateNarrative($input: UpdateNarrativeInput!) {
    updateNarrative(input: $input) {
      id
      narrativeTitle
      narrativeCategory
      narrativeShortDescription
      narrativeLongDescription
      narrativeFrequency
      usePalmettoForREMInformation
      useFinancialReimbursementStatus
      narrativePOCFirstName
      narrativePOCLastName
      narrativePOCPhoneNumber
      narrativePOCEmailAddress
      narrativePOCPositionTitle
      quarter
      deleted
      createdAt
      updatedAt
      updatedBy
      year
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      testField
      groups {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
        }
        nextToken
      }
    }
  }
`;
export const deleteNarrative = /* GraphQL */ `
  mutation DeleteNarrative($input: DeleteNarrativeInput!) {
    deleteNarrative(input: $input) {
      id
      narrativeTitle
      narrativeCategory
      narrativeShortDescription
      narrativeLongDescription
      narrativeFrequency
      usePalmettoForREMInformation
      useFinancialReimbursementStatus
      narrativePOCFirstName
      narrativePOCLastName
      narrativePOCPhoneNumber
      narrativePOCEmailAddress
      narrativePOCPositionTitle
      quarter
      deleted
      createdAt
      updatedAt
      updatedBy
      year
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      testField
      groups {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
        }
        nextToken
      }
    }
  }
`;
export const createREMContact = /* GraphQL */ `
  mutation CreateREMContact($input: CreateREMContactInput!) {
    createREMContact(input: $input) {
      id
      firstName
      lastName
      emailAddress
      phoneNumber
      region
      deleted
      createdAt
      updatedAt
      updatedBy
      positionTitle
      year
    }
  }
`;
export const updateREMContact = /* GraphQL */ `
  mutation UpdateREMContact($input: UpdateREMContactInput!) {
    updateREMContact(input: $input) {
      id
      firstName
      lastName
      emailAddress
      phoneNumber
      region
      deleted
      createdAt
      updatedAt
      updatedBy
      positionTitle
      year
    }
  }
`;
export const deleteREMContact = /* GraphQL */ `
  mutation DeleteREMContact($input: DeleteREMContactInput!) {
    deleteREMContact(input: $input) {
      id
      firstName
      lastName
      emailAddress
      phoneNumber
      region
      deleted
      createdAt
      updatedAt
      updatedBy
      positionTitle
      year
    }
  }
`;
export const createGroupNarrativeM2M = /* GraphQL */ `
  mutation CreateGroupNarrativeM2M($input: CreateGroupNarrativeM2MInput!) {
    createGroupNarrativeM2M(input: $input) {
      id
      narrative {
        id
        narrativeTitle
        narrativeCategory
        narrativeShortDescription
        narrativeLongDescription
        narrativeFrequency
        usePalmettoForREMInformation
        useFinancialReimbursementStatus
        narrativePOCFirstName
        narrativePOCLastName
        narrativePOCPhoneNumber
        narrativePOCEmailAddress
        narrativePOCPositionTitle
        quarter
        deleted
        createdAt
        updatedAt
        updatedBy
        year
        uploadedFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        testField
        groups {
          nextToken
        }
      }
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      completed
      createdAt
      updatedAt
      deleted
      updatedBy
      status
      notes
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      quarter
      updatedByFirstName
      updatedByLastName
      year
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      m117CompletedDocument {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      comments {
        items {
          id
          comment
          createdAt
          updatedAt
          deleted
          updatedBy
          updatedByFirstName
          updatedByLastName
        }
        nextToken
      }
    }
  }
`;
export const updateGroupNarrativeM2M = /* GraphQL */ `
  mutation UpdateGroupNarrativeM2M($input: UpdateGroupNarrativeM2MInput!) {
    updateGroupNarrativeM2M(input: $input) {
      id
      narrative {
        id
        narrativeTitle
        narrativeCategory
        narrativeShortDescription
        narrativeLongDescription
        narrativeFrequency
        usePalmettoForREMInformation
        useFinancialReimbursementStatus
        narrativePOCFirstName
        narrativePOCLastName
        narrativePOCPhoneNumber
        narrativePOCEmailAddress
        narrativePOCPositionTitle
        quarter
        deleted
        createdAt
        updatedAt
        updatedBy
        year
        uploadedFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        testField
        groups {
          nextToken
        }
      }
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      completed
      createdAt
      updatedAt
      deleted
      updatedBy
      status
      notes
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      quarter
      updatedByFirstName
      updatedByLastName
      year
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      m117CompletedDocument {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      comments {
        items {
          id
          comment
          createdAt
          updatedAt
          deleted
          updatedBy
          updatedByFirstName
          updatedByLastName
        }
        nextToken
      }
    }
  }
`;
export const deleteGroupNarrativeM2M = /* GraphQL */ `
  mutation DeleteGroupNarrativeM2M($input: DeleteGroupNarrativeM2MInput!) {
    deleteGroupNarrativeM2M(input: $input) {
      id
      narrative {
        id
        narrativeTitle
        narrativeCategory
        narrativeShortDescription
        narrativeLongDescription
        narrativeFrequency
        usePalmettoForREMInformation
        useFinancialReimbursementStatus
        narrativePOCFirstName
        narrativePOCLastName
        narrativePOCPhoneNumber
        narrativePOCEmailAddress
        narrativePOCPositionTitle
        quarter
        deleted
        createdAt
        updatedAt
        updatedBy
        year
        uploadedFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        testField
        groups {
          nextToken
        }
      }
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      completed
      createdAt
      updatedAt
      deleted
      updatedBy
      status
      notes
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      quarter
      updatedByFirstName
      updatedByLastName
      year
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      m117CompletedDocument {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      comments {
        items {
          id
          comment
          createdAt
          updatedAt
          deleted
          updatedBy
          updatedByFirstName
          updatedByLastName
        }
        nextToken
      }
    }
  }
`;
export const createComments = /* GraphQL */ `
  mutation CreateComments($input: CreateCommentsInput!) {
    createComments(input: $input) {
      id
      comment
      createdAt
      updatedAt
      deleted
      updatedBy
      updatedByFirstName
      updatedByLastName
    }
  }
`;
export const updateComments = /* GraphQL */ `
  mutation UpdateComments($input: UpdateCommentsInput!) {
    updateComments(input: $input) {
      id
      comment
      createdAt
      updatedAt
      deleted
      updatedBy
      updatedByFirstName
      updatedByLastName
    }
  }
`;
export const deleteComments = /* GraphQL */ `
  mutation DeleteComments($input: DeleteCommentsInput!) {
    deleteComments(input: $input) {
      id
      comment
      createdAt
      updatedAt
      deleted
      updatedBy
      updatedByFirstName
      updatedByLastName
    }
  }
`;
export const createExtension = /* GraphQL */ `
  mutation CreateExtension($input: CreateExtensionInput!) {
    createExtension(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      year
      type
      group
      status
      for
      quarterFrom
      quarterTo
      dueDate
      reasonForExtension
      dateAdjustedTo
      reasonForAdjustment
      reasonForDeniedExtension
    }
  }
`;
export const updateExtension = /* GraphQL */ `
  mutation UpdateExtension($input: UpdateExtensionInput!) {
    updateExtension(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      year
      type
      group
      status
      for
      quarterFrom
      quarterTo
      dueDate
      reasonForExtension
      dateAdjustedTo
      reasonForAdjustment
      reasonForDeniedExtension
    }
  }
`;
export const deleteExtension = /* GraphQL */ `
  mutation DeleteExtension($input: DeleteExtensionInput!) {
    deleteExtension(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      year
      type
      group
      status
      for
      quarterFrom
      quarterTo
      dueDate
      reasonForExtension
      dateAdjustedTo
      reasonForAdjustment
      reasonForDeniedExtension
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting($input: CreateSettingInput!) {
    createSetting(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      type
      value
      userId
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting($input: UpdateSettingInput!) {
    updateSetting(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      type
      value
      userId
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting($input: DeleteSettingInput!) {
    deleteSetting(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      type
      value
      userId
    }
  }
`;
export const createEmailRecord = /* GraphQL */ `
  mutation CreateEmailRecord($input: CreateEmailRecordInput!) {
    createEmailRecord(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      payload
      for
      userId
      status
      extraInformation
    }
  }
`;
export const updateEmailRecord = /* GraphQL */ `
  mutation UpdateEmailRecord($input: UpdateEmailRecordInput!) {
    updateEmailRecord(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      payload
      for
      userId
      status
      extraInformation
    }
  }
`;
export const deleteEmailRecord = /* GraphQL */ `
  mutation DeleteEmailRecord($input: DeleteEmailRecordInput!) {
    deleteEmailRecord(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      payload
      for
      userId
      status
      extraInformation
    }
  }
`;
export const createMonitoringGrantInformation = /* GraphQL */ `
  mutation CreateMonitoringGrantInformation(
    $input: CreateMonitoringGrantInformationInput!
  ) {
    createMonitoringGrantInformation(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      monitoringType
      monitoringDate
      subRecipientAgency
      grantProjectTitle
      grantNumber
      approvedGrantStartDate
      approvedGrantEndDate
      extensionEndDate
      name
      title
      projectDirectorName
      projectDirectorTitle
      personnelName
      personnelTitle
    }
  }
`;
export const updateMonitoringGrantInformation = /* GraphQL */ `
  mutation UpdateMonitoringGrantInformation(
    $input: UpdateMonitoringGrantInformationInput!
  ) {
    updateMonitoringGrantInformation(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      monitoringType
      monitoringDate
      subRecipientAgency
      grantProjectTitle
      grantNumber
      approvedGrantStartDate
      approvedGrantEndDate
      extensionEndDate
      name
      title
      projectDirectorName
      projectDirectorTitle
      personnelName
      personnelTitle
    }
  }
`;
export const deleteMonitoringGrantInformation = /* GraphQL */ `
  mutation DeleteMonitoringGrantInformation(
    $input: DeleteMonitoringGrantInformationInput!
  ) {
    deleteMonitoringGrantInformation(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      monitoringType
      monitoringDate
      subRecipientAgency
      grantProjectTitle
      grantNumber
      approvedGrantStartDate
      approvedGrantEndDate
      extensionEndDate
      name
      title
      projectDirectorName
      projectDirectorTitle
      personnelName
      personnelTitle
    }
  }
`;
export const createMonitoringSiteVisitReview = /* GraphQL */ `
  mutation CreateMonitoringSiteVisitReview(
    $input: CreateMonitoringSiteVisitReviewInput!
  ) {
    createMonitoringSiteVisitReview(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      gaOnFile
      gaSupportingDocumentationOnFile
      gaComments
      personnelFunded
      personnelDocumentationOnFile
      personnelRecordsOnFile
      personnelComments
      contractualServiceOnFile
      contractualServiceExecutedContractsOnFile
      contractualServiceComments
      travelOnFile
      travelDocumentationOnFile
      travelComments
      equipmentProperlySheltered
      equipmentTransferred
      equipmentComments
      suppliesOnFile
      suppliesTransferred
      suppliesComments
      otherOnFile
      otherComments
      summaryComments
      monitoringReportSubmittedBy
      monitoringReportSubmittedAt
      scemdPOCReviewedBy
      scemdPOCReviewedAt
      monitoringFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
      status
    }
  }
`;
export const updateMonitoringSiteVisitReview = /* GraphQL */ `
  mutation UpdateMonitoringSiteVisitReview(
    $input: UpdateMonitoringSiteVisitReviewInput!
  ) {
    updateMonitoringSiteVisitReview(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      gaOnFile
      gaSupportingDocumentationOnFile
      gaComments
      personnelFunded
      personnelDocumentationOnFile
      personnelRecordsOnFile
      personnelComments
      contractualServiceOnFile
      contractualServiceExecutedContractsOnFile
      contractualServiceComments
      travelOnFile
      travelDocumentationOnFile
      travelComments
      equipmentProperlySheltered
      equipmentTransferred
      equipmentComments
      suppliesOnFile
      suppliesTransferred
      suppliesComments
      otherOnFile
      otherComments
      summaryComments
      monitoringReportSubmittedBy
      monitoringReportSubmittedAt
      scemdPOCReviewedBy
      scemdPOCReviewedAt
      monitoringFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
      status
    }
  }
`;
export const deleteMonitoringSiteVisitReview = /* GraphQL */ `
  mutation DeleteMonitoringSiteVisitReview(
    $input: DeleteMonitoringSiteVisitReviewInput!
  ) {
    deleteMonitoringSiteVisitReview(input: $input) {
      id
      updatedAt
      createdAt
      updatedBy
      gaOnFile
      gaSupportingDocumentationOnFile
      gaComments
      personnelFunded
      personnelDocumentationOnFile
      personnelRecordsOnFile
      personnelComments
      contractualServiceOnFile
      contractualServiceExecutedContractsOnFile
      contractualServiceComments
      travelOnFile
      travelDocumentationOnFile
      travelComments
      equipmentProperlySheltered
      equipmentTransferred
      equipmentComments
      suppliesOnFile
      suppliesTransferred
      suppliesComments
      otherOnFile
      otherComments
      summaryComments
      monitoringReportSubmittedBy
      monitoringReportSubmittedAt
      scemdPOCReviewedBy
      scemdPOCReviewedAt
      monitoringFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
      status
    }
  }
`;
