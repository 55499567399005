import React from 'react';
import { connect } from 'react-redux'
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import IconButton from "@mui/material/IconButton";
import { MdArrowBack, MdWarning } from 'react-icons/md';

import { SecondaryButton } from "../../styled-components/Buttons/Buttons";

import {
    draftFinancialReimbursementApplication,
} from "../../../redux/actions/index";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        currentQuarter: state.rootReducer.currentQuarter,
        grantApplication: state.rootReducer.grantApplication,
        selectedCounty: state.rootReducer.selectedCounty,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        draftFinancialReimbursementApplication: (payload) => dispatch(draftFinancialReimbursementApplication(payload)),        
    }
}

class FinancialReimbursementChangesRequested extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.changeFinancialReimStatusToDraft = this.changeFinancialReimStatusToDraft.bind(this);
    }

    changeFinancialReimStatusToDraft () {
        this.props.draftFinancialReimbursementApplication();
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())
        return (
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Financial Reimbursement</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>{quarterSubheaderTitle}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                />

                <div className="flex layout vertical center vertical-center container-overflow-y">

                    <MdWarning style={{color:'red'}} className="application-status-icon" />
                    <div style={{ paddingTop:"4px"}} className="layout horizontal center center-justified application-status-title-small">Changes Requested</div>
                    <div style={{ paddingTop:"14px", paddingBottom:"18px", maxWidth: "600px", textAlign: "justify", lineHeight: "1.5" }}>{this.props.currentQuarter.notes}</div>
                    <div className="layout horizontal center-justified">
                        <SecondaryButton onClick={() => this.changeFinancialReimStatusToDraft()}>Edit Reimbursement Form</SecondaryButton>
                    </div>

                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReimbursementChangesRequested);
