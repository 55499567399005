/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFiscalYear = /* GraphQL */ `
  query GetFiscalYear($id: ID!) {
    getFiscalYear(id: $id) {
      id
      year
      availableToCounties
      createdAt
      updatedAt
      updatedBy
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      guidelinesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const listFiscalYears = /* GraphQL */ `
  query ListFiscalYears(
    $filter: ModelFiscalYearFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiscalYears(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        year
        availableToCounties
        createdAt
        updatedAt
        updatedBy
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        guidelinesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFiles {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getYearCountyApplication = /* GraphQL */ `
  query GetYearCountyApplication($id: ID!) {
    getYearCountyApplication(id: $id) {
      id
      year
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listYearCountyApplications = /* GraphQL */ `
  query ListYearCountyApplications(
    $filter: ModelYearCountyApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listYearCountyApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      groupName
      createdAt
      updatedAt
      region
      palmettoId
      narratives {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
        }
        nextToken
      }
      quarterlyprogressreporting {
        items {
          id
          quarter
          taskingslipstatus
          updatedAt
          updatedBy
          updatedByRemName
          createdAt
        }
        nextToken
      }
      monitoringgrantinformation {
        id
        updatedAt
        createdAt
        updatedBy
        monitoringType
        monitoringDate
        subRecipientAgency
        grantProjectTitle
        grantNumber
        approvedGrantStartDate
        approvedGrantEndDate
        extensionEndDate
        name
        title
        projectDirectorName
        projectDirectorTitle
        personnelName
        personnelTitle
      }
      monitoringsitevisitreview {
        id
        updatedAt
        createdAt
        updatedBy
        gaOnFile
        gaSupportingDocumentationOnFile
        gaComments
        personnelFunded
        personnelDocumentationOnFile
        personnelRecordsOnFile
        personnelComments
        contractualServiceOnFile
        contractualServiceExecutedContractsOnFile
        contractualServiceComments
        travelOnFile
        travelDocumentationOnFile
        travelComments
        equipmentProperlySheltered
        equipmentTransferred
        equipmentComments
        suppliesOnFile
        suppliesTransferred
        suppliesComments
        otherOnFile
        otherComments
        summaryComments
        monitoringReportSubmittedBy
        monitoringReportSubmittedAt
        scemdPOCReviewedBy
        scemdPOCReviewedAt
        monitoringFiles {
          nextToken
        }
        status
      }
      awardAmount
      year
      deleted
      grantapplication {
        items {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        nextToken
      }
      financialreimbursement {
        items {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          notes
          awarded
          lastSubmitDate
          lastSubmitName
          lastSubmitUserId
          lastSubmitEmail
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          deobligatedFunds
          deobligatedFundsReason
        }
        nextToken
      }
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getQuarterlyProgressReporting = /* GraphQL */ `
  query GetQuarterlyProgressReporting($id: ID!) {
    getQuarterlyProgressReporting(id: $id) {
      id
      quarter
      taskingslipstatus
      updatedAt
      updatedBy
      updatedByRemName
      createdAt
    }
  }
`;
export const listQuarterlyProgressReportings = /* GraphQL */ `
  query ListQuarterlyProgressReportings(
    $filter: ModelQuarterlyProgressReportingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuarterlyProgressReportings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quarter
        taskingslipstatus
        updatedAt
        updatedBy
        updatedByRemName
        createdAt
      }
      nextToken
    }
  }
`;
export const getFinancialReimbursement = /* GraphQL */ `
  query GetFinancialReimbursement($id: ID!) {
    getFinancialReimbursement(id: $id) {
      id
      quarter
      quarterStartDate
      quarterEndDate
      updatedBy
      year
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      createdAt
      updatedAt
      deleted
      status
      notes
      awarded
      lastSubmitDate
      lastSubmitName
      lastSubmitUserId
      lastSubmitEmail
      checkNumber
      checkAmount
      checkMailDate
      checksReissued {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      currentReissuedCheck {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      additionalChecks {
        items {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        nextToken
      }
      additionalCheckInProgress {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      attachedGrantApplication {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      personnel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualService {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      travel {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      equipment {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      supply {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      cert {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      other {
        items {
          id
          deleted
          updatedBy
          positionTitle
          quarterAmount
          matchAmount
          taxAmount
          notes
          file
          createdAt
          updatedAt
        }
        nextToken
      }
      deobligatedFunds
      deobligatedFundsReason
    }
  }
`;
export const listFinancialReimbursements = /* GraphQL */ `
  query ListFinancialReimbursements(
    $filter: ModelFinancialReimbursementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFinancialReimbursements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      nextToken
    }
  }
`;
export const getChecksReissued = /* GraphQL */ `
  query GetChecksReissued($id: ID!) {
    getChecksReissued(id: $id) {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const listChecksReissueds = /* GraphQL */ `
  query ListChecksReissueds(
    $filter: ModelChecksReissuedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChecksReissueds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      nextToken
    }
  }
`;
export const getAdditionalChecks = /* GraphQL */ `
  query GetAdditionalChecks($id: ID!) {
    getAdditionalChecks(id: $id) {
      id
      deleted
      updatedBy
      createdAt
      updatedAt
      checkNumber
      checkAmount
      checkMailDate
      stateSceisDocumentNumber
      stateSceisSubmissionDate
      reason
      checkNumber2
      checkAmount2
      checkMailDate2
      stateSceisDocumentNumber2
      stateSceisSubmissionDate2
      reason2
    }
  }
`;
export const listAdditionalCheckss = /* GraphQL */ `
  query ListAdditionalCheckss(
    $filter: ModelAdditionalChecksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdditionalCheckss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deleted
        updatedBy
        createdAt
        updatedAt
        checkNumber
        checkAmount
        checkMailDate
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        reason
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        reason2
      }
      nextToken
    }
  }
`;
export const getFimPersonnel = /* GraphQL */ `
  query GetFimPersonnel($id: ID!) {
    getFimPersonnel(id: $id) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFimPersonnels = /* GraphQL */ `
  query ListFimPersonnels(
    $filter: ModelFimPersonnelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFimPersonnels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        notes
        file
        financialreimbursement {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          notes
          awarded
          lastSubmitDate
          lastSubmitName
          lastSubmitUserId
          lastSubmitEmail
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          deobligatedFunds
          deobligatedFundsReason
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFimContractualService = /* GraphQL */ `
  query GetFimContractualService($id: ID!) {
    getFimContractualService(id: $id) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFimContractualServices = /* GraphQL */ `
  query ListFimContractualServices(
    $filter: ModelFimContractualServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFimContractualServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        notes
        file
        financialreimbursement {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          notes
          awarded
          lastSubmitDate
          lastSubmitName
          lastSubmitUserId
          lastSubmitEmail
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          deobligatedFunds
          deobligatedFundsReason
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFimTravel = /* GraphQL */ `
  query GetFimTravel($id: ID!) {
    getFimTravel(id: $id) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFimTravels = /* GraphQL */ `
  query ListFimTravels(
    $filter: ModelFimTravelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFimTravels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        notes
        file
        financialreimbursement {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          notes
          awarded
          lastSubmitDate
          lastSubmitName
          lastSubmitUserId
          lastSubmitEmail
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          deobligatedFunds
          deobligatedFundsReason
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFimEquipment = /* GraphQL */ `
  query GetFimEquipment($id: ID!) {
    getFimEquipment(id: $id) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFimEquipments = /* GraphQL */ `
  query ListFimEquipments(
    $filter: ModelFimEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFimEquipments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        notes
        file
        financialreimbursement {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          notes
          awarded
          lastSubmitDate
          lastSubmitName
          lastSubmitUserId
          lastSubmitEmail
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          deobligatedFunds
          deobligatedFundsReason
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFimSupply = /* GraphQL */ `
  query GetFimSupply($id: ID!) {
    getFimSupply(id: $id) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFimSupplys = /* GraphQL */ `
  query ListFimSupplys(
    $filter: ModelFimSupplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFimSupplys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        notes
        file
        financialreimbursement {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          notes
          awarded
          lastSubmitDate
          lastSubmitName
          lastSubmitUserId
          lastSubmitEmail
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          deobligatedFunds
          deobligatedFundsReason
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFimCert = /* GraphQL */ `
  query GetFimCert($id: ID!) {
    getFimCert(id: $id) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFimCerts = /* GraphQL */ `
  query ListFimCerts(
    $filter: ModelFimCertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFimCerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        notes
        file
        financialreimbursement {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          notes
          awarded
          lastSubmitDate
          lastSubmitName
          lastSubmitUserId
          lastSubmitEmail
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          deobligatedFunds
          deobligatedFundsReason
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFimOther = /* GraphQL */ `
  query GetFimOther($id: ID!) {
    getFimOther(id: $id) {
      id
      deleted
      updatedBy
      positionTitle
      quarterAmount
      matchAmount
      taxAmount
      notes
      file
      financialreimbursement {
        id
        quarter
        quarterStartDate
        quarterEndDate
        updatedBy
        year
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        createdAt
        updatedAt
        deleted
        status
        notes
        awarded
        lastSubmitDate
        lastSubmitName
        lastSubmitUserId
        lastSubmitEmail
        checkNumber
        checkAmount
        checkMailDate
        checksReissued {
          nextToken
        }
        currentReissuedCheck {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        additionalChecks {
          nextToken
        }
        additionalCheckInProgress {
          id
          deleted
          updatedBy
          createdAt
          updatedAt
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          reason
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          reason2
        }
        stateSceisDocumentNumber
        stateSceisSubmissionDate
        checkNumber2
        checkAmount2
        checkMailDate2
        stateSceisDocumentNumber2
        stateSceisSubmissionDate2
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        attachedGrantApplication {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        personnel {
          nextToken
        }
        contractualService {
          nextToken
        }
        travel {
          nextToken
        }
        equipment {
          nextToken
        }
        supply {
          nextToken
        }
        cert {
          nextToken
        }
        other {
          nextToken
        }
        deobligatedFunds
        deobligatedFundsReason
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFimOthers = /* GraphQL */ `
  query ListFimOthers(
    $filter: ModelFimOtherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFimOthers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        updatedBy
        positionTitle
        quarterAmount
        matchAmount
        taxAmount
        notes
        file
        financialreimbursement {
          id
          quarter
          quarterStartDate
          quarterEndDate
          updatedBy
          year
          createdAt
          updatedAt
          deleted
          status
          notes
          awarded
          lastSubmitDate
          lastSubmitName
          lastSubmitUserId
          lastSubmitEmail
          checkNumber
          checkAmount
          checkMailDate
          stateSceisDocumentNumber
          stateSceisSubmissionDate
          checkNumber2
          checkAmount2
          checkMailDate2
          stateSceisDocumentNumber2
          stateSceisSubmissionDate2
          deobligatedFunds
          deobligatedFundsReason
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGrantApplication = /* GraphQL */ `
  query GetGrantApplication($id: ID!) {
    getGrantApplication(id: $id) {
      id
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      updatedBy
      version
      versionName
      createdAt
      updatedAt
      year
      deleted
      status
      awarded
      deductCertFromFederal
      salary {
        items {
          id
          deleted
          updatedBy
          name
          positionTitle
          federal
          county
          countyInKind
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          createdAt
          updatedAt
        }
        nextToken
      }
      salaryNarrative
      fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        createdAt
        updatedAt
      }
      fringeBenefitNarrative
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      contractualService {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      contractualServiceNarrative
      travel {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      travelNarrative
      equipment {
        items {
          id
          name
          femaItemNumber
          quantity
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      equipmentNarrative
      supply {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      supplyNarrative
      other {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      otherNarrative
      cert {
        items {
          id
          name
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        nextToken
      }
      certNarrative
      acceptanceOfAuditReqFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      certificationDocumentFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      assurancesNonContructionFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      lobbyingCertificationFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      edmEquipmentPolicyFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      positionDescFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      acknowledgementFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      otherFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      scopeOfWorkFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      narrativesFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      awardDocumentsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardCoverLetterUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardSpecialInstructionsUploadedByState {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      awardDocumentsUploadedByCounty {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      changesRequestedOnApplication
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      name
      address
      address2
      city
      state
      county
      zipcode
      latitude
      longitude
      enableReverseGeocoding
      implementingAgency
      phoneNumber
      faxNumber
      currentApplicationRevisionId
      grantApplicationRevision {
        items {
          id
          createdAt
          updatedAt
          grantApplicationLastUpdatedAt
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const listGrantApplications = /* GraphQL */ `
  query ListGrantApplications(
    $filter: ModelGrantApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGrantApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getGrantApplicationRevision = /* GraphQL */ `
  query GetGrantApplicationRevision($id: ID!) {
    getGrantApplicationRevision(id: $id) {
      id
      createdAt
      updatedAt
      grantApplicationLastUpdatedAt
      revision {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      updatedBy
    }
  }
`;
export const listGrantApplicationRevisions = /* GraphQL */ `
  query ListGrantApplicationRevisions(
    $filter: ModelGrantApplicationRevisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGrantApplicationRevisions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        grantApplicationLastUpdatedAt
        revision {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        updatedBy
      }
      nextToken
    }
  }
`;
export const getSalary = /* GraphQL */ `
  query GetSalary($id: ID!) {
    getSalary(id: $id) {
      id
      deleted
      updatedBy
      name
      positionTitle
      federal
      county
      countyInKind
      fbFederalFunding
      fbCountyCashFunding
      fbCountyInkindFunding
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSalarys = /* GraphQL */ `
  query ListSalarys(
    $filter: ModelSalaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalarys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        updatedBy
        name
        positionTitle
        federal
        county
        countyInKind
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFringeBenefit = /* GraphQL */ `
  query GetFringeBenefit($id: ID!) {
    getFringeBenefit(id: $id) {
      id
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFringeBenefits = /* GraphQL */ `
  query ListFringeBenefits(
    $filter: ModelFringeBenefitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFringeBenefits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deleted
        updatedBy
        federal
        county
        countyInKind
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContractualService = /* GraphQL */ `
  query GetContractualService($id: ID!) {
    getContractualService(id: $id) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listContractualServices = /* GraphQL */ `
  query ListContractualServices(
    $filter: ModelContractualServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractualServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        deleted
        updatedBy
        federal
        county
        countyInKind
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTravel = /* GraphQL */ `
  query GetTravel($id: ID!) {
    getTravel(id: $id) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTravels = /* GraphQL */ `
  query ListTravels(
    $filter: ModelTravelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTravels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        deleted
        updatedBy
        federal
        county
        countyInKind
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEquipment = /* GraphQL */ `
  query GetEquipment($id: ID!) {
    getEquipment(id: $id) {
      id
      name
      femaItemNumber
      quantity
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEquipments = /* GraphQL */ `
  query ListEquipments(
    $filter: ModelEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEquipments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        femaItemNumber
        quantity
        deleted
        updatedBy
        federal
        county
        countyInKind
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSupply = /* GraphQL */ `
  query GetSupply($id: ID!) {
    getSupply(id: $id) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSupplys = /* GraphQL */ `
  query ListSupplys(
    $filter: ModelSupplyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupplys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        deleted
        updatedBy
        federal
        county
        countyInKind
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOther = /* GraphQL */ `
  query GetOther($id: ID!) {
    getOther(id: $id) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOthers = /* GraphQL */ `
  query ListOthers(
    $filter: ModelOtherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOthers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        deleted
        updatedBy
        federal
        county
        countyInKind
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCert = /* GraphQL */ `
  query GetCert($id: ID!) {
    getCert(id: $id) {
      id
      name
      deleted
      updatedBy
      federal
      county
      countyInKind
      grantapplication {
        id
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        updatedBy
        version
        versionName
        createdAt
        updatedAt
        year
        deleted
        status
        awarded
        deductCertFromFederal
        salary {
          nextToken
        }
        salaryNarrative
        fringeBenefit {
          id
          deleted
          updatedBy
          federal
          county
          countyInKind
          createdAt
          updatedAt
        }
        fringeBenefitNarrative
        fbFederalFunding
        fbCountyCashFunding
        fbCountyInkindFunding
        contractualService {
          nextToken
        }
        contractualServiceNarrative
        travel {
          nextToken
        }
        travelNarrative
        equipment {
          nextToken
        }
        equipmentNarrative
        supply {
          nextToken
        }
        supplyNarrative
        other {
          nextToken
        }
        otherNarrative
        cert {
          nextToken
        }
        certNarrative
        acceptanceOfAuditReqFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        certificationDocumentFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        assurancesNonContructionFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        lobbyingCertificationFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        edmEquipmentPolicyFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        positionDescFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        acknowledgementFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        otherFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        scopeOfWorkFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        narrativesFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        awardDocumentsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardCoverLetterUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardSpecialInstructionsUploadedByState {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        awardDocumentsUploadedByCounty {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        changesRequestedOnApplication
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        name
        address
        address2
        city
        state
        county
        zipcode
        latitude
        longitude
        enableReverseGeocoding
        implementingAgency
        phoneNumber
        faxNumber
        currentApplicationRevisionId
        grantApplicationRevision {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCerts = /* GraphQL */ `
  query ListCerts(
    $filter: ModelCertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        deleted
        updatedBy
        federal
        county
        countyInKind
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFunding = /* GraphQL */ `
  query GetFunding($id: ID!) {
    getFunding(id: $id) {
      id
      federal
      county
      countyInKind
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const listFundings = /* GraphQL */ `
  query ListFundings(
    $filter: ModelFundingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFundings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        federal
        county
        countyInKind
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      nextToken
    }
  }
`;
export const getNarrative = /* GraphQL */ `
  query GetNarrative($id: ID!) {
    getNarrative(id: $id) {
      id
      narrativeTitle
      narrativeCategory
      narrativeShortDescription
      narrativeLongDescription
      narrativeFrequency
      usePalmettoForREMInformation
      useFinancialReimbursementStatus
      narrativePOCFirstName
      narrativePOCLastName
      narrativePOCPhoneNumber
      narrativePOCEmailAddress
      narrativePOCPositionTitle
      quarter
      deleted
      createdAt
      updatedAt
      updatedBy
      year
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      testField
      groups {
        items {
          id
          completed
          createdAt
          updatedAt
          deleted
          updatedBy
          status
          notes
          extraFile
          quarter
          updatedByFirstName
          updatedByLastName
          year
        }
        nextToken
      }
    }
  }
`;
export const listNarratives = /* GraphQL */ `
  query ListNarratives(
    $filter: ModelNarrativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNarratives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        narrativeTitle
        narrativeCategory
        narrativeShortDescription
        narrativeLongDescription
        narrativeFrequency
        usePalmettoForREMInformation
        useFinancialReimbursementStatus
        narrativePOCFirstName
        narrativePOCLastName
        narrativePOCPhoneNumber
        narrativePOCEmailAddress
        narrativePOCPositionTitle
        quarter
        deleted
        createdAt
        updatedAt
        updatedBy
        year
        uploadedFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        testField
        groups {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getREMContact = /* GraphQL */ `
  query GetREMContact($id: ID!) {
    getREMContact(id: $id) {
      id
      firstName
      lastName
      emailAddress
      phoneNumber
      region
      deleted
      createdAt
      updatedAt
      updatedBy
      positionTitle
      year
    }
  }
`;
export const listREMContacts = /* GraphQL */ `
  query ListREMContacts(
    $filter: ModelREMContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listREMContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        emailAddress
        phoneNumber
        region
        deleted
        createdAt
        updatedAt
        updatedBy
        positionTitle
        year
      }
      nextToken
    }
  }
`;
export const getGroupNarrativeM2M = /* GraphQL */ `
  query GetGroupNarrativeM2M($id: ID!) {
    getGroupNarrativeM2M(id: $id) {
      id
      narrative {
        id
        narrativeTitle
        narrativeCategory
        narrativeShortDescription
        narrativeLongDescription
        narrativeFrequency
        usePalmettoForREMInformation
        useFinancialReimbursementStatus
        narrativePOCFirstName
        narrativePOCLastName
        narrativePOCPhoneNumber
        narrativePOCEmailAddress
        narrativePOCPositionTitle
        quarter
        deleted
        createdAt
        updatedAt
        updatedBy
        year
        uploadedFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        testField
        groups {
          nextToken
        }
      }
      group {
        id
        groupName
        createdAt
        updatedAt
        region
        palmettoId
        narratives {
          nextToken
        }
        quarterlyprogressreporting {
          nextToken
        }
        monitoringgrantinformation {
          id
          updatedAt
          createdAt
          updatedBy
          monitoringType
          monitoringDate
          subRecipientAgency
          grantProjectTitle
          grantNumber
          approvedGrantStartDate
          approvedGrantEndDate
          extensionEndDate
          name
          title
          projectDirectorName
          projectDirectorTitle
          personnelName
          personnelTitle
        }
        monitoringsitevisitreview {
          id
          updatedAt
          createdAt
          updatedBy
          gaOnFile
          gaSupportingDocumentationOnFile
          gaComments
          personnelFunded
          personnelDocumentationOnFile
          personnelRecordsOnFile
          personnelComments
          contractualServiceOnFile
          contractualServiceExecutedContractsOnFile
          contractualServiceComments
          travelOnFile
          travelDocumentationOnFile
          travelComments
          equipmentProperlySheltered
          equipmentTransferred
          equipmentComments
          suppliesOnFile
          suppliesTransferred
          suppliesComments
          otherOnFile
          otherComments
          summaryComments
          monitoringReportSubmittedBy
          monitoringReportSubmittedAt
          scemdPOCReviewedBy
          scemdPOCReviewedAt
          status
        }
        awardAmount
        year
        deleted
        grantapplication {
          nextToken
        }
        financialreimbursement {
          nextToken
        }
      }
      completed
      createdAt
      updatedAt
      deleted
      updatedBy
      status
      notes
      uploadedFile {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      extraFile
      quarter
      updatedByFirstName
      updatedByLastName
      year
      extensions {
        items {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        nextToken
      }
      currentExtension {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      m117CompletedDocument {
        id
        bucket
        title
        size
        downloadTitle
        createdAt
        updatedAt
        deleted
        updatedBy
      }
      comments {
        items {
          id
          comment
          createdAt
          updatedAt
          deleted
          updatedBy
          updatedByFirstName
          updatedByLastName
        }
        nextToken
      }
    }
  }
`;
export const listGroupNarrativeM2Ms = /* GraphQL */ `
  query ListGroupNarrativeM2Ms(
    $filter: ModelGroupNarrativeM2MFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupNarrativeM2Ms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        narrative {
          id
          narrativeTitle
          narrativeCategory
          narrativeShortDescription
          narrativeLongDescription
          narrativeFrequency
          usePalmettoForREMInformation
          useFinancialReimbursementStatus
          narrativePOCFirstName
          narrativePOCLastName
          narrativePOCPhoneNumber
          narrativePOCEmailAddress
          narrativePOCPositionTitle
          quarter
          deleted
          createdAt
          updatedAt
          updatedBy
          year
          testField
        }
        group {
          id
          groupName
          createdAt
          updatedAt
          region
          palmettoId
          awardAmount
          year
          deleted
        }
        completed
        createdAt
        updatedAt
        deleted
        updatedBy
        status
        notes
        uploadedFile {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        extraFile
        quarter
        updatedByFirstName
        updatedByLastName
        year
        extensions {
          nextToken
        }
        currentExtension {
          id
          updatedAt
          createdAt
          updatedBy
          year
          type
          group
          status
          for
          quarterFrom
          quarterTo
          dueDate
          reasonForExtension
          dateAdjustedTo
          reasonForAdjustment
          reasonForDeniedExtension
        }
        m117CompletedDocument {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getComments = /* GraphQL */ `
  query GetComments($id: ID!) {
    getComments(id: $id) {
      id
      comment
      createdAt
      updatedAt
      deleted
      updatedBy
      updatedByFirstName
      updatedByLastName
    }
  }
`;
export const listCommentss = /* GraphQL */ `
  query ListCommentss(
    $filter: ModelCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        comment
        createdAt
        updatedAt
        deleted
        updatedBy
        updatedByFirstName
        updatedByLastName
      }
      nextToken
    }
  }
`;
export const getExtension = /* GraphQL */ `
  query GetExtension($id: ID!) {
    getExtension(id: $id) {
      id
      updatedAt
      createdAt
      updatedBy
      year
      type
      group
      status
      for
      quarterFrom
      quarterTo
      dueDate
      reasonForExtension
      dateAdjustedTo
      reasonForAdjustment
      reasonForDeniedExtension
    }
  }
`;
export const listExtensions = /* GraphQL */ `
  query ListExtensions(
    $filter: ModelExtensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExtensions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      nextToken
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($id: ID!) {
    getSetting(id: $id) {
      id
      updatedAt
      createdAt
      updatedBy
      type
      value
      userId
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        updatedAt
        createdAt
        updatedBy
        type
        value
        userId
      }
      nextToken
    }
  }
`;
export const getEmailRecord = /* GraphQL */ `
  query GetEmailRecord($id: ID!) {
    getEmailRecord(id: $id) {
      id
      updatedAt
      createdAt
      updatedBy
      payload
      for
      userId
      status
      extraInformation
    }
  }
`;
export const listEmailRecords = /* GraphQL */ `
  query ListEmailRecords(
    $filter: ModelEmailRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        updatedAt
        createdAt
        updatedBy
        payload
        for
        userId
        status
        extraInformation
      }
      nextToken
    }
  }
`;
export const getMonitoringGrantInformation = /* GraphQL */ `
  query GetMonitoringGrantInformation($id: ID!) {
    getMonitoringGrantInformation(id: $id) {
      id
      updatedAt
      createdAt
      updatedBy
      monitoringType
      monitoringDate
      subRecipientAgency
      grantProjectTitle
      grantNumber
      approvedGrantStartDate
      approvedGrantEndDate
      extensionEndDate
      name
      title
      projectDirectorName
      projectDirectorTitle
      personnelName
      personnelTitle
    }
  }
`;
export const listMonitoringGrantInformations = /* GraphQL */ `
  query ListMonitoringGrantInformations(
    $filter: ModelMonitoringGrantInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonitoringGrantInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        updatedBy
        monitoringType
        monitoringDate
        subRecipientAgency
        grantProjectTitle
        grantNumber
        approvedGrantStartDate
        approvedGrantEndDate
        extensionEndDate
        name
        title
        projectDirectorName
        projectDirectorTitle
        personnelName
        personnelTitle
      }
      nextToken
    }
  }
`;
export const getMonitoringSiteVisitReview = /* GraphQL */ `
  query GetMonitoringSiteVisitReview($id: ID!) {
    getMonitoringSiteVisitReview(id: $id) {
      id
      updatedAt
      createdAt
      updatedBy
      gaOnFile
      gaSupportingDocumentationOnFile
      gaComments
      personnelFunded
      personnelDocumentationOnFile
      personnelRecordsOnFile
      personnelComments
      contractualServiceOnFile
      contractualServiceExecutedContractsOnFile
      contractualServiceComments
      travelOnFile
      travelDocumentationOnFile
      travelComments
      equipmentProperlySheltered
      equipmentTransferred
      equipmentComments
      suppliesOnFile
      suppliesTransferred
      suppliesComments
      otherOnFile
      otherComments
      summaryComments
      monitoringReportSubmittedBy
      monitoringReportSubmittedAt
      scemdPOCReviewedBy
      scemdPOCReviewedAt
      monitoringFiles {
        items {
          id
          bucket
          title
          size
          downloadTitle
          createdAt
          updatedAt
          deleted
          updatedBy
        }
        nextToken
      }
      status
    }
  }
`;
export const listMonitoringSiteVisitReviews = /* GraphQL */ `
  query ListMonitoringSiteVisitReviews(
    $filter: ModelMonitoringSiteVisitReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMonitoringSiteVisitReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        updatedAt
        createdAt
        updatedBy
        gaOnFile
        gaSupportingDocumentationOnFile
        gaComments
        personnelFunded
        personnelDocumentationOnFile
        personnelRecordsOnFile
        personnelComments
        contractualServiceOnFile
        contractualServiceExecutedContractsOnFile
        contractualServiceComments
        travelOnFile
        travelDocumentationOnFile
        travelComments
        equipmentProperlySheltered
        equipmentTransferred
        equipmentComments
        suppliesOnFile
        suppliesTransferred
        suppliesComments
        otherOnFile
        otherComments
        summaryComments
        monitoringReportSubmittedBy
        monitoringReportSubmittedAt
        scemdPOCReviewedBy
        scemdPOCReviewedAt
        monitoringFiles {
          nextToken
        }
        status
      }
      nextToken
    }
  }
`;
