// This file will contain system level config on how certain parts of application should work
// TODO: Move this config into a config model when a UI is set up for this

const AppConfig = {
    "2019": {
        "grantapplication": {
            "cert": {
                "showCheckbox": true,
                "autoDeductAmount": false,
            }
        }
    },
    "2020": {
        "grantapplication": {
            "cert": {
                "showCheckbox": false,
                "autoDeductAmount": true,
            }
        }
    },
    "2021": {
        "grantapplication": {
            "cert": {
                "showCheckbox": false,
                "autoDeductAmount": true,
            }
        }
    },
    "2022": {
        "grantapplication": {
            "cert": {
                "showCheckbox": false,
                "autoDeductAmount": true,
            }
        }
    },        
    "2023": {
        "grantapplication": {
            "cert": {
                "showCheckbox": false,
                "autoDeductAmount": true,
            }
        }
    },        
    "2024": {
        "grantapplication": {
            "cert": {
                "showCheckbox": false,
                "autoDeductAmount": true,
            }
        }
    },
    "2025": {
        "grantapplication": {
            "cert": {
                "showCheckbox": false,
                "autoDeductAmount": true,
            }
        }
    },  
    "2026": {
        "grantapplication": {
            "cert": {
                "showCheckbox": false,
                "autoDeductAmount": true,
            }
        }
    },                
};

export default AppConfig;