import React from 'react';
import { connect } from 'react-redux'

import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import IconButton from "@mui/material/IconButton";
import { MdArrowBack, MdMoreVert } from 'react-icons/md';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import FileUpload from "../common/FileUpload";
import { GreenButton } from "../styled-components/Buttons/Buttons";
import { saveAwardLetterFileState, awardApplication, printGrantApplicationForm } from "../../redux/actions/index";
import ErrorBox from "../common/ErrorBox";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveAwardLetterFileState: (payload, callback) => dispatch(saveAwardLetterFileState(payload, callback)),
        awardApplication: () => dispatch(awardApplication()),
        printGrantApplicationForm: (payload) => dispatch(printGrantApplicationForm(payload)),     
    };
};

class StateSubmitApproval extends React.Component {

    state = {
        cover_letter_file_id: this.props.currentApplicationState && this.props.currentApplicationState.awardCoverLetterUploadedByState && this.props.currentApplicationState.awardCoverLetterUploadedByState.id || "",

        award_letter_file_id: this.props.currentApplicationState && this.props.currentApplicationState.awardDocumentUploadedByState && this.props.currentApplicationState.awardDocumentUploadedByState.id || "",

        special_instructions_file_id: this.props.currentApplicationState && this.props.currentApplicationState.awardSpecialInstructionsUploadedByState && this.props.currentApplicationState.awardSpecialInstructionsUploadedByState.id || "",
        errors: [],
        anchorEl: null,
    }

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
        this.fileToSave = this.fileToSave.bind(this);
        this.fileToDelete = this.fileToDelete.bind(this);
        this.submitApproval = this.submitApproval.bind(this);
        this.saveFileCallback = this.saveFileCallback.bind(this);
        this.printGrantApplicationForm = this.printGrantApplicationForm.bind(this);
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    printGrantApplicationForm() {
        this.handleClose();
        this.props.printGrantApplicationForm(this.props.currentApplicationState)
    }



    fileToSave(data, field) {
        this.setState({ [field]: data.data.createFile.id });
    }

    fileToDelete(data, field) {
        this.setState({ [field]: null });
    }

    submitApproval () {
        this.state.errors = [];
        this.setState({
            errors: this.state.errors
        });

        if(!this.state.cover_letter_file_id) {
            this.state.errors.push({
                text: "Please attach cover letter document"
            })
        }

        if (!this.state.award_letter_file_id) {
            this.state.errors.push({
                text: "Please attach award letter document"
            })
        }

        if (this.state.errors.length > 0) {
            this.setState({
                errors: this.state.errors
            });
            return;
        }

        this.props.saveAwardLetterFileState(this.state, this.saveFileCallback)
    }

    saveFileCallback(data) {
        // After State submits the documents, put the county into `Awarded` state
        this.props.awardApplication()
        this.props.history.replace("/application/")
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        
        return (

            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Application</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>LEMPG {this.props.selectedYear} • {this.props.grantApplication.group && this.props.grantApplication.group.groupName}</span>}

                    suffix={
                        <div>
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'application-menu' : undefined}
                                onClick={this.handleIconDropdownClick}
                            >
                                <MdMoreVert className="icon" />
                            </IconButton>

                            <Menu
                                id="application-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                <MenuItem key="printApplicationForm" onClick={() => this.printGrantApplicationForm()}>
                                    Print Grant Application Form
                                    </MenuItem>
                            </Menu>
                        </div>

                    }                


                />

                <div className="flex form-container">

                    {
                        this.state.errors.length ? <ErrorBox errors={this.state.errors} />
                            : ""
                    }
                    <div className="layout horizontal center section-title">Award Documents</div>
                    <div className="height-10"></div>
                    <div className="opacity-54">
                        Attach the county's Award Documents below. Cover letter and Award letter are required, while Special instructions are only needed in specific instances.
                    </div>

                    <div className="height-20"></div>

                    <div className="layout horizontal center section-title"><span className="title-small">Cover Letter</span></div>

                    <FileUpload showCancelForState field="cover_letter_file_id" fileToSave={this.fileToSave} fileToDelete={this.fileToDelete}
                        singleupload="true" uploadedFile={this.props.currentApplicationState && this.props.currentApplicationState.awardCoverLetterUploadedByState ? this.props.currentApplicationState.awardCoverLetterUploadedByState : null} nocontainer="true" notitle="true" />

                    <div className="height-20"></div>

                    <div className="layout horizontal center section-title"><span className="title-small">Award Letter</span></div>

                    <FileUpload showCancelForState field="award_letter_file_id" fileToSave={this.fileToSave} fileToDelete={this.fileToDelete}
                        singleupload="true" uploadedFile={this.props.currentApplicationState && this.props.currentApplicationState.awardDocumentUploadedByState ? this.props.currentApplicationState.awardDocumentUploadedByState : null} nocontainer="true" notitle="true" />

                    <div className="height-20"></div>

                    <div className="layout horizontal center section-title"><span className="title-small">Special Instructions</span></div>

                    <FileUpload showCancelForState field="special_instructions_file_id" fileToSave={this.fileToSave} fileToDelete={this.fileToDelete}
                        singleupload="true" uploadedFile={this.props.currentApplicationState && this.props.currentApplicationState.awardSpecialInstructionsUploadedByState ? this.props.currentApplicationState.awardSpecialInstructionsUploadedByState : null} nocontainer="true" notitle="true" />


                </div>
                <div className="layout horizontal center form-container">
                    <div className="flex"></div>
                    <GreenButton setwidth="true" onClick={() => this.submitApproval()}>
                        Award
                    </GreenButton>
                </div>

            </div>
            
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StateSubmitApproval);

// export default connect(null, mapDispatchToProps)(StateSubmitApproval);
