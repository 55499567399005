import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdClear } from 'react-icons/md';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { RequiredDropdown } from "../../styled-components/Dropdowns/Dropdown.js";

import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { PrimaryButton } from "../../styled-components/Buttons/Buttons.js";
import ErrorBox from "../../common/ErrorBox.js";
import { RequiredTextField } from "../../styled-components/TextFields/TextFields.js";

import { requestProgressReportingExtension } from "../../../redux/actions/index"


// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        currentNarrative: state.rootReducer.currentNarrative,
        currentGroupNarrativeM2M: state.rootReducer.currentGroupNarrativeM2M,
        lempgUserType: state.rootReducer.lempgUserType,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        requestProgressReportingExtension: (payload, callback) => dispatch(requestProgressReportingExtension(payload, callback)),
    }
}

const DropdownMenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
    },
    PaperProps: {
        style: {
            minWidth: "200px",
        },
    },
};


class ProgressReportingExtensionByQuarter extends React.Component {

    state = {
        open: false,
        errors: [],
        validate: false,
        reasonForExtension: "",
        type: "BY_QUARTER",
        reasonForDeniedExtension: "",
        narrativeTitle: (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.narrative && this.props.currentGroupNarrativeM2M.narrative.narrativeTitle) || "",
        quarterFrom: (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.quarter) || "",
        quarterTo: ""
    }
    constructor(props, context) {
        super(props, context);

        if (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.currentExtension) {
        }
    }

    toUTC = (date) => {
        var newDate = new Date();
        newDate.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        return newDate;
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    requestProgressReportingExtension = () => {
        this.props.requestProgressReportingExtension(this.state, () => {
            this.goBack();
        })
    }

    goBack = () => {
        this.props.history.goBack()
    }

    handleClose = (variable) => {
        this.setState({
            [variable]: false
        });
    };

    openDialog = (variable) => {
        this.setState({
            [variable]: true
        });
    }

    handleQuarterToChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    checkForErrors = () => {
        this.state.validate = false;
        this.state.errors = [];
        this.setState({ validate: this.state.validate });
        this.setState({ errors: this.state.errors });

        if (false && !this.state.newQtr) {
            this.state.errors.push({
                text: "Please select due date"
            });
        }

        if (!this.state.reasonForExtension) {
            this.state.errors.push({
                text: "Please enter reason for extension request"
            });
        }

        if (this.state.errors.length > 0) {
            this.state.validate = true;
            this.setState({ validate: this.state.validate });
            this.setState({ errors: this.state.errors });
            return;
        }


        this.openDialog("open");
    }


    componentDidUpdate() { }

    render() {

        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentGroupNarrativeM2M.quarter.split("").reverse())

        return (

            <div className="layout vertical full-height">

                <Dialog
                    open={this.state.open}
                    onClose={() => this.handleClose("open")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{ maxWidth: "320px" }}>
                        <DialogTitle id="alert-dialog-title">Request an extension: Move</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You are about to request an extension for {this.state.narrativeTitle} this quarter. Are you sure you want to do this?
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.handleClose("open")} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.requestProgressReportingExtension} color="primary" autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </div>

                </Dialog>

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdClear className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Request an extension: Move</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>Progress Reporting • {quarterSubheaderTitle}  • FY{this.props.selectedYear} • {this.props.selectedCounty && this.props.selectedCounty.groupName} • {this.state.narrativeTitle} </span>}
                />

                <div className="flex form-container">
                    {
                        this.state.errors.length ?
                            <ErrorBox errors={this.state.errors} />
                            : ""
                    }
                    <div className="layout horizontal center section-title">Extension Details</div>
                    <div className="opacity-54">
                        You are requesting an extension to move  {this.state.narrativeTitle} from  <b>{quarterSubheaderTitle} FY{this.props.selectedYear}</b> to another quarter. 
                        The state will review and, if approved, move your task to be completed in the approved quarter (and no longer see it in the previous quarter). Note that moving a task to a later quarter will 
                        not have an affect on your Financial Reimbursement check. You will be notified of progress via email.
                    </div>

                    <div style={{ marginTop:"18px", marginLeft:"-6px", marginRight: "-6px" }} className="layout horizontal">
                     
                            {/* <span style={{ "font-size": "12px", position: "relative", left: "92px", top: "16px", color: "var(--required-textfield-color)" }}>●</span> */}
                            <FormControl className="flex">
                            <InputLabel style={{ margin: "0 6px" }} shrink htmlFor="selectQuarterTo"> New quarter <span className="required"></span> </InputLabel>
                            <RequiredDropdown
                                value={this.state.quarterTo}
                                onChange={this.handleQuarterToChange('quarterTo')}
                                input={<Input name="selectQuarterTo" id="selectQuarterTo" />}
                                MenuProps={DropdownMenuProps}
                                custommargin="true"
                                displayEmpty
                                name="selectQuarterTo"
                                className="required-dropdown"
                            >
                                <MenuItem value="">
                                    <span className="placeholder">Select new quarter</span>
                                </MenuItem>
                                <MenuItem value="quarter1">Q1</MenuItem>
                                <MenuItem value="quarter2">Q2</MenuItem>
                                <MenuItem value="quarter3">Q3</MenuItem>
                                <MenuItem value="quarter4">Q4</MenuItem>
                            </RequiredDropdown>
                        </FormControl>

                    </div>

                    <div className="layout horizontal">

                        <RequiredTextField value={this.state.reasonForExtension} multiline onChange={this.handleChange('reasonForExtension')} id="extensionReason" label="Reason for extension request" error={!this.state.checkAmount && this.state.validate ? true : false} placeholder="Enter reason for extension request" className="flex" margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />


                    </div>

                </div>
                <div className="layout horizontal center-justified form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                    <PrimaryButton setwidth="true" onClick={() => this.checkForErrors()}>
                        SUBMIT
                    </PrimaryButton>
                </div>


            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressReportingExtensionByQuarter);
