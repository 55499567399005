import { Storage } from 'aws-amplify';


export async function getFileUploadedLink(payload) {
    if(!payload || !payload.title) {
        return
    }
    let fileRes = await Storage.get(payload.title, {
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        }
    })

    return fileRes;
}

export async function getFileUploadedLinkFromBucket(payload, bucket) {
    if (!payload || !payload.key) {
        return
    }

    let fileRes = await Storage.get(payload.key, {
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
        bucket: bucket,
        region: 'us-east-1',
    })

    return fileRes;
}

export async function getFileUploadedObject(payload) {
    if(!payload || !payload.title) {
        return
    }
    let fileRes = await Storage.get(payload.title, {
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        }
    })

    return {
        originalObject: payload, 
        link: fileRes
    }
}

export function triggerDownload(name, url) {
    var aelement = document.createElement('a');
    aelement.download = name;
    aelement.href = url;
    aelement.target = '_blank';
    document.body.appendChild(aelement);
    aelement.click();
    // document.body.removeChild(aelement);
}

function downloadFileBlob(url, callback, payload){
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = 'blob';
    xhr.onreadystatechange = function (e) {
        if (this.readyState === 4 && this.status === 200) {
            var reader = new FileReader();
            reader.onload = function (e) {
                callback(e.target.result, payload)
            };
            reader.readAsArrayBuffer(this.response);
        }
    };
    xhr.send();
}



export async function zipDownload(files, zipName) {

    var arr = files || [];

    const zip = require('jszip')()
    var folderName = zipName;
    var folder = zip;

    var funcs = []

    arr.forEach((fileToDownload) => {
        funcs.push(getFileUploadedObject(fileToDownload))
    })

    var progress = 0;

    Promise.all(funcs)
        .then((links) => {
            links.forEach((link, index) => {
                downloadFileBlob(link.link, (data, payload) => {
                    progress++

                    var fileName = payload.originalObject.title.substring(payload.originalObject.title.indexOf("__") + 2, payload.originalObject.title.length)

                    folder.file(fileName, data)

                    if (progress === arr.length) {
                        var zipPromise = zip.generateAsync({ type: "blob" });
                        zipPromise.then((content) => {
                            var name = folderName + ".zip";
                            var url = window.URL.createObjectURL(content);
                            triggerDownload(name, url);
                        })
                    }
                }, link)
            })

        })
        .catch(function (err) {
            console.log("Errored", err)
        })
}

export const put = async (filename, content, contentType) => {
    return Storage.put(filename, content, {
        contentType: contentType,
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        }
    })
}

export const getObject = async (filename) => {
    return Storage.get(filename, {
        level: 'public',
        download: true,
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        }
    })
}

export const get = async (filename, bucket) => {
    return Storage.get(filename, {
        level: 'public',
        bucket: bucket,
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        }
    })
}