import React from 'react';
import { connect } from 'react-redux'
import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import IconButton from "@mui/material/IconButton";
import { MdMenu, MdAccessTime } from 'react-icons/md';


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

class StateRequestChanges extends React.Component {
    constructor(props, context) {
        super(props, context);
    }


    render() {
        return (
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Application</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>LEMPG {this.props.selectedYear} • {this.props.grantApplication.group && this.props.grantApplication.group.groupName}</span>}
                />

                <div className="flex layout vertical center vertical-center container-overflow-y splash-screen">

                    <MdAccessTime className="icon-2 application-status-icon" />
                    <div className="height-20"></div>
                    <span className="layout horizontal center center-justified application-status-title-small">Changes Requested</span>
                    <div className="height-20"></div>

                    <div style={{ padding: "0 16px", maxWidth: "280px" }} className="text-center title-big opacity-54"> We’ve let <span style={{ textTransform: "capitalize" }}>{this.props.grantApplication.group && this.props.grantApplication.group.groupName} </span> county know that their grant application requires changes.</div>

                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StateRequestChanges);
