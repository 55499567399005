import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { MdClear } from 'react-icons/md';

// Local imports

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { PrimaryButton, HeaderButton, DeleteButton } from "../../styled-components/Buttons/Buttons.js";
import { DecimalFormatCustom } from "../../utilities/NumberFormat.js";
import { RequiredDropdown } from "../../styled-components/Dropdowns/Dropdown.js";
import { NormalTextField, RequiredTextField } from "../../styled-components/TextFields/TextFields.js";
import FileUpload from "../../common/FileUpload.js";
import ErrorBox from "../../common/ErrorBox.js";

// Redux imports
import { saveCert } from "../../../redux/actions/financial_reimbursements/FinancialCertAction.js";


// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        counties: state.rootReducer.counties,
        currentQuarter: state.rootReducer.currentQuarter,
        grantApplication: state.rootReducer.grantApplication,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveCert: (payload, callback) => dispatch(saveCert(payload, callback)),
    }
};

const DropdownMenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
    },
    PaperProps: {
        style: {
            minWidth: "200px",
        },
    },
};



class FinancialCertEditPage extends React.Component {

    state = {
        cert: (this.props.currentQuarter && this.props.currentQuarter.cert && this.props.currentQuarter.cert && this.props.currentQuarter.cert.items) || [],
        positionTitle: this.props.positionTitle || "",
        quarterAmount: this.props.quarterAmount || "",
        matchAmount: this.props.matchAmount || "",
        notes: this.props.notes || "",
        taxAmount: this.props.taxAmount || "",
        id: this.props.id || "",
        files: this.props.files || [],
        errors: [],
        results: {},
        validate: false,

    }
    
    constructor(props, context) {
        super(props, context);
        this.fileToSave = this.fileToSave.bind(this);
        this.fileToDelete = this.fileToDelete.bind(this);
        this.saveFileCallback = this.saveFileCallback.bind(this);
    }

    showItems = () => {
        this.props.showItems();
    }

    componentDidUpdate() { }
    
    deleteCert = () => {
        this.state.deleted = true;
        this.props.saveCert(this.state, this.callback)
    }
    callback = (err, data) => {
        if (err) {
            return;
        }

        this.showItems();
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    saveCert = () => {

        this.state.validate = false;
        this.state.errors = [];
        this.setState({ validate: this.state.validate });
        this.setState({ errors: this.state.errors });

        if (!this.state.positionTitle) {
            this.state.errors.push({
                text: "Please select a cert"
            });
        }

        if (!this.state.quarterAmount) {
            this.state.errors.push({
                text: "Please enter quarter amount"
            });
        }

        if (this.state.errors.length > 0) {
            this.state.validate = true;
            this.setState({ validate: this.state.validate });
            this.setState({ errors: this.state.errors });
            return;
        }

        this.props.saveCert(this.state, this.callback)
    }


    /**
     * File uploading functions
     * 
     */


    fileToSave(data) {
        this.state.files.push({
            file: data.data.createFile.id,
            key: data.data.createFile.title,
            title: data.data.createFile.title,
            size: data.data.createFile.size,
        });
    }

    fileToDelete(file) {
        var files = this.state.files;
        for (let i = files.length; i--;) {
            var _file = files[i];
            if (file.key === _file.key) {
                files.splice(i, 1);
                break;
            }
        }
        this.state.files = files
    }

    saveFileCallback(data) { }

    render() {
        
        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())

            return (
                <div className="layout vertical full-height">
                    <DualTitleHeaderComponent
                        icon={
                            <IconButton
                                aria-label="close"
                                onClick={this.showItems}
                            >
                                <MdClear className="icon" />
                            </IconButton>

                        }
                        titleBig={this.state.id ? <span> Edit Cert </span> : <span> Add Cert </span>}
                        titleSmall={<span style={{ textTransform: "capitalize" }}>{quarterSubheaderTitle}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}

                        suffix={
                            this.state.id ?
                                <div className="form-margin-16">
                                    <HeaderButton onClick={() => this.saveCert()}>
                                        Save
                                    </HeaderButton>
                                </div> : ""
                        }
                    />

                    <div className="flex form-shell container-overflow-y">

                        {
                            this.state.errors.length ?
                                <div className="margin-10">
                                    <ErrorBox errors={this.state.errors} />
                                </div>
                                : ""
                        }


                        <div className="layout horizontal center section-title form-margin">Cert Info</div>

                        <div className="height-10"></div>

                        <div className="layout horizontal">

                            {
                                this.state.id ?
                                    <RequiredTextField value={this.state.positionTitle} id="certpositionTitle" label="Cert" custommargin="true" className="flex" InputProps={{
                                        readOnly: true,
                                    }} InputLabelProps={{
                                        shrink: true,
                                    }} />
                                    :
                                    <FormControl className="flex" error={this.state.positionTitle.length === 0 && this.state.validate ? true : false}>
                                        <InputLabel style={{ margin: "0 6px" }} shrink htmlFor="cert-select-position"> Cert <span className="required"></span> </InputLabel>
                                        <RequiredDropdown
                                            value={this.state.positionTitle}
                                            onChange={this.handleChange('positionTitle')}
                                            input={<Input name="positionTitle" id="cert-select-position" />}
                                            MenuProps={DropdownMenuProps}
                                            custommargin="true"
                                            displayEmpty
                                            name="positionTitle"
                                            className="required-dropdown"
                                        >
                                            <MenuItem value="">
                                                <span className="placeholder">Select cert</span>
                                            </MenuItem>
                                            {
                                                this.props.getItems('cert').map((item) => {
                                                    return (
                                                        <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
                                                    )

                                                })
                                            }
                                        </RequiredDropdown>
                                    </FormControl>
                            }

                        </div>

                        <div className="layout horizontal">
                            {/*  */}
                            <RequiredTextField value={this.state.quarterAmount} onChange={this.handleChange('quarterAmount')} id="certQuarterAmount" label="Quarter Amount (Federal)" error={!this.state.quarterAmount && this.state.validate ? true : false} custommargin="true" placeholder="Receipts and documentation must equal the amount entered" className="flex" margin="normal"
                                InputProps={{ inputComponent: DecimalFormatCustom }}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </div>

                        <div className="layout horizontal">
                            <NormalTextField value={this.state.matchAmount} onChange={this.handleChange('matchAmount')} id="certMatchAmount" label="Match Amount (County)" custommargin="true" placeholder="Receipts and documentation must equal the amount entered" className="flex" margin="normal"
                                InputProps={{ inputComponent: DecimalFormatCustom }}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </div>
                        
                        <div className="layout horizontal">
                            <NormalTextField
                                multiline
                                variant="standard"
                                aria-label="minimum height"
                                minRows={1}
                                custommargin="true"
                                className="flex" margin="normal"
                                value={this.state.notes}
                                onChange={this.handleChange("notes")}
                                id="personnelNotes"
                                placeholder="Enter information about the documentation you are submitting here"
                                label="Notes"
                                autoFocus
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>

                        <div className="layout horizontal end section-title form-margin">File Attachments</div>
                        <div className="layout horizontal form-margin">
                            <FileUpload allowStateToEdit="true"  fileToSave={this.fileToSave} fileToDelete={this.fileToDelete} supportingTitle="Include supporting documentation" notitle="true" className="flex" extraFiles={this.state.files} />
                        </div>

                        <div className="height-20"></div>

                        {
                            this.state.id ?
                                <div className="layout horizontal form-margin end-justified">
                                    <DeleteButton setwidth="true" onClick={() => this.deleteCert()}> Delete </DeleteButton>
                                </div>
                                :
                                <div className="layout horizontal form-margin end-justified">
                                    <PrimaryButton setwidth="true" onClick={() => this.saveCert()}> Submit </PrimaryButton>
                                </div>
                        }


                    </div>
                </div>

            )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialCertEditPage);
