import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog';
import Drawer from '@mui/material/Drawer';
import IconButton from "@mui/material/IconButton";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { MdClear, MdExitToApp, MdAnnouncement } from 'react-icons/md';
import { withStyles } from '@mui//styles';
import FormControl from '@mui/material/FormControl';

import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { RequiredDropdown } from "../styled-components/Dropdowns/Dropdown.js";
import { RequiredTextField} from "../styled-components/TextFields/TextFields.js";
import ErrorBox from "../common/ErrorBox";

import { HeaderComponent } from "../layout/HeaderComponent.js"
import { SecondaryButton, HeaderButton, PrimaryButton } from "../styled-components/Buttons/Buttons";
import { getCountyApplicationData, getFilesAssociatedWithCounty, getAllExtensions, selectYear, getFiscalYearNarratives, performProgressReportingOperations, contactSupport } from "../../redux/actions/index";

import SelectNewYearDialog from './SelectNewYearDialog';

// CSS imports
import "../../styles/common.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

const styles = {
    listItem: {
        height: "48px",
        paddingLeft: "16px",
        paddingRight: "16px",
    },
    listItemHover: {
        '&:hover': {
            backgroundColor: "#E0E0E0"
        }
    },
    heightInherit: {
        height: "inherit",
        color: "black !important",
    }

};

const DropdownMenuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
    },
    PaperProps: {
        style: {
            minWidth: "200px",
        },
    },
};


const mapStateToProps = (state) => {
    const { browser, rootReducer } = state;
    const groupName = state.rootReducer.selectedCounty.groupName;
    return {
        browser,
        groupName,
        rootReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCountyApplicationData: (payload) => dispatch(getCountyApplicationData(payload)),
        getFilesAssociatedWithCounty: () => dispatch(getFilesAssociatedWithCounty()),
        getAllExtensions: () => dispatch(getAllExtensions()),
        selectYear: (year) => dispatch(selectYear(year)),
        getFiscalYearNarratives: (year) => dispatch(getFiscalYearNarratives(year)),
        performProgressReportingOperations: () => dispatch(performProgressReportingOperations()),
        contactSupport: (payload, callback) => dispatch(contactSupport(payload, callback)),
    }
}

const _convertToTitleCase = (str) => {
    str = (str && str.toLowerCase()) || '';
    return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
}

class DrawerComponent extends React.Component {

    state = {
        left: false,
        persistant: true,
        smallScreen: false,
        drawerItems: [

        ],
        open: false,
        openContactSupport: false,
        selectedYear: this.props.rootReducer.selectedYear,
        supportSubject: '',
        supportMessage: '',
        errors: [],
    }

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    logOutUser() {
        sessionStorage.removeItem("userAuthCreds");
        window.location.reload()
    }

    handleDialog = (variable, value) => {
        this.setState({ [variable]: value });
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    selectYear() {
        this.props.selectYear(this.state.selectedYear)
    }

    handleRadioButtonChange = event => {
        this.setState({ selectedYear: event.target.value })
    };

    constructor(props, context) {
        super(props, context);

        this.logOutUser = this.logOutUser.bind(this);
        this.getCountyApplicationInfo = this.getCountyApplicationInfo.bind(this);
        this.selectYear = this.selectYear.bind(this)
        this.handleDialog = this.handleDialog.bind(this);

      
        //TODO: Probably update this code to use redux 

        window.addEventListener("toggle-sidebar", () => {
            if (!this.state.smallScreen) {
                if (!this.state.left) {
                    document.querySelector("#appHome").style.width = "100%";
                    document.querySelector("#appHome").style.marginLeft = "0px";
                    document.querySelector("#appSideDrawer").style.width = "0px";
                } else {
                    document.querySelector("#appHome").style.width = "calc(100% - 288px)";
                    document.querySelector("#appHome").style.marginLeft = "288px";
                    document.querySelector("#appSideDrawer").style.width = "288px";
                }

            }
            this.setState({ "left": !this.state.left })
        })
    }

    getCountyApplicationInfo(item) {
        if (item.key === "application") {
            this.props.getCountyApplicationData(item);
        } else if ((item.key === "filelibrary") && (item.route === "/filelibrary/county")) {
            this.props.getFilesAssociatedWithCounty();
        } else if (item.key === "extensions") {
            this.props.getAllExtensions();
        } else if (item.key === "narratives") {
            this.props.getFiscalYearNarratives();
        } else if (item.key === "progressreport") {
            this.props.performProgressReportingOperations();
        }
    }



    componentDidMount() {

        this.checkWidth = () => {
            const match = window.matchMedia(`(max-width: 768px)`).matches;
            if (match === this.state.smallScreen) return;
            if (match) {
                document.querySelector("#appHome").style.width = "100%";
                document.querySelector("#appHome").style.marginLeft = "0px";
                this.setState({ smallScreen: true });
            } else {
                document.querySelector("#appHome").style.width = "calc(100% - 288px)";
                document.querySelector("#appHome").style.marginLeft = "288px";
                this.setState({ smallScreen: false });
            }
        };

        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);

    }

    contactSupport = () => {
    
        this.setState({ validate: false });
        let errors = []
        this.setState({ errors: [] });

        if (!this.state.supportSubject) {
            errors.push({
                text: "Please select a subject"
            });
        }

        if (!this.state.supportMessage) {
            errors.push({
                text: "Please enter a message"
            });
        }

        if (errors.length > 0) {
            this.setState({ validate: true });
            this.setState({ errors: errors });
            return
        }

        // make the request

        this.props.contactSupport({
            subject: this.state.supportSubject,
            message: this.state.supportMessage
        }, () => {
            this.setState({
                openContactSupport: false
            })
        })
    
    }

    closeSupportDialog = () => {
        this.setState({
            openContactSupport: false,
            errors: [],
            supportSubject: '',
            supportMessage: ''
        })
    }

    handleYearDialog = () => {
        window.dispatchEvent(new CustomEvent('show-new-year-dialog'))
    }

    componentDidUpdate() { }

    render() {

        const { classes } = this.props;

        const drawer = (
            //  onClick={this.toggleDrawer('left', false)} onKeyDown={this.toggleDrawer('left', false)}
            <div id="appSideDrawer" onClick={this.toggleDrawer('left', false)} tabIndex={0} className="layout vertical full-height" role="button">
                <div className="flex full-height  container-overflow-y">


                    <div className="flex">

                        <div className="height-20"></div>
                        <div className="height-20"></div>

                        <div className="layout horizontal center center-justified">
                            <img sizing="contain" className="app-logo" src={"/images/launcher.png"} />
                        </div>

                        <div className="height-20"></div>

                        <span className="layout horizontal center center-justified app-title">{process.env.REACT_APP_APP_TITLE}</span>
                        {  this.props.rootReducer.lempgUserType.indexOf("STATE") === -1 &&
                        <span className="layout horizontal center center-justified app-subtitle">{this.props.groupName ? 'Welcome ' + _convertToTitleCase(this.props.groupName) : ''}</span>
                        }
                        <div className="height-20"></div>
                        <div className="height-20"></div>

                        <List>
                            {
                                this.props.rootReducer.drawerItems.map((item, index) => {
                                    return (
                                        !item.hidden ?

                                            <ListItem key={item.key + index} onClick={() => this.getCountyApplicationInfo(item)} className={`${classes.listItem} ${item.disabled ? "" : classes.listItemHover}`}>
                                                <Link className={`layout horizontal flex center ${classes.heightInherit}`} to={item.route}>
                                                    <ListItemText title={item.disabled ? "Under development" : item.title} primary={item.title} className={item.disabled ? "opacity-54" : ""} />
                                                </Link>
                                            </ListItem>
                                            : ""
                                    )
                                })
                            }
                        </List>
                    </div>


                    <div onClick={() => this.handleDialog("openContactSupport", true)} className={`layout horizontal center cursor-pointer ${classes.listItemHover}`}>
                        <IconButton
                            aria-label="Close"
                            aria-haspopup="true"
                        >
                            <MdAnnouncement className="icon-2" />
                        </IconButton>
                        <span className="form-margin-16 log-out">Contact Support</span>
                    </div>

                    <div onClick={this.logOutUser} className={`layout horizontal center cursor-pointer ${classes.listItemHover}`}>
                        <IconButton
                            aria-label="Close"
                            aria-haspopup="true"
                        >
                            <MdExitToApp className="icon-2" />
                        </IconButton>
                        <span className="form-margin-16 log-out">Logout</span>
                    </div>
                    <div className="height-20"></div>

                    <div className="layout horizontal center form-margin-16">
                        <SecondaryButton onClick={this.handleYearDialog} className="flex">Change Year ({this.props.rootReducer.selectedYear})</SecondaryButton>
                    </div>

                    <div className="layout horizontal center center-justified " style={{ padding: "20px 0 16px 0" }}>
                        <span className="title-54">Version 1.0.32</span>
                    </div>

                    <div className="layout horizontal center center-justified ">
                        <span className="title-54"> Logged in as {this.props.rootReducer.user.username}</span>
                    </div>
                </div>
            </div>
        );

        const contactSupportDialog = (
            <Dialog
                open={this.state.openContactSupport}
                onClose={() => this.handleDialog("openContactSupport", false)}
                classes={{ paper: 'contact-support-dialog-container' }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="layout vertical contact-support-dialog-container">
                    <HeaderComponent
                        icon={
                            <IconButton
                                onClick={this.closeSupportDialog}
                            >
                                <MdClear className="icon" />
                            </IconButton>
                        }
                        title={<span className="truncate"> Contact support </span>}
                    />

                    <div className="flex container-overflow-y" style={{ padding: 10}}>

                        {
                            this.state.errors.length ? <ErrorBox errors={this.state.errors} /> : ""
                        }

                        <div className='height-10'></div>

                        <div className='layout horizontal'>
                            <FormControl className="flex">
                                <InputLabel style={{ margin: "0 6px" }} shrink htmlFor="supportSubject"> Support subject <span className="required"></span> </InputLabel>
                                <RequiredDropdown
                                    error={this.state.validate ? true : false} 
                                    value={this.state.supportSubject}
                                    onChange={this.handleChange('supportSubject')}
                                    input={<Input name="supportSubject" id="supportSubject" />}
                                    MenuProps={DropdownMenuProps}
                                    custommargin="true"
                                    displayEmpty
                                    name="supportSubject"
                                    className="required-dropdown"
                                >
                                    <MenuItem value="">
                                        <span className="placeholder">Select subject</span>
                                    </MenuItem>
                                    <MenuItem value="Report a bug">Report a bug</MenuItem>
                                    <MenuItem value="Suggestion/Improvements">Suggestion/Improvements</MenuItem>
                                    <MenuItem value="Technical support">Technical support</MenuItem>
                                </RequiredDropdown>
                            </FormControl>
                        </div>


                        <div className="height-10"></div>

                        <div className="layout horizontal wrap">
                            <RequiredTextField value={this.state.supportMessage || ''} onChange={this.handleChange('supportMessage')} error={this.state.validate ? true : false} id="supportMessage" multiline placeholder="Enter message"  label="Message" custommargin="true" className="flex" InputProps={{ readOnly: false, }} InputLabelProps={{ shrink: true,}} />
                        </div>
                    </div>

                    <div className="layout horizontal center center-justified" style={{ height: 56}}>
                        <PrimaryButton className="flex" fullWidth={true} onClick={this.contactSupport}>
                            Send
                        </PrimaryButton>
                    </div>
                </div>
            </Dialog>
        )

        // const dialog = (
        //     <Dialog
        //         open={this.state.open}
        //         onClose={() => this.handleDialog("open", false)}
        //         classes={{ paper: 'dialog-container' }}
        //         aria-labelledby="alert-dialog-title"
        //         aria-describedby="alert-dialog-description"
        //     >

        //         <div className="layout vertical dialog-container">

        //             <HeaderComponent
        //                 icon={
        //                     <IconButton
        //                         onClick={() => this.handleDialog("open", false)}
        //                     >
        //                         <MdClear className="icon" />
        //                     </IconButton>
        //                 }
        //                 title={<span className="truncate"> Select year </span>}
        //                 suffix={
        //                     <HeaderButton onClick={() => this.selectYear()}>
        //                         Save
        //                     </HeaderButton>
        //                 }
        //             />

        //             <div className="flex container-overflow-y">

        //                 {/* TODO Change this in future make this more selective */}

        //                 <FormControl>
        //                     <RadioGroup
        //                         aria-label="yearSelection"
        //                         name="yearSelection"
        //                         value={this.state.selectedYear}
        //                         onChange={this.handleRadioButtonChange}
        //                     >
        //                         {
        //                             this.props.rootReducer.fiscalYears.map((year) => {
        //                                 return (
        //                                     this.props.rootReducer.lempgUserType === "LEMPG_ACCESS" ?
        //                                         <FormControlLabel
        //                                             key={year.id}
        //                                             value={year.year}
        //                                             control={<Radio classes={{ root: 'layout horizontal material-radio' }} color="primary" />}
        //                                             disabled={!year.availableToCounties}
        //                                             label={year.year}
        //                                         />
        //                                         :
        //                                         <FormControlLabel
        //                                             key={year.id}
        //                                             value={year.year}
        //                                             control={<Radio classes={{ root: 'layout horizontal material-radio' }} color="primary" />}
        //                                             label={year.year}
        //                                         />
        //                                 )
        //                             })
        //                         }
        //                     </RadioGroup>
        //                     {/* <Fab color="secondary" aria-label="edit">
        //                         <MdAdd className='icon' />
        //                     </Fab>                             */}
        //                 </FormControl>
        //                 <Fab onClick={this.createNewYear} color="secondary" aria-label="add" style={{ position: "absolute", bottom: 32, right: 32 }}>
        //                     <MdAdd className="icon"/>
        //                 </Fab>

        //             </div>

        //             {/* <div className="layout horizontal center container-56">
        //                 <SecondaryButton className="flex" fullWidth={true} onClick={this.selectYear}>
        //                     Select
        //                 </SecondaryButton>
        //             </div> */}

        //         </div>
        //     </Dialog>
        // )

        // if (this.props.browser.is.extraSmall || this.props.browser.is.small) {
        if (this.state.smallScreen) {

            return (
                <>
                    <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
                        {contactSupportDialog}
                        {drawer}
                    </Drawer>
                    <SelectNewYearDialog />
                </>
            )

        } else {

            return (
                <Drawer variant="permanent" open>
                    {contactSupportDialog}
                    <SelectNewYearDialog />
                    {drawer}
                </Drawer>
            )
        }

        // return (
        //     <Drawer 
        //         {...(this.state.smallScreen ? {} : { variant: 'permanent' })} 
        //         {...(this.state.smallScreen ? { open: this.state.left } : { open: true })}
        //     >
        //         {dialog}
        //         {drawer}
        //     </Drawer>
        // )

    }
}

DrawerComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DrawerComponent));