import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdClear } from 'react-icons/md';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { PrimaryButton } from "../../styled-components/Buttons/Buttons.js";
import { NormalTextField } from "../../styled-components/TextFields/TextFields.js";
import { PalmettoDatePicker } from "@zawarski/palmetto-ui-components";

import { approveExtensionProgressReporting, approveExtensionFinancialReimbursement } from "../../../redux/actions/index"


// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        currentQuarter: state.rootReducer.currentQuarter,
        currentGroupNarrativeM2M: state.rootReducer.currentGroupNarrativeM2M,
        currentExtension: state.rootReducer.currentExtension,
        lempgUserType: state.rootReducer.lempgUserType,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        approveExtensionProgressReporting: (payload, callback) => dispatch(approveExtensionProgressReporting(payload, callback)),
        approveExtensionFinancialReimbursement: (payload, callback) => dispatch(approveExtensionFinancialReimbursement(payload, callback)),

    }
}


class ExtensionsAdjust extends React.Component {

    state = {
        openAdjustDialog: false,
        errors: [],
        validate: false,
        reasonForAdjustment: "",
        dueDate: (this.props.currentQuarter && this.props.currentQuarter.currentExtension && this.props.currentQuarter.currentExtension.dueDate) || "",
        dueDateDisplay: (this.props.currentExtension && this.props.currentExtension.currentExtension && this.props.currentExtension.currentExtension.dueDate) || "",
        adjustment: true,
    }

    constructor(props, context) {
        super(props, context);

        if (this.props.currentExtension) {
            this.state.dueDateDisplay = moment(this.state.dueDate, "YYYY-MM-DD").format("MM-DD-YYYY");
        }

        if (this.props.currentExtension && this.props.currentExtension.for === "FINANCIAL_REIMBURSEMENT") {
            this.state.quarterTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())
        }
        if (this.props.currentExtension && this.props.currentExtension.for === "PROGRESS_REPORTING") {
            this.state.quarterTitle = "Q" + parseInt(this.props.currentGroupNarrativeM2M.quarter.split("").reverse())
        }

    }

    toUTC = (date) => {
        var newDate = new Date();
        newDate.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        return newDate;
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    goBack = () => {
        this.props.history.goBack()
    }

    handleClose = (variable) => {
        this.setState({
            [variable]: false
        });
    };

    openDialog = (variable) => {
        this.setState({
            [variable]: true
        });
    }

    adjustExtension = () => {
        this.state.dateAdjustedTo = this.state.dueDateDisplay;
        this.state.adjust = true;
        if (this.props.currentExtension && this.props.currentExtension.for === "FINANCIAL_REIMBURSEMENT") {
            this.props.approveExtensionFinancialReimbursement(this.state, () => {
                this.props.history.entries = [];
                this.props.history.index = -1;
                this.props.history.push("/extensions/")
            });
        }
        if (this.props.currentExtension && this.props.currentExtension.for === "PROGRESS_REPORTING") {
            this.props.approveExtensionProgressReporting(this.state, () => {
                this.props.history.entries = [];
                this.props.history.index = -1;
                this.props.history.push("/extensions/")
            });
        }

    }

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    handleAdjustDateChange = date => {
        this.state.dueDate = moment(date).format("YYYY-MM-DD");
        this.setState({ "dueDate": this.state.dueDate });
        this.state.dueDateDisplay = new Date(date);
        this.setState({ "dueDateDisplay": this.state.dueDateDisplay });
    }

    componentDidUpdate() { }

    render() {

        return (

            <div className="layout vertical full-height">
                <Dialog
                    open={this.state.openAdjustDialog}
                    onClose={() => this.handleClose("openAdjustDialog")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{ maxWidth: "320px" }}>
                        <DialogTitle id="alert-dialog-title">Adjust/approve extension</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You are about to <b>approve</b> an extension for <b> {this._convertToTitleCase(this.props.currentExtension && this.props.currentExtension.group)} </b> County. This will also remove their extension request from the list and cannot be undone. Are you sure you want to do this?
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.handleClose("openAdjustDialog")} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => this.adjustExtension()} color="primary" autoFocus>
                                Approve
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdClear className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Adjust extension</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}> {this.props.currentExtension && this.props.currentExtension.group} • {this.state.quarterTitle}  • {this.props.selectedYear}</span>}
                />

                <div className="flex form-shell container-overflow-y">

                    <div className="layout horizontal center section-title form-margin">Extension Details</div>

                    <div className="layout horizontal" style={{marginTop:'12px'}}>
                            <PalmettoDatePicker
                                margin="normal"
                                id="mui-pickers-date"
                                label="New due date"
                                placeholder="Select new due date"
                                className="flex"
                                mandatory="true"
                                // custommargin="true"
                                value={this.state.dueDateDisplay}
                                onChange={this.handleAdjustDateChange}
                                autoOk={true}
                                inputFormat='MMM dd, yyyy'
                                invalidDateMessage=""
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                    </div>

                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.reasonForAdjustment} id="groupName" onChange={this.handleChange('reasonForAdjustment')} label="Reason" placeholder="Enter reason for adjustment" className="flex" multiline custommargin="true" customtbmargin="true" InputLabelProps={{ shrink: true, }} />

                    </div>

                </div>

                <div className="layout horizontal center-justified form-container " style={{ borderTop: "1px solid #E0E0E0" }}>
                    <div className="form-margin">
                        <PrimaryButton setwidth="true" onClick={() => this.openDialog("openAdjustDialog")}>
                            Submit
                        </PrimaryButton>
                    </div>

                </div>


            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionsAdjust);
