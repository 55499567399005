import React from 'react';
import { connect } from 'react-redux';

import NewChart from "./Charts";

import "../../../styles/common.css";
import "../../../styles/common.charts.css";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        counties: state.rootReducer.counties,
        selectedCounty: state.rootReducer.selectedCounty,
    };
};


class ApplicationStatusByCountyPieChart extends React.Component {

    state = {
        
    }

    constructor(props, context) {
        super(props, context);

        this.calcInProgressApplications = this.calcInProgressApplications.bind(this);
        this.calcSubmittedApplications = this.calcSubmittedApplications.bind(this);
        this.calcCompletedApplications = this.calcCompletedApplications.bind(this);
        this.calcAwardedApplications = this.calcAwardedApplications.bind(this);
    }

    calculateConfig = () => {
        let config = {
            type: 'pie',
            data: {
                datasets: [{
                    data: [
                        this.calcInProgressApplications(),
                        this.calcSubmittedApplications(),
                        this.calcAwardedApplications(),
                        this.calcCompletedApplications(),
                    ],
                    backgroundColor: [
                        "#a1a1a1",
                        "#c2b61b",
                        '#417505',
                        "#19C922",
                    ],
                    label: 'Dataset 1'
                }],
                labels: [
                    'DRAFT',
                    'SUBMITTED',
                    'AWARDED',
                    'COMPLETED',
                ]
            },
            options: {
                layout: {
                    padding: {
                        top: 20
                    }
                },
                responsive: true,
                segmentShowStroke: false,
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                        boxWidth: 16,
                        fontColor: "#333",
                        fontSize: 14,
                        // padding: 30,
                    },
                }
            }
        }

        return config;
    }

    calcInProgressApplications() {
        var count = 0;
        this.props.counties.forEach(county => {
            var a = county.grantapplication && county.grantapplication.items[0] && county.grantapplication.items[0].status === "DRAFT" ? (count++) : null;
        });

        return count;
    }

    calcSubmittedApplications() {
        var count = 0;
        this.props.counties.forEach(county => {
            count += county.grantapplication && county.grantapplication.items[0] && county.grantapplication.items[0].status === "SUBMITTED" ? 1 : 0;
        });

        return count;

    }

    calcCompletedApplications() {
        var count = 0;

        this.props.counties.forEach(county => {
            var a = county.grantapplication && county.grantapplication.items[0] && (county.grantapplication.items[0].status === "AWARDED") && (county.grantapplication.items[0].awardDocumentsUploadedByCounty && county.grantapplication.items[0].awardDocumentsUploadedByCounty.id)  ? (count++) : null;
        });

        return count;

    }



    calcAwardedApplications() {
        var count = 0;

        // Dont count COMPLETED counties
        this.props.counties.forEach(county => {
            var a = county.grantapplication && county.grantapplication.items[0] && ((county.grantapplication.items[0].status === "AWARDED") && !(county.grantapplication.items[0].awardDocumentsUploadedByCounty && county.grantapplication.items[0].awardDocumentsUploadedByCounty.id))   ? (count++) : null;
        });

        return count;

    }

    render() {
        return (
            <div className="pie-chart-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">Application status by county</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="pie-chart">
                    <NewChart id="ApplicationStatusByCountyPieChartComp" config={this.calculateConfig()} />
                </div>
            </div>            
        )
    }

}


export default connect(mapStateToProps, null)(ApplicationStatusByCountyPieChart);
