import styled from 'styled-components';
import {Select}  from '@mui/material';

export const NormalDropdown = styled(Select)`
    && {

        min-width: 250px;
        margin-left: ${props => props.custommargin ? "6px" : "0"};
        margin-right: ${props => props.custommargin ? "6px" : "0"};


        // we dont want to highlight our labels
        & > label {
            line-height: 1.33;
            color: var(--default-label-color);
        }

        // This styling will override default hover color of material ui Dropdown
        & > div:hover:before {
            border-bottom: 1px solid var(--default-label-color) !important;
        }

        // Did not want to bother to override 'required' so just added mandatory.
        & > label::after {
            content: "●";
            width: 6px;
            height: 6px;
            margin-left: 2px;
            color: ${props => props.mandatory === 'true' ? "var(--required-Dropdown-color)" : "transparent"};
        }
    }
`;

export const RequiredDropdown = styled(Select)`
    && {
        min-width: 250px;
        margin-left: ${props => props.custommargin ? "6px" : "0"};
        margin-right: ${props => props.custommargin ? "6px" : "0"};

        // we dont want to highlight our labels
        & > label {
            line-height: 1.33;
            color: ${props => props.error ? "var(--required-Dropdown-color)" : "var(--default-label-color)"};
        }

        // This styling will override default hover color of material ui Dropdown
        &:before {
            border-bottom: 1px solid var(--default-label-color) !important;
        }

        & > label::after {
            content: "●";
            width: 6px;
            height: 6px;
            margin-left: 2px;
            color: var(--required-Dropdown-color);
        }

    }
`;