// Reducers produce the state of the application.
// Reducers know when to change state is by sending a signal to the store. 
// The signal is an action. "Dispatching an action" is the process of sending out a signal.

// Important thing to note is you dont change an existing state. You make a copy of that state with current plus new data.

import { combineReducers } from 'redux'
import AppConfig from "../other/AppConfig";

import {
    AUTHENTICATED_USER,
    APP_CONFIGURATION,
    SET_USER_SETTINGS,
    SET_SELECTED_GROUP,
    SET_LEMPG_POSITION,
    SET_USER_ACCOUNT_INFO,
    SET_APP_CONFIGURATION,
    LEMPG_USER_TYPE,
    SIDE_DRAWER_ITEMS,
    GET_COUNTIES_FOR_STATE_USER,
    SET_COUNTIES_FOR_STATE_USER,
    SET_SELECTED_COUNTY,
    GET_SELECTED_COUNTY,
    GET_AWARD_AMOUNT_FOR_COUNTIES,
    SET_GRANT_APPLICATION,
    GET_GRANT_APPLICATION,
    SET_CURRENT_FINANCIAL_FILTER_MODEL,
    SET_CURRENT_PROGRESS_REPORT_FILTER_MODEL,
    SET_CURRENT_PROGRESS_REPORT_COUNTY_PAGE_FOR_STATE_FILTER_MODEL,
    CURRENT_APPLICATION_STATE,
    APPLICATION_LOADING_STATE,
    APP_LOADING_STATE,
    UPDATE_CURRENT_APPLICATION_STATE,
    CURRENT_QUARTER,
    CURRENT_QUARTER_ITEM,
    CURRENT_NARRATIVE,
    CURRENT_GROUP_NARRATIVE_M2M,
    FILES_UPLOADED_BY_COUNTY,
    IS_TASKING_SLIP_GENERATED_FOR_QUARTER,
    PROGRESS_REPORTING_QUARTER,
    ACTIVITY_FEED_HISTORY,
    ACTIVITY_FEED_COMMENTS,
    ACTIVITY_FEED_HISTORY_COMMENTS,
    ACTIVITY_FEED_FIELD_TITLE_REFERENCE,
    ACTIVITY_FEED_FIELD_VALUE_REFERENCE,
    SELECTED_QUARTER_FINANCIAL,
    SELECTED_QUARTER_PROGRESS,
    CURRENT_EXTENSION,
    ALL_EXTENSIONS,
    PROGRESS_REPORTING_STATE_COUNTIES_TABLE_SORT,
    PROGRESS_REPORTING_STATE_COUNTY_TABLE_SORT,
    PROGRESS_REPORTING_COUNTY_TABLE_SORT,
    FINANCIAL_REIMBURSEMENT_STATE_COUNTIES_TABLE_SORT,
    SET_SELECTED_YEAR,
    SELECTED_YEAR_OBJECT,
    SET_FISCAL_YEARS,
    CURRENT_FISCAL_YEAR,
    PRE_GRANT_SELECTED_COUNTY,
    SELECTED_QUARTER_PRE_FISCAL_YEAR,
    ALL_NARRATIVES,
    PROGRESS_REPORTING_COUNTIES,
    CURRENT_MONITORING_GRANT_INFORMATION_PAGE,
    CURRENT_MONITORING_SITE_VISIT_REVIEW,
    FISCAL_YEAR_STATUS,
    BEGIN_FISCAL_YEAR_PROCESS,
    PROPOSED_FISCAL_YEAR,
    LEMPG_CHIEF_OF_STAFF,
    CURRENT_REM_CONTACT,
    ALL_REM_CONTACTS,

} from "../constants/action-types";


const initialState = {
    user: {},
    configuration: {},
    token: "",
    settings: {},
    // This reflects palmetto's selected group
    selectedGroup: null,
    lempgPosition: {},
    lempgUserType: null,
    lempgChiefOfStaff: false,
    grantApplication: {
        salaryNarrative: "",
        fbFederalFunding: "",
        fbCountyCashFunding: "",
        fbCountyInkindFunding: "",
        contractualServiceNarrative: "",
        travelNarrative: "",
        equipmentNarrative: "",
        supplyNarrative: "",
        otherNarrative: "",
        certNarrative: "",
        name: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        county: "",
        zipcode: "",
        latitude: "",
        longitude: "",
        enableReverseGeocoding: "",
        implementingAgency: "",
        phoneNumber: "",
        faxNumber: "",
        group: {},
        
        // Items stored in different model
        salary: {},
        contractualService: {},
        travel: {},
        equipment: {},
        supply: {},
        other: {},
        cert: {},

    },
    currentApplicationState: {
        salaryNarrative: "",
        fbFederalFunding: "",
        fbCountyCashFunding: "",
        fbCountyInkindFunding: "",
        contractualServiceNarrative: "",
        travelNarrative: "",
        equipmentNarrative: "",
        supplyNarrative: "",
        otherNarrative: "",
        certNarrative: "",
        name: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        county: "",
        zipcode: "",
        latitude: "",
        longitude: "",
        enableReverseGeocoding: "",
        implementingAgency: "",
        phoneNumber: "",
        faxNumber: "",
        group: {},

        // Items stored in different model
        salary: {},
        contractualService: {},
        travel: {},
        equipment: {},
        supply: {},
        other: {},
        cert: {},

    },
    loading: false, 
    appLoading: false,
    counties: [],
    currentFinancialFilterModel: null,
    // This reflects palmetto's current county's application
    selectedCounty: {},
    drawerItems: [
        {
            route: "/",
            title: "Home",
        }
    ],
    currentFinancialFilter: {},
    currentQuarter: {},
    currentQuarterItem: {},
    currentNarrative: {},
    currentGroupNarrativeM2M: {},
    filesUploadedByCounty: [],
    isTaskingSlipGeneratedForQuarter: false,
    progressReportingQuarter: "",

    history: [],
    comments: [],
    historyComments: [],

    fieldTitleReference: {},
    fieldValueReference: {},

    awardAmountForCounties: [],
    selectedQuarterFinancial: 0,
    selectedQuarterProgress: 0,

    currentExtension: {},
    allExtensions: [],

    progressReportingStateCountiesTableSort: [],
    progressReportingStateCountyTableSort: [],
    progressReportingCountyTableSort: [],
    financialReimbursementStateCountiesTableSort: [],
    selectedYear: "",
    selectedYearObject: {},
    fiscalYears: [],
    currentFiscalYear: {},
    preGrantSelectedCounty: {},
    selectedQuarterPreFiscalYear: 0,
    allNarratives: [],
    grantApplicationShowCertCheckbox: true,
    grantApplicationAutoDeductCertAmount: false,
    progressReportingCounties: [],

    currentMonitoringSiteVisitReview: {},
    currentMonitoringGrantInformationPage: {},        
    
    fiscalYearStatus: [],
    beginFiscalYearProcess: false,
    proposedFiscalYear: '',

    currentREMContact: {},
    allREMContacts: [],
};

function rootReducer(state = initialState, action) {

    if(action.type === AUTHENTICATED_USER) {
        // This is wrong because it break state immutability rule of redux
        // state.user.push(action.payload)

        // To fix  the above rule we create a "copy" of the state
        return state.user
    }
    else if (action.type === APP_CONFIGURATION) {
        return state.configuration;
    }
    else if (action.type === SET_APP_CONFIGURATION) {
        return Object.assign({}, state, {
            configuration: action.payload
        })
    }
    else if (action.type === SET_USER_SETTINGS) {
        return Object.assign({}, state, {
            settings: action.payload
        })
    }
    else if (action.type === SET_SELECTED_GROUP) {
        return Object.assign({}, state, {
            selectedGroup: action.payload
        })
    }
    else if (action.type === SET_LEMPG_POSITION) {
        return Object.assign({}, state, {
            lempgPosition: action.payload
        })
    }
    else if (action.type === SET_USER_ACCOUNT_INFO) {
        return Object.assign({}, state, {
            user: action.payload
        })
    }
    else if (action.type === LEMPG_USER_TYPE) {
        return Object.assign({}, state, {
            lempgUserType: action.payload
        })
    }
    else if (action.type === LEMPG_CHIEF_OF_STAFF) {
        return Object.assign({}, state, {
            lempgChiefOfStaff: action.payload
        })
    }
    else if (action.type === SIDE_DRAWER_ITEMS) {
        return Object.assign({}, state, {
            drawerItems: action.payload
        })
    }
    else if (action.type === SET_COUNTIES_FOR_STATE_USER) {
        return Object.assign({}, state, {
            counties: action.payload
        })
    }
    else if (action.type === GET_COUNTIES_FOR_STATE_USER) {
        return state.counties
    }
    else if (action.type === SET_CURRENT_FINANCIAL_FILTER_MODEL) {
        state.currentFinancialFilterModel = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === SET_CURRENT_PROGRESS_REPORT_FILTER_MODEL) {
        state.currentProgressReportFilterModel = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === SET_CURRENT_PROGRESS_REPORT_COUNTY_PAGE_FOR_STATE_FILTER_MODEL) {
        state.currentProgressReportCountyPageForStateFilterModel = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === SET_SELECTED_COUNTY) {
        return Object.assign({}, state, JSON.parse(JSON.stringify({
            selectedCounty: action.payload
        })))
    }
    else if (action.type === GET_SELECTED_COUNTY) {
        return state.selectedCounty
    }
    else if (action.type === SET_GRANT_APPLICATION) {
        return Object.assign({}, state, {
            grantApplication: action.payload
        })
    }
    else if (action.type === GET_GRANT_APPLICATION) {
        return state.grantApplication
    }
    else if (action.type === CURRENT_APPLICATION_STATE) {
        return Object.assign({}, state, {
            currentApplicationState: action.payload
        })
    }
    else if (action.type === UPDATE_CURRENT_APPLICATION_STATE) {
        return Object.assign({}, state, JSON.parse(JSON.stringify({
            currentApplicationState: action.payload
        })))    
    }
    else if (action.type === APPLICATION_LOADING_STATE) {
        state.loading = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === APP_LOADING_STATE) {
        state.appLoading = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === CURRENT_QUARTER) {
        state.currentQuarter = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === CURRENT_QUARTER_ITEM) {
        state.currentQuarterItem = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === CURRENT_NARRATIVE) {
        state.currentNarrative = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === CURRENT_GROUP_NARRATIVE_M2M) {
        state.currentGroupNarrativeM2M = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === FILES_UPLOADED_BY_COUNTY) {
        state.filesUploadedByCounty = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === IS_TASKING_SLIP_GENERATED_FOR_QUARTER) {
        state.isTaskingSlipGeneratedForQuarter = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === PROGRESS_REPORTING_QUARTER) {
        state.progressReportingQuarter = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === SET_SELECTED_YEAR) {
        state.selectedYear = action.payload;
        // Apply application level settings based on selected year
        if(action.payload) {

            // Show cert box in the grant application UI
            state.grantApplicationShowCertCheckbox = AppConfig[action.payload]["grantapplication"]["cert"].showCheckbox;
            
            // If true, this will auto deduct amount if the checkbox is hidden
            state.grantApplicationAutoDeductCertAmount = AppConfig[action.payload]["grantapplication"]["cert"].autoDeductAmount;
        }

        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === SELECTED_YEAR_OBJECT) {
        state.selectedYearObject = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === SET_FISCAL_YEARS) {
        state.fiscalYears = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === CURRENT_FISCAL_YEAR) {
        state.currentFiscalYear = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if(action.type === PRE_GRANT_SELECTED_COUNTY) {
        state.preGrantSelectedCounty = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === ACTIVITY_FEED_HISTORY) {
        state.history = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === ACTIVITY_FEED_COMMENTS) {
        state.comments = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === ACTIVITY_FEED_HISTORY_COMMENTS) {
        state.historyComments = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === ACTIVITY_FEED_FIELD_VALUE_REFERENCE) {
        state.fieldValueReference = action.payload;
        return state;
    }
    else if (action.type === ACTIVITY_FEED_FIELD_TITLE_REFERENCE) {
        state.fieldTitleReference = action.payload;
        return state;
    }
    else if (action.type === GET_AWARD_AMOUNT_FOR_COUNTIES) {
        state.awardAmountForCounties = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === SELECTED_QUARTER_FINANCIAL) {
        state.selectedQuarterFinancial = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === SELECTED_QUARTER_PROGRESS) {
        state.selectedQuarterProgress = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === SELECTED_QUARTER_PRE_FISCAL_YEAR) {
        state.selectedQuarterPreFiscalYear = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === CURRENT_EXTENSION) {
        state.currentExtension = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === ALL_EXTENSIONS) {
        state.allExtensions = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === PROGRESS_REPORTING_STATE_COUNTIES_TABLE_SORT) {
        state.progressReportingStateCountiesTableSort = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === PROGRESS_REPORTING_STATE_COUNTY_TABLE_SORT) {
        state.progressReportingStateCountyTableSort = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === PROGRESS_REPORTING_COUNTY_TABLE_SORT) {
        state.progressReportingCountyTableSort = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === FINANCIAL_REIMBURSEMENT_STATE_COUNTIES_TABLE_SORT) {
        state.financialReimbursementStateCountiesTableSort = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === ALL_NARRATIVES) {
        state.allNarratives = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === PROGRESS_REPORTING_COUNTIES) {
        state.progressReportingCounties = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === CURRENT_MONITORING_GRANT_INFORMATION_PAGE) {
        state.currentMonitoringGrantInformationPage = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === CURRENT_MONITORING_SITE_VISIT_REVIEW) {
        state.currentMonitoringSiteVisitReview = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === FISCAL_YEAR_STATUS) {
        state.fiscalYearStatus = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === BEGIN_FISCAL_YEAR_PROCESS) {
        state.beginFiscalYearProcess = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === PROPOSED_FISCAL_YEAR) {
        state.proposedFiscalYear = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === CURRENT_REM_CONTACT) {
        state.currentREMContact = action.payload;
        return JSON.parse(JSON.stringify(state));
    }
    else if (action.type === ALL_REM_CONTACTS) {
        state.allREMContacts = action.payload;
        return JSON.parse(JSON.stringify(state));
    }    

    return state;
}

// export default rootReducer;

export default combineReducers({
    rootReducer
})