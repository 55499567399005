/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://hgs3w5eowvdbbl7qr6xphnyqaq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-s4quv245ozaplfwhcaxtkhnlim",
    "aws_cognito_identity_pool_id": "us-east-1:e2bbd7b8-7cb1-49bb-af13-4cc87890742b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_q7hBI9iYZ",
    "aws_user_pools_web_client_id": "42dp26gi3voji21ns9qokg9l5d",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lempg-amplify56695f87031f45bcaa2d19ab2de38745-newprod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
