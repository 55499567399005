import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdArrowBack, MdClear } from 'react-icons/md';
import NumberFormat from 'react-number-format';

// Local imports
import FinancialOtherEditPage from "./FinancialOtherEditPage";
import { FinancialInformationalCards, FinancialInformationalCardsMobile } from './FinancialInformationalCards';

import {
    getQuarterAmountByAmountType,
    splitAmountIntoWholeNumberAndDecimal,
    calculateAmount,
    getNonDeletedItems,
    getAwardedTotal,
    calculateRemainingFederal
} from './FinancialCommonApi';

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js";
import { PrimaryButton } from "../../styled-components/Buttons/Buttons.js";
import { CardComponent2R1C } from "../../layout/CardComponent.js";

//Redux import

import { setCurrentQuarterlyItem } from "../../../redux/actions/index";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        currentQuarter: state.rootReducer.currentQuarter,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentQuarterlyItem: (payload) => dispatch(setCurrentQuarterlyItem(payload)),
    }
};

class FinancialOther extends React.Component {

    state = {
        other: (this.props.currentQuarter && this.props.currentQuarter.other && this.props.currentQuarter.other && this.props.currentQuarter.other.items) || [],
        showEditPage: false,
    }

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
    }

    showEditPage = (event) => {
        this.state.showEditPage = true;
        this.setState({ "showEditPage": this.state.showEditPage });
    }

    showItems = () => {
        this.state.showEditPage = false;
        this.setState({ "showEditPage": this.state.showEditPage });
    }

    addOther = () => {
        this.state.id = "";
        this.state.positionTitle = "";
        this.state.files = [];
        this.state.quarterAmount = "";
        this.state.matchAmount = "";
        this.state.notes = "";
        this.state.taxAmount = "";
        this.state.deleted = false;
        this.showEditPage();
    }

    goBack() {
        this.props.history.goBack();
    }

    componentDidUpdate() { }

    getOtherItems = (type) => {

        var items = this.props.grantApplication && this.props.grantApplication[type] && this.props.grantApplication[type].items || [];

        var remainingItems = []

        items.forEach((item) => {
            if (!item.deleted) {
                var found = false
                this.state.other.forEach((other) => {
                    if ((item.name === other.positionTitle) && !other.deleted && !item.deleted) {
                        found = true
                    }
                });
                if (!found) remainingItems.push(item)
            }
        })

        return remainingItems;

    }

    getOtherItem = (item) => {
        // this.props.setCurrentQuarterlyItem(item)
        this.state.id = item.id;
        this.state.positionTitle = item.positionTitle;
        this.state.files = JSON.parse(item.file);
        this.state.quarterAmount = item.quarterAmount;
        this.state.matchAmount = item.matchAmount;
        this.state.notes = item.notes;
        this.state.taxAmount = item.taxAmount;
        this.state.deleted = item.deleted;
        this.state.validate = false;
        this.state.errors = [];
        this.showEditPage();
    }


    /**
     * 
     * This is a feeder function for showing types of informational cards being displayed
     * The calculation for these cards might depend on some functions defined in FinancialCommonApi
     * 
     */


    getInformationalCardInformation = (currentQuarter) => {
        let informationalCards = [];
        let count = 0;
        // other amount awarded
       
        let federalShareTotal = getQuarterAmountByAmountType(this.props.currentQuarter, ["other"], "quarterAmount");

        let remaining = calculateRemainingFederal(this.props).types['other'];
  
        let { whole, decimal } = splitAmountIntoWholeNumberAndDecimal(remaining);
         
        informationalCards.push({
            id: count++,
            title: "OTHER AMT. AWARDED",
            amount1: whole,
            amount2: decimal ? ("." + decimal) : "",
            class: remaining > 0 ? "informational-card-text-color-safe" : "informational-card-text-color-danger",
        })

        let { whole: federalShareTotalWhole, decimal: federalShareTotalDecimal } = splitAmountIntoWholeNumberAndDecimal(federalShareTotal)

        informationalCards.push({
            id: count++,
            title: "FED SHARE TOTAL",
            amount1: federalShareTotalWhole,
            amount2: federalShareTotalDecimal ? ("." + federalShareTotalDecimal) : "",
            class: "informational-card-text-color-safe",
        })


        // county share total

        let countyShareTotal = getQuarterAmountByAmountType(this.props.currentQuarter, ["other"], "matchAmount");

        let { whole: countyShareTotalWhole, decimal: countyShareTotalDecimal } = splitAmountIntoWholeNumberAndDecimal(countyShareTotal)

        informationalCards.push({
            id: count++,
            title: "COUNTY SHARE TOTAL",
            amount1: countyShareTotalWhole,
            amount2: countyShareTotalDecimal ? ("." + countyShareTotalDecimal) : "",
            class: "informational-card-text-color-safe",
        })


        // overmatch

        let overmatch = (countyShareTotal - federalShareTotal);
        let { whole: overmatchWhole, decimal: overmatchDecimal } = splitAmountIntoWholeNumberAndDecimal(overmatch)

        informationalCards.push({
            id: count++,
            title: "OVERMATCH",
            amount1: overmatchWhole,
            amount2: (overmatchDecimal ? ("." + overmatchDecimal) : ""),
            hideLine: true,
            class: federalShareTotal > countyShareTotal ? "informational-card-text-color-info" : "informational-card-text-color-safe",
        })


        return informationalCards;
    }  


    render() {

        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())

        const OtherList = (props) => {
            return (
                <div className="layout vertical full-height">
                    <div className="flex container-overflow-y">
                        {
                            this.state.other.map((item, index) => {
                                if (!item.deleted) {
                                    return (
                                        <div key={item.id} className="layout horizontal cursor-pointer" onClick={() => this.getOtherItem(item)}>
                                            <CardComponent2R1C
                                                className="flex no-opacity-important-childs"
                                                title={<span style={{ textTransform: "capitalize" }}>{item.positionTitle}</span>}
                                                title2={<span className="title-54-14">
                                                    <NumberFormat value={calculateAmount(this.state.other, item)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                </span>}
                                            />
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>

                    <div className="layout horizontal margin-16">
                        <div className="flex"></div>
                        {
                            this.getOtherItems('other').length > 0 ?
                                <PrimaryButton setwidth="true" onClick={() => this.addOther()}> Add Other </PrimaryButton>
                                : ""
                        }
                    </div>
                </div>
            )
        }

        const EmptyState = (props) => {
            return (
                <div className="layout vertical full-height">
                    <div className="layout vertical flex  vertical-center">
                        <span className="text-center title-87-24">No others  added</span>
                        <span className="text-center title-54-16 line-height-15">Select add other button below to get started</span>
                    </div>

                    <div className="layout horizontal margin-16">
                        <div className="flex"></div>
                        {
                            this.getOtherItems('other').length > 0 ?
                                <PrimaryButton setwidth="true" onClick={() => this.addOther()}> Add Other </PrimaryButton>
                                : ""
                        }
                    </div>
                </div>
            )
        }

        return (

            this.state.showEditPage ? <FinancialOtherEditPage showItems={this.showItems} getItems={this.getOtherItems} {...this.state} />
                :
                <div className="layout vertical full-height">

                    <DualTitleHeaderComponent
                        icon={
                            <IconButton
                                aria-label="close"
                                onClick={this.goBack}
                            >
                                <MdArrowBack className="icon" />
                            </IconButton>

                        }
                        titleBig={<span> Other </span>}
                        titleSmall={<span style={{ textTransform: "capitalize" }}>{quarterSubheaderTitle}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                        suffix={
                            <FinancialInformationalCardsMobile items={this.getInformationalCardInformation(this.props.currentQuarter)} />
                        }
                    />

                    <FinancialInformationalCards items={this.getInformationalCardInformation(this.props.currentQuarter)} />

                    <div className="flex container-overflow-y">

                        {
                            this.state.other && this.state.other.length && getNonDeletedItems(this.state.other) > 0 ? <OtherList /> : <EmptyState {...this.props} />

                        }

                    </div>


                </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialOther);
