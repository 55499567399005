export const updateGrantApplication = `mutation UpdateGrantApplication($input: UpdateGrantApplicationInput!) {
  updateGrantApplication(input: $input) {
    id
    group {
      id
      groupName
      createdAt
      updatedAt
      region
      palmettoId
      narratives {
        nextToken
      }
      quarterlyprogressreporting {
        nextToken
      }
      deleted
      grantapplication {
        nextToken
      }
      financialreimbursement {
        nextToken
      }
    }
    updatedBy
    version
    versionName
    createdAt
    updatedAt
    year
    deleted
    status
    awarded
    deductCertFromFederal
    salary(limit: 50) {
      items {
        id
        deleted
        updatedBy
        name
        positionTitle
        federal
        county
        countyInKind
      }
      nextToken
    }
    salaryNarrative
    fringeBenefit(limit: 50) {
      items {
        id
        deleted
        updatedBy
        federal
        county
        countyInKind
        grantapplication {
          id
          updatedBy
          version
          versionName
          createdAt
          updatedAt
          year
          deleted
          status
          awarded
          deductCertFromFederal
          salaryNarrative
          fringeBenefitNarrative
          fbFederalFunding
          fbCountyCashFunding
          fbCountyInkindFunding
          contractualServiceNarrative
          travelNarrative
          equipmentNarrative
          supplyNarrative
          otherNarrative
          certNarrative
          extraFile
          changesRequestedOnApplication
          name
          address
          address2
          city
          state
          county
          zipcode
          latitude
          longitude
          enableReverseGeocoding
          implementingAgency
          phoneNumber
          faxNumber
          currentApplicationRevisionId
        }
      }
      nextToken
    }
    fringeBenefitNarrative
    fbFederalFunding
    fbCountyCashFunding
    fbCountyInkindFunding
    contractualService(limit: 50) {
      items {
        id
        name
        deleted
        updatedBy
        federal
        county
        countyInKind
      }
      nextToken
    }
    contractualServiceNarrative
    travel(limit: 50) {
      items {
        id
        name
        deleted
        updatedBy
        federal
        county
        countyInKind
      }
      nextToken
    }
    travelNarrative
    equipment(limit: 50) {
      items {
        id
        name
        femaItemNumber
        quantity
        deleted
        updatedBy
        federal
        county
        countyInKind
      }
      nextToken
    }
    equipmentNarrative
    supply(limit: 50) {
      items {
        id
        name
        deleted
        updatedBy
        federal
        county
        countyInKind
      }
      nextToken
    }
    supplyNarrative
    other(limit: 50) {
      items {
        id
        name
        deleted
        updatedBy
        federal
        county
        countyInKind
      }
      nextToken
    }
    otherNarrative
    cert(limit: 50) {
      items {
        id
        name
        deleted
        updatedBy
        federal
        county
        countyInKind
      }
      nextToken
    }
    certNarrative
    acceptanceOfAuditReqFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    certificationDocumentFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    assurancesNonContructionFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    lobbyingCertificationFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    edmEquipmentPolicyFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    positionDescFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    acknowledgementFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    otherFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    scopeOfWorkFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    narrativesFile {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    extraFile
    awardDocumentsUploadedByState {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    awardCoverLetterUploadedByState {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    awardDocumentUploadedByState {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    awardSpecialInstructionsUploadedByState {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    awardDocumentsUploadedByCounty {
      id
      bucket
      title
      size
      downloadTitle
      createdAt
      updatedAt
      deleted
      updatedBy
    }
    extensions(limit: 50) {
      items {
        id
        updatedAt
        createdAt
        updatedBy
        year
        type
        group
        status
        for
        quarterFrom
        quarterTo
        dueDate
        reasonForExtension
        dateAdjustedTo
        reasonForAdjustment
        reasonForDeniedExtension
      }
      nextToken
    }
    currentExtension {
      id
      updatedAt
      createdAt
      updatedBy
      year
      type
      group
      status
      for
      quarterFrom
      quarterTo
      dueDate
      reasonForExtension
      dateAdjustedTo
      reasonForAdjustment
      reasonForDeniedExtension
    }
    changesRequestedOnApplication
    name
    address
    address2
    city
    state
    county
    zipcode
    latitude
    longitude
    enableReverseGeocoding
    implementingAgency
    phoneNumber
    faxNumber
    currentApplicationRevisionId
    grantApplicationRevision(limit: 50) {
      items {
        id
        createdAt
        updatedAt
        grantApplicationLastUpdatedAt
        updatedBy
      }
      nextToken
    }
  }
}
`;
