import React from 'react';
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

// Local imports

// Component imports
import ExtensionsStatePage from "./ExtensionsStatePage";
import ExtensionsAdjust from "./ExtensionsAdjust";
import ExtensionsDenied from "./ExtensionsDenied";
import FinancialReimbursementExtensionsView from "./FinancialReimbursementExtensionsView";
import ProgressReportingExtensionsView from "./ProgressReportingExtensionsView";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        loading: state.rootReducer.loading,
    }
};

class ExtensionsHome extends React.Component {

    state = {
    }

    constructor(props, context) {
        super(props, context);
    }

    componentDidUpdate() { }

    render() {
        return (

            <Switch>
                <Route
                    exact
                    path="/extensions/financialreimbursements/view"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <FinancialReimbursementExtensionsView {...props} />
                    }
                />
                <Route
                    exact
                    path="/extensions/progressreporting/view"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <ProgressReportingExtensionsView {...props} />
                    }
                />
                <Route
                    exact
                    path="/extensions/adjust"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <ExtensionsAdjust {...props} />
                    }
                />
                <Route
                    exact
                    path="/extensions/denied"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <ExtensionsDenied {...props} />
                    }
                />
                <Route
                    path="/extensions/"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <ExtensionsStatePage {...props} />
                    }
                />
                {/* <Route
                    path="/Extensions/state/county/quarter"
                    component={
                        (props) => this.props.loading ?
                            <div className="layout vertical vertical-center full-height">
                                <Fade
                                    in={this.props.loading}
                                    size={80}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                                <div className="height-20"></div>
                                <span style={{ padding: "0 16px" }} className="text-center title-big opacity-54">Loading your application. Please wait</span>
                            </div>
                            : <ExtensionsStatePackage {...props} />
                    }
                /> */}
            </Switch>

        )
    }
}

export default connect(mapStateToProps, null)(ExtensionsHome);
