import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdClear } from 'react-icons/md';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { PrimaryButton } from "../../styled-components/Buttons/Buttons.js";
import ErrorBox from "../../common/ErrorBox.js";
import { RequiredTextField } from "../../styled-components/TextFields/TextFields.js";

import { PalmettoDatePicker } from "@zawarski/palmetto-ui-components";

import { requestProgressReportingExtension } from "../../../redux/actions/index"


// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        currentNarrative: state.rootReducer.currentNarrative,
        currentGroupNarrativeM2M: state.rootReducer.currentGroupNarrativeM2M,
        lempgUserType: state.rootReducer.lempgUserType,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        requestProgressReportingExtension: (payload, callback) => dispatch(requestProgressReportingExtension(payload, callback)),
    }
}


class ProgressReportingExtensionByDate extends React.Component {

    state = {
        open: false,
        errors: [],
        validate: false,
        reasonForExtension: "",
        type: "BY_DATE",

        narrativeTitle: (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.narrative && this.props.currentGroupNarrativeM2M.narrative.narrativeTitle) || "",
        dueDate: (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.currentExtension && this.props.currentGroupNarrativeM2M.currentExtension.dueDate) || "",
        dueDateDisplay: (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.currentExtension && this.props.currentGroupNarrativeM2M.currentExtension.dueDate) || "",
    }

    constructor(props, context) {
        super(props, context);

        if (this.props.currentGroupNarrativeM2M && this.props.currentGroupNarrativeM2M.currentExtension) {
            this.state.dueDateDisplay = this.props.currentGroupNarrativeM2M.currentExtension.dueDate && (new Date(this.toUTC(new Date(this.props.currentGroupNarrativeM2M.currentExtension.dueDate))));

        }
    }

    toUTC = (date) => {
        var newDate = new Date();
        newDate.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        return newDate;
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    requestProgressReportingExtension = () => {
        this.props.requestProgressReportingExtension(this.state, () => {
            this.goBack();
        })
    }

    goBack = () => {
        this.props.history.goBack()
    }

    handleClose = (variable) => {
        this.setState({
            [variable]: false
        });
    };

    openDialog = (variable) => {
        this.setState({
            [variable]: true
        });
    }


    handleDueDateChange = date => {
        this.state.dueDate = moment(date).format("YYYY-MM-DD");
        this.setState({ "dueDate": this.state.dueDate });
        this.state.dueDateDisplay = new Date(date);
        this.setState({ "dueDateDisplay": this.state.dueDateDisplay });
    }


    checkForErrors = () => {
        this.state.validate = false;
        this.state.errors = [];
        this.setState({ validate: this.state.validate });
        this.setState({ errors: this.state.errors });

        if (!this.state.dueDate) {
            this.state.errors.push({
                text: "Please select due date"
            });
        }

        if (!this.state.reasonForExtension) {
            this.state.errors.push({
                text: "Please enter reason for extension request"
            });
        }

        if (this.state.errors.length > 0) {
            this.state.validate = true;
            this.setState({ validate: this.state.validate });
            this.setState({ errors: this.state.errors });
            return;
        }


        this.openDialog("open");
    }


    componentDidUpdate() { }

    render() {

        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentGroupNarrativeM2M.quarter.split("").reverse())

        return (

            <div className="layout vertical full-height">

                <Dialog
                    open={this.state.open}
                    onClose={() => this.handleClose("open")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{ maxWidth: "320px" }}>
                        <DialogTitle id="alert-dialog-title">Request an extension: Date</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You are about to request an extension for {this.state.narrativeTitle} this quarter. Are you sure you want to do this?
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.handleClose("open")} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.requestProgressReportingExtension} color="primary" autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </div>

                </Dialog>

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdClear className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Request an extension: Date</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>Progress Reporting • {quarterSubheaderTitle}  • FY{this.props.selectedYear} • {this.props.selectedCounty && this.props.selectedCounty.groupName} • {this.state.narrativeTitle} </span>}
                />

                <div className="flex form-container">
                    {
                        this.state.errors.length ?
                            <ErrorBox errors={this.state.errors} />
                            : ""
                    }
                    <div className="layout horizontal center section-title">Extension Details</div>

                    <div style={{fontSize:'14px',marginLeft:'8px',marginRight:'8px',fontStyle:'italic'}}>
                      You are requesting an extension for {this.state.narrativeTitle} <b>{quarterSubheaderTitle} FY{this.props.selectedYear}</b> Progress Reporting. The state will review and, if approved, may adjust your new due date based on situation. Please note that extending a due date will hold your Financial Reimbursement check until the task has been completed. You will be notified of progress via email.
                    </div>

                    <div className="layout horizontal" style={{marginTop:'12px'}}>
                            <PalmettoDatePicker
                                margin="normal"
                                id="mui-pickers-date"
                                label="New due date"
                                placeholder="Select new due date"
                                className="flex"
                                mandatory="true"
                                style={{marginLeft:'8px',marginRight:'8px',width:'100%'}}
                                // custommargin="true"
                                value={this.state.dueDateDisplay}
                                onChange={this.handleDueDateChange}
                                autoOk={true}
                                inputFormat='MMM dd, yyyy'
                                invalidDateMessage=""
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ readOnly: true }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                    </div>

                    <div className="layout horizontal">

                        <RequiredTextField value={this.state.reasonForExtension} multiline onChange={this.handleChange('reasonForExtension')} id="extensionReason" label="Reason for extension request" error={!this.state.checkAmount && this.state.validate ? true : false} placeholder="Enter reason for extension request" className="flex" margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />


                    </div>

                </div>
                <div className="layout horizontal center-justified form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                    <PrimaryButton setwidth="true" onClick={() => this.checkForErrors()}>
                        Submit
                    </PrimaryButton>
                </div>


            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressReportingExtensionByDate);
