import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { MdMenu, MdMoreVert } from 'react-icons/md';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

// Local imports
import { getFileUploadedLinkFromBucket } from '../../common/FileUploadApi';
import { checkIfQuarterIsStarted } from '../../utilities/QuarterDates';
import { generateYTDSpendingReportData } from './FinancialCommonApi';

// Component imports
import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { CardComponent2R1C } from "../../layout/CardComponent";
import StatusComponent from "../../styled-components/Statuses/StausComponent";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

// Redux imports
import { getFinancialQuarterlyData, printTable } from "../../../redux/actions/index";

const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        selectedYear: state.rootReducer.selectedYear,
        currentQuarter: state.rootReducer.currentQuarter
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFinancialQuarterlyData: (payload, callback) => dispatch(getFinancialQuarterlyData(payload, callback)),
        printTable: (type, payload, callback) => dispatch(printTable(type, payload, callback)),
    }
};



class FinancialCountyPage extends React.Component {

    state = {
        anchorEl: null,
    }

    constructor(props, context) {
        super(props, context);
        this.checkIfQuarterIsStarted = checkIfQuarterIsStarted.bind(this);
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClickOpen = (value) => () => {
        this.setState({ [value]: true });
    };

    handleDialogClose = (value) => () => {
        this.setState({ [value]: false });
    };

    sortItems (items) {

        if (items) {
            items.forEach((item) => {
                item.updatedStatus = item.status;
                if (item.status === "NOT_AVAILABLE") {
                    item.updatedStatus = "Not Started";
                }
                if (item.status === "NOT_STARTED") item.updatedStatus = "Not Started";
                if (item.status === "CHANGES_REQUESTED") item.updatedStatus = "Changes Requested";

                item.title = "Q" + parseInt(item.quarter.split("").reverse());
            })
            return items.sort((a, b) => {
                return a.quarter.localeCompare(b.quarter);
            });
        }
        return null;

    }

    selectQuarter = (quarter) => {
        this.props.getFinancialQuarterlyData(quarter)
    }

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    handleDeobligation = () => {
        this.setState({ anchorEl: null });
        if (checkIfQuarterIsStarted(3, this.props.selectedYear)) {
            // Open Dialog since we only do Deobligation for Q4
            this.setState({deobligationOpen: true});
        } else {
            this.props.history.push("/financialreimbursement/deobligation");
        }
    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    downloadFinancialReimbursementUserGuide = async () => {
        var a = await getFileUploadedLinkFromBucket({ key: "LEMPG-FinancialReimbursement.pdf" }, "palmetto-lempg-global-documents");
        window.open(a);
    }

    printYTDReport = () => {
        let data = generateYTDSpendingReportData(this.props.selectedCounty);
        let payload = {
            items: [],
            columns: [
                {
                    title: "County",
                    path: "groupName",
                    checked: true,
                },
                {
                    title: "Total Grant Award",
                    path: "total",
                    checked: true,
                },
                {
                    title: "Personnel",
                    path: "personnel",
                    checked: true,
                },
                {
                    title: "Contractual Services",
                    path: "contractualServices",
                    checked: true,
                },
                {
                    title: "Travel",
                    path: "travel",
                    checked: true,
                },
                {
                    title: "Equipment",
                    path: "equipment",
                    checked: true,
                },
                {
                    title: "Supplies",
                    path: "supply",
                    checked: true,
                },
                {
                    title: "CERT",
                    path: "cert",
                    checked: true,
                },
                {
                    title: "Other",
                    path: "other",
                    checked: true,
                },
                {
                    title: "Available County Overmatch",
                    path: "overmatch",
                    checked: true,
                },
                {
                    title: "Unexpended",
                    path: "unexpended",
                    checked: true,
                },
            ],
            timezone: "America/New_York"
        }
        payload.items.push(data);
        this.props.printTable("financialreimbursement-YTD_spending_report-" + data.groupName, payload);
        this.handleClose();
    }

    checkForDeobligation = () => {
        let q4 = null;
        if (this.props.selectedCounty.financialreimbursement && this.props.selectedCounty.financialreimbursement.items) {
            this.props.selectedCounty.financialreimbursement.items.forEach((item) => {
                if (item.quarter === "quarter4") {
                    q4 = item;
                }
            });
            if (q4.deobligatedFunds) {
                return true;
            }
        }
        return false;
    }

    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        const hasDeobligation = this.checkForDeobligation();

        return (

            <div className="layout vertical full-height">
                <Dialog
                    open={this.state.deobligationOpen || false}
                    onClose={this.handleDialogClose("deobligationOpen")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                >
                    <DialogTitle id="alert-dialog-title">Deobligation</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You can only deobligate funds from a Quarter 4 Financial Reimbursement.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleDialogClose("deobligationOpen")} color="primary" autoFocus>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Financial Reimbursement</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>LEMPG {this.props.selectedYear}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                    suffix={
                        <div>
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'application-menu' : undefined}
                                onClick={this.handleIconDropdownClick}
                            >
                                <MdMoreVert className="icon" />
                            </IconButton>
                            <Menu
                                id="application-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open || false}
                                onClose={this.handleClose}
                            >
                                <MenuItem key="printYTDReport" onClick={() => this.printYTDReport()}>
                                    Print YTD Spending Report
                                </MenuItem>
                                <MenuItem key="progressReportingHelp" onClick={() => this.downloadFinancialReimbursementUserGuide()}>
                                    Help
                                </MenuItem>
                            </Menu>
                        </div>
                    }
                />

                <div className="flex container-overflow-y">
                    {
                        this.sortItems(this.props.selectedCounty.financialreimbursement && this.props.selectedCounty.financialreimbursement.items).map((item) => {
                            if(item.updatedStatus === "Not Available") {
                                return (
                                    <div key={item.quarter} className="layout horizontal">
                                        <CardComponent2R1C
                                            className="flex no-opacity-important-childs"
                                            title={<span>{item.title}</span>}
                                            title2={<StatusComponent style={{ textTransform: "capitalize" }} text={item.updatedStatus} />}
                                        />
                                    </div>
                                )

                            } else {
                                return (
                                    <Link key={item.quarter} to={"/financialreimbursement/county/quarter/"}  className="layout horizontal">
                                        <div className="layout horizontal flex cursor-pointer" onClick={(e) => this.selectQuarter(item)}>
                                            <CardComponent2R1C
                                                className="flex cursor-pointer  no-opacity-important-childs"
                                                title={<span>{item.title}</span>}
                                                title2={<StatusComponent text={this._convertToTitleCase(item.updatedStatus)} />}
                                            />
                                        </div>
                                    </Link>
    
                                )

                            }
                        })
                    }

                </div>
            </div>


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialCountyPage);
