 
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from "react-router-dom";

// Component imports
import PreGrantOverview from './PreGrantOverview';
import PreGrantNewPage from './PreGrantNewPage';

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    return {
        counties: state.rootReducer.counties
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};


// Routes all Pre-Grant Award Pages
class PreGrantHome extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    render() {
        return (
            <Switch>
                <Route
                    exact
                    path="/pregrantawards/"
                    component={props => <PreGrantOverview {...props} />}
                />
                <Route
                    exact
                    path="/pregrantawards/new"
                    component={props => <PreGrantNewPage {...props} />}
                />
            </Switch>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreGrantHome);