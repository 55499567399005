import axios from 'axios';
import {createEmailRecord} from '../../graphql/mutations';
import { API, graphqlOperation } from "aws-amplify";


export const sendEmail = async  (payload = {}, userObj, emailRecordFor, callback) => {

    // Send email

    await axios.post(`${process.env.REACT_APP_PALMETTO_ENDPOINT}/messages/send?user_id=${userObj.userId}&access_token=${userObj.id}`, payload, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(async (response) => {
        // Save email to email records table
        callback(null, response);

        let appSyncFilter = {
            input: {
                createdAt: new Date(),
                updatedAt: new Date(),
                updatedBy: userObj.id,
                for: emailRecordFor,
                payload: JSON.stringify(payload),
                status: 'SUCCESS',
            }
        }

        await API.graphql(graphqlOperation(createEmailRecord, appSyncFilter));

    }).catch(async (error) => {
        console.log(error);
        callback(error);

        let appSyncFilter = {
            input: {
                createdAt: new Date(),
                updatedAt: new Date(),
                updatedBy: userObj.id,
                for: emailRecordFor,
                payload: JSON.stringify(payload),
                status: 'ERROR',
            }
        }

        await API.graphql(graphqlOperation(createEmailRecord, appSyncFilter));

    })


}