import { datadogLogs } from '@datadog/browser-logs';

const ENV = 'LEMPG-' + process.env.REACT_APP_NODE_ENV;

datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    datacenter: 'us',
    env: ENV,
    forwardErrorsToLogs: false,
    sampleRate: 100
})

datadogLogs.createLogger('consoleLogger', 'info', 'console', { 'env': ENV })
datadogLogs.createLogger('httpLogger', 'info', 'http', { 'env': ENV })

