import { API, graphqlOperation } from "aws-amplify";
import uuid from "uuid";

import * as mutations from '../../graphql/mutations';

import {
    UPDATE_CURRENT_APPLICATION_STATE
} from "../constants/action-types";

export async function saveCertAppSync(items, grantApplication, user, funcs) {

    items.forEach((payload) => {
        if (!payload.id) {
            // This means that you are creating a new cert ok this should refresh
            var certFilter = {
                input: {
                    certGrantapplicationId: grantApplication.id,
                    name: payload.name,
                    federal: payload.federal || null,
                    county: payload.county || null,
                    countyInKind: payload.countyInKind || null,
                    deleted: payload.deleted,
                    updatedBy: user.username
                }
            }

            funcs.push(API.graphql(graphqlOperation(mutations.createCert, certFilter)));
        } else {


            var same = false;
            for (let index = 0; index < grantApplication.cert.items.length; index++) {
                const item = grantApplication.cert.items[index];
                if ((item.id === payload.id) && (JSON.stringify(item) === JSON.stringify(payload))) {
                    same = true;
                    break;
                }
            }

            if(!same) {
                var certFilter = {
                    input: {
                        certGrantapplicationId: grantApplication.id,
                        id: payload.id,
                        name: payload.name,
                        federal: payload.federal || null,
                        county: payload.county || null,
                        countyInKind: payload.countyInKind || null,
                        deleted: payload.deleted,
                        updatedBy: user.username
                    }
                }
    
                funcs.push(API.graphql(graphqlOperation(mutations.updateCert, certFilter)));
            }

        }
    })
}

export function saveCert(payload) {
    return async function (dispatch, getState) {


        const { grantApplication, currentApplicationState } = getState().rootReducer;
        if (!currentApplicationState.cert.items) {
            currentApplicationState.cert.items = [];
        }

        if (!payload.id && !payload.tempId) {
            payload.tempId = uuid.v4();
        }

        var arr = currentApplicationState.cert.items, found = false;
        for (let i = 0; i < arr.length; i++) {
            var item = arr[i];
            if (item.tempId) {
                if (item.tempId === payload.tempId) {
                    currentApplicationState.cert.items[i] = payload;
                    found = true;
                    break;
                }
            } else {
                if (item.id === payload.id) {
                    currentApplicationState.cert.items[i] = payload;
                    found = true;
                    break;
                }
            }

        }

        if (!found) {
            currentApplicationState.cert.items.push(payload)
        }

        dispatch({ type: UPDATE_CURRENT_APPLICATION_STATE, payload: JSON.parse(JSON.stringify(currentApplicationState)) });

    }
}
