import React from "react";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { MdArrowBack } from "react-icons/md";
import moment from "moment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
// Component imports
import { PrimaryButton, DeleteButton } from "../../styled-components/Buttons/Buttons";
import { Skeleton } from "@mui/lab";
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import { getGridSingleSelectOperators } from '@mui/x-data-grid-pro';
import { PalmettoReactTable } from "@zawarski/palmetto-direflow-react-table";
// Custom Data imports
import { REMContactInfo } from "../../../redux/other/ProgressReportingREMContactInfo";
// Redux imports
import {
  getNarrativeAndGroupNarrativeM2MData,
  setProgressReportingStateCountyTableSort,
  progressReportingNotifyREM,
  setCurrentProgressReportCountyPageForStateFilterModel,
  setLoading
} from "../../../redux/actions/index";

// CSS imports

//import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
  return {
    selectedCounty: state.rootReducer.selectedCounty,
    lempgUserType: state.rootReducer.lempgUserType,
    isTaskingSlipGeneratedForQuarter: state.rootReducer.isTaskingSlipGeneratedForQuarter,
    progressReportingQuarter: state.rootReducer.progressReportingQuarter,
    progressReportingStateCountyTableSort: state.rootReducer.progressReportingStateCountyTableSort,
    selectedYear: state.rootReducer.selectedYear,
    allREMContacts: state.rootReducer.allREMContacts,
    currentProgressReportCountyPageForStateFilterModel: state.rootReducer.currentProgressReportCountyPageForStateFilterModel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNarrativeAndGroupNarrativeM2MData: (payload, callback) =>
      dispatch(getNarrativeAndGroupNarrativeM2MData(payload, callback)),
    setProgressReportingStateCountyTableSort: (payload) =>
      dispatch(setProgressReportingStateCountyTableSort(payload)),
    progressReportingNotifyREM: (payload, callback) =>
      dispatch(progressReportingNotifyREM(payload, callback)),
    setCurrentProgressReportCountyPageForStateFilterModel: (payload) =>
      dispatch(setCurrentProgressReportCountyPageForStateFilterModel(payload)),
    setLoading: (payload) =>
      dispatch(setLoading(payload)),  
  };
};

const filterOperators = getGridSingleSelectOperators().filter(({ value }) =>
    ['isAnyOf' /* add more over time */ ].includes(value),
);

const COLUMNS = [
    {
      minWidth: 100,
      field: "index",
      numeric: true,
      headerName: "#",
      enabled: true,
      filterable: false
  },
  {
      minWidth: 200,
      field: "narrativeTitle",
      numeric: false,
      headerName: "EMF",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "narrativeTitle"
  },
  {
      minWidth: 200,
      field: "narrativeFrequency",
      numeric: false,
      headerName: "Frequency",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "narrativeFrequency"
  },
  {
      minWidth: 200,
      field: "narrativePOCFullName",
      numeric: true,
      headerName: "POC",
      enabled: true,
      filterable: false,
      columnField: "narrativePOCFullName"
  },
  {
    minWidth: 200,
    field: "extensionDate",
    numeric: false,
    headerName: "Extension",
    enabled: true,
    filterable: false,
    columnField: "extensionDate"
  },
{
    minWidth: 140,
    field: "updatedStatus",
    numeric: false,
    headerName: "Status",
    enabled: true,
    type: 'singleSelect',
    valueOptions: [
        { "value": "Complete", "label": "Complete" },
        { "value": "Incomplete", "label": "Incomplete" }
    ],
    renderCell: param => showStatusCell(param.value),
    filterOperators
    }
];

const _convertToTitleCase = (str) => {
  str = str.toLowerCase();
  return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
}

const defaultFilterModel =
{"items":[{"columnField":"updatedStatus","operatorValue":"isAnyOf","id":31248,"value":[]}],"linkOperator":"and","quickFilterValues":[],"quickFilterLogicOperator":"and"};
    
const showStatusCell = (value, mobile = false) => {
    const style = {};
    style.paddingTop = "8px";
    style.paddingBottom = "9px";
    style.paddingLeft = "0";
    style.textAlign = "center";
    style.borderRadius = "0";
    switch (value.toLowerCase()) {
        case "processed":
        case "complete":
            style.whiteSpace = 'nowrap';
            style.backgroundColor = '#19C922';
            style.color = 'rgba(255, 255, 255, 0.87)';
            break;
        case "approved":
        case "completed":
        case "awarded":
            style.whiteSpace = 'nowrap';
            style.backgroundColor = '#417505';
            style.color = 'rgba(255, 255, 255, 0.87)';
            break;
        case "changes requested":
        case "tasked":
        case "submitted with issues":
            style.backgroundColor = '#4b97f2';
            style.color = 'rgba(255, 255, 255, 0.87)';
            break;
        case "cancelled":
            style.backgroundColor = '#990013';
            style.color = 'rgba(255,255,255,0.87)';
            break;
        case "draft":
        case "incomplete":
        case "not available":
            style.whiteSpace = 'nowrap';
            style.backgroundColor = '#A1A1A1';
            style.color = 'rgba(0, 0, 0, 0, 0.87)';
            break;
        case "not started":
            style.whiteSpace = 'nowrap';
            style.backgroundColor = '#4A4A4A';
            style.color = 'rgba(255, 255, 255, 0.87)';
            break;
        case "submitted":
            style.whiteSpace = 'nowrap';
            style.backgroundColor = '#C2B61B';
            style.color = 'rgba(0, 0, 0, 0, 0.87)';
            break;
        default:
            style.backgroundColor = 'black';
            style.color = 'rgba(255, 255, 255, 0.87)';
            break;
    }
    style.marginTop = '-10px';
    style.marginLeft = '-10px';
    style.marginRight = '-10px';
    style.textOverflow = 'ellipsis';
  
    style.overflow = 'hidden';
    style.height = '40px !important';
    style.minHeight = '40px !important';
    return (
        <>{value !== undefined ?
            <div style={style} className={`${mobile ? "" : "flex"}`}>{_convertToTitleCase(value)}</div> :
            <Skeleton style={{height: 20, width: '100%'}}/>
        } </>);
  }

class ProgressReportCountyPageForState extends React.Component {


    state = {
        selectedQuarter: 0,
        progressReportingStateCountyTableSort:
          this.props.progressReportingStateCountyTableSort,
        remDialogOpen: false,
        filterModel: this.props.currentProgressReportCountyPageForStateFilterModel || defaultFilterModel,
    }

    constructor(props, context) {
        super(props, context);
        this.processAndSortItems = this.processAndSortItems.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        // Typical usage (don't forget to compare props):
        if (this.state.filterModel !== prevState.filterModel) {
          this.setState({filterModel: this.state.filterModel});
        }
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

  goBack = () => {
    this.props.history.goBack();
  };

  generateTaskingSlip = () => {
    this.props.history.push("/progressreport/county/taskingslip");
  };

  capitalize(str) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  }

  notifyREM = () => {
    let payload = this.props.selectedCounty;
    payload.quarter =
      this.props.progressReportingQuarter &&
      this.props.progressReportingQuarter.split("").reverse()[0];
    this.props.progressReportingNotifyREM(payload, (err, res) => {
      if (err) {
        console.log("Error:", err);
      } else {
        this.setState({ remDialogOpen: true });
      }
    });
  };

  /* State users cant see tasking slip unless financial reimbursements for the particular quarter is approved AND all narratives are approved */

  checkForApprovedNarratives = () => {
    let areAllNarrativesApproved = true;
    let finItems =
      this.props.selectedCounty &&
      this.props.selectedCounty.financialreimbursement &&
      this.props.selectedCounty.financialreimbursement.items;

    this.props.selectedCounty &&
      this.props.selectedCounty.narratives &&
      this.props.selectedCounty.narratives.items &&
      this.props.selectedCounty.narratives.items.forEach((item) => {
        if (item.quarter === this.props.progressReportingQuarter) {
          // check for M-116
          if (item.narrative.useFinancialReimbursementStatus) {
            // loop over finItems
            finItems.forEach((finItem) => {
              if (finItem.quarter === this.props.progressReportingQuarter) {
                item.status = finItem.status;
                if (item.status === "AWARDED") item.status = "APPROVED";
                else if (item.status === "PROCESSED") item.status = "APPROVED";
                else if (item.status === "NOT_AVAILABLE") item.status = "DRAFT";
                else if (item.status === "NOT_STARTED") item.status = "DRAFT";
              }
            });
          }
          if (item.status) {
            if (item.status && item.status !== "APPROVED") {
              areAllNarrativesApproved = false;
            }
          } else {
            areAllNarrativesApproved = false;
          }
        }
      });

    return areAllNarrativesApproved;
  };

  returnUpdatedPOCInformation = (narrative) => {
    if (narrative.usePalmettoForREMInformation) {
      this.props.allREMContacts.forEach((contact) => {
        if (contact.region === this.props.selectedCounty.region) {
          narrative.narrativePOCFirstName = contact.firstName;
          narrative.narrativePOCLastName = contact.lastName;
          narrative.narrativePOCPhoneNumber = contact.phoneNumber;
          narrative.narrativePOCEmailAddress = contact.emailAddress;
          narrative.narrativePOCPositionTitle = contact.positionTitle;
        }
      });
    } 
    /*
    else {
      if (
        narrative.narrativeTitle === "M-113" ||
        narrative.narrativeTitle === "M-107" ||
        narrative.narrativeTitle === "M-108" ||
        narrative.narrativeTitle === "M113" ||
        narrative.narrativeTitle === "M107" ||
        narrative.narrativeTitle === "M108"
      ) {
        let contactInfo =
          REMContactInfo[narrative.narrativeTitle][
            this.props.selectedCounty.region
          ];

        Object.keys(contactInfo).forEach((key) => {
          narrative[key] = contactInfo[key];
        });
      }
    }
    */
    return narrative;
  };

  processAndSortItems(county) {
    var arr = [];

    let items = county && county.narratives && county.narratives.items;
    let finItems =
      county &&
      county.financialreimbursement &&
      county.financialreimbursement.items;

    items.forEach((item) => {
      if (item.quarter === this.props.progressReportingQuarter) {
        if (item.narrative.useFinancialReimbursementStatus) {
          // loop over finItems
          finItems.forEach((finItem) => {
            if (finItem.quarter === this.props.progressReportingQuarter) {
              item.status = finItem.status;
            }
          });
          item.updatedStatus = item.status ? item.status : "Draft";
          if (item.status === "AWARDED") item.updatedStatus = "Approved";
          if (item.status === "PROCESSED") item.updatedStatus = "Approved";
          if (item.status === "NOT_AVAILABLE") item.updatedStatus = "Draft";
          if (item.status === "NOT_STARTED") item.updatedStatus = "Draft";
        }
      }
    });

    items.forEach((item) => {
       // console.log(JSON.stringify(item));
      if (item.quarter === this.props.progressReportingQuarter) {
        item.updatedStatus = item.status ? item.status : "Draft";
        if (item.status === "NOT_AVAILABLE")
          item.updatedStatus = "Not Available";
        if (item.status === "NOT_STARTED") item.updatedStatus = "Not Started";
        if (item.status === "CHANGES_REQUESTED")
          item.updatedStatus = "Changes Requested";

        if (item.narrative) {
          item.narrative = this.returnUpdatedPOCInformation(item.narrative);
          item.narrativePOCFullName =
            (item.narrative.narrativePOCFirstName || "") +
            " " +
            (item.narrative.narrativePOCLastName || "");
          item.narrativeTitle =
            (item.narrative.narrativeTitle || "");
          item.narrativeFrequency =
            (item.narrative.narrativeFrequency || "");

        }

        if (
          item.currentExtension &&
          item.currentExtension.status === "APPROVED" &&
          item.currentExtension.dueDate
        ) {
          item.extensionDate = moment(
            item.currentExtension.dateAdjustedTo ||
              item.currentExtension.dueDate,
            "YYYY-MM-DD"
          )
            .local()
            .format("MM-DD-YYYY");
        } else {
            item.extensionDate = "";
        }

        arr.push(item);
      }
    });

    return arr && arr.sort((a, b) => {
        return a.narrative.narrativeTitle.localeCompare(b.narrative.narrativeTitle);
    });
    
  }

  onRowClick = (rowInfo) => {
            setTimeout(() => {
              //this.props.setLoading(true);
              let quarterSubheaderTitle = parseInt((this.props.selectedCounty && this.props.selectedCounty.quarter && this.props.selectedCounty.quarter.split("").reverse()) || "1");
              rowInfo.row.quarter = "quarter" + quarterSubheaderTitle;
              this.props.getNarrativeAndGroupNarrativeM2MData(rowInfo.row);
              this.props.history.push("/progressreport/county/narrative")
              }
            );
           };
    
  onSortChange = (column) => {
    this.props.setProgressReportingStateCountyTableSort(column);
  };

  handleClose = (e) => {
    this.setState({ remDialogOpen: false });
  };

 

  render() {
    let quarterSubheaderTitle = parseInt((this.props.selectedCounty && this.props.selectedCounty.quarter && this.props.selectedCounty.quarter.split("").reverse()) || "1");
    quarterSubheaderTitle = "Q" + quarterSubheaderTitle;
    
    console.log("Tasking slip: " + this.props.isTaskingSlipGeneratedForQuarter  + " " + (this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS") + " " + this.checkForApprovedNarratives()); 
 
 
     return (
        <>
        <div style={{display:!this.props.loading ? 'none' : 'flex', zIndex:'9',position:'absolute', top:'57px',left:'0',width:'100vw',height:'100vh',backgroundColor:'white'}} className="layout vertical vertical-center full-height">
            <Fade
                in={this.props.loading}
                size={80}
                unmountOnExit
            >
                <CircularProgress />
            </Fade>
        </div>
       
      <div
        className="layout vertical full-height"
        style={{ paddingBottom: "8px" }}
      >
        <div
          style={{ overflow: "hidden", paddingBottom: "32px" }}
          className="flex"
        >
          <Dialog
            open={this.state.remDialogOpen}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Notify REM</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                REM has been notified via email that{" "}
                {this.capitalize(this.props.selectedCounty.groupName)} County's
                work elements have been submitted and approved.
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Okay
              </Button>
            </DialogActions>
          </Dialog>

          <PalmettoReactTable
            headerIcon={
              <IconButton aria-label="close" onClick={this.goBack}>
                <MdArrowBack className="icon" />
              </IconButton>
            }
            columns={COLUMNS}
            dataset={this.processAndSortItems(this.props.selectedCounty)}
            extraHeaders={<></>}
            idField={"id"}
            limit={200}
            onRowClick={this.onRowClick}
            loading={true}
            filterModel={this.state.filterModel}
            gridToolbar={true}
            onFilterModelChange={ (params) => {
              this.props.setCurrentProgressReportCountyPageForStateFilterModel(params);
            }}
            title={"Progress Reporting"}
            subtitleSuffix={
              "entries • " +
              this.props.selectedYear +
              " • " +
              quarterSubheaderTitle +
              " • " +
              _convertToTitleCase(
                this.props.selectedCounty.groupName.toString()
              )
            }
            rowHeight={48}
            virtualRowHeight={48}
          />
        </div>
        {/* State users cant see tasking slip unless financial reimbursements for the particular quarter is approved AND all narratives are approved */}

        {
                    this.props.isTaskingSlipGeneratedForQuarter ? "" :
                    this.props.lempgUserType && this.props.lempgUserType != "LEMPG_ACCESS"
                        && this.checkForApprovedNarratives() 
                        ?
                        <div className="layout horizontal center-justified form-container" style={{ borderTop: "1px solid #E0E0E0" }}>
                            <PrimaryButton setwidth="true" margin="6px" onClick={() => this.generateTaskingSlip()}> Generate Tasking Slip </PrimaryButton>
                            <PrimaryButton setwidth="true" margin="6px" onClick={() => this.notifyREM()}> Notify REM </PrimaryButton>
                        </div>
                        : ""
                }

      </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgressReportCountyPageForState);
