import React from 'react';
import { connect } from 'react-redux'
import equal from 'fast-deep-equal'


import { ProgressBarMulti } from "./Charts";

import "../../../styles/common.css";
import "../../../styles/common.charts.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        selectedCounty: state.rootReducer.selectedCounty,
    };
};


class CountyProgressReportingStatusProgressBar extends React.Component {

    state = {
    }

    calcProgressBarMultiValue = ( quarter ) => {
        let quarterNarrativesCount = 0;
        let approvedNarrativesCount = 0;

        let county = this.props.selectedCounty;

        if (county.narratives && county.narratives.items) {
            county.narratives.items.forEach((item) => {

                if (item.quarter === quarter) {
                    quarterNarrativesCount++;
                }

            })
        }

        if (county.narratives && county.narratives.items) {
            county.narratives.items.forEach((item) => {
                if ((item.quarter === quarter) && (item.status === "APPROVED")) {
                    approvedNarrativesCount++;
                }
            })
        }

        return Math.ceil(((approvedNarrativesCount / quarterNarrativesCount) * 100))
    }

    calcProgressBarMultiSuffix = (quarter) => {
        let quarterNarrativesCount = 0;
        let approvedNarrativesCount = 0;

        let county = this.props.selectedCounty;

        if (county.narratives && county.narratives.items) {
            county.narratives.items.forEach((item) => {

                if (item.quarter === quarter) {
                    quarterNarrativesCount++;
                }

            })
        }

        if (county.narratives && county.narratives.items) {
            county.narratives.items.forEach((item) => {
                if ((item.quarter === quarter) && (item.status === "APPROVED")) {
                    approvedNarrativesCount++;
                }
            })
        }

        return approvedNarrativesCount + " out of " + quarterNarrativesCount + " approved";
    }


    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div className="layout vertical pie-chart-container">
                <div>
                    <span className="section-title form-margin-16">Progress reporting completion</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                    <div className="height-20"></div>
                </div>

                <div className="form-margin-16 pie-chart">
                    <ProgressBarMulti title="Q1" value={this.calcProgressBarMultiValue("quarter1")} suffix={this.calcProgressBarMultiSuffix("quarter1")} />
                </div>

                <div className="height-20"></div>

                <div className="form-margin-16 pie-chart">
                    <ProgressBarMulti title="Q2" value={this.calcProgressBarMultiValue("quarter2")} suffix={this.calcProgressBarMultiSuffix("quarter2")} />
                </div>

                <div className="height-20"></div>

                <div className="form-margin-16 pie-chart">
                    <ProgressBarMulti title="Q3" value={this.calcProgressBarMultiValue("quarter3")} suffix={this.calcProgressBarMultiSuffix("quarter3")} />
                </div>

                <div className="height-20"></div>

                <div className="form-margin-16 pie-chart">
                    <ProgressBarMulti title="Q4" value={this.calcProgressBarMultiValue("quarter4")} suffix={this.calcProgressBarMultiSuffix("quarter4")} />
                </div>

            </div>
        )
    }

}


export default connect(mapStateToProps, null)(CountyProgressReportingStatusProgressBar);
