import React from 'react';
import { Route, Link, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdMenu } from 'react-icons/md';

// js
import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import { CardComponent2R1C } from "../layout/CardComponent";
import StatusComponent from "../styled-components/Statuses/StausComponent";

import { getCountyAction, getCountyApplicationData } from "../../redux/actions/index";

// CSS imports
import "../../styles/common.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCountyAction: (payload, callback) => dispatch(getCountyAction(payload, callback)),
        getCountyApplicationData: () => dispatch(getCountyApplicationData()),
    }
}


class CountyPageForState extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.selectedCountyCallback = this.selectedCountyCallback.bind(this);
    }

    componentDidMount () {}

   
    getCountyApplicationStatus(county) {
        return county.grantapplication.items[0].status && (county.grantapplication.items[0].status === "AWARDED") ?
            (county.grantapplication.items[0].awardDocumentsUploadedByCounty && county.grantapplication.items[0].awardDocumentsUploadedByCounty.id ?
                "COMPLETE" : "AWARDED") :
            county.grantapplication && county.grantapplication.items[0] && county.grantapplication.items[0].status;
    }


    getSelectedCountyApplication(county) {
        this.props.getCountyAction({ selectedGroupID: parseInt(county.palmettoId) }, this.selectedCountyCallback);
    }
    
    selectedCountyCallback() {
        this.props.getCountyApplicationData()
    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    render() {
        return (


            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Grant Application</span>}
                titleSmall={<span>LEMPG {this.props.selectedYear}</span>}
                />


                <div className="flex container-overflow-y">

                    {
                        this.props.counties.map((county) => {
                            return (
                                <Link key={county.groupName} to="/application" onClick={() => this.getSelectedCountyApplication(county)} className="layout horizontal">
                                    <CardComponent2R1C
                                        className="flex no-opacity-important-childs" 
                                        title={<span style={{ textTransform: "capitalize"}}>{county.groupName}</span>}
                                        title2={<StatusComponent text={this.getCountyApplicationStatus(county)} />}
                                    />
                                </Link>

                            )
                        })
                    }
                
                </div>
            </div>
        )
    }
}

// export default CountyPageForState;
export default connect(mapStateToProps, mapDispatchToProps)(CountyPageForState);
