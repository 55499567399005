import React from "react";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MdMenu } from "react-icons/md";
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import dateFormat from "dateformat";
import moment from "moment";
import { Skeleton } from "@mui/lab";
import { getGridSingleSelectOperators } from '@mui/x-data-grid-pro';
import { PalmettoReactTable } from "@zawarski/palmetto-direflow-react-table";

// Local imports
import { getFileUploadedLinkFromBucket } from "../../common/FileUploadApi";
import { checkIfQuarterIsStarted } from "../../utilities/QuarterDates";
import {
  generateYTDSpendingReportData,
  formatNumberAsDollar,
} from "./FinancialCommonApi";
import { getFormattedNumberForPrint } from "../../utilities/NumberFormat";

// Component imports

// Redux

import {
  getFinancialQuarterlyData,
  getCountyAction,
  setCurrentFinancialFilterModel,
  selectQuarterFinancialReimbursement,
  setFinancialReimbursementStateCountiesTableSort,
  printTable,
  setLoading
} from "../../../redux/actions/index";

// CSS imports
////import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
  return {
    loading: state.rootReducer.loading,
    selectedCounty: state.rootReducer.selectedCounty,
    selectedQuarterFinancial: state.rootReducer.selectedQuarterFinancial,
    currentFinancialFilterModel: state.rootReducer.currentFinancialFilterModel,
    counties: state.rootReducer.counties,
    financialReimbursementStateCountiesTableSort:
    state.rootReducer.financialReimbursementStateCountiesTableSort,
    selectedYear: state.rootReducer.selectedYear,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFinancialQuarterlyData: (payload, callback) =>
      dispatch(getFinancialQuarterlyData(payload, callback)),
    getCountyAction: (payload, callback) =>
      dispatch(getCountyAction(payload, callback)),
    selectQuarterFinancialReimbursement: (payload) =>
      dispatch(selectQuarterFinancialReimbursement(payload)),
    setFinancialReimbursementStateCountiesTableSort: (payload) =>
      dispatch(setFinancialReimbursementStateCountiesTableSort(payload)),
    setCurrentFinancialFilterModel: (payload) =>
      dispatch(setCurrentFinancialFilterModel(payload)),
    printTable: (type, payload, callback) =>
      dispatch(printTable(type, payload, callback)),
    setLoading: (payload) =>
      dispatch(setLoading(payload)),  
}};

const filterOperators = getGridSingleSelectOperators().filter(({ value }) =>
    ['isAnyOf' /* add more over time */ ].includes(value),
);

const COLUMNS = [
  {
    minWidth: 100,
    field: "index",
    numeric: true,
    flex: 1,
    headerName: "#",
    enabled: true,
    filterable: false
},
{
    flex: 1,
    field: "groupName",
    numeric: false,
    headerName: "County",
    enabled: true,
    operatorValue: 'contains',
    filterable: false,
    columnField: "groupName"
},
{
    flex: 1,
    field: "updatedStatus",
    numeric: false,
    headerName: "Status",
    enabled: true,
    type: 'singleSelect',
    valueOptions: [
        { "value": "Approved", "label": "Approved" },
        { "value": "Awarded", "label": "Awarded" },
        { "value": "Changes Requested", "label": "Changes Requested" },
        { "value": "Denied", "label": "Denied" },
        { "value": "Draft", "label": "Draft" },
        { "value": "Not Available", "label": "Not Available" },
        { "value": "Not Started", "label": "Not Started" },
        { "value": "Processed", "label": "Processed" },
        { "value": "Submitted", "label": "Submitted" }
    ],
    renderCell: param => showStatusCell(param.value),
    filterOperators
},
{
    flex: 1,
    field: "totalAmount",
    type: "number",
    headerName: "Total amount expended",
    enabled: true,
    filterable: false,
    renderCell: param => dollarFormat(param.value)
},
{
  flex: 1,
    field: "checkAmount",
    numeric: true,
    headerName: "Amount reimbursed",
    enabled: true,
    filterable: false,
    renderCell: param => dollarFormat(param.value)
},
{
  flex: 1,
    field: "updatedAtDisplay",
    numeric: false,
    headerName: "Last updated",
    enabled: true,
    filterable: false,
    renderCell: param => dateTimeFormat(param.value)
},
{
  flex: 1,
    field: "extensionDate",
    numeric: false,
    headerName: "Extension",
    enabled: true,
    filterable: false
},
{
  flex: 1,
  field: "lastSubmitDate",
  numeric: false,
  headerName: "Submit date",
  enabled: true,
  filterable: false,
  columnField: "lastSubmitDate",
  renderCell: param => dateTimeFormat(param.value)
},
{
  flex: 1,
  field: "lastSubmitEmail",
  numeric: false,
  headerName: "Submitter email",
  enabled: true,
  filterable: false,
  columnField: "lastSubmitEmail"
},
{
  flex: 1,
  field: "lastSubmitName",
  numeric: false,
  headerName: "Submitted by",
  enabled: true,
  filterable: false,
  columnField: "lastSubmitName"
}
];

const dateTimeFormat = (date, dateOnly = false) => {
    console.log(date);
    return (date !== undefined ?
        (date ? (!dateOnly ?
            dateFormat(date, "yyyy-mm-dd HH:MM TT") :
            dateFormat("yyyy-mm-dd")) : '') :
        <Skeleton style={{height: '20px', marginTop: 'auto', marginBottom: 'auto', width: '100%'}}/>);
}

const dollarFormat = (amount) => {
    return (
        <>{amount !== undefined ?
            <div style={{color: (amount < 0 ? "red" : "rgba(0 ,0,0,.7)"), textAlign:"right"}}>{!isNaN(amount) ? currencyFormatter.format(amount) : ''}</div> :
            <Skeleton style={{height: 20, width: '100%'}}/>
        } </>);
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const defaultFilterModel =
{"items":[{"columnField":"updatedStatus","operatorValue":"isAnyOf","id":31248,"value":[]}],"linkOperator":"and","quickFilterValues":[],"quickFilterLogicOperator":"and"};

const showStatusCell = (value, mobile = false) => {
  const style = {};
  style.paddingTop = "8px";
  style.paddingBottom = "9px";
  style.paddingLeft = "0";
  style.textAlign = "center";
  style.borderRadius = "0";
  switch (value) {
      case "Processed":
          style.whiteSpace = 'nowrap';
          style.backgroundColor = '#19C922';
          style.color = 'rgba(255, 255, 255, 0.87)';
          break;
      case "Approved":
          style.whiteSpace = 'nowrap';
          style.backgroundColor = '#417505';
          style.color = 'rgba(255, 255, 255, 0.87)';
          break;
      case "Changes Requested":
          style.backgroundColor = '#4b97f2';
          style.color = 'rgba(255, 255, 255, 0.87)';
          break;
      case "Draft":
          style.whiteSpace = 'nowrap';
          style.backgroundColor = '#A1A1A1';
          style.color = 'rgba(0, 0, 0, 0, 0.87)';
          break;
      case "Not Started":
          style.whiteSpace = 'nowrap';
          style.backgroundColor = '#4A4A4A';
          style.color = 'rgba(255, 255, 255, 0.87)';
          break;
      case "Submitted":
          style.whiteSpace = 'nowrap';
          style.backgroundColor = '#C2B61B';
          style.color = 'rgba(0, 0, 0, 0, 0.87)';
          break;
      default:
          style.backgroundColor = 'black';
          style.color = 'rgba(255, 255, 255, 0.87)';
          break;
  }
  style.marginTop = '-10px';
  style.marginLeft = '-10px';
  style.marginRight = '-10px';
  style.textOverflow = 'ellipsis';

  style.overflow = 'hidden';
  style.height = '40px !important';
  style.minHeight = '40px !important';
  return (
      <>{value !== undefined ?
          <div style={style} className={`${mobile ? "" : "flex"}`}>{value}</div> :
          <Skeleton style={{height: 20, width: '100%'}}/>
      } </>);
}

class FinancialStatePage extends React.Component {

  state = {
    counties: this.props.counties || [],
    selectedQuarter: this.props.selectedQuarterFinancial || 0,
    anchorEl: null,
    filterModel: this.props.currentFinancialFilterModel || defaultFilterModel,
    financialReimbursementStateCountiesTableSort:
      this.props.financialReimbursementStateCountiesTableSort,
      quarterData: null  
  };

  constructor(props, context) {
    super(props, context);
    this.selectedCountyCallback = this.selectedCountyCallback.bind(this);
    this.checkIfQuarterIsStarted = checkIfQuarterIsStarted.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props):
    if (this.state.filterModel !== prevState.filterModel) {
      this.setState({filterModel: this.state.filterModel});
    }
    this.props.setLoading(false);
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  printTable = () => {
    var data = this.getQuarterData(
      this.state.counties,
      this.state.selectedQuarter
    );
    var payload = {
      items: [],
      columns: [
        {
          title: "County",
          path: "groupName",
          checked: true,
        },
        {
          title: "Status",
          path: "updatedStatus",
          checked: true,
        },
        {
          title: "Total Amount Expended",
          path: "totalAmount",
          checked: true,
        },
        {
          title: "Amount Reimbursed",
          path: "checkAmount",
          checked: true,
        },
        {
          title: "Last Updated At",
          path: "updatedAtDisplay",
          checked: true,
        },
        {
          title: "Quarter",
          path: "quarter",
          checked: true,
        },
      ],
      timezone: "America/New_York",
    };
    data.forEach((item) => {
      payload.items.push({
        groupName: " " + item.groupName,
        updatedStatus: " " + item.updatedStatus,
        totalAmount: " $" + getFormattedNumberForPrint(item.totalAmount),
        updatedAtDisplay: " " + item.updatedAtDisplay,
        quarter: " Quarter " + (parseInt(this.state.selectedQuarter) + 1),
        reimbursed: item.checkAmount ? `$${item.checkAmount}` :`$0.00` 
      });
    });
    this.props.printTable("financialreimbursement", payload);
    this.handleClose();
  };

  printYTDReport = () => {
    let data = [];
    let payload = {
      items: [],
      columns: [
        {
          title: "County",
          path: "groupName",
          checked: true,
        },
        {
          title: "Total Grant Award",
          path: "total",
          checked: true,
        },
        {
          title: "Personnel",
          path: "personnel",
          checked: true,
        },
        {
          title: "Contractual Services",
          path: "contractualServices",
          checked: true,
        },
        {
          title: "Travel",
          path: "travel",
          checked: true,
        },
        {
          title: "Equipment",
          path: "equipment",
          checked: true,
        },
        {
          title: "Supplies",
          path: "supply",
          checked: true,
        },
        {
          title: "CERT",
          path: "cert",
          checked: true,
        },
        {
          title: "Other",
          path: "other",
          checked: true,
        },
        {
          title: "Available County Overmatch",
          path: "overmatch",
          checked: true,
        },
        {
          title: "Unexpended",
          path: "unexpended",
          checked: true,
        },
      ],
      timezone: "America/New_York",
    };
    this.state.counties.forEach((county) => {
      data.push(generateYTDSpendingReportData(county));
    });

    let totalRow = {
      groupName: "Total",
    };

    for (let i = 0; i < data.length; i++) {
      let obj = Object.keys(data[i]);

      for (let j = 0; j < obj.length; j++) {
        let key = obj[j];
        if (key !== "groupName" && key.indexOf("_unformatted") < 0) {
          totalRow[key] = totalRow[key] || 0;
          totalRow[key] += data[i][key + "_unformatted"];
        }
      }
    }

    Object.keys(totalRow).forEach((key) => {
      if (key.indexOf("_unformatted") < 0 && typeof totalRow[key] === "number") {
        totalRow[key] = formatNumberAsDollar(totalRow[key]);
      }
    });

    data.push(totalRow);

    payload.items = data;
    this.props.printTable(
      "financialreimbursement-YTD_spending_report-state",
      payload
    );
    this.handleClose();
  };

  _convertToTitleCase = (str) => {
    str = str.toLowerCase();
    return str.replace(/(^|\s)\S/g, function (t) {
      return t.toUpperCase();
    });
  };

  calculateAmount = (type, currentQuarter) => {
    let totalAmount = 0;
    currentQuarter[type] &&
      currentQuarter[type].items &&
      currentQuarter[type].items.forEach((item) => {
        if (!item.deleted) {
          item.type = type;
          if (item.quarterAmount && !isNaN(parseFloat(item.quarterAmount))) {
            totalAmount += parseFloat(item.quarterAmount);
          }
          if (item.matchAmount && !isNaN(parseFloat(item.matchAmount))) {
            totalAmount += parseFloat(item.matchAmount);
          }
          // if (item.taxAmount && !isNaN(parseFloat(item.taxAmount))) {
          //     totalAmount += parseFloat(item.taxAmount);
          // }
        }
      });

    return totalAmount;
  };

  getQuarterAmount = (currentQuarter) => {
    let totalAmount = 0;

    let types = [
      "personnel",
      "equipment",
      "contractualService",
      "supply",
      "travel",
      "cert",
      "other",
    ];

    types.forEach((type) => {
      totalAmount += this.calculateAmount(type, currentQuarter);
    });

    return totalAmount;
  };

  getQuarterData = (counties, selectedQuarter, ) => {
    let quarter = "quarter" + (selectedQuarter + 1);
    let GAcategories = [
      "salary",
      "contractualServices",
      "travel",
      "equipment",
      "supply",
      "cert",
      "other",
    ];

    var arr = [];

    counties.forEach((county) => {
      let reimbursed = 0;

      // Calculate Amount Awarded from Grant Application
      if (
        county.grantapplication &&
        county.grantapplication.items &&
        county.financialreimbursement &&
        county.financialreimbursement.items
      ) {
        county.grantapplication.items.forEach((grantApp) => {
          GAcategories.forEach((gaType) => {
            if (grantApp[gaType] && grantApp[gaType].items) {
              grantApp[gaType].items.forEach((item) => {
                if (
                  !item.deleted &&
                  parseFloat(item.federal) &&
                  !isNaN(parseFloat(item.federal))
                ) {
                  // Only doing Federal category for now
                  reimbursed += parseFloat(item.federal);
                }
              });
            }
          });
        });
      }

      if (
        county.financialreimbursement &&
        county.financialreimbursement.items
      ) {
        county.financialreimbursement.items.forEach((item) => {
          if (item.quarter === quarter) {
            item.updatedStatus = item.status;
            if (item.status === "NOT_AVAILABLE")
              item.updatedStatus = "Not Started";
            if (item.status === "NOT_STARTED")
              item.updatedStatus = "Not Started";
            if (item.status === "CHANGES_REQUESTED")
              item.updatedStatus = "Changes Requested";

            item.updatedStatus = this._convertToTitleCase(item.updatedStatus);
            item.totalAmount = Number.parseFloat(
              this.getQuarterAmount(item)
            ).toFixed(2);
            if (
              item.status === "NOT_STARTED" ||
              item.status === "NOT_AVAILABLE"
            ) {
              item.updatedAtDisplay = "";
            } else {
              item.updatedAtDisplay = moment(item.updatedAt).format(
                "MM/DD/YYYY HH:mm:ss"
              );
            }
            item.reimbursed = reimbursed || "";
            item.extensionDate = "";
            if (item.currentExtension) {
              item.extensionDate = moment(
                item.currentExtension.dateAdjustedTo ||
                  item.currentExtension.dueDate
              ).format("MM/DD/YYYY");
            }
            if (item.extensionDate === "Invalid date") item.extensionDate = "";
            arr.push({
              ...{
                groupName: this._convertToTitleCase(county.groupName),
                palmettoId: county.palmettoId,
              },
              ...item,
            });
          }
        });
      }
    });

    return arr;
  };

  downloadFinancialReimbursementUserGuide = async () => {
    var a = await getFileUploadedLinkFromBucket(
      { key: "LEMPG-FinancialReimbursement.pdf" },
      "palmetto-lempg-global-documents"
    );
    window.open(a);
  };

  onSortChange = (column) => {
    this.props.setFinancialReimbursementStateCountiesTableSort(column);
  };

  // Only enable tabs if the Quarter has been started, and there is at least one entry from the selected quarter in the financial reimbursement items from a county
  // disableTabIfQuarterIsNotStarted = (counties, selectedQuarter) => {
  //     let quarter = "quarter" + (selectedQuarter + 1);

  //     let disabled = true;

  //     counties.forEach((county) => {
  //         if (county.financialreimbursement && county.financialreimbursement.items) {
  //             console.log("County Items:", county.financialreimbursement.items);
  //             county.financialreimbursement.items.forEach((item) => {
  //                 if ((item.quarter === quarter) && this.checkIfQuarterIsStarted(selectedQuarter, this.props.selectedYear)) {
  //                     disabled = false;
  //                 }

  //             })
  //         }

  //     });

  //     return disabled;
  // }

  selectTab = (index) => {
    let filterModel = this.state.filterModel;
    this.setState({
      filterModel: filterModel,
      selectedQuarter: index
    });
    this.props.selectQuarterFinancialReimbursement(index);
  };

  onRowClick = (rowInfo) => {
    setTimeout(() => {
     // this.props.setLoading(true);
      this.props.getCountyAction(
        { selectedGroupID: parseInt(rowInfo.row.palmettoId) },
        () => {
          this.props.getFinancialQuarterlyData(
            rowInfo,
            (data) => {
              this.props.history.push("/financialreimbursement/state/county/quarter");
            }
          );
        
        }
      );

      setTimeout(() => {
        //this.props.history.push("/financialreimbursement/state/county/quarter");
      })
  });
  };

  _convertToTitleCase = (str) => {
    str = str.toLowerCase();
    return str.replace(/(^|\s)\S/g, function (t) {
      return t.toUpperCase();
    });
  };

  selectedCountyCallback(...args) {}

  handleMenuClick = (event) => {
    window.dispatchEvent(new CustomEvent("toggle-sidebar"));
  };

  downloadFinancialReimbursementUserGuide = async () => {
    var a = await getFileUploadedLinkFromBucket(
      { key: "LEMPG-FinancialReimbursement.pdf" },
      "palmetto-lempg-global-documents"
    );
    window.open(a);
  };

  onSortChange = (column) => {
    this.props.setFinancialReimbursementStateCountiesTableSort(column);
  };

  render() {
    let quarterData = this.getQuarterData(
      this.state.counties,
      this.state.selectedQuarter);
  
    //console.log("QD: " + JSON.stringify(quarterData));
   let tabs =  <Tabs
   className="layout horizontal center"
   value={this.state.selectedQuarter}
   indicatorColor="primary"
   textColor="primary"
   classes={{
     root: "tabs-root",
     flexContainer: "flex",
     indicator: "tab-indicator-color",
   }}
   // onChange={handleChange}
   aria-label="disabled tabs example"
 >
   <Tab
     className="flex"
     classes={{
       root: "tab-root",
       selected: "tab-selected",
     }}
     onClick={() => this.selectTab(0)}
     label="Q1"
   />

   <Tab
     className="flex"
     classes={{
       root: "tab-root",
       selected: "tab-selected",
     }}
     onClick={() => this.selectTab(1)}
     label="Q2"
   />
   <Tab
     className="flex"
     classes={{
       root: "tab-root",
       selected: "tab-selected",
     }}
     onClick={() => this.selectTab(2)}
     label="Q3"
   />
   <Tab
     className="flex"
     classes={{
       root: "tab-root",
       selected: "tab-selected",
     }}
     onClick={() => this.selectTab(3)}
     label="Q4"
   />
 </Tabs>;

    return (
      <>
      <div style={{display:!this.props.loading ? 'none' : 'flex', zIndex:'9',position:'absolute', top:'57px',left:'0',width:'100vw',height:'100vh',backgroundColor:'white'}} className="layout vertical vertical-center full-height">
          <Fade
              in={this.props.loading}
              size={80}
              unmountOnExit
          >
              <CircularProgress />
          </Fade>
      </div>
     
      <div className="layout vertical full-height">
          <PalmettoReactTable
            columns={COLUMNS}
            dataset={quarterData}
            extraHeaders={<></>}
            filterModel={this.state.filterModel}
            headerIcon={
              <IconButton aria-label="menu"
                onClick={
                 () => window.dispatchEvent(new CustomEvent("toggle-sidebar"))
                }
              >
                  <MdMenu className="icon"/>
              </IconButton>
            }
            onFilterModelChange={ (params) => {
              this.props.setCurrentFinancialFilterModel(params);
            }}
            gridToolbar={true}
            idField={"id"}
            subheader={tabs}
            limit={200}
            onRowClick={this.onRowClick}
            loading={true}
            subtitleSuffix={"reports • " + this.props.selectedYear }
            title={"Financial Reimbursement"}
            rowHeight={48}
            virtualRowHeight={48}
            noHelp={true}
            MenuItems={[
                    { onClick: () => this.printTable(), title: 'Print table' },
                    { onClick: () => this.printYTDReport(), title: 'Print YTD spending report' },
                    { onClick: () => this.downloadFinancialReimbursementUserGuide(), title: 'Help' }
            ]}
          />
      </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialStatePage);
