import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdMenu } from 'react-icons/md';

import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import { Skeleton } from "@mui/lab";
import { getGridSingleSelectOperators } from '@mui/x-data-grid-pro';
import { PalmettoReactTable } from "@zawarski/palmetto-direflow-react-table";

import { 
    setMonitoringCounty, 
    setCurrentMonitoringStateFilterModel,
    setLoading
} from "../../../redux/actions/index";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        token: state.rootReducer.token,
        counties: state.rootReducer.counties,
        selectedYear: state.rootReducer.selectedYear,
        currentMonitoringStateFilterModel: state.rootReducer.currentMonitoringStateFilterModel,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMonitoringCounty: (payload, callback) => dispatch(setMonitoringCounty(payload, callback)),
        setCurrentMonitoringStateFilterModel: (payload) => dispatch(setCurrentMonitoringStateFilterModel(payload)),
        setLoading: (payload) => dispatch(setLoading(payload)), 
    }
}


const filterOperators = getGridSingleSelectOperators().filter(({ value }) =>
    ['isAnyOf' /* add more over time */ ].includes(value),
);

const COLUMNS = [
  {
    minWidth: 100,
    field: "index",
    numeric: true,
    headerName: "#",
    enabled: true,
    filterable: false
},
{
    minWidth: 200,
    field: "updatedGroupName",
    numeric: false,
    headerName: "County",
    enabled: true,
    operatorValue: 'contains',
    filterable: false,
    columnField: "updatedGroupName"
},
{
    minWidth: 200,
    field: "grantInformationEntered",
    numeric: false,
    headerName: "Grant Information",
    enabled: true,
    operatorValue: 'contains',
    filterable: false,
    columnField: "grantInformationEntered"
},
{
    minWidth: 240,
    field: "siteVisitReviewStatus",
    numeric: false,
    headerName: "Site Review Status",
    enabled: true,
    type: 'singleSelect',
    valueOptions: [
        { "value": "Approved", "label": "Approved" },
        { "value": "Awarded", "label": "Awarded" },
        { "value": "Changes Requested", "label": "Changes Requested" },
        { "value": "Denied", "label": "Denied" },
        { "value": "Draft", "label": "Draft" },
        { "value": "Not Available", "label": "Not Available" },
        { "value": "Not Started", "label": "Not Started" },
        { "value": "Processed", "label": "Processed" },
        { "value": "Submitted", "label": "Submitted" }
    ],
    renderCell: param => showStatusCell(param.value),
    filterOperators
},
{
    minWidth: 200,
    field: "updatedAtDisplay",
    numeric: false,
    headerName: "Last updated",
    enabled: false,
    filterable: false,
    renderCell: param => dateTimeFormat(param.value)
}
];

const dateTimeFormat = (date, dateOnly = false) => {
    return (<>{date !== undefined ?
        (<div style={{textAlign: "center"}}>{date ? (!dateOnly ?
            moment(date).utc().format("YYYY-MM-DD HH:MM a") :
            moment(date).utc().format("YYYY-MM-DD")) : ''}</div>) :
        <Skeleton style={{height: '20px', marginTop: 'auto', marginBottom: 'auto', width: '100%'}}/>} </>);
}

const defaultFilterModel =
{"items":[{"columnField":"updatedStatus","operatorValue":"isAnyOf","id":31248,"value":[]}],"linkOperator":"and","quickFilterValues":[],"quickFilterLogicOperator":"and"};


const _convertToTitleCase = (str) => {
    if(!str) return ''
    str = str.toLowerCase();
    return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
}

const showStatusCell = (value, mobile = false) => {
  const style = {};
  style.paddingTop = "8px";
  style.paddingBottom = "9px";
  style.paddingLeft = "0";
  style.textAlign = "center";
  style.borderRadius = "0";
  value = value ? value.toLowerCase() : '';  
  switch (value) {
      case "processed":
          style.whiteSpace = 'nowrap';
          style.backgroundColor = '#19C922';
          style.color = 'rgba(255, 255, 255, 0.87)';
          break;
      case "approved":
          style.whiteSpace = 'nowrap';
          style.backgroundColor = '#417505';
          style.color = 'rgba(255, 255, 255, 0.87)';
          break;
      case "changes requested":
      case "submitted with issues":  
          style.backgroundColor = '#4b97f2';
          style.color = 'rgba(255, 255, 255, 0.87)';
          break;
      case "draft":
          style.whiteSpace = 'nowrap';
          style.backgroundColor = '#A1A1A1';
          style.color = 'rgba(0, 0, 0, 0, 0.87)';
          break;
      case "not started":
          style.whiteSpace = 'nowrap';
          style.backgroundColor = '#4A4A4A';
          style.color = 'rgba(255, 255, 255, 0.87)';
          break;
      case "submitted":
          style.whiteSpace = 'nowrap';
          style.backgroundColor = '#C2B61B';
          style.color = 'rgba(0, 0, 0, 0, 0.87)';
          break;
      default:
          style.backgroundColor = value.length ? 'black' : 'white';
          style.color = 'rgba(255, 255, 255, 0.87)';
          break;
  }
  style.marginTop = '-10px';
  style.marginLeft = '-10px';
  style.marginRight = '-10px';
  style.textOverflow = 'ellipsis';

  style.overflow = 'hidden';
  style.height = '40px !important';
  style.minHeight = '40px !important';
  return (
      <>{value !== undefined ?
          <div style={style} className={`${mobile ? "" : "flex"}`}>{_convertToTitleCase(value)}</div> :
          <Skeleton style={{height: 20, width: '100%'}}/>
      } </>);
}

class MonitoringStatePage extends React.Component {

    state = {
        selectedTab: 1,
        smallScreen: false,
        filterModel: this.props.currentMonitoringStateFilterModel || defaultFilterModel,
    }

    constructor(props, context) {
        super(props, context);
        this.selectedCountyCallback = this.selectedCountyCallback.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        // Typical usage (don't forget to compare props):
        if (this.state.filterModel !== prevState.filterModel) {
          this.setState({filterModel: this.state.filterModel});
        }
        this.props.setLoading(false);
    }
    
    checkWidth = () => {
        const match = window.matchMedia(`(max-width: 768px)`).matches;
        if (match === this.state.smallScreen) return;
        if (match) {
            this.setState({ smallScreen: true });
        } else {
            this.setState({ smallScreen: false });
        }
    }

    componentDidMount() {
        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkWidth);
    }

    setCounty(county) {
        this.props.setMonitoringCounty({ selectedGroupID: parseInt(county.palmettoId) }, this.selectedCountyCallback);
    }

    selectedCountyCallback() {
        // get county monitoring information
    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    selectTab = (index) => {
        this.setState({ selectedTab: index })
    }

    getStatus(county) {
        return county.grantapplication.items[0].status && (county.grantapplication.items[0].status === "AWARDED") ?
            (county.grantapplication.items[0].awardDocumentsUploadedByCounty && county.grantapplication.items[0].awardDocumentsUploadedByCounty.id ?
                "COMPLETE" : "AWARDED") :
            county.grantapplication && county.grantapplication.items[0] && county.grantapplication.items[0].status;
    }

    getData = (counties) => {
        var arr = [];
        counties.forEach((county) => {
            
            let status = '';
            if(county.monitoringsitevisitreview ) {
                if(county.monitoringsitevisitreview.status === 'SUBMITTED_WITH_ISSUES') {
                    status = 'SUBMITTED WITH ISSUES'
                } else {
                    status = county.monitoringsitevisitreview.status;
                }
            } 

            arr.push(
                {
                    ...county,
                    ...{
                        updatedGroupName: _convertToTitleCase(county.groupName),
                        grantInformationEntered: county.monitoringgrantinformation ? 'Yes' : 'No',
                        siteVisitReviewStatus: status
                    },
                }
            )

        });

        return arr;
    }

    onRowClick = (rowInfo) => {
        setTimeout(() => {
          //this.props.setLoading(true);
          rowInfo.row.quarter = "quarter" + (this.state.selectedQuarter + 1);
          this.props.history.push("/monitoring/county")
          this.setCounty(rowInfo.row);
          }
        );
       };


    render() {
            return (
                <>
                
               
                <div className="layout vertical full-height">
                    <PalmettoReactTable
                      columns={COLUMNS}
                      dataset={this.getData(this.props.counties)}
                      extraHeaders={<></>}
                      filterModel={this.state.filterModel}
                      headerIcon={
                        <IconButton aria-label="menu"
                          onClick={
                           () => window.dispatchEvent(new CustomEvent("toggle-sidebar"))
                          }
                        >
                            <MdMenu className="icon"/>
                        </IconButton>
                      }
                      onFilterModelChange={ (params) => {
                        this.props.setCurrentMonitoringStateFilterModel(params);
                      }}
                      gridToolbar={true}
                      idField={"id"}
                      limit={200}
                      noHelp={true}
                      onRowClick={this.onRowClick}
                      loading={true}
                      subtitleSuffix={"reports • " + this.props.selectedYear }
                      title={"Monitoring"}
                      rowHeight={48}
                      virtualRowHeight={48}
                  
                    />
                </div>
                </>
           )
    }
}

// export default MonitoringStatePage;
export default connect(mapStateToProps, mapDispatchToProps)(MonitoringStatePage);
