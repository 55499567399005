import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { MdArrowBack } from 'react-icons/md';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
// Local imports
import { PrimaryButton, DeleteButton } from "../../styled-components/Buttons/Buttons";
import { NormalTextField} from "../../styled-components/TextFields/TextFields.js";
import { Skeleton } from "@mui/lab";
// Component imports
import FinancialPersonnelEditPage from './FinancialPersonnelEditPage.js';
import FinancialCertEditPage from './FinancialCertEditPage.js';
import FinancialContractualServiceEditPage from './FinancialContractualServiceEditPage.js';
import FinancialEquipmentEditPage from './FinancialEquipmentEditPage.js';
import FinancialSupplyEditPage from './FinancialSupplyEditPage.js';
import FinancialTravelEditPage from './FinancialTravelEditPage.js';
import FinancialOtherEditPage from './FinancialOtherEditPage.js';
import { FinancialInformationalCards } from './FinancialInformationalCards';
import FinancialReimbursementApprovedState from "./FinancialReimbursementApprovedState";
import FinancialReimbursementChangesRequestedState from "./FinancialReimbursementChangesRequestedState";
import FinancialReimbursementProcessed from "./FinancialReimbursementProcessed";
import { PalmettoReactTable } from "@zawarski/palmetto-direflow-react-table";
// Redux

import { 
    setCurrentQuarterlyItem, 
    approveFinancialReimbursementApplication, 
    requestChangesFinancialReimbursementApplication,
    printFinancialReimbursementForm,
 } from "../../../redux/actions/index";

import {
    getInformationalCardInformation,
    getMostRecentGrantApplicationForAQuarter
} from './FinancialCommonApi';

// CSS imports
////import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

const mapStateToProps = (state) => {
    
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        counties: state.rootReducer.counties,
        currentQuarter: state.rootReducer.currentQuarter,
        currentQuarterItem: state.rootReducer.currentQuarterItem,
        currentApplicationState: state.rootReducer.currentApplicationState,
        grantApplication: state.rootReducer.grantApplication,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentQuarterlyItem: (payload) => dispatch(setCurrentQuarterlyItem(payload)),
        approveFinancialReimbursementApplication: (payload) => dispatch(approveFinancialReimbursementApplication(payload)),
        requestChangesFinancialReimbursementApplication: (payload) => dispatch(requestChangesFinancialReimbursementApplication(payload)),
        printFinancialReimbursementForm: (payload) => dispatch(printFinancialReimbursementForm(payload)),
        
    }
};

const type2Title = {
    "personnel": "Personnel",
    "equipment": "Equipment",
    "contractualService": "Contractual Service",
    "supply": "Supply",
    "travel": "Travel",
    "cert": "CERT",
    "other": "Other",
}

const COLUMNS = [
    {
      minWidth: 100,
      field: "index",
      numeric: true,
      headerName: "#",
      enabled: true,
      filterable: false
  },
  {
      minWidth: 200,
      field: "positionTitle",
      numeric: false,
      headerName: "Name",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "positionTitle"
  },
  {
      minWidth: 200,
      field: "title",
      numeric: false,
      headerName: "Type",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "title"
  },
  {
      minWidth: 100,
      field: "totalAmount",
      numeric: true,
      headerName: "Total amount",
      enabled: true,
      filterable: false,
      renderCell: param => dollarFormat(param.value)
  }
  ];
  
  
  const dollarFormat = (amount) => {
      return (
          <>{amount !== undefined ?
              <div style={{color: (amount < 0 ? "red" : "rgba(0 ,0,0,.7)"), textAlign:"right"}}>{!isNaN(amount) ? currencyFormatter.format(amount) : ''}</div> :
              <Skeleton style={{height: 20, width: '100%'}}/>
          } </>);
  }
  
  const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
  });

class FinancialReimbursementStatePackage extends React.Component {

    state = {
        open: false,
        notes: (this.props.currentQuarterItem && this.props.currentQuarterItem.notes) || "",
        changeNotes: 'The state found errors within your Financial Reimbursement form. If you have any questions, reach out to your State contact.',
        counties: this.props.counties || [],
        personnel: (this.props.currentQuarter && this.props.currentQuarter.personnel && this.props.currentQuarter.personnel && this.props.currentQuarter.personnel.items) || [],
        equipment: (this.props.currentQuarter && this.props.currentQuarter.equipment && this.props.currentQuarter.equipment && this.props.currentQuarter.equipment.items) || [],
        travel: (this.props.currentQuarter && this.props.currentQuarter.travel && this.props.currentQuarter.travel && this.props.currentQuarter.travel.items) || [],
        contractualService: (this.props.currentQuarter && this.props.currentQuarter.contractualService && this.props.currentQuarter.contractualService && this.props.currentQuarter.contractualService.items) || [],
        supply: (this.props.currentQuarter && this.props.currentQuarter.supply && this.props.currentQuarter.supply && this.props.currentQuarter.supply.items) || [],
        cert: (this.props.currentQuarter && this.props.currentQuarter.cert && this.props.currentQuarter.cert && this.props.currentQuarter.cert.items) || [],
        other: (this.props.currentQuarter && this.props.currentQuarter.other && this.props.currentQuarter.other && this.props.currentQuarter.other.items) || [],
        positionTitle: (this.props.currentQuarterItem && this.props.currentQuarterItem.positionTitle) || "",
        quarterAmount: (this.props.currentQuarterItem && this.props.currentQuarterItem.quarterAmount) || "",
        matchAmount: (this.props.currentQuarterItem && this.props.currentQuarterItem.matchAmount) || "",
        taxAmount: (this.props.currentQuarterItem && this.props.currentQuarterItem.taxAmount) || "",
        id: (this.props.currentQuarterItem && this.props.currentQuarterItem.id) || "",
        files: (this.props.currentQuarterItem && this.props.currentQuarterItem.file && JSON.parse(this.props.currentQuarterItem.file)) || [],
        showEditPage: false, 
        anchorEl: null,

    }

    constructor(props, context) {
        super(props, context);
        this.requestChangesFimApplication = this.requestChangesFimApplication.bind(this);
        this.approveFimApplication = this.approveFimApplication.bind(this);
    }

    componentDidUpdate() { }

    goBack = () => {
        this.props.history.goBack();
    }

    getItems = (type) => {

        var type2 = type === "salary" ? "personnel" : type;

        var items = (this.props.grantApplication && this.props.grantApplication[type] && this.props.grantApplication[type].items) || [];

        var remainingItems = []
        items.forEach((item) => {
            if (!item.deleted) {
                var found = false
                this.state[type2].forEach((personnel) => {
                    if ((item.positionTitle === personnel.positionTitle) && !personnel.deleted && !item.deleted) {
                        found = true
                    }
                });
                if (!found) remainingItems.push(item)
            }
        })

        return remainingItems;

    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    showItems = () => {
        this.props.history.goBack();
    }

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    async printFinancialReimbursementForm() {
        this.handleClose();

        let mostUpdatedGrantApplication = await getMostRecentGrantApplicationForAQuarter(this.props.currentQuarter, this.props.currentApplicationState, this.props.selectedYear);


        this.props.currentQuarter.currentApplicationState = mostUpdatedGrantApplication;
        this.props.currentQuarter.financialreimbursement = {};
        this.props.currentQuarter.financialreimbursement.items = this.props.selectedCounty.financialreimbursement.items;
        this.props.printFinancialReimbursementForm(this.props.currentQuarter);        
    }    

    getTableData = (type) => {
        var arr = [];
        this.props.currentQuarter[type] && 
        this.props.currentQuarter[type].items && 
        this.props.currentQuarter[type].items.forEach((item) => {
            if(!item.deleted) {
                let totalAmount = 0;
                item.type = type;
                item.title = type2Title[type];
                if (item.quarterAmount && !isNaN(parseFloat(item.quarterAmount))) {
                    totalAmount += parseFloat(item.quarterAmount);
                }
                if (item.matchAmount && !isNaN(parseFloat(item.matchAmount))) {
                    totalAmount += parseFloat(item.matchAmount);
                }
                // if (item.taxAmount && !isNaN(parseFloat(item.taxAmount))) {
                //     totalAmount += parseFloat(item.taxAmount);
                // }
    
                item.totalAmount = Number.parseFloat(totalAmount).toFixed(2);
    
                arr.push(item)
            }
        })
        return arr;
    }

    getQuarterData = (currentQuarter) => {
        var arr = [];
        let types = [
            "personnel",
            "equipment",
            "contractualService",
            "supply",
            "travel",
            "cert",
            "other"
        ]

        types.forEach( (type) => {
            arr = arr.concat(this.getTableData(type))
        })

        return arr;
    }

    onRowClick = (rowInfo) => {
            setTimeout(() => {
                this.props.setCurrentQuarterlyItem(rowInfo.row)

                setTimeout(() => {
                    this.props.history.push("/financialreimbursement/state/county/quarter/" + rowInfo.row.type)
                })
            });
    };

    requestChangesFimApplication () {
        this.props.requestChangesFinancialReimbursementApplication(this.state.changeNotes);
    }

    approveFimApplication () {
        this.props.approveFinancialReimbursementApplication();
    }

    handleClose = (variable) => {
        this.setState({
            [variable]: false
        });

    };

    openDialog = (variable) => {
        this.setState({
            [variable]: true
        });
    }
    
    handleChange = name => event => {
        this.setState({ [name]: event.target.value })
        event.preventDefault();
        event.stopPropagation();
    };
    
    render() {

        const quarterSubheaderTitle = "Q" + parseInt((this.props.currentQuarter && this.props.currentQuarter.quarter && this.props.currentQuarter.quarter.split("").reverse()) || "1")

        const TableComp = (props) => {

            // What county sees if financial application is approved and progress reporting is not done and only state person sees is


            if (this.props.currentQuarter.status && this.props.currentQuarter.status === "APPROVED") {
                return <FinancialReimbursementApprovedState {...props} />
            } 
            else if (this.props.currentQuarter.status && this.props.currentQuarter.status === "CHANGES_REQUESTED") {
                return <FinancialReimbursementChangesRequestedState {...props} />
            } 

            else if (this.props.currentQuarter.status && this.props.currentQuarter.status === "PROCESSED") {
                return <FinancialReimbursementProcessed {...props} />
            } 

            else {
                return (
                    <>
                
                
                    <div className="layout vertical full-height" style={{paddingBottom:'8px'}}>
                   
          	<div style={{overflow:'hidden',paddingBottom:'32px'}} className='flex'>
            <PalmettoReactTable
                headerIcon={
                <IconButton
                    aria-label="close"
                    onClick={this.goBack}>
                    <MdArrowBack className="icon" />
                </IconButton>
            }
            columns={COLUMNS}
            dataset={this.getQuarterData(this.props.currentQuarter)}
            extraHeaders={<></>}
            idField={"id"}
            subheader={ <FinancialInformationalCards items={getInformationalCardInformation(this.props)} />}
            limit={200}
            onRowClick={this.onRowClick}
            loading={true}
            title={"Financial Reimbursement"}
            subtitleSuffix={"entries • " + this.props.selectedYear + " • " + quarterSubheaderTitle + " • " + this._convertToTitleCase(this.props.selectedCounty.groupName.toString())} 
            noHelp={true}
            MenuItems={[
                    { onClick: () => this.printFinancialReimbursementForm(), title: ' Print financial reimbursement form' }
            ]}
            rowHeight={48}
            virtualRowHeight={48}
          />
           </div>
           <div style={{position:'relative',width:'100%',height:'48px',maxHeight:'48px',bottom:'0'}}>
            <div className="flex" style={{width:'100%',textAlign:'center'}}>
                            <DeleteButton margin="8px 8px" setwidth="true" onClick={() =>  this.openDialog("open")}> Request Changes </DeleteButton>
                            <PrimaryButton margin="8px 8px" setwidth="true" onClick={() => this.approveFimApplication()}> Approve </PrimaryButton>
            </div>
            </div>
            </div>
            </>
            )
            }

        }

        return (
<>

            <Dialog
            fullWidth
            open={this.state.open || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
          
                <DialogTitle id="alert-dialog-title">{"Financial Reimbursement Form Change"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please provide explanation for change request.
                    </DialogContentText>
                    <TextField
                        multiline
                        variant="standard"
                        aria-label="minimum height"
                        minRows={3}
                        style={{marginTop:"4px"}}
                        value={this.state.changeNotes}
                        onChange={this.handleChange("changeNotes")}
                        id="changeNotes"
                        placeholder='The state found errors within your Financial Reimbursement form. If you have any questions, reach out to your State contact.'
                        label=""
                        autoFocus
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleClose("open")} color="primary">Cancel</Button>
                    <Button onClick={() => this.requestChangesFimApplication()} color="primary">Submit</Button>
                </DialogActions>
        
        </Dialog>
            <Switch>

                <Route
                    exact
                    path="/financialreimbursement/state/county/quarter"
                    component={(props) => <TableComp {...props} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/personnel"
                    component={(props) => <FinancialPersonnelEditPage getItems={this.getItems} showItems={this.showItems} {...this.props}  {...this.state} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/equipment"
                    component={(props) => <FinancialEquipmentEditPage getItems={this.getItems} showItems={this.showItems} {...this.props} {...this.state} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/travel"
                    component={(props) => <FinancialTravelEditPage getItems={this.getItems} showItems={this.showItems} {...this.props} {...this.state} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/cert"
                    component={(props) => <FinancialCertEditPage getItems={this.getItems} showItems={this.showItems} {...this.props} {...this.state} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/supply"
                    component={(props) => <FinancialSupplyEditPage getItems={this.getItems} showItems={this.showItems} {...this.props} {...this.state} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/other"
                    component={(props) => <FinancialOtherEditPage getItems={this.getItems} showItems={this.showItems} {...this.props} {...this.state} />}
                />

                <Route
                    path="/financialreimbursement/state/county/quarter/contractualService"
                    component={(props) => <FinancialContractualServiceEditPage getItems={this.getItems} showItems={this.showItems} {...this.props} {...this.state} />}
                />

            </Switch>
            </>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReimbursementStatePackage);
