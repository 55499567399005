import React from 'react';
import Menu from '@mui/material/Menu';
import IconButton from "@mui/material/IconButton";
import MenuItem from '@mui/material/MenuItem';
import { MdClear, MdAssessment } from 'react-icons/md';


import NumberFormat from 'react-number-format';


export class FinancialInformationalCards extends React.Component {
    state =  {
        smallScreen: false,
    }


    checkWidth = () => {
        const match = window.matchMedia(`(max-width: 1100px)`).matches;
        if (match === this.state.smallScreen) return;
        if (match) {
            this.setState({ smallScreen: true });
        } else {
            this.setState({ smallScreen: false });
        }
    }

    componentDidMount() {
        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkWidth);
    }


    render () {

        const InformationalCards = (props) => {
            return (
                <div className="layout horizontal informational-cards-container">
                    {
                        this.props.items.map((item, index) => {
                            return (
                                <div key={item.id} className="layout horizontal flex">
                                    <div className="layout vertical flex informational-card">
                                        <span className="informational-card-title">{item.title}</span>
                                        <div className={`layout horizontal center center-justified ${item.class}`}>

                                            <span className="informational-card-amount-1"><NumberFormat value={item.amount1} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
                                            <span className="informational-card-amount-2">{item.amount2}</span>


                                        </div>
                                    </div>
                                    {
                                        item.hideLine || index === this.props.items.length - 1 ? "" :
                                            <div className="information-card-vertical-line"></div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            )
        }


        return (
            //this.state.smallScreen ? "" : <InformationalCards />
            <InformationalCards />
        )

    }
}

export class FinancialInformationalCardsMobile extends React.Component {

    state = {
        smallScreen: false,
        anchorEl: null,
    }


    checkWidth = () => {
        const match = window.matchMedia(`(max-width: 1100px)`).matches;
        if (match === this.state.smallScreen) return;
        if (match) {
            this.setState({ smallScreen: true });
        } else {
            this.setState({ smallScreen: false });
        }
    }

    componentDidMount() {
        this.checkWidth();
        window.addEventListener("resize", this.checkWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkWidth);
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    }



    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        const InformationalCardsMobile = (props) => {
            return (

                <div>
                    <IconButton
                        aria-label="More"
                        aria-haspopup="true"
                        aria-owns={open ? 'application-menu' : undefined}
                        onClick={this.handleIconDropdownClick}
                    >
                        <MdAssessment className="icon" />
                    </IconButton>

                    <Menu
                        id="application-menu"
                        // anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        open={open}
                        onClose={this.handleClose}
                    >

                        <div className="layout vertical informational-cards-container-mobile">
                            {
                                this.props.items.map((item, index) => {
                                    return (
                                        <div key={item.id} className="layout vertical flex">
                                            <div className="layout vertical flex informational-card">
                                                <span className="informational-card-title">{item.title}</span>
                                                <div className={`layout horizontal center center-justified ${item.class}`}>
                                                    <span className="informational-card-amount-1"><NumberFormat value={item.amount1} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
                                                    <span className="informational-card-amount-2">{item.amount2}</span>
                                                </div>
                                            </div>
                                            {
                                                item.hideLine ? "" :
                                                    <div className="information-card-horizontal-line"></div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>


                    </Menu>
                </div>
            )
        }

        return (
            this.state.smallScreen ? <InformationalCardsMobile /> : ""
        )

    }
}

FinancialInformationalCards.defaultProps = {
    items: [],
};
