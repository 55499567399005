
import React from 'react';
import { connect } from 'react-redux'
import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import IconButton from "@mui/material/IconButton";
import { MdMenu, MdCheckCircle, MdMoreVert } from 'react-icons/md';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { PrimaryButton } from "../styled-components/Buttons/Buttons.js";

import { reviseGrantApplication, printGrantApplicationForm } from "../../redux/actions/index";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        selectedCounty: state.rootReducer.selectedCounty,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reviseGrantApplication: (payload, callback) => dispatch(reviseGrantApplication(payload, callback)),
        printGrantApplicationForm: (payload) => dispatch(printGrantApplicationForm(payload)),        
    }
}

class CountyGrantApplicationCompleted extends React.Component {

    state = {
        open: false,

    }

    constructor(props, context) {
        super(props, context);
    }

    goBack = () => {
        this.props.history.goBack()
    }

    printGrantApplicationForm() {
        this.handleMenuClose();
        this.props.printGrantApplicationForm(this.props.currentApplicationState)
    }

    reviseGrantApplication = () => {
        this.props.reviseGrantApplication()
        this.handleClose()
    }
    

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };


    handleClose = () => {
        this.setState({ open: false });
    };


    handleClickOpen = () => {
        this.setState({ open: true });
    };

    checkIfQ4FinancialReimbursementIsApprovedOrProcessed = () => {
        if (this.props.selectedCounty && this.props.selectedCounty.financialreimbursement && this.props.selectedCounty.financialreimbursement.items && this.props.selectedCounty.financialreimbursement.items.length) {

            let items = this.props.selectedCounty.financialreimbursement.items;

            for(var i = 0; i < items.length; i++) {
                var item = items[i];

                if ((item.quarter === "quarter4") && ( (item.status === 'APPROVED') || (item.status === 'PROCESSED'))) {
                    // dont show the button
                    return true;
                }
            }
        }

        // Show revise button
        return false;
    }

    checkIfAnyFinancialQuarterIsNotSubmitted = () => {
        if (this.props.selectedCounty && this.props.selectedCounty.financialreimbursement && this.props.selectedCounty.financialreimbursement.items && this.props.selectedCounty.financialreimbursement.items.length) {
            let items = this.props.selectedCounty.financialreimbursement.items;
            for(var i = 0; i < items.length; i++) {
                var item = items[i];
                if (item.status === 'SUBMITTED') {
                    // dont show the button
                    return true;
                }
            }
        }
        return false;
    }

    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div className="layout vertical full-height">


                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{ maxWidth: "320px" }}>
                        <DialogTitle id="alert-dialog-title">{"Revise grant application"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You are about to revise your Grant application. This will move your application back into <b>Draft</b>  and require another approval from the state. Are you sure you want to do this?
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.reviseGrantApplication} color="primary" autoFocus>
                                Revise
                            </Button>
                        </DialogActions>
                    </div>

                </Dialog>


                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Grant Application</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>LEMPG {this.props.selectedYear}  • {this.props.selectedCounty && this.props.selectedCounty.groupName}</span>}
                    suffix={
                        <div>
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'application-menu' : undefined}
                                onClick={this.handleIconDropdownClick}
                            >
                                <MdMoreVert className="icon" />
                            </IconButton>

                            <Menu
                                id="application-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                <MenuItem key="printApplicationForm" onClick={() => this.printGrantApplicationForm()}>
                                    Download current application
                                </MenuItem>
                            </Menu>
                        </div>

                    }        

                />

                <div className="flex layout vertical center vertical-center container-overflow-y">

                    <div className="layout horizontal center center-justified">
                        <MdCheckCircle className="icon-2 application-status-icon color-green" />
                    </div>
                    <div className="height-20"></div>
                    <span className="layout horizontal center center-justified application-status-title-small">Completed</span>
                    <div className="height-20"></div>

                    <span style={{ padding: "0 16px", maxWidth: "456px" }} className="text-center title-big opacity-54">You have completed the grant application process. 
Copies of all grant documents are available for download in 
the <b>Documents</b> seen in the side drawer.</span>

                    <div className="height-20"></div>

                    {
                        this.checkIfQ4FinancialReimbursementIsApprovedOrProcessed() ? '' :
                        this.checkIfAnyFinancialQuarterIsNotSubmitted() ? '' : 
                        <div className="layout horizontal center-justified">
                            <PrimaryButton onClick={() => this.handleClickOpen()}>Revise Application</PrimaryButton>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CountyGrantApplicationCompleted);
