import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import { MdMenu, MdAccessTime } from 'react-icons/md';

import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { CardComponent2R1C } from "../../layout/CardComponent";

import { setActionsForFinancialReimbursementExtensions, setActionsForProgressReportingExtensions } from "../../../redux/actions/index";

// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        loading: state.rootReducer.loading,
        counties: state.rootReducer.counties,
        allExtensions: state.rootReducer.allExtensions,
        selectedYear: state.rootReducer.selectedYear
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setActionsForFinancialReimbursementExtensions: (payload, callback) => dispatch(setActionsForFinancialReimbursementExtensions(payload, callback)),
        setActionsForProgressReportingExtensions: (payload, callback) => dispatch(setActionsForProgressReportingExtensions(payload, callback))
    }
}

class ExtensionsStatePage extends React.Component {

    state = {
        cards: [],
    }

    constructor(props, context) {
        super(props, context);
    }

    goBack = () => {
        this.props.history.goBack();
    }

    viewExtension = (item) => {

        if(item.currentExtension.for === "FINANCIAL_REIMBURSEMENT") {
            this.props.setActionsForFinancialReimbursementExtensions(item);
            this.props.history.push("/extensions/financialreimbursements/view")
        }
        if (item.currentExtension.for === "PROGRESS_REPORTING") {
            this.props.setActionsForProgressReportingExtensions(item);
            this.props.history.push("/extensions/progressreporting/view")
        }
    }

    render() {
        return (

            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Extensions</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>LEMPG {this.props.selectedYear} </span>}
                />
                {
                    this.props.allExtensions.length ?
                        <div className="flex form-container">
                            <div className="layout horizontal wrap">

                                {
                                    this.props.allExtensions.map((item, index) => {
                                        return (
                                            <div className="cursor-pointer" key={item.id} onClick={() => this.viewExtension(item)}>
                                                <CardComponent2R1C className="set-width card-shell" title={<span>{item.title}</span>} title2={item.subtitle} />
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>

                    : 
                    <div className="flex layout vertical center vertical-center container-overflow-y splash-screen">

                        <MdAccessTime className="icon-2 application-status-icon" />
                        <div className="height-20"></div>
                            <span className="layout horizontal center center-justified application-status-title-small">No pending extensions</span>
                        <div className="height-20"></div>

                            <span style={{ padding: "0 16px", maxWidth: "400px"}} className="text-center title-big opacity-54">No pending extensions available. We’ll email you when an extension is filed or you can check here for updates.</span>

                    </div>
                }
            </div>
            
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionsStatePage);
