import React from 'react';
import { connect } from 'react-redux'
import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import IconButton from "@mui/material/IconButton";
import { MdArrowBack, MdCheckCircle } from 'react-icons/md';
import { SecondaryButton } from "../styled-components/Buttons/Buttons";
import { getFileUploadedLink } from '../common/FileUploadApi';

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

class ApplicationApproved extends React.Component {

    state = {
        coverLetterFileLink: "",
        awardLetterFileLink: "",
        specialInstructionsFileLink: "",
        errors: [],
    }


    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);

    }


    async componentDidMount() {
        let coverLetterFileLink = await getFileUploadedLink(this.props.grantApplication && this.props.grantApplication.awardCoverLetterUploadedByState);

        let awardLetterFileLink = await getFileUploadedLink(this.props.grantApplication && this.props.grantApplication.awardDocumentUploadedByState);

        let specialInstructionsFileLink = await getFileUploadedLink(this.props.grantApplication && this.props.grantApplication.awardSpecialInstructionsUploadedByState);

        this.setState({
            coverLetterFileLink: coverLetterFileLink
        })

        this.setState({
            awardLetterFileLink: awardLetterFileLink
        })

        specialInstructionsFileLink && this.setState({
            specialInstructionsFileLink: specialInstructionsFileLink
        })
    }

    downloadForms = () => {
        this.state.coverLetterFileLink && window.open(this.state.coverLetterFileLink);
        this.state.awardLetterFileLink && window.open(this.state.awardLetterFileLink);
        this.state.specialInstructionsFileLink && window.open(this.state.specialInstructionsFileLink);
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdArrowBack className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Application</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>LEMPG {this.props.selectedYear} • {this.props.grantApplication.group && this.props.grantApplication.group.groupName}</span>}
                />

                <div className="flex layout vertical center vertical-center container-overflow-y splash-screen">

                    <MdCheckCircle className="icon-2 application-status-icon color-green" />
                    <div className="height-20"></div>
                    <span className="layout horizontal center center-justified application-status-title-small">Approved</span>
                    <div className="height-20"></div>

                    <span style={{ padding: "0 16px", maxWidth: "280px" }} className="text-center title-big opacity-54">The grant application has been officially approved..</span>

                    <div className="height-20"></div>

                    {
                        this.props.grantApplication && this.props.grantApplication.awardCoverLetterUploadedByState ?
                            <SecondaryButton className="form-margin-16" onClick={this.downloadForms} setwidth="true">
                                Download forms
                        </SecondaryButton>
                            : <span> No documents uploaded yet </span>

                    }
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationApproved);
