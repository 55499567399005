import React from 'react';

import NewChart, { ProgressBarMultiTemp } from "./Charts";
import { connect } from 'react-redux'
import moment from 'moment';
import numeral from 'numeral';

import { calcQuarters } from '../../utilities/QuarterDates';

import "../../../styles/common.css";
import "../../../styles/common.charts.css";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        counties: state.rootReducer.counties,
        selectedCounty: state.rootReducer.selectedCounty,
        selectedYear: state.rootReducer.selectedYear
    };
};



class SpendingByQuarterHorizontalBar extends React.Component {

    state = {
        items: []
    }

    checkQuarterBasedOnDate = (quarterStart, quarterEnd) => {
        var start = new Date(quarterStart);
        var current = new Date();
        current.setHours(0, 0, 0, 0);
        // var end = new Date(quarterEnd);
        // && current <= end
        // We need to allow users to test pass the current FY year
        return (start <= current);
    }

    checkWhichQuarters = () => {
        let obj = calcQuarters(this.props.selectedYear);

        // Check per quarter basis, which quarter we are in

        var quarters = []

        if (this.checkQuarterBasedOnDate(obj.quarter1.start, obj.quarter1.end)) {
            quarters = ["quarter1"];
        }
        if (this.checkQuarterBasedOnDate(obj.quarter2.start, obj.quarter2.end)) {
            quarters = ["quarter1", "quarter2"];
        }
        if (this.checkQuarterBasedOnDate(obj.quarter3.start, obj.quarter3.end)) {
            quarters = ["quarter1", "quarter2", "quarter3"];
        }
        if (this.checkQuarterBasedOnDate(obj.quarter4.start, obj.quarter4.end)) {
            quarters = ["quarter1", "quarter2", "quarter3", "quarter4"];
        }

        return quarters;
    }



    calculateAmount = (type, currentQuarter) => {
        let amount = 0;
        if (!currentQuarter[type]) return amount;
        if (!currentQuarter[type].items) return amount;
        if (!currentQuarter[type].items.length) return amount;

        currentQuarter[type].items.forEach((item) => {
            if (!item.deleted) {
                if (item.quarterAmount && !isNaN(parseFloat(item.quarterAmount))) {
                    amount += parseFloat(item.quarterAmount);
                }
                // if (item.matchAmount && !isNaN(parseFloat(item.matchAmount))) {
                //     amount += parseFloat(item.matchAmount);
                // }
                // if (item.taxAmount && !isNaN(parseFloat(item.taxAmount))) {
                //     amount += parseFloat(item.taxAmount);
                // }
            }
        })

        return Number.parseFloat(amount).toFixed(2);
    }

    /**
     * 
     * Calculates amount for a financial reimbursements by amountType
     * 
     */

    getQuarterAmountByAmountType = (currentQuarter) => {
        let totalAmount = 0;

        let types = [
            "personnel",
            "equipment",
            "contractualService",
            "supply",
            "travel",
            "cert",
            "other"
        ]

        types.forEach((type) => {
            totalAmount += parseFloat(this.calculateAmount(type, currentQuarter))
        })

        return parseFloat(totalAmount);
    }    

    getEstimatedCountyBudgetFromGrantApplication = (county) => {

        var arr = []

        /**
         * 
         * Line chart will be calculated by calculating percentage of individual category vs summation of entered amount in category
         * 
        */

       
       let amount = 0;
        var applicationTypes = ['salary', 'contractualService', 'travel', 'equipment', 'supply', 'other', 'cert'];

        // First calculate total amount awarded for all types
        // Then calculate how much percent is being used in each category

        if (county.grantapplication && county.grantapplication.items) {
            county.grantapplication.items.forEach((grantApp) => {

                let obj = {
                    'salary': 0,
                    'contractualService': 0,
                    'travel': 0,
                    'supply': 0,
                    'other': 0,
                    'cert': 0,
                    'equipment': 0,
                }

                applicationTypes.forEach((type) => {
                    grantApp[type].items.forEach((item) => {
                        if (!item.deleted) {

                            let typesOfFunding = ["federal"]
                            let sum = 0;

                            typesOfFunding.forEach((typeOfFunding) => {

                                if (item[typeOfFunding] && parseFloat(item[typeOfFunding]) && !isNaN(parseFloat(item[typeOfFunding]))) {
                                    sum += parseFloat(item[typeOfFunding]);
                                }
                            })

                            // Individual category sum
                            obj[type] += sum;

                            // Total sum
                            amount += sum;
                        }
                    })

                })

            });
        }

        return amount
    }    



    calculateItems = () => {

        let datasets = [{
            label: 'Processed',
            backgroundColor: "#417505",
            borderWidth: 1,
            data: [0, 0, 0, 0]
        }, {
            label: 'Submitted',
            backgroundColor: "#c2b61b",
            borderWidth: 1,
            data: [0, 0, 0, 0]
        }, {
            label: 'Draft',
            backgroundColor: "#a1a1a1",
            borderWidth: 1,
            data: [0, 0, 0, 0]
        }, {
            label: 'Not Started ',
            backgroundColor: "#4a4a4a",
            borderWidth: 1,
            data: [0, 0, 0, 0]
        }];

        /**
         * Each stacked bar graph indicates how many counties are in `PROCESSED, DRAFT, SUBMITTED and NOT_STARTED` phase determined by which quarter we are in currently.
         * 
         */

        // ["Q1-Total"]
        // ["Q2-Total"]
        // ["Q3-Total"]
        // ["Q1-Total"]

        let quarters = this.checkWhichQuarters();
        let county = this.props.selectedCounty;
        
        if (county.financialreimbursement && county.financialreimbursement.items) {

            // Get financial reimbursements forms for quarters up until now
            let forms = []

            county.financialreimbursement.items.forEach((item) => {
                if (item.status === "PROCESSED") {
                    if (quarters.indexOf(item.quarter) >= 0) {
                        forms.push(item);
                    }
                }    
            })

            let totalGrantAmount = this.getEstimatedCountyBudgetFromGrantApplication(this.props.selectedCounty);
            let quarterAmount = 0;

            let items = [];
            forms.forEach((form) => {
                quarterAmount = this.getQuarterAmountByAmountType(form)
                items.push({
                    title: "Q" + parseFloat(form.quarter.split("").reverse().join()),
                    value: (quarterAmount / totalGrantAmount),
                    amount: quarterAmount,
                    displayTitle: "Q" + parseFloat(form.quarter.split("").reverse().join()) + " " + numeral(quarterAmount).format('($ 0.00 a)')
                })
            })

            items.forEach( (item) => {
                if (item.title === "Q1") {
                    item.color = "#2961fd";
                }
                if (item.title === "Q2") {
                    item.color = "#4e7dfd";
                } 
                if (item.title === "Q3") {
                    item.color = "#9ab4fd";
                } 
                if (item.title === "Q4") {
                    item.color = "#c0d0fd";
                } 
            })

            items.sort((a, b) => { return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0); })

            return items;
        }

        return [];

    }

    render() {
        return (
            <div className="layout vertical pie-chart-container inline-grid">
                <div>
                    <span className="section-title form-margin-16">Spending by quarter</span>
                    <div className="height-10"></div>
                    <div className="layout horizontal form-margin-16 title-divider"></div>
                </div>
                <div className="height-20"></div>
                <div className="form-margin-16">
                    <ProgressBarMultiTemp items={this.calculateItems()} />
                </div>
                <div className="height-20"></div>
                <div className="layout horizontal form-margin-16">
                    <div className="layout horizontal flex">
                        <div style={{ width: "16px", height: "16px", backgroundColor: "#2961fd" }}></div> <span style={{marginLeft: "16px"}}>Q1</span>
                    </div>

                    <div className="layout horizontal flex">
                        <div style={{ width: "16px", height: "16px", backgroundColor: "#4e7dfd" }}></div> <span style={{ marginLeft: "16px" }}>Q2</span>
                    </div>

                    <div className="layout horizontal flex">
                        <div style={{ width: "16px", height: "16px", backgroundColor: "#9ab4fd" }}></div> <span style={{ marginLeft: "16px" }}>Q3</span>
                    </div>

                    <div className="layout horizontal flex">
                        <div style={{ width: "16px", height: "16px", backgroundColor: "#c0d0fd" }}></div> <span style={{ marginLeft: "16px" }}>Q4</span>
                    </div>


                </div>
            </div>
        )
    }

}


export default connect(mapStateToProps, null)(SpendingByQuarterHorizontalBar);
