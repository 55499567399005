 
import React from 'react';
import { connect } from 'react-redux';
import IconButton from "@mui/material/IconButton";
import { MdAdd, MdClear } from 'react-icons/md';
import { PaperFabButton } from "../../styled-components/Buttons/Buttons.js";
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import { Skeleton } from "@mui/lab";
import { PalmettoReactTable } from "@zawarski/palmetto-direflow-react-table"

// Local imports

// CSS imports

////import "react-table/react-table.css";
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";

// Redux imports
import { resetREMContact, setCurrentREMContact } from "../../../redux/actions/index";


const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        selectedQuarterProgress: state.rootReducer.selectedQuarterProgress,
        selectedYear: state.rootReducer.selectedYear,
        allREMContacts: state.rootReducer.allREMContacts,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetREMContact: () => dispatch(resetREMContact()),
        setCurrentREMContact: (payload) => dispatch(setCurrentREMContact(payload)),
    }
};
								
const COLUMNS = [
    {
      minWidth: 100,
      field: "index",
      numeric: true,
      headerName: "#",
      enabled: false,
      filterable: false
  },
  {
      minWidth: 200,
      field: "region",
      numeric: false,
      headerName: "Region",
      enabled: true,
      filterable: false,
      renderCell: param => {param.value && parseInt(param.value.split("").reverse().join(""))}
  },
  {
      minWidth: 200,
      field: "firstName",
      numeric: false,
      headerName: "First",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "firstName"
  },
   {
      minWidth: 200,
      field: "lastName",
      numeric: false,
      headerName: "Last",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "lastName"
  },
   {
      minWidth: 200,
      field: "phoneNumber",
      numeric: false,
      headerName: "Phone",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "phoneNumber"
  },
   {
      minWidth: 200,
      field: "emailAddress",
      numeric: false,
      headerName: "Email",
      enabled: true,
      operatorValue: 'contains',
      filterable: false,
      columnField: "emailAddress"
  }
] 

class REMContactHome extends React.Component {

    state = {
        anchorEl: null,
    }

    onRowClick = (rowInfo) => {
        setTimeout(() => {
          //this.props.setLoading(true);
          this.props.setCurrentREMContact(rowInfo.row);
          this.props.history.push("/narratives/rem/edit");
         }
        );
       };

   
    goBack = (event) => {
        this.props.history.goBack();
    }

    openNewPage = () => {
        this.props.resetREMContact();
        this.props.history.push("/narratives/rem/new")
    }

    render() {
        return (
          <div className="layout vertical full-height">
          <PalmettoReactTable
            columns={COLUMNS}
            dataset={this.props.allREMContacts}
            extraHeaders={<></>}
            headerIcon={
                <IconButton
                aria-label="close"
                onClick={this.goBack}
            >
                <MdClear className="icon" />
            </IconButton>
            }
            gridToolbar={false}
            idField={"id"}
            limit={200}
            onRowClick={this.onRowClick}
            loading={true}
            subtitleSuffix={"contacts"}
            title={"Update REM Contacts"}
            rowHeight={48}
            virtualRowHeight={48}
            />
     
                    <PaperFabButton onClick={() => this.openNewPage()} aria-label="add">
                        <MdAdd className="icon" />
                    </PaperFabButton>
  
            </div>


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(REMContactHome);
