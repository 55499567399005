import React from 'react';
import IconButton from "@mui/material/IconButton";
import { MdMenu } from 'react-icons/md';

import { HeaderComponent } from "../layout/HeaderComponent.js"
import { connect } from 'react-redux'

// Chart imports

import ApplicationStatusByCountyPieChart from "./charts/ApplicationStatusByCountyPieChart";
import TotalMoneySpentByCountyDoughnutChart from "./charts/TotalMoneySpentByCountyDoughnutChart";
import FinancialReportingStatusByCountyStackedBarGraph from "./charts/FinancialReportingStatusByCountyStackedBarGraph";
import CountiesEstimatedBudgetByCategoryLineChart from "./charts/CountiesEstimatedBudgetByCategoryLineChart";
import ProgressReportingCompletionProgressBar from "./charts/ProgressReportingCompletionProgressBar";
import BSIRPersonnelCard from './charts/BSIRPersonnelCard'
import BSIROtherCard from './charts/BSIROtherCard.js';

// CSS imports
import "../../styles/common.css";
import "../../styles/common.charts.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        user: state.rootReducer.user,
        counties: state.rootReducer.counties,
        selectedCounty: state.rootReducer.selectedCounty,
        selectedYear: state.rootReducer.selectedYear
    };
};

class StateSplashScreen extends React.Component {

    state = {
        config: {}
    }

    constructor(props, context) {
        super(props, context);
    }

    handleMenuClick = (event) => {
        window.dispatchEvent(new CustomEvent("toggle-sidebar"))
    }

    render() {

        return (

            <div className="layout vertical full-height splash-screen">

                <HeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    title={<span>LEMPG FY{this.props.selectedYear}</span>}
                />

                <div className="charts-container flex form-container container-overflow-y">

                    <div className="chart-container-1">
                        <CountiesEstimatedBudgetByCategoryLineChart />
                        <div className="chart-container-3">
                            <ProgressReportingCompletionProgressBar />
                            <FinancialReportingStatusByCountyStackedBarGraph />
                        </div>
                    </div>

                    <div className="chart-container-2">
                        <BSIRPersonnelCard />
                        <BSIROtherCard />
                        <ApplicationStatusByCountyPieChart />
                        <TotalMoneySpentByCountyDoughnutChart />
                    </div>

                    {/* <div className="line-chart">
                        <CountiesEstimatedBudgetByCategoryLineChart />
                    </div>
                    <div className="progress-bar-chart">
                        <ProgressReportingCompletionProgressBar />
                    </div>
                    <div className="stacked-bar-chart">
                        <FinancialReportingStatusByCountyStackedBarGraph />
                    </div>
                    <div className="pie-chart">
                        <ApplicationStatusByCountyPieChart />
                    </div>
                    <div className="doughnut-chart">
                        <TotalMoneySpentByCountyDoughnutChart />
                    </div> */}

                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(StateSplashScreen);
