import React from 'react';

import uniqueid from 'uniqueid';

import "../../styles/common.css";
import "../../styles/common.card.css";
import "../../styles/iron-flex-layout.css";
import "../../styles/iron-flex-layout-classes.css";

class CardComponent3R1C extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    render() {

        // The properties should be defined as attribute during the defination of this component

        const { title, title2, title3, status } = this.props;

        return (
            <div className="layout horizontal center card-container card-container-3r-1c">
                <div className="layout vertical truncate flex">
                    <div className="layout horizontal">
                        <div className="flex truncate card-slot-1r">{title}</div>
                        {status}
                    </div>
                    <div className="layout horizontal  card-slot-2r">
                        <span className="truncate">{title2}</span>
                    </div>
                    <div className="layout horizontal  truncate card-slot-3r">
                        <span className="truncate">{title3}</span>
                    </div>
                </div>
            </div>
        )
    }

}

class CardComponent2R1C extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    render() {

        // The properties should be defined as attribute during the defination of this component

        const { title, title2, status, suffix } = this.props;

        return (
            <div className={this.props.className + " layout horizontal center card-container card-container-2r-1c"}>
                <div className="layout vertical truncate flex">
                    <div className="layout horizontal">
                        <div className="flex truncate card-slot-1r">{title}</div>
                        {status}
                    </div>
                    <div className="layout horizontal  card-slot-2r">
                        <span className="truncate">{title2}</span>
                    </div>
                </div>
                <div className="layout horizontal center">
                    {suffix}
                </div>
            </div>
        );
    }

}



class CardComponentDownloadCard extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const { icon, title, suffix } = this.props;

        return (
            <div className="layout horizontal center card-container card-container-2r-1c">
                {icon}
                <div className="card-spacer"></div>
                <div className="truncate card-slot-1r flex">
                    {title}
                </div>
                {suffix}
            </div>
        )
    }


}

class CardComponentSummaryCard extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
  
    render() {
        const {cardTitle, cardText } = this.props;
        return (
            <div className="layout vertical box-shadow summary-card">
                <div className="layout horizontal center summary-card-text-container summary-card-text">
                    {cardText}
                </div>
                <div className="layout horizontal center  center-justified flex summary-card-title-container summary-card-title">
                    {cardTitle}
                </div>
            </div>
            )
           
    }
}

class CardComponentSummaryCards extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        var cards = this.props.cards.map(function(card,index){
            return (
                <div className="layout vertical summary-card" key={uniqueid('summary-card') + index}>
                   <div className={index === 0 ? 
                   'layout horizontal center summary-card-text-container summary-card-text' :
                   'layout horizontal center summary-card-text-container summary-card-text summary-card-text-divider'}
                       >{card.text}</div>
                    <div className={index === 0 ? 
                    'layout horizontal center center-justified flex summary-card-title-container summary-card-title' :
                    'layout horizontal center center-justified flex summary-card-title-container summary-card-title summary-card-title-divider'}
                      >{card.title}</div>
                </div>
            )
        });
        return  <div className="layout horizontal">{cards}</div>;
    }
}



class CardComponentExecSummaryCard extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const { cardTitle, cardText, cardClass } = this.props;

        return (
            <div className={this.props.className + " layout vertical exec-summary-card"}>
                <div className="layout horizontal center exec-summary-card-text-container exec-summary-card-text">
                    {cardText}
                </div>
                <div className={cardClass + " layout horizontal center  center-justified summary-card-title-container summary-card-title flex"}>
                    {cardTitle}
                </div>
            </div>

        )

    }

}



export { 
    CardComponent3R1C, 
    CardComponent2R1C, 
    CardComponentSummaryCard, 
    CardComponentSummaryCards, 
    CardComponentDownloadCard, 
    CardComponentExecSummaryCard, 
}