import React from 'react';
import { connect } from 'react-redux'
import IconButton from "@mui/material/IconButton";
import { MdClear, MdMoreVert } from 'react-icons/md';

import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';


import { DualTitleHeaderComponent } from "../../layout/HeaderComponent.js"
import { PrimaryButton, SecondaryButton } from "../../styled-components/Buttons/Buttons.js";
import { RequiredTextField, NormalTextField } from "../../styled-components/TextFields/TextFields.js";

import { approveExtensionFinancialReimbursement } from "../../../redux/actions/index"
import { calcQuarters } from '../../utilities/QuarterDates';


// CSS imports
import "../../../styles/common.css";
import "../../../styles/iron-flex-layout.css";
import "../../../styles/iron-flex-layout-classes.css";


const mapStateToProps = (state) => {
    return {
        selectedCounty: state.rootReducer.selectedCounty,
        currentQuarter: state.rootReducer.currentQuarter,
        currentExtension: state.rootReducer.currentExtension,
        lempgUserType: state.rootReducer.lempgUserType,
        selectedYear: state.rootReducer.selectedYear
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        approveExtensionFinancialReimbursement: (payload, callback) => dispatch(approveExtensionFinancialReimbursement(payload, callback)),
    }
}


class FinancialReimbursementExtensionsView extends React.Component {

    state = {
        openApprove: false,
        errors: [],
        validate: false,
        reasonForExtension: this.props.currentExtension && this.props.currentExtension.reasonForExtension,
        dueDate: (this.props.currentQuarter && this.props.currentQuarter.currentExtension && this.props.currentQuarter.currentExtension.dueDate) || "",
        dueDateDisplay: (this.props.currentExtension && this.props.currentExtension.currentExtension && this.props.currentExtension.currentExtension.dueDate) || "",
        
    }

    constructor(props, context) {
        super(props, context);

        if (this.props.currentExtension) {
            this.state.dueDateDisplay = moment(this.state.dueDate, "YYYY-MM-DD").format("MM-DD-YYYY");

            // this.state.dueDateDisplay = this.props.currentExtension.dueDate && (new Date(this.toUTC(new Date(this.props.currentExtension.dueDate))));

        }
        this.calcQuarters = calcQuarters.bind(this);
    }

    toUTC = (date) => {
        var newDate = new Date();
        newDate.setTime(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));
        return newDate;
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    goBack = () => {
        this.props.history.goBack()
    }

    handleClose = (variable) => {
        this.setState({
            [variable]: false
        });
    };

    openDialog = (variable) => {
        this.setState({
            [variable]: true
        });
    }

    approveExtensionFinancialReimbursement = () => {
        this.props.approveExtensionFinancialReimbursement(this.state,  () => {
            this.goBack()
        });
    }

    adjustExtension = () => {
        this.props.history.push("/extensions/adjust");
    }

    getType = (str) => {
        if (str === "FINANCIAL_REIMBURSEMENT") return "Financial Reimbursement";
        if (str === "PROGESS_REPORTING") return "Progress Reporting";

        return ""
    }

    getQuarterEndDate = (quarter) => {
        if(!quarter) return

        var obj = calcQuarters(this.props.selectedYear);

        return obj[quarter].end;
    }

    _convertToTitleCase = (str) => {
        str = str.toLowerCase();
        return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
    }

    componentDidUpdate() { }

    render() {

        const quarterSubheaderTitle = "Q" + parseInt(this.props.currentQuarter.quarter.split("").reverse())

        return (

            <div className="layout vertical full-height">
                <Dialog
                    open={this.state.openApprove}
                    onClose={() => this.handleClose("openApprove")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div style={{ maxWidth: "320px" }}>
                        <DialogTitle id="alert-dialog-title">Approve extension</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                You are about to <b>approve</b> an extension for <b> {this._convertToTitleCase(this.props.currentExtension && this.props.currentExtension.group)} </b> County. This will also remove their extension request from the list and cannot be undone. Are you sure you want to do this? 
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.handleClose("openApprove")} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => this.approveExtensionFinancialReimbursement()} color="primary" autoFocus>
                                Approve
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.goBack}
                        >
                            <MdClear className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Extensions</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}> {this.props.currentExtension && this.props.currentExtension.group} • {quarterSubheaderTitle}  • {this.props.selectedYear}</span>}
                />

                <div className="flex form-shell container-overflow-y">

                    <div className="layout horizontal center section-title form-margin">Entry Details</div>
                    <div className="layout horizontal wrap">
                        <NormalTextField value={this._convertToTitleCase(this.props.currentExtension && this.props.currentExtension.group)} id="groupName" label="County name" className="flex" InputProps={{ readOnly: true, }}  custommargin="true" customtbmargin="true" InputLabelProps={{ shrink: true, }} />

                        <NormalTextField value={quarterSubheaderTitle} id="extensionQuarter" label="Quarter" className="flex" InputProps={{ readOnly: true, }}  custommargin="true" customtbmargin="true" InputLabelProps={{ shrink: true, }} />

                        <NormalTextField value={this.getType(this.props.currentExtension && this.props.currentExtension.for)} id="narrativeFrequency" label="Type" className="flex" InputProps={{ readOnly: true, }} custommargin="true" customtbmargin="true" InputLabelProps={{ shrink: true, }} />
                    </div>

                    <div className="layout horizontal center section-title form-margin">Extension Details</div>
                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.dueDateDisplay} id="extensionProposedDueDate" label="Proposed due date" className="flex" InputProps={{ readOnly: true, }} custommargin="true" customtbmargin="true" InputLabelProps={{ shrink: true, }} />

                        <NormalTextField value={this.getQuarterEndDate(this.props.currentQuarter && this.props.currentQuarter.quarter)} id="extensionInitialDueDate" label="Initial due date" className="flex" InputProps={{ readOnly: true, }} custommargin="true" customtbmargin="true" InputLabelProps={{ shrink: true, }} />
                    </div>
                    <div className="layout horizontal wrap">
                        <NormalTextField value={this.state.reasonForExtension} id="extensionReason" label="Reason" className="flex" InputProps={{ readOnly: true, }} custommargin="true" customtbmargin="true" InputLabelProps={{ shrink: true, }} />
                    </div>

                </div>
                
                <div className="layout horizontal center-justified form-container " style={{ borderTop: "1px solid #E0E0E0" }}>
                    <div className="form-margin">

                        {/* Financial reimbursement - Adjust */}
                        {/* Progress Reporting - BY_DATE - Adjust */}
                        {/* Progress Reporting - BY_QUARTER - Decline */}
                        
                        
                        <SecondaryButton setwidth="true" onClick={() => this.adjustExtension()}>
                            Adjust
                        </SecondaryButton>
                    </div>
                    <div className="form-margin">
                        <PrimaryButton setwidth="true" onClick={() => this.openDialog("openApprove")}>
                            Approve
                        </PrimaryButton>
                    </div>
                    
                </div>


            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReimbursementExtensionsView);
