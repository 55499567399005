import React from 'react';
import { connect } from 'react-redux'
import { DualTitleHeaderComponent } from "../layout/HeaderComponent.js"
import IconButton from "@mui/material/IconButton";
import { MdMenu, MdAccessTime, MdMoreVert } from 'react-icons/md';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//  Redux import
import {  printGrantApplicationForm } from "../../redux/actions/index";


const mapStateToProps = state => {
    return {
        lempgUserType: state.rootReducer.lempgUserType,
        grantApplication: state.rootReducer.grantApplication,
        currentApplicationState: state.rootReducer.currentApplicationState,
        selectedYear: state.rootReducer.selectedYear
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        printGrantApplicationForm: (payload) => dispatch(printGrantApplicationForm(payload)),        
    }
}

class ApplicationSubmitted extends React.Component {

    state = {
        errors: [],
        anchorEl: null,
    }


    constructor(props, context) {
        super(props, context);
        this.printGrantApplicationForm = this.printGrantApplicationForm.bind(this);
    }

    handleIconDropdownClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    printGrantApplicationForm() {
        this.handleClose();
        this.props.printGrantApplicationForm(this.props.currentApplicationState)
    }


    render() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div className="layout vertical full-height">

                <DualTitleHeaderComponent
                    icon={
                        <IconButton
                            aria-label="close"
                            onClick={this.handleMenuClick}
                        >
                            <MdMenu className="icon" />
                        </IconButton>

                    }
                    titleBig={<span>Application</span>}
                    titleSmall={<span style={{ textTransform: "capitalize" }}>LEMPG {this.props.selectedYear} • {this.props.grantApplication.group && this.props.grantApplication.group.groupName}</span>}
                    suffix={
                        <div>
                            <IconButton
                                aria-label="More"
                                aria-haspopup="true"
                                aria-owns={open ? 'application-menu' : undefined}
                                onClick={this.handleIconDropdownClick}
                            >
                                <MdMoreVert className="icon" />
                            </IconButton>

                            <Menu
                                id="application-menu"
                                // anchorEl={anchorEl}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={open}
                                onClose={this.handleClose}
                            >
                                <MenuItem key="printApplicationForm" onClick={() => this.printGrantApplicationForm()}>
                                    Print Grant Application Form
                                    </MenuItem>
                            </Menu>
                        </div>

                    }                
                />

                <div className="flex layout vertical center vertical-center container-overflow-y splash-screen">
                    
                    <MdAccessTime className="icon-2 application-status-icon" />
                    <div className="height-20"></div>
                    <span className="layout horizontal center center-justified application-status-title-small">Submitted</span>
                    <div className="height-20"></div>

                    <span style={{ padding: "0 16px", maxWidth: "280px" }} className="text-center title-big opacity-54">Your application has been sent to the state official for review. Please check back again later for an update.</span>
                    
                </div>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationSubmitted);
